import { Box } from '@mui/material'

// This is the tab styling

interface TabPanelProps {
	children?: React.ReactNode
	dir?: string
	index: number
	value: number
}

// Tab Panel - Tab content styling
export function StyledTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}>
			{value === index && (
				<Box
					className='tab-panel'
					sx={{
						padding: '30px 0',
					}}>
					{children}
				</Box>
			)}
		</Box>
	)
}
