import { ComingSoon } from '../../components/shared/displays/ComingSoon/ComingSoon'

function KnowledgeBase() {
	return (
		<>
			<ComingSoon />
		</>
	)
}

export default KnowledgeBase
