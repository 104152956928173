import { Box, IconButton, Typography, useTheme } from '@mui/material'
import { FC, ReactNode } from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { LoadingButton } from '@mui/lab'
import './ModalForm.scss'

// Custom interface
interface ModalFormProps {
	icon: ReactNode
	title: string
	description: string
	children: ReactNode // This form content
	buttonText?: string
	buttonAction?: () => void
	buttonLoading?: boolean
	buttonDisabled?: boolean
	closeModal: () => void
}

// Custom styling for modal form
export const ModalForm: FC<ModalFormProps> = ({
	icon,
	title,
	description,
	children,
	buttonText,
	buttonAction,
	buttonLoading,
	buttonDisabled,
	closeModal,
}) => {
	// Theme
	const theme = useTheme()

	return (
		<Box className='modal-form-container'>
			{/* Header */}
			<Box className='modal-form-header'>
				{/* Icon and Close Icon Flex */}
				<Box className='icon'>
					<Box
						className='main'
						sx={{
							color: theme.palette.primary.main,
						}}>
						{icon}
					</Box>
					<IconButton className='close' onClick={closeModal}>
						<CloseOutlinedIcon />
					</IconButton>
				</Box>
				{/* Title and description */}
				<Box className='title'>
					<Typography component='h1'>{title}</Typography>
					<Typography component='p'>{description}</Typography>
				</Box>
			</Box>
			{/* Form Content */}
			<Box className='modal-form-content'>{children}</Box>
			{/* Button */}
			{buttonText && buttonAction && (
				<Box className='modal-form-button'>
					<LoadingButton
						disabled={buttonDisabled}
						loading={buttonLoading}
						variant='contained'
						onClick={buttonAction}>
						{buttonText}
					</LoadingButton>
				</Box>
			)}
		</Box>
	)
}
