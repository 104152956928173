import { Box, Typography, useTheme } from '@mui/material'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import DoDisturbOutlinedIcon from '@mui/icons-material/DoDisturbOutlined'

const BaseLicenseTip = () => {
	// Theme
	const theme = useTheme()

	return (
		<>
			<Box className='tum-tooltip-container'>
				{/* Heading */}
				<Box className='tooltip-title'>
					{/* Heading */}
					<Typography component='h5'>Base License Information</Typography>
					{/* Description */}
					<Typography component='p'>
						You can view your list of base licenses by clicking on the{' '}
						<strong>arrow button</strong> on the overview.
					</Typography>
				</Box>
				{/* Legend */}
				<Box className='tooltip-legend-container'>
					{/* Text */}
					<Typography component='p'>
						Below are the unique identifiers on the license dropdown:
					</Typography>
					{/* Legend 1 - Green Icon */}
					<Box className='tooltip-legend'>
						{/* Icon */}
						<CheckCircleOutlinedIcon
							sx={{ color: theme.palette.success.main }}
						/>
						{/* Text */}
						<Typography component='p'>
							This states the base license does not require an add-on and is
							available to be assigned.
						</Typography>
					</Box>
					{/* Legend 2 - Warning Icon */}
					<Box className='tooltip-legend'>
						{/* Icon */}
						<RemoveCircleOutlineOutlinedIcon color='warning' />
						{/* Text */}
						<Typography component='p'>
							This states the base license REQUIRES an add-on and is available
							to be assigned. Once selected, you will need to also select a
							valid add-on license.
						</Typography>
					</Box>
					{/* Legend 3 - Red Icon */}
					<Box className='tooltip-legend'>
						{/* Icon */}
						<DoDisturbOutlinedIcon sx={{ color: theme.palette.error.main }} />
						{/* Text */}
						<Typography component='p'>
							This states that this license is not available to be assigned.
						</Typography>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default BaseLicenseTip
