import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import './LogoUpload.scss'
import { useRef, useState } from 'react'
import ImageIcon from '@mui/icons-material/Image'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'

const LogoUpload = ({ handleLogoChange }: { handleLogoChange: any }) => {
	// Display constants
	const [imageURL, setImageURL] = useState('')
	const [fileName, setFileName] = useState('Default Logo')
	const [logoError, setLogoError] = useState(false)

	// Flags
	const fileInputRef = useRef<HTMLInputElement | null>(null)

	// Handle the click of imagebox
	const handleImageBoxClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click()
		}
	}

	// Handle Function - File upload
	const handleFileUpload = (e: { target: any }) => {
		// Error handling
		setLogoError(false)

		// Get file uploaded
		const file = e.target.files[0]

		if (file) {
			// Check if the file type starts with "image/"
			if (file.type.startsWith('image/')) {
				// Set the new image and file name
				setFileName(file.name)
				setImageURL(URL.createObjectURL(file))
				// Set the file to be uploaded - Send this back to parent
				handleLogoChange(file)
			} else {
				// Show an error message or handle the invalid file type as needed
				setLogoError(true)
			}
		}
	}

	// Handle Function - Remove uploaded file
	const handleRemoveUpload = () => {
		// Reset the input value to clear any cached file data
		if (fileInputRef.current) {
			fileInputRef.current.value = ''
		}
		setFileName('Default Logo')
		setImageURL('')
		handleLogoChange(null)
	}

	return (
		<>
			{/* Image upload input */}
			<Box
				component='form'
				className='image-upload-container'
				onClick={handleImageBoxClick}>
				<input
					type='file'
					accept='image/*'
					className='image-uploader'
					ref={fileInputRef}
					onChange={handleFileUpload}
					hidden
				/>
				{/* Show image - If not default, then display chosen image */}
				{fileName === 'Default Logo' ? (
					<Box className='default-logo'>
						<img
							src={require('../../../../assets/images/generic/logo/halo-logo.png')}
							alt={fileName}
							height={50}
						/>
						<Typography component='p'>
							Please see above the default logo of the site. Browse files on
							your PC to upload.
						</Typography>
					</Box>
				) : (
					<Box className='logo-upload'>
						<img src={imageURL} alt={fileName} />
					</Box>
				)}
			</Box>
			{/* The image name and delete button */}
			<Box className='image-details-container'>
				{/* Icon */}
				<ImageIcon />
				{/* Image name and delete */}
				<Box className='image-details'>
					<Typography component='span'>{fileName}</Typography>
					{/* Delete Icon - Show if file name changed */}
					{fileName !== '' && fileName !== 'Default Logo' && (
						<Tooltip title='Remove uploaded logo'>
							<IconButton onClick={handleRemoveUpload}>
								<DeleteOutlineOutlinedIcon
									sx={{
										color: 'error.main',
									}}
								/>
							</IconButton>
						</Tooltip>
					)}
				</Box>
			</Box>
			{/* Error */}
			{logoError && (
				<Box className='error'>
					<Typography component='p' color='error.main'>
						Invalid file type. Please upload an image.
					</Typography>
				</Box>
			)}
		</>
	)
}

export default LogoUpload
