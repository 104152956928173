import { useEffect, useState } from 'react'
import UseCustomBackendCall from '../../../utils/customHooks/APICalls/UseCustomBackendCall'
import { toBetaString } from '../../../utils/helperFunctions/helperFunctions'
import {
	LeadSize,
	LeadType,
	LeadWeight,
} from '../../../utils/interfaces/DBModels'
import {
	LeadContactInfoDisplay,
	LeadListDisplay,
} from '../../../utils/interfaces/ComponentModels'
import './LeadManagement.scss'
import LeadDisplay from '../../../components/distinct/management/LeadManagement/LeadDisplay'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import UseCrud from '../../../utils/customHooks/APICalls/UseCrud'
import { OCLeadStatus, ReturnTypes } from '../../../utils/enums/enums'
import {
	DataResponse,
	LeadsResponse,
} from '../../../utils/interfaces/APIModels'
import LoadingBox from '../../../components/shared/displays/LoadingBox/LoadingBox'

function LeadManagement() {
	const { fetchCustomData } = UseCustomBackendCall()

	// Hooks
	const { fetchData } = UseCrud()

	const [isPageLoadingDone, setIsPageLoadingDone] = useState(false)

	// variables
	const [partnerLeads, setPartnerLeads] = useState([] as LeadListDisplay[])
	const [leadTypes, setLeadTypes] = useState([] as LeadType[])
	const [leadSizes, setLeadSizes] = useState([] as LeadSize[])
	const [leadWeights, setLeadWeights] = useState([] as LeadWeight[])
	var partnerID = useSelector(
		(state: RootState) => state.RootReducer.partnerIDReducer.value
	)

	useEffect(() => {
		if (!isPageLoadingDone) {
			getLeadTypesAndSizes()
			getLeads()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPageLoadingDone])

	const getLeads = async () => {
		setIsPageLoadingDone(false)
		var dataResponseResults = await fetchCustomData<LeadsResponse[]>({
			QueryURL: `GetLeadsByPartner?partnerID=${toBetaString(
				partnerID.toString()
			)}`,
			QueryObj: null,
			ErrorMessage: 'An error occurred when getting users from auth0',
			ShowErrorToast: false,
			ShowSuccessToast: false,
			LogErrorToDB: true,
			FileAndFunctionName: 'PortalUserManagement.tsx Generic Api call',
		})
		var dataResponse = dataResponseResults ?? []
		if (dataResponse.length > 0) {
			var _partnerLeadList = [] as LeadListDisplay[]
			for (var i = 0; i < dataResponse.length; i++) {
				var _leadContactInfoList = [] as LeadContactInfoDisplay[]
				var _leadContactDetails = dataResponse[i].LeadContactDetails ?? []
				for (var j = 0; j < _leadContactDetails.length; j++) {
					var _contactInfo: LeadContactInfoDisplay = {
						Email: _leadContactDetails[j].Email ?? '',
						PhoneNumber: _leadContactDetails[j].PhoneNumber ?? '',
						ContactName: _leadContactDetails[j].Name ?? '',
					}
					_leadContactInfoList.push(_contactInfo)
				}
				var _leadObj: LeadListDisplay = {
					LeadID: Number(dataResponse[i].LeadID),
					CompanyName: dataResponse[i].CompanyName,
					DateCreated: dataResponse[i].DateCreated,
					ExternalSalesAgent: dataResponse[i].ExternalSalesAgent ?? '',
					LeadSize: dataResponse[i].LeadSize ?? '',
					LeadStatus: dataResponse[i].LeadStatus ?? '',
					LeadType: dataResponse[i].LeadType ?? '',
					OCLeadStatusID:
						getOCLeadStatusValue(dataResponse[i].OperatorConnectStatus) ?? 0,
					OCLeadStatus: (dataResponse[i].OperatorConnectStatus ?? '')
						.replace(/([A-Z])/g, ' $1')
						.trim(),
					Locations: dataResponse[i].Locations ?? '',
					TenantID: dataResponse[i].TenantID ?? '',
					LeadContactInfoDisplay: _leadContactInfoList,
					OCConsentStatus: dataResponse[i].Status ?? '',
					Stage: dataResponse[i].LeadStage ?? '',
				}
				_partnerLeadList.push(_leadObj)
			}
			setPartnerLeads(_partnerLeadList)
		}
		setIsPageLoadingDone(true)
	}

	function getOCLeadStatusValue(statusString: string): number | undefined {
		const statusEnumValue =
			OCLeadStatus[statusString as keyof typeof OCLeadStatus]
		return statusEnumValue
	}

	// GET: Return lead types and sizes
	const getLeadTypesAndSizes = async () => {
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'LeadManagement.tsx: getLeadTypesAndSizes()',
			QueryURL: 'GetV2?Params=LeadType.All(),LeadSize.All(),LeadWeight.All()',
			ErrorMessage: 'An error occurred when getting lead information',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse

		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var leadTypeList = dataResponse.Obj.LeadTypeList as LeadType[]
			var leadSizeList = dataResponse.Obj.LeadSizeList as LeadSize[]
			var leadWeightList = dataResponse.Obj.LeadWeightList as LeadWeight[]

			if (leadTypeList && leadTypeList.length > 0) {
				setLeadTypes(leadTypeList)
			}

			if (leadSizeList && leadSizeList.length > 0) {
				setLeadSizes(leadSizeList)
			}

			if (leadWeightList && leadWeightList.length > 0) {
				setLeadWeights(leadWeightList)
			}
		}
	}

	return isPageLoadingDone ? (
		<LeadDisplay
			getPartnerLeadsList={getLeads}
			partnerLeadList={partnerLeads}
			leadTypeList={leadTypes}
			leadSizeList={leadSizes}
			leadWeightList={leadWeights}
		/>
	) : (
		// Show loading
		<LoadingBox title='Getting Leads' />
	)
}

export default LeadManagement
