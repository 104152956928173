import { useEffect, useState } from 'react'
import { StyledDataGrid } from '../../../../styles/styledComponents/displays/StyledDataGrid'
import { Invitation } from '../../../../utils/interfaces/APIModels'
import {
	GridColDef,
	GridToolbarContainer,
	GridToolbarQuickFilter,
} from '@mui/x-data-grid'
import Refresh from '@mui/icons-material/Refresh'
import { Chip, IconButton, Modal } from '@mui/material'
import { InvitationListDisplay } from '../../../../utils/interfaces/ComponentModels'
import { StyledButton } from '../../../../styles/styledComponents/inputs/StyledButton'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { Roles } from '../../../../utils/enums/enums'
import ResendInvite from './Modals/ResendInvite'
import StyledModal from '../../../../styles/styledComponents/displays/StyledModal'

function Invites({
	invitationList,
	status,
	handleSendInvite,
	getInvites,
	loading,
}: {
	invitationList: Invitation[]
	status: string
	handleSendInvite: (email: string, customerID: string, role: string) => void
	getInvites: () => void
	loading: boolean
}) {
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 5,
		page: 0,
	})

	const [openModal, setOpenModal] = useState(false)
	const [sendingInvite, setSendingInvite] = useState(false)
	const [invite, setInvite] = useState({} as Invitation)
	const [invitationDisplayList, setInvitationDisplayList] = useState(
		[] as InvitationListDisplay[]
	)
	const roleID = useSelector(
		(state: RootState) => state.RootReducer.roleIDReducer.value
	)
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const isCustomerAdmin = roleID === Roles.CustomerAdmin
	const customerRoleID = process.env.REACT_APP_CUSTOMER_ADMIN_ROLE_ID + ''
	const partnerRoleID = process.env.REACT_APP_PARTNER_ADMIN_ROLE_ID + ''
	const pendingStatus = 'Pending'

	const handleCloseModal = () => {
		setOpenModal(false)
	}
	function customToolBar() {
		return (
			<>
				<GridToolbarContainer>
					{/*  an empty div to push the filter to the right */}
					<GridToolbarQuickFilter sx={{ marginBottom: '1rem' }} />
					<div style={{ flex: 1 }}></div>{' '}
					<IconButton
						onClick={() => {
							getInvites()
						}}>
						<Refresh />
					</IconButton>
				</GridToolbarContainer>
			</>
		)
	}
	const invitationsColoumns: GridColDef[] = [
		{
			field: 'InvitationEmail',
			headerName: 'Email',
			width: 300,
			hideable: false,
		},
		{
			field: 'InvitationStatus',
			headerName: 'Status',
			width: 100,
			hideable: false,
			renderCell: (params) => {
				var pending = params.row.InvitationStatus === pendingStatus
				return (
					<Chip
						label={params.value}
						variant='outlined'
						color={pending ? 'success' : 'error'}
					/>
				)
			},
		},
		{
			field: 'InvitationRole',
			headerName: 'Admin',
			width: 150,
			hideable: true,
		},

		{
			field: 'InvitationInviter',
			headerName: 'Inviter',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'actions',
			type: 'actions',
			cellClassName: 'actions',
			width: 220,
			editable: false,
			align: 'center',
			hideable: false,
			flex: 1,
			getActions: ({ id }) => {
				return [
					<>
						<StyledButton
							onClick={async () => {
								var email = id.toString()
								var invite = invitationList.find(
									(i) => i.invitedEmail === email
								) as Invitation
								setInvite(invite)
								setOpenModal(true)
							}}
							variant='contained'>
							resend Invite
						</StyledButton>
					</>,
				]
			},
		},
	]
	const handleResendInvite = async (invite: Invitation) => {
		setSendingInvite(true)
		await handleSendInvite(
			invite?.invitedEmail + '',
			invite?.customerID + '',
			invite?.role + ''
		)
		setSendingInvite(false)
	}
	useEffect(() => {
		if (invitationList.length > 0) {
			var _invitationDisplayList = [] as InvitationListDisplay[]
			var _invitationList = invitationList

			_invitationList = invitationList.filter((i) => i.status === status)
			if (isCustomerAdmin) {
				_invitationList = _invitationList.filter(
					(i) =>
						i.customerID === loggedInUser.customerID &&
						i.role === customerRoleID
				)
			}
			_invitationList.forEach((i) => {
				var _email = i.invitedEmail
				var _inviter = i.inviterEmail
				var _invitationDisplayListObj: InvitationListDisplay = {
					InvitationEmail: _email,
					InvitationInviter: _inviter,
					InvitationStatus: i.status,
					InvitationRole:
						i.role === partnerRoleID
							? 'Partner'
							: i.role === customerRoleID
							? 'Customer'
							: 'User has no role',
				}

				_invitationDisplayList.push(_invitationDisplayListObj)
			})

			setInvitationDisplayList(_invitationDisplayList)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invitationList])
	return (
		<>
			<StyledDataGrid
				getRowId={(row) => row.InvitationEmail}
				autoHeight
				rowSelection
				loading={loading || sendingInvite}
				pagination
				paginationModel={paginationModel}
				onPaginationModelChange={setPaginationModel}
				pageSizeOptions={[10]}
				slots={{
					toolbar: customToolBar,
				}}
				columns={invitationsColoumns}
				rows={invitationDisplayList}
			/>
			<Modal open={openModal}>
				<StyledModal width={544}>
					<ResendInvite
						invite={invite}
						handleResendInvite={handleResendInvite}
						cancel={handleCloseModal}
					/>
				</StyledModal>
			</Modal>
		</>
	)
}

export default Invites
