import { Box, CircularProgress, Typography } from '@mui/material'
import './CustomLoaderWithIcon.scss'

const CustomLoaderWithIcon = ({
	icon,
	progressValue,
	error,
}: {
	icon: any
	progressValue: number
	error: boolean
}) => {
	return (
		<Box
			position='relative'
			display='inline-flex'
			className='loading-icon-container'>
			<CircularProgress
				variant='determinate'
				value={progressValue}
				color={error ? 'error' : 'primary'}
				size={100}
			/>
			<Box
				top={0}
				left={0}
				bottom={0}
				right={0}
				position='absolute'
				display='flex'
				alignItems='center'
				justifyContent='center'>
				<Typography
					className={error ? 'loading-icon' : 'loading-icon shimmer'}
					variant='caption'
					component='div'
					color={error ? 'error' : 'primary'}
					lineHeight={1}>
					{icon}
				</Typography>
			</Box>
		</Box>
	)
}

export default CustomLoaderWithIcon
