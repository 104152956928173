import { Alert, Box, Divider, Modal, Typography } from '@mui/material'
import './Login.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { StyledButton } from '../../styles/styledComponents/inputs/StyledButton'
import { StyledLinkButton } from '../../styles/styledComponents/inputs/StyledLinkButton'
import { useAuth0 } from '@auth0/auth0-react'
import StyledModal from '../../styles/styledComponents/displays/StyledModal'
import { useState } from 'react'
import Preloader from '../../components/shared/displays/Preloader/Preloader'

// Login Page - If user is not logged in, it will redirect to this page and do the auth0 login page
function Login({ errorMessage }: { errorMessage: string }) {
	// Global variables
	const logoURL = useSelector(
		(state: RootState) => state.RootReducer.logoReducer.value
	)

	// Auth0
	const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0()

	// Flag
	const [openFAQModal, setOpenFAQModal] = useState(false)

	// Handle Functions
	const handleOpenModal = () => {
		setOpenFAQModal(true)
	}

	const handleCloseModal = () => {
		setOpenFAQModal(false)
	}

	return !isAuthenticated && !isLoading ? (
		<>
			{/* Main Login Container */}
			<Box id='login' className='login-container'>
				{/* Login Box */}
				<Box className='login-content'>
					{/* Logo - Get from state */}
					{logoURL.length > 0 ? (
						<img src={logoURL} alt='HALO Logo' />
					) : (
						<img
							src={require('../../assets/images/generic/logo/halo-logo.png')}
							alt='HALO Logo'
						/>
					)}
					{/* 
					{/* Text */}
					<Box className='login-text'>
						{/* Heading */}
						<Typography component='h1'>Login to UC Platform</Typography>
						{/* Description */}
						<Typography component='p'>
							Click on the button below to login to the UC Platform.
						</Typography>
						{errorMessage.length > 0 && (
							<Alert severity='error' sx={{ maxWidth: 'max-content' }}>
								{errorMessage}
							</Alert>
						)}
					</Box>
					{/* Button */}
					<StyledButton onClick={() => loginWithRedirect()}>Login</StyledButton>
					{/* Divider */}
					<Divider flexItem />
					{/* FAQ */}
					<Box className='login-text'>
						<Typography>
							New user and having issues with the invite? Click the link below:
						</Typography>
						<StyledLinkButton onClick={handleOpenModal}>
							What should happen when you accept the invite?
						</StyledLinkButton>
					</Box>
				</Box>
			</Box>
			{/* Modal - Email FAQ */}
			<Modal open={openFAQModal} onClose={handleCloseModal}>
				<StyledModal width={500}>
					<Box className='faq'>
						{/* Question - What will happen when you accept this invite? */}
						<Typography component='ul'>
							<Typography component='li'>
								When you accept the invitation link below, you will get{' '}
								<b>access</b> to the <b>UC Platform.</b>
							</Typography>
							<Typography component='li'>
								Logons to the platform use a static username and password, you
								create during the invitation process.
							</Typography>
							<Typography component='li'>
								The account accessing this platform needs no administrative
								rights. If an action is performed in the platform that requires
								rights, you will be prompted to enter the account details at
								that time.
							</Typography>
						</Typography>
						<br></br>
						<Typography component='h4'>Sign Up:</Typography>
						<Typography>
							To begin the process of accessing the UC Platform you will receive
							an invitation via email
						</Typography>
						<Typography component='ol'>
							<Typography component='li'>
								Press the <b>"Accept"</b> button at the bottom of this
								invitation
							</Typography>

							<Typography>
								You will then be redirected to a signup page, in which you will
								set a password for your account on UC Platform.
							</Typography>
							<Typography>
								The password you set must meet the minimum complexity
								requirements
							</Typography>

							<Typography component='li'>
								Fill in a password in the Password box then press the{' '}
								<b>"Continue"</b> button
							</Typography>
						</Typography>
						<Typography>The sign up process is now complete</Typography>
						{/* insert img here */}
						<br></br>
						<Typography component='h4'>Password Reset:</Typography>
						<Typography>
							In the event that you have forgotten your password to access
							ucplatform.io please follow the simple steps below to reset your
							password
						</Typography>
						<Typography component='ol'>
							<Typography component='li'>
								Press the <b>“Forgot password?”</b> located just below the
								password box
							</Typography>
							<Typography component='li'>
								Enter the email address associated with your account, this will
								trigger a forgot password email to that account. Then press the{' '}
								<b>"Continue"</b> button
							</Typography>
							<Typography component='li'>
								You should now receive an email with the link to reset your
								password
							</Typography>
							<Typography component='li'>
								Press the <b>“Change Password”</b> button in the email
							</Typography>
							<Typography component='li'>
								Enter your new password twice and then press the{' '}
								<b>“Reset Password”</b> button
							</Typography>
						</Typography>
						<Typography>Your password reset is now complete</Typography>
					</Box>
				</StyledModal>
			</Modal>
		</>
	) : (
		<Preloader />
	)
}

export default Login
