import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import LoadingBox from '../../displays/LoadingBox/LoadingBox'
import UseCrud from '../../../../utils/customHooks/APICalls/UseCrud'
import { DataResponse } from '../../../../utils/interfaces/APIModels'
import { ReturnTypes } from '../../../../utils/enums/enums'
import {
	Address,
	AddressType,
	Country,
	State,
} from '../../../../utils/interfaces/DBModels'
import { AddressDisplay } from '../../../../utils/interfaces/ComponentModels'
import AddressOverviewDisplay from './AddressOverviewDisplay/AddressOverviewDisplay'

const AddressOverview = () => {
	const [apiCallsDone, setAPICallsDone] = useState(false)

	// Flags
	const [addressCallMade] = useState(false)

	//display lists
	const [addressDisplay, setAddressDisplay] = useState([] as AddressDisplay[])
	const [stateList, setStateList] = useState([] as State[])
	const [countryList, setCountryList] = useState([] as Country[])

	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	// Hooks
	const { fetchData } = UseCrud()

	useEffect(() => {
		if (!addressCallMade) {
			getAddresses()
		}
	}, [addressCallMade])

	/* GET: all the existing addresses for that customer */
	const getAddresses = async () => {
		var requestURL = `GetV2?Params=Address.AddressMap.Where(AddressMap.CustomerID='${loggedInUser.customerID}'), AddressType.All(), State.Where(State.IsActive = 'true'), Country.Where(Country.IsActive = 'true') `
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'AddressOverview.tsx: getAddresses()',
			QueryURL: requestURL,
			ErrorMessage: `An error occurred while trying to retrieve the customer's existing address information`,
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse

		if (Number(dataResponse.Count) > 0 || dataResponse.Obj) {
			var addressList = dataResponse.Obj.AddressList as Address[]
			var addressTypeList = dataResponse.Obj.AddressTypeList as AddressType[]
			var stateList = dataResponse.Obj.StateList as State[]
			var countryList = dataResponse.Obj.CountryList as Country[]

			//if they have existing addresses build display list for summary table
			if (addressList && addressList.length > 0 && addressList !== undefined) {
				var addressDisplay = [] as AddressDisplay[]

				for (var i = 0; i < addressList.length; i++) {
					var addressMapList = addressList[i].AddressMapList

					if (addressMapList) {
						for (var z = 0; z < addressMapList?.length; z++) {
							var addressName = addressMapList[z]?.AddressName

							var addressType = addressTypeList.find(
								(x) => x.AddressTypeID === addressList[i].AddressTypeID
							)?.AddressType

							var stateObj = stateList.find(
								(x) => x.StateID === addressList[i].StateID
							)

							var stateName = stateObj?.StateName

							var countryName = countryList.find(
								(x) => x.CountryID === stateObj?.CountryID
							)?.CountryName

							addressDisplay.push({
								AddressMapID: addressMapList[z].AddressMapID,
								AddressID: addressList[i].AddressID,
								AddressName: addressName,
								AddressType: addressType,
								HouseNumber: addressList[i]?.HouseNumber,
								AddressLine1: addressList[i].AddressLine1,
								AddressLine2: addressList[i].AddressLine2,
								City: addressList[i].City,
								State: stateName,
								Country: countryName,
								PostalCode: addressList[i].PostalCode,
							})

							setAddressDisplay(addressDisplay)
						}
					}
				}

				setAddressDisplay(addressDisplay)
			}

			setStateList(stateList)
			setCountryList(countryList)
		}

		setAPICallsDone(true)
	}

	return apiCallsDone ? (
		<>
			<AddressOverviewDisplay
				addressDisplay={addressDisplay}
				stateList={stateList}
				countryList={countryList}
				getAddresses={getAddresses}
			/>
		</>
	) : (
		<LoadingBox title='Getting Addresses' />
	)
}

export default AddressOverview
