import { styled, Alert } from '@mui/material'

//Info Styling for description of Details
//note this does not include theme colours
export const StyledAlert = styled(Alert)(({ theme }) => ({
	'& .MuiAlert-icon': {
		color: theme.palette.primary.main,
	},
	backgroundColor: '#fff',
	color: 'black',
	fontSize: '14px',
}))
