import { Box, Divider, Radio, Typography } from '@mui/material'
import { AddressDisplay } from '../../../../../utils/interfaces/ComponentModels'
import { useEffect, useState } from 'react'
import {
	CustomPagination,
	StyledDataGrid,
} from '../../../../../styles/styledComponents/displays/StyledDataGrid'
import { GridColDef } from '@mui/x-data-grid-pro'
import AddUpdateAddressForm from './AddAddressForm/AddUpdateAddressForm'
import { Country, State } from '../../../../../utils/interfaces/DBModels'

const AddressOverviewDisplay = ({
	addressDisplay,
	stateList,
	countryList,
	getAddresses,
}: {
	addressDisplay: AddressDisplay[]
	stateList: State[]
	countryList: Country[]
	getAddresses: any
}) => {
	const [addressRows, setAddressRows] = useState([] as AddressDisplay[])
	const [editAddress, setEditAddress] = useState(false)
	const [selectedRow, setSelectedRow] = useState(null)

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 5,
		page: 0,
	})

	useEffect(() => {
		if (addressDisplay) {
			setAddressRows(addressDisplay)
		}
	}, [addressDisplay])

	const handleEditAddress = (rowObj: any) => {
		setSelectedRow(rowObj)
		setEditAddress(true)
	}

	const clearSelectedRow = () => {
		setSelectedRow(null)
		setEditAddress(false)
	}

	//handle check and unchecked state
	const handleCheckedState = (rowObj: any) => {
		if (rowObj === rowObj) {
			clearSelectedRow()
		} else {
			setSelectedRow(rowObj)
			setEditAddress(true)
		}
	}

	const columns: GridColDef[] = [
		{
			field: 'AddressName',
			headerName: 'Address Name',
			flex: 2,
		},
		{
			field: 'AddressType',
			headerName: 'Address type',
			flex: 1,
		},
		{
			field: 'HouseNumber',
			headerName: 'House Number',
			flex: 1,
		},
		{
			field: 'AddressLine1',
			headerName: 'Address Line 1',
			flex: 1,
		},
		{
			field: 'AddressLine2',
			headerName: 'Address Line 2',
			flex: 1,
		},
		{
			field: 'City',
			headerName: 'City',
			flex: 1,
		},
		{
			field: 'State',
			headerName: 'State',
			flex: 1,
		},
		{
			field: 'Country',
			headerName: 'Country',
			flex: 1,
		},
		{
			field: 'PostalCode',
			headerName: 'Postal code',
			flex: 1,
		},
		{
			field: 'Edit',
			headerName: 'Edit',
			renderCell: (params) => (
				<>
					<Box>
						<Radio
							onChange={() => handleEditAddress(params.row)}
							onClick={() => handleCheckedState(params.row)}
							checked={selectedRow === params.row}
						/>
					</Box>
				</>
			),
		},
	]

	return (
		<>
			<Box>
				<Box sx={{ paddingBottom: '10px' }}>
					<Typography component='h2'>Manage customer addresses</Typography>
				</Box>

				<Box className='description'>
					<Typography component='p'>
						You can manage customer addresses here.
					</Typography>
				</Box>
				<Divider />
			</Box>

			{/* Add/Update Address Form */}
			<Box className='address-form'>
				<AddUpdateAddressForm
					stateList={stateList}
					countryList={countryList}
					editAddress={editAddress}
					selectedRow={selectedRow}
					getAddresses={getAddresses}
					clearSelectedRow={clearSelectedRow}
				/>
			</Box>

			{/*  Address Summary Table*/}
			<Box>
				<Box sx={{ paddingBottom: '20px' }}>
					<Typography component='h2'>Customer Address Summary</Typography>
				</Box>

				<StyledDataGrid
					rows={addressRows}
					columns={columns}
					getRowId={(row) => row.AddressID}
					autoHeight
					pagination
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					pageSizeOptions={[7]}
					slots={{
						pagination: CustomPagination,
					}}
				/>
			</Box>
		</>
	)
}

export default AddressOverviewDisplay
