import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { useEffect, useState } from 'react'
import UseCrud from '../../../utils/customHooks/APICalls/UseCrud'
import LoadingBox from '../../../components/shared/displays/LoadingBox/LoadingBox'
import { ReturnTypes } from '../../../utils/enums/enums'
import { DataResponse } from '../../../utils/interfaces/APIModels'
import {
	SKUPartnerMap,
	Range,
	Currency,
	FXRate,
	CustomerPartner,
} from '../../../utils/interfaces/DBModels'
import { Box, Tab, useTheme, Divider } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import PriceBooksOverview from '../../../components/shared/pageShared/PriceBooksOverview/PriceBooksOverview'
import FXRatesOverview from '../../../components/shared/pageShared/FXRatesOverview/FXRatesOverview'
import { FXConversionRateDisplay } from '../../../utils/interfaces/ComponentModels'
import moment from 'moment'
import { hexToRgba } from '../../../utils/helperFunctions/helperFunctions'
import EmptyDisplay from '../../../components/shared/displays/EmptyDisplay/EmptyDisplay'

function PriceBookManagement() {
	// Global variables
	const partnerID = useSelector(
		(state: RootState) => state.RootReducer.partnerIDReducer.value
	)

	//Tab Panels
	const [tabPanelIndex, setTabPanelIndex] = useState('1')
	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setTabPanelIndex(newValue)
	}

	// Hooks
	const { fetchData } = UseCrud()

	//// Styling of divider
	const theme = useTheme()
	const sectionColor = hexToRgba(theme.palette.primary.main, 0.2)

	// Flags
	const [dataCallMade, setDataCallMade] = useState(false)
	const [apiCallsDone, setAPICallsDone] = useState(false)

	//Variables
	const [baseCurrencyID, setBaseCurrencyID] = useState(0)

	//Arrays
	const [skuPriceBookList, setSKUPriceBookList] = useState(
		[] as SKUPartnerMap[]
	)
	const [fxRateList, setFXRateList] = useState([] as FXRate[])
	const [rangeList, setRangeList] = useState([] as Range[])
	const [fxRateConversionList, setFXRateConversionList] = useState(
		[] as FXConversionRateDisplay[]
	)
	const [currencyList, setCurrencyList] = useState([] as Currency[])

	useEffect(() => {
		// Make call if not already made
		if (!dataCallMade) {
			setDataCallMade(true)
			getData()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataCallMade, apiCallsDone])

	// *** API Calls *** //

	const getData = async () => {
		await getPriceBooks()
		await getFXRates()
		await getBaseCurrency()
		// Stop loading
		setAPICallsDone(true)
	}

	// GET: Return price books based on partnerID
	const getPriceBooks = async () => {
		// Make call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'PriceBookManagement.tsx: getPriceBooks()',
			QueryURL: `GetV2?Params=SKUPartnerMap.PriceBook.Where(SKUPartnerMap.CustomerPartnerID = '${partnerID}'), Range.All()`,
			ErrorMessage: 'An error occurred when getting price book information',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse

		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			// *** Get the all relevant lists and build the display array *** //

			var rangeResponseList = dataResponse.Obj.RangeList as Range[]
			if (rangeResponseList && rangeResponseList.length > 0) {
				setRangeList(rangeResponseList)
			}

			var skuPartnerMapPriceBookList = dataResponse.Obj
				.SKUPartnerMapList as SKUPartnerMap[]

			//sort alphabetically
			skuPartnerMapPriceBookList = skuPartnerMapPriceBookList.sort((a, b) =>
				a.SKUTypeID!.localeCompare(b.SKUTypeID!)
			)

			// *** Build the relevant display array if there is skuData*** //
			if (skuPartnerMapPriceBookList && skuPartnerMapPriceBookList.length > 0) {
				setSKUPriceBookList(skuPartnerMapPriceBookList)
			} else {
				//if no sku and pricebook data set empty to show no pricebook data display
				setSKUPriceBookList([])
			}
		}
	}

	// GET: Return Currency and FXRates
	const getFXRates = async () => {
		// Make call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'PriceBookManagement.tsx: getFXRates()',
			QueryURL: `GetV2?Params=Currency.All(), FxRate.All()`,
			ErrorMessage:
				'An error occurred when getting FX Rates and Currency information',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse

		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			// *** Get the all relevant lists and build the display array *** //
			var currencyResponseList = dataResponse.Obj.CurrencyList as Currency[]

			if (currencyResponseList && currencyResponseList.length > 0) {
				setCurrencyList(currencyResponseList)
				var fxRateResponseList = dataResponse.Obj.FXRateList as FXRate[]
				setFXRateList(fxRateResponseList)

				if (fxRateResponseList && fxRateResponseList.length > 0) {
					// *** Build the fxRate display array if there is data in FX Rate Table /

					var fxRateConversionArr = fxRateResponseList.map((fxObj) => {
						//get Currency Names for from and to ID's
						var fromCurr = currencyResponseList.find(
							(curr) => curr.CurrencyID === fxObj.FromCurrencyID
						)
						var toCurr = currencyResponseList.find(
							(curr) => curr.CurrencyID === fxObj.ToCurrencyID
						)

						var fxDisplayObj = {
							FXRateID: fxObj.FXRateID,
							FromCurrencyName: fromCurr?.CurrencyName,
							ToCurrencyName: toCurr?.CurrencyName,
							FXRate: fxObj.FXRate,
							FXConversion: `1 ${fromCurr?.CurrencyName} = ${fxObj.FXRate} ${toCurr?.CurrencyName}`,
							DateFXSet: moment(fxObj.DateFXSet).format('DD/MM/YYYY'),
						}

						//return new Object with added Conversion properties
						return fxDisplayObj
					}) as FXConversionRateDisplay[]
					//fxRateConversionList will be used for display table
					setFXRateConversionList(fxRateConversionArr)
				} else {
					//if no fxRate data,show no display
					setFXRateConversionList([])
				}
			}
		}
	}

	// GET: BaseCurrency for Partner
	const getBaseCurrency = async () => {
		// Make call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'PriceBookManagement.tsx: getBaseCurrency()',
			QueryURL: `GetV2?Params=CustomerPartner.Where(CustomerPartner.CustomerPartnerID = '${partnerID}')`,
			ErrorMessage: 'An error occurred when getting price book information',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse

		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var customerPartnerResponseList = dataResponse.Obj
				.CustomerPartnerList as CustomerPartner[]
			if (
				customerPartnerResponseList &&
				customerPartnerResponseList.length > 0
			) {
				var _baseCurrencyID = customerPartnerResponseList[0]
					.CurrencyID as number
				setBaseCurrencyID(_baseCurrencyID)
			}
		}
	}

	return apiCallsDone ? (
		skuPriceBookList.length > 0 ? (
			<>
				<Box id='pricebooks'>
					<Box className='tabs'>
						<TabContext value={tabPanelIndex}>
							<Box>
								<TabList
									onChange={handleChange}
									aria-label='Skus and fx rates tabs'>
									<Tab className='tab' label='SKUs' value='1' />
									<Tab className='tab' label='FX rates' value='2' />
								</TabList>
							</Box>
							<Divider
								className='customer-form-divider'
								sx={{
									borderColor: sectionColor,
								}}
							/>
							<TabPanel value='1'>
								<PriceBooksOverview
									skuPriceBookList={skuPriceBookList}
									rangeList={rangeList}
									currencyList={currencyList}
									baseCurrencyID={baseCurrencyID}
									fxRateList={fxRateList}
								/>
							</TabPanel>
							<TabPanel value='2'>
								<FXRatesOverview fxRateConversionList={fxRateConversionList} />
							</TabPanel>
						</TabContext>
					</Box>
				</Box>
			</>
		) : (
			<EmptyDisplay
				title='No price books found'
				description='There were no price books found'
			/>
		)
	) : (
		// Show loading
		<LoadingBox title='Getting Price Books' />
	)
}

export default PriceBookManagement
