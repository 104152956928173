import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import { StyledAnimatedBox } from '../../../../styles/styledComponents/displays/StyledAnimatedBox'
import SimpleCard from '../AnimatedBox/SimpleCardContent/SimpleCardContent'
import './LicensesDisplayBox.scss'
import StreamIcon from '@mui/icons-material/Stream'

export const LicensesDisplayBox = ({
	title,
	totalUserLicenses,
	availableUserLicenses,
	totalTeamsPhoneLicenses,
	availableTeamsPhoneLicenses,
	licensesLoading,
}: {
	title: string
	totalUserLicenses: number
	availableUserLicenses: number
	totalTeamsPhoneLicenses: number
	availableTeamsPhoneLicenses: number
	licensesLoading: boolean
}) => {
	const theme = useTheme()

	return (
		<StyledAnimatedBox sx={{ padding: '0px', margin: '0px' }}>
			<Box className='li-container'>
				{/* Title and Description */}
				<SimpleCard
					icon={<StreamIcon style={{ color: theme.palette.primary.main }} />}
					title={title}
					description={''}
					showAction={false}
				/>

				<Box className='li-content'>
					<Typography component='h6' sx={{ marginBottom: '5px' }}>
						User Licenses:
					</Typography>
					<Box className='li-content-flex'>
						{/* totalUserLicenses */}
						<Box className='li-content-col'>
							<Typography component='h6'>Total</Typography>

							{licensesLoading ? (
								<Box className='centerBox'>
									<CircularProgress size={25} color='inherit' />
								</Box>
							) : (
								<Typography component='p'>{totalUserLicenses + ''}</Typography>
							)}
						</Box>

						{/* availableUserLicenses */}
						<Box className='li-content-col'>
							<Typography component='h6'>Available</Typography>

							{licensesLoading ? (
								<Box className='centerBox'>
									<CircularProgress size={25} color='inherit' />
								</Box>
							) : (
								<Typography component='p'>
									{availableUserLicenses + ''}
								</Typography>
							)}
						</Box>
					</Box>
				</Box>

				<Box className='li-content'>
					<Typography sx={{ marginTop: '10px' }} component='h6'>
						Teams Phone Standard licenses:
					</Typography>
					<Box className='li-content-flex'>
						{/* totalTeamsPhoneLicenses */}
						<Box className='li-content-col'>
							<Typography component='h6'>Total</Typography>

							{licensesLoading ? (
								<Box className='centerBox'>
									<CircularProgress size={25} color='inherit' />
								</Box>
							) : (
								<Typography component='p'>
									{totalTeamsPhoneLicenses + ''}
								</Typography>
							)}
						</Box>

						{/* availableTeamsPhoneLicenses */}
						<Box className='li-content-col'>
							<Typography component='h6'>Available</Typography>

							{licensesLoading ? (
								<Box className='centerBox'>
									<CircularProgress size={25} color='inherit' />
								</Box>
							) : (
								<Typography component='p'>
									{availableTeamsPhoneLicenses + ''}
								</Typography>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		</StyledAnimatedBox>
	)
}
