import { Box, Typography, useTheme } from '@mui/material'
import { ChangeEvent, useState, useRef, useEffect } from 'react'
import { BaseLicenseResponse } from '../../../../../../../../utils/interfaces/APIModels'
import ClearIcon from '@mui/icons-material/Clear'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import DoDisturbOutlinedIcon from '@mui/icons-material/DoDisturbOutlined'
import { hexToRgba } from '../../../../../../../../utils/helperFunctions/helperFunctions'

const BaseLicenseAutocomplete = ({
	disabled,
	assignedLicense,
	baseLicenses,
	handleValueChange,
}: {
	disabled: boolean
	assignedLicense: string
	baseLicenses: BaseLicenseResponse[]
	handleValueChange: any
}) => {
	// Theme
	const theme = useTheme()

	// Display constants
	const [baseLicenseInput, setBaseLicenseInput] = useState(
		assignedLicense ? assignedLicense + '' : ''
	)
	const [baseLicenseList] = useState(baseLicenses)
	const [showLicenses, setShowLicenses] = useState(false)

	const containerRef = useRef<HTMLDivElement>(null)

	// Handle user input
	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
		setBaseLicenseInput(value)
		setShowLicenses(true)
	}

	// Handle user selection
	const handleBaseSelection = (license: string) => {
		// Once a license is selected, set the value
		if (license) {
			// Filter license and check for addon
			var baseExists = baseLicenseList.find(
				(x) => x.baseLicenseName === license
			)

			if (
				baseExists &&
				baseExists.numberPurchased - baseExists.numberAssigned > 0
			) {
				setBaseLicenseInput(license)
				setShowLicenses(false)
				handleValueChange('base', license)
			}
		}
	}

	// Handle click of clear icon
	const handleClear = () => {
		if (!disabled) {
			setBaseLicenseInput('')
			setShowLicenses(false)
			handleValueChange('base', '')
		}
	}

	// Close dropdown when clicking outside
	const handleClickOutside = (event: MouseEvent) => {
		if (
			containerRef.current &&
			!containerRef.current.contains(event.target as Node)
		) {
			setShowLicenses(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<Box className='autocomplete-container' ref={containerRef}>
			<input
				disabled={disabled}
				type='text'
				value={baseLicenseInput}
				onChange={handleInputChange}
				onFocus={() => setShowLicenses(true)}
				style={{
					borderColor: hexToRgba(theme.palette.primary.main, 0.2),
				}}
			/>
			{baseLicenseInput.length > 0 && (
				<ClearIcon className='clear-icon' onClick={handleClear} />
			)}
			{showLicenses && baseLicenseList.length > 0 && (
				<ul>
					{baseLicenseList.map((item, index) => (
						<li
							className={
								item.numberPurchased - item.numberAssigned === 0
									? 'disabled-item'
									: ''
							}
							key={index}
							value={item.baseLicenseSKUID + ''}
							onClick={() => handleBaseSelection(item.baseLicenseName + '')}>
							<Box className='menu-item'>
								{/* Text */}
								<Typography variant='body1' flexGrow={1}>
									{item.baseLicenseName + ''}
								</Typography>
								{/* Icon */}
								{item.numberPurchased - item.numberAssigned === 0 ? (
									<DoDisturbOutlinedIcon
										sx={{
											color: theme.palette.error.main,
										}}
									/>
								) : item.addonRequired ? (
									<RemoveCircleOutlineOutlinedIcon color='warning' />
								) : (
									<CheckCircleOutlinedIcon
										sx={{
											color: theme.palette.success.main,
										}}
									/>
								)}
							</Box>
						</li>
					))}
				</ul>
			)}
		</Box>
	)
}

export default BaseLicenseAutocomplete
