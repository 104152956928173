import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { hexToRgba } from '../../../../../../../../utils/helperFunctions/helperFunctions'
import { TUMDDIsForAssignment } from '../../../../../../../../utils/interfaces/ComponentModels'

const PhoneAutocomplete = ({
	disabled,
	currentValue,
	handleValueChange,
	handleFilteringOfDDIs,
	handlePhoneErrorHandling,
	tumDDIAssignmentList,
	isLoading,
	callDone,
}: {
	disabled: boolean
	currentValue: string
	handleValueChange: any
	handleFilteringOfDDIs: any
	handlePhoneErrorHandling: any
	tumDDIAssignmentList: TUMDDIsForAssignment[]
	isLoading: boolean
	callDone: boolean
}) => {
	// Pass in the number already assigned to display on the input
	// Check whether the user selected to assign a teams DR or OC number (numberType)
	// Make the call once the user types in something to retrieve latest 10 numbers based on input (phoneInput)

	// #region - Constants

	// Theme
	const theme = useTheme()

	// Display constants
	const [phoneInput, setPhoneInput] = useState(
		currentValue ? currentValue + '' : ''
	)
	const [userDDIList, setUserDDIList] = useState([] as TUMDDIsForAssignment[])

	// Flag
	const [numberSelected, setNumberSelected] = useState(false)

	// #endregion

	useEffect(() => {
		if (tumDDIAssignmentList) {
			setUserDDIList(tumDDIAssignmentList)
		}
	}, [tumDDIAssignmentList])

	// #region - Handle Functions

	// Handle user input to make API call
	const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
		// Get the value
		const value = e.target.value
		if (value.length === 0) {
			setPhoneInput(value)
		} else {
			// Validate number
			if (validatePhoneNumber(value)) {
				handlePhoneErrorHandling('')
				setPhoneInput(value)

				// Check value to filter results
				await handleFilteringOfDDIs(value)
			} else {
				// Helper error
				handlePhoneErrorHandling(
					'Invalid phone number input. Only digits are allowed.'
				)
			}
		}
	}

	// Handle phone selection
	const handlePhoneSelection = (phoneNumber: string) => {
		setPhoneInput(phoneNumber)
		setUserDDIList([])

		// Once a phone number is selected, set the value
		if (phoneNumber) {
			// Filter license and check for addon
			var phoneExists = userDDIList.find((x) => x.DDI + '' === phoneNumber)

			if (phoneExists) {
				handleValueChange('phone', phoneNumber)
				setNumberSelected(true)
			}
		}
	}

	// Handle click of clear icon
	const handleClear = async () => {
		if (!disabled) {
			setPhoneInput('')
			setNumberSelected(false)
			await handleFilteringOfDDIs('')
			handleValueChange('phone', '')
		}
	}

	// Validate phone
	const validatePhoneNumber = (phoneNumber: string): boolean => {
		const phoneRegex = /^\+?[0-9]+$/
		return phoneRegex.test(phoneNumber)
	}

	// #endregion

	return (
		<>
			<Box className='phone-container'>
				{/* Autocomplete */}
				<Box className='autocomplete-container'>
					{/* Input field */}
					<input
						disabled={disabled}
						type='text'
						value={phoneInput}
						onChange={handleInputChange}
						style={{
							borderColor: hexToRgba(theme.palette.primary.main, 0.2),
						}}
					/>
					{(isLoading || phoneInput.length > 0) &&
						(isLoading ? (
							<CircularProgress className='loading' size={24} />
						) : (
							<ClearIcon className='clear-icon' onClick={handleClear} />
						))}
					{/* Phone Number List */}
					{callDone && !numberSelected && (
						<ul>
							{userDDIList.length > 0 ? (
								userDDIList.map((item, index) => (
									<li
										key={index}
										onClick={() => handlePhoneSelection(item.DDI + '')}>
										<Box className='menu-item'>
											{/* Text */}
											<Typography variant='body1' flexGrow={1}>
												{item.DDI + ''}
											</Typography>
										</Box>
									</li>
								))
							) : (
								<li className='disabled-item'>
									No records available for this range and/or search
								</li>
							)}
						</ul>
					)}
				</Box>
			</Box>
		</>
	)
}

export default PhoneAutocomplete
