import { useEffect, useState } from 'react'
import {
	PriceBook,
	SKUPartnerMap,
	Range,
	Currency,
	FXRate,
} from '../../../../utils/interfaces/DBModels'
import './PriceBooksOverview.scss'
import {
	Box,
	MenuItem,
	Typography,
	useTheme,
	Alert,
	styled,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@mui/material'
import { StyledSelect } from '../../../../styles/styledComponents/inputs/StyledSelect'
import { PriceBookDisplay } from '../../../../utils/interfaces/ComponentModels'
import { GenericTable } from '../../../../components/shared/displays/GenericTable/GenericTable'
import { filterTableData } from '../../../../utils/helperFunctions/helperFunctions'
import { GenericColumn } from '../../../../utils/interfaces/ComponentModels'
import { Currencies } from '../../../../utils/enums/enums'

// Column Definition: PriceBook Table
const columns: GenericColumn[] = [
	{
		ID: 'PriceBookID',
		Label: 'Price Book ID',
		IsEditable: false,
	},
	{
		ID: 'PriceBookTerm',
		Label: 'Term',
		IsEditable: false,
	},
	{
		ID: 'PriceBookRange',
		Label: 'Range',
		IsEditable: false,
	},
	{
		ID: 'PriceBookSipcomMRC',
		Label: 'Sipcom MRC/Unit',
		IsEditable: false,
	},
	{
		ID: 'PriceBookSipcomNRC',
		Label: 'Sipcom NRC/Unit',
		IsEditable: false,
	},
	{
		ID: 'PriceBookPartnerMRC',
		Label: 'Partner MRC/Unit',
		IsEditable: false,
	},
	{
		ID: 'PriceBookPartnerNRC',
		Label: 'Partner NRC/Unit',
		IsEditable: false,
	},
]

const PriceBooksOverview = ({
	skuPriceBookList,
	rangeList,
	currencyList,
	baseCurrencyID,
	fxRateList,
}: {
	skuPriceBookList: SKUPartnerMap[]
	rangeList: Range[]
	currencyList: Currency[]
	baseCurrencyID: number
	fxRateList: FXRate[]
}) => {
	// Variables
	const [filteredSKU, setFilteredSKU] = useState(
		skuPriceBookList[0].SKUTypeID as string
	)
	// Search
	const [globalSearchValue, setGlobalSearchValue] = useState('')

	// This array manages the change in search, edit and more
	const [filteredData, setFilteredData] = useState<PriceBookDisplay[]>([])
	const [showTermColumn, setShowTermColumn] = useState(false)
	const [showRangeColumn, setShowRangeColumn] = useState(false)

	//Currency Variables for changing currencies
	const [selectedCurrency, setSelectedCurrency] = useState(baseCurrencyID)
	const [displayCurrencyList, setDisplayCurrencyList] = useState(
		[] as Currency[]
	)
	const [originalData, setOriginalData] = useState<PriceBookDisplay[]>([])

	//Display
	const theme = useTheme()

	//Footer info styling
	const InfoAlert = styled(Alert)({
		'& .MuiAlert-icon': {
			color: 'black',
		},
		backgroundColor: '#ffffff',
		color: 'black',
		fontSize: '14px',
		textAlign: 'center',
		display: 'flex',
		margin: 'auto',
	})

	useEffect(() => {
		// Set the rows
		createPriceBookDisplay(filteredSKU)
		createCurrencyDisplay(currencyList)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalSearchValue, currencyList])

	const createPriceBookDisplay = (inputValue: string) => {
		var filteredPriceBookInfo = skuPriceBookList.filter(
			(e) => e.SKUTypeID === inputValue
		)[0].PriceBookList as PriceBook[]

		if (filteredPriceBookInfo.length > 0) {
			//build display array
			var filteredPriceBookDisplay = [] as PriceBookDisplay[]

			// Loop through price books
			for (let f = 0; f < filteredPriceBookInfo.length; f++) {
				// Push to list
				filteredPriceBookDisplay.push({
					PriceBookID: filteredPriceBookInfo[f].PriceBookID,
					PriceBookTerm:
						Number(filteredPriceBookInfo[f].Term) > 0
							? filteredPriceBookInfo[f].Term + ''
							: '',
					PriceBookRange: getRange(Number(filteredPriceBookInfo[f].RangeID)),
					PriceBookSipcomMRC: filteredPriceBookInfo[f].SipcomMRC?.toFixed(2),
					PriceBookSipcomNRC: filteredPriceBookInfo[f].SipcomNRC?.toFixed(2),
					PriceBookPartnerMRC: filteredPriceBookInfo[f].PartnerMRC?.toFixed(2),
					PriceBookPartnerNRC: filteredPriceBookInfo[f].PartnerNRC?.toFixed(2),
				})
			}

			//set states for column visibility for term and range , first row of price books enough to confirm
			filteredPriceBookDisplay[0].PriceBookTerm === ''
				? setShowTermColumn(false)
				: setShowTermColumn(true)
			filteredPriceBookDisplay[0].PriceBookRange === ''
				? setShowRangeColumn(false)
				: setShowRangeColumn(true)

			setFilteredData(
				filterTableData(filteredPriceBookDisplay, globalSearchValue)
			)
			setOriginalData(filteredPriceBookDisplay)
		}
	}

	const createCurrencyDisplay = (currencyList: Currency[]) => {
		var availableCurrencyList = currencyList.filter(
			(item) => item.CurrencyID !== Currencies.No_Currency
		)

		//list for radio button
		setDisplayCurrencyList(availableCurrencyList)
	}

	//create string range for display
	const getRange = (rangeID: number) => {
		var displayRange = ''
		var range = rangeList.find((el) => el.RangeID === rangeID) as Range
		if (range) {
			displayRange = range.MinSeats + '-' + range.MaxSeats
		}
		return displayRange
	}

	// *** Handle Functions *** //
	const handleSKUTypeFilterChange = (e: { target: any }) => {
		// Set value
		var filterValue = e.target.value + ''
		setFilteredSKU(filterValue)

		//get pricebook list for selected sku
		createPriceBookDisplay(filterValue)
		setSelectedCurrency(baseCurrencyID)
	}

	const handleCurrencyChange = (e: { target: any }) => {
		var selectedCurrencyID = Number(e.target.value)
		setSelectedCurrency(selectedCurrencyID)
		//determine if conversion is to another currency or back to base
		if (selectedCurrencyID !== baseCurrencyID) {
			var rate = fxRateList.find(
				(rate) =>
					rate.FromCurrencyID === Number(baseCurrencyID) &&
					rate.ToCurrencyID === Number(selectedCurrencyID)
			)?.FXRate as Number

			if (rate) {
				//calculate values in new selected currency with decimal view

				var priceBookCurrencyChangeDisplay = [] as PriceBookDisplay[]

				const convertToRate = (price: number) => {
					var convertedPrice = (price * Number(rate)).toFixed(2)
					return convertedPrice
				}

				originalData.forEach((x) => {
					var newRow: PriceBookDisplay = {
						PriceBookID: x?.PriceBookID,
						PriceBookTerm: x?.PriceBookTerm,
						PriceBookRange: x?.PriceBookRange,
						PriceBookSipcomMRC: convertToRate(Number(x?.PriceBookSipcomMRC)),
						PriceBookSipcomNRC: convertToRate(Number(x?.PriceBookSipcomNRC)),
						PriceBookPartnerMRC: convertToRate(Number(x?.PriceBookPartnerMRC)),
						PriceBookPartnerNRC: convertToRate(Number(x?.PriceBookPartnerNRC)),
					}

					priceBookCurrencyChangeDisplay.push(newRow)
				})

				setFilteredData(
					filterTableData(priceBookCurrencyChangeDisplay, globalSearchValue)
				)
			}
		} else {
			setFilteredData(filterTableData(originalData, globalSearchValue))
		}
	}

	const handleGlobalSearchChange = (value: string) => {
		setGlobalSearchValue(value)
	}

	// Choose which columns you wish to hide
	const showColumn = (columnName: string) => {
		// Price Book ID to be hidden
		if (columnName === columns[0].ID) {
			return false
		}

		// Hide Term field if none
		if (columnName === columns[1].ID && !showTermColumn) {
			return false
		}

		// Hide Range field if none
		if (columnName === columns[2].ID && !showRangeColumn) {
			return false
		}

		return true // Logic for showing column
	}

	return (
		<Box className='pricebook-overview-container'>
			{/* SKU Type filter */}
			<Box className='pricebook-overview-filters'>
				<Typography component='h2' className='pricebook-heading'>
					View SKU pricing
				</Typography>
				<Box className='pricebook-fxrates-container'>
					<Box>
						<Typography className='pricebook-sku-heading' component='p'>
							SKU:
						</Typography>
						<StyledSelect
							variant='outlined'
							value={filteredSKU}
							className='pricebook-element'
							onChange={handleSKUTypeFilterChange}>
							{skuPriceBookList.map((item, index) => (
								<MenuItem key={index} value={item.SKUTypeID}>
									{item.SKUTypeID}
								</MenuItem>
							))}
						</StyledSelect>
					</Box>
					<Box className='currency-container'>
						<Box className='pricebook-element'>
							<Typography className='pricebook-currency-heading' component='p'>
								Base Currency:
							</Typography>
							<Box
								className='pricebook-currency-display'
								component='p'
								sx={{
									p: 2,
									mt: 2,
									border: `1px solid  ${theme.palette.primary.main}`,
								}}>
								{Currencies[baseCurrencyID]}
							</Box>
						</Box>
						<Box>
							<InfoAlert
								severity='info'
								style={{
									fontSize: '12px',
								}}
								sx={{
									'& .MuiAlert-icon': {
										color: theme.palette.primary.main,
									},
								}}>
								Change the currency view below
							</InfoAlert>
							<FormControl>
								<RadioGroup
									row
									name='row-radio-buttons-group'
									value={selectedCurrency}
									onChange={handleCurrencyChange}>
									{displayCurrencyList.map((item, index) => (
										<FormControlLabel
											value={item.CurrencyID}
											control={<Radio />}
											label={item.CurrencyName}
											labelPlacement='top'
										/>
									))}
								</RadioGroup>
							</FormControl>
						</Box>
					</Box>
				</Box>
			</Box>
			{/* Generic Table */}
			<Box id='generictable'>
				<GenericTable
					data={filteredData}
					columns={columns}
					showColumn={showColumn}
					globalSearchValue={globalSearchValue}
					onGlobalSearchChange={handleGlobalSearchChange}
					rowIdKey={columns[0].ID}
				/>
			</Box>
		</Box>
	)
}

export default PriceBooksOverview
