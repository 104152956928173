import { useEffect, useState } from 'react'
import Refresh from '@mui/icons-material/Refresh'
import { StyledDataGrid } from '../../../../styles/styledComponents/displays/StyledDataGrid'
import { GridColDef } from '@mui/x-data-grid'
import { IconButton, Modal, Tooltip } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Delete'
import {
	GridToolbarContainer,
	GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro'
import { Member, User } from '../../../../utils/interfaces/APIModels'
import { MemberListDisplay } from '../../../../utils/interfaces/ComponentModels'
import RemoveUser from './Modals/RemoveUser'
import { Roles, UserManagementType } from '../../../../utils/enums/enums'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import StyledModal from '../../../../styles/styledComponents/displays/StyledModal'

function Members({
	memberList,
	apiCall,
	getMembers,
	loading,
}: {
	memberList: Member[]
	apiCall: (type: Number, id: string) => Promise<string>
	getMembers: () => void
	loading: boolean
}) {
	const [openModal, setOpenModal] = useState(false)
	const [user, setUser] = useState({} as User)
	const [memberDisplayList, setMemberDisplayList] = useState(
		[] as MemberListDisplay[]
	)
	const roleID = useSelector(
		(state: RootState) => state.RootReducer.roleIDReducer.value
	)
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const isPartnerAdmin = roleID === Roles.PartnerAdmin
	const isCustomerAdmin = roleID === Roles.CustomerAdmin
	const customerID = loggedInUser.customerID
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 5,
		page: 0,
	})
	const handleRemoveUser = async (userID: string) => {
		setOpenModal(true)
		setUser({} as User)
		var result = await apiCall(UserManagementType.User, userID)
		var _user = JSON.parse(result) as User
		setUser(_user)
	}
	const membersColoumns: GridColDef[] = [
		{
			field: 'MemberName',
			headerName: 'Name',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'MemberEmail',
			headerName: 'Email',
			width: 250,
			hideable: false,
			flex: 1,
		},

		{
			field: 'MemberRole',
			headerName: 'Role',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'actions',
			type: 'actions',
			cellClassName: 'actions',
			width: 220,
			editable: false,
			align: 'center',
			hideable: false,
			flex: 1,
			getActions: ({ id }) => {
				return [
					<>
						<Tooltip title='Remove User'>
							<IconButton
								onClick={(e: any) => {
									handleRemoveUser(id.toString())
								}}>
								<RemoveIcon sx={{ color: 'red' }} />
							</IconButton>
						</Tooltip>
					</>,
				]
			},
		},
	]
	useEffect(() => {
		if (memberList.length > 0) {
			var _memberDisplayList = [] as MemberListDisplay[]
			memberList.forEach((m) => {
				var _userID = m.user_ID ?? ''
				var _name = m.name ?? ''
				var _email = m.email ?? ''
				var _role =
					m.roles.length > 0
						? m.roles[0].name.replace(/([a-z])([A-Z])/g, '$1 $2') ?? ''
						: ''
				var _MemberObj: MemberListDisplay = {
					MemberEmail: _email,
					MemberName: _name,
					MemberRole: _role,
					MemberUserID: _userID,
				}

				if (
					!loggedInUser.email.match(_email) &&
					(isPartnerAdmin ||
						(isCustomerAdmin &&
							_role.includes('Customer') &&
							customerID === m.customerID))
				) {
					_memberDisplayList.push(_MemberObj)
				}
			})
			setMemberDisplayList(_memberDisplayList)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [memberList, loading])
	const handleCloseModal = () => {
		setOpenModal(false)
	}
	function customToolBar() {
		return (
			<>
				<GridToolbarContainer>
					{/*  an empty div to push the filter to the right */}
					<GridToolbarQuickFilter sx={{ marginBottom: '1rem' }} />
					<div style={{ flex: 1 }}></div>{' '}
					<IconButton onClick={getMembers}>
						<Refresh />
					</IconButton>
				</GridToolbarContainer>
			</>
		)
	}
	return (
		<>
			<StyledDataGrid
				getRowId={(row) => row.MemberUserID}
				autoHeight
				rowSelection
				loading={loading}
				pagination
				paginationModel={paginationModel}
				onPaginationModelChange={setPaginationModel}
				pageSizeOptions={[10]}
				slots={{
					toolbar: customToolBar,
				}}
				columns={membersColoumns}
				rows={memberDisplayList}
			/>
			<Modal open={openModal}>
				<StyledModal width={548}>
					<RemoveUser cancel={handleCloseModal} apiCall={apiCall} user={user} />
				</StyledModal>
			</Modal>
		</>
	)
}

export default Members
