import { Tab } from '@mui/material'
import { Permissions } from '../../../../../utils/enums/enums'
import OrdersOverview from '../../OrdersOverview/OrdersOverview'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import { mdiTextBoxEditOutline } from '@mdi/js'
import Icon from '@mdi/react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import { StyledTabs } from '../../../../../styles/styledComponents/displays/StyledTabs'
import { useState } from 'react'
import { StyledTabPanel } from '../../../../../styles/styledComponents/displays/StyledTabPanel'
import TNManagement from '../../../../../pages/management/TNManagement/TNMangement'

const CustomerTabs = ({ customerID }: { customerID: string }) => {
	// Global variables
	const loggedInUserPermissions = useSelector(
		(state: RootState) =>
			state.RootReducer.loggedInUserRolesPermissionsReducer.value
	)

	// Flags
	const [tabValue, setTabValue] = useState(0)

	// Tabs Array
	const initialTabItems = [
		{
			permission: Permissions.ViewOrders,
			label: 'Customer Orders',
			icon: <Icon path={mdiTextBoxEditOutline} size={1} />,
			component: <OrdersOverview />,
		},
		{
			permission: Permissions.ViewDDI,
			label: 'Customer Numbers',
			icon: <LocalPhoneOutlinedIcon />,
			component: <TNManagement />,
		},
	]
	// Filter out tabs the user doesn't have permission to view
	const visibleTabs = initialTabItems.filter((tab) =>
		loggedInUserPermissions.some((perm) => perm.PermissionID === tab.permission)
	)

	// Handle Function - Tab change
	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue)
	}

	return (
		<>
			{/* Tabs */}
			<StyledTabs
				value={tabValue}
				onChange={handleTabChange}
				variant='fullWidth'>
				{visibleTabs.map((tab, index) => (
					<Tab key={index} icon={tab.icon} label={tab.label} />
				))}
			</StyledTabs>
			{/* Tab Panels */}
			{visibleTabs.map((tab, index) => (
				<StyledTabPanel key={index} value={tabValue} index={index}>
					{tab.component}
				</StyledTabPanel>
			))}
		</>
	)
}

export default CustomerTabs
