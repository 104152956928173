import { Box, IconButton, MenuItem, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { StyledSelect } from '../../../../../../../styles/styledComponents/inputs/StyledSelect'
import { StyledTextField } from '../../../../../../../styles/styledComponents/inputs/StyledTextField'
import { SkuDetails } from '../../../../../../../utils/interfaces/APIModels'
import { handleWheelEvent } from '../../../../../../../utils/helperFunctions/helperFunctions'
import { OrderSKUAutoRenewTerm } from '../../../../../../../utils/enums/enums'

const NewOrderType = ({
	skuDetailsArray,
	skuDropDownList,
	disabledSkus,
	handleSkuChange,
	index,
	skuDetailsErrorArray,
	handleQuantityChange,
	handleSkuTermChange,
	deleteConfirmation,
	numberOfSkus,
	skuTermYearsList,
	isCoTerm,
	autoRenewPeriodList,
	handleAutoRenewTermChange,
	disableDeleteButtonForNewOrRenewalOrderType,
}: {
	skuDetailsArray: SkuDetails[]
	skuDropDownList: string[]
	disabledSkus: any
	handleSkuChange: any
	index: number
	skuDetailsErrorArray: any[]
	handleQuantityChange: any
	handleSkuTermChange: any
	deleteConfirmation: any
	numberOfSkus: number
	skuTermYearsList: any[]
	isCoTerm: boolean
	autoRenewPeriodList: string[]
	handleAutoRenewTermChange: any
	disableDeleteButtonForNewOrRenewalOrderType: any
}) => {
	return (
		<Box key={index} className='order-form-pd' style={{ padding: '0px' }}>
			{/* SKU Dropdown */}
			<Box className='order-form-input'>
				<Typography component='p'>
					SKU Name:{' '}
					<Typography component='span' className='required'>
						*
					</Typography>
				</Typography>

				<StyledSelect
					fullWidth
					value={skuDetailsArray[index]?.SkuTypeID || 'Please select a SKU'}
					type='text'
					onChange={(e) => handleSkuChange(e, index)}>
					<MenuItem disabled value={'Please select a SKU'}>
						Please select a SKU
					</MenuItem>
					{skuDropDownList.map((item, index) => {
						return (
							<MenuItem
								key={index}
								value={item}
								disabled={disabledSkus.includes(item)}>
								{item}
							</MenuItem>
						)
					})}
				</StyledSelect>
			</Box>

			{/* Quantity */}
			<Box className='order-form-input'>
				<Typography component='p'>
					Quantity:{' '}
					<Typography component='span' className='required'>
						*
					</Typography>
				</Typography>

				<StyledTextField
					fullWidth
					required
					error={skuDetailsErrorArray[index]?.Quantity}
					helperText={
						skuDetailsErrorArray[index]?.Quantity &&
						'The quantity must be at least one and should not be a decimal value.'
					}
					value={
						skuDetailsArray[index]?.Quantity
							? skuDetailsArray[index]?.Quantity
							: 0
					}
					type='number'
					inputProps={{
						inputMode: 'numeric',
						pattern: '[0-9]*',
						min: 0,
						placeholder: '0',
					}}
					onChange={(e) => handleQuantityChange(e, index)}
					onWheel={(e) => handleWheelEvent(e)}
				/>
			</Box>

			{/* SKU Term | Hide SKU Term if isCoTerm */}
			{!isCoTerm && (
				<Box className='order-form-input'>
					<Typography component='p'>
						SKU Term (years):{' '}
						<Typography component='span' className='required'>
							*
						</Typography>
					</Typography>

					{skuDetailsArray[index]?.HasSkuTerm ? (
						<StyledSelect
							fullWidth
							required
							error={skuDetailsErrorArray[index]?.SkuTerm}
							value={skuDetailsArray[index]?.SkuTerm || 'Please select a value'}
							type='number'
							placeholder='SKU Term'
							onChange={(e) => handleSkuTermChange(e, index)}>
							<MenuItem disabled value={'Please select a value'}>
								Please select a value
							</MenuItem>
							{skuTermYearsList.map((item, index) => {
								return (
									<MenuItem key={index} value={item}>
										{item}
									</MenuItem>
								)
							})}
						</StyledSelect>
					) : (
						<StyledTextField fullWidth disabled type='text' value='No Term' />
					)}
				</Box>
			)}

			<Box className='order-form-input'>
				{/* Auto Renew Term (months) */}
				<Typography component='p'>Auto Renew Term: </Typography>
				<StyledSelect
					fullWidth
					required
					value={
						skuDetailsArray[index]?.AutoRenewMonths || OrderSKUAutoRenewTerm[3]
					}
					placeholder='SKU Term'
					onChange={(e) => handleAutoRenewTermChange(e, index)}>
					{autoRenewPeriodList.map((item, index) => {
						return (
							<MenuItem key={index} value={item}>
								{item}
							</MenuItem>
						)
					})}
				</StyledSelect>
			</Box>

			{numberOfSkus === 1 ? (
				<>
					<Box className='order-form-input'></Box>
				</>
			) : (
				<>
					<IconButton
						className='order-form-delete-icon'
						disabled={disableDeleteButtonForNewOrRenewalOrderType(index)}
						onClick={() => {
							deleteConfirmation(index)
						}}>
						<Typography
							component='p'
							style={{
								color: disableDeleteButtonForNewOrRenewalOrderType(index)
									? '#CCCCCC'
									: '#CE3434',
							}}>
							Delete SKU
						</Typography>
						<DeleteIcon
							className='delete-icon'
							style={{
								color: disableDeleteButtonForNewOrRenewalOrderType(index)
									? '#CCCCCC'
									: '#CE3434',
							}}
						/>
					</IconButton>
				</>
			)}
		</Box>
	)
}

export default NewOrderType
