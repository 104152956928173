import { useCallback, useEffect, useState } from 'react'
import {
	addToArrayInLocalStorage,
	amendArrayItemInLocalStorage,
	getLocalStorage,
	removeFromArrayInLocalStorage,
} from '../../helperFunctions/helperFunctions'

// This is a custom hook that adds and removes items from an array in local storage
// Currently used in Teams User MGT
const UseLocalStorage = (key: string) => {
	// Data array stored in storage
	const [data, setData] = useState(() => getLocalStorage(key) || [])

	useEffect(() => {
		// Custom handle function to manage if changes occurred
		const handleStorageChange = (e: StorageEvent) => {
			if (e.key === key) {
				setData(getLocalStorage(key) || [])
			}
		}

		// Custom handle function for custom event
		const handleCustomStorageChange = (e: Event) => {
			setData(getLocalStorage(key) || [])
		}

		window.addEventListener('storage', handleStorageChange)
		window.addEventListener(
			'localStorageUpdate',
			handleCustomStorageChange as EventListener
		)

		return () => {
			window.removeEventListener('storage', handleStorageChange)
			window.removeEventListener(
				'localStorageUpdate',
				handleCustomStorageChange as EventListener
			)
		}
	}, [key])

	// Add item to array
	const addItem = useCallback(
		(item: any) => {
			addToArrayInLocalStorage(key, item)
			setData(getLocalStorage(key) || [])
			window.dispatchEvent(new Event('localStorageUpdate'))
		},
		[key]
	)

	// Remove item from array
	const removeItem = useCallback(
		(item: any, compareFn: (a: any, b: any) => boolean) => {
			removeFromArrayInLocalStorage(key, item, compareFn)
			setData(getLocalStorage(key) || [])
			window.dispatchEvent(new Event('localStorageUpdate'))
		},
		[key]
	)

	// Amend item in array
	const amendItem = useCallback(
		(newValue: any, compareFn: (a: any, b: any) => boolean) => {
			amendArrayItemInLocalStorage(key, newValue, compareFn)
			setData(getLocalStorage(key) || [])
			window.dispatchEvent(new Event('localStorageUpdate'))
		},
		[key]
	)

	return { data, addItem, removeItem, amendItem }
}

export default UseLocalStorage
