import { ComponentType, FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { RootState } from '../../../store/store'

export interface WithRoleBasedRedirectOptions {
	permissionID: number
}
export const withRoleBasedRedirect =
	<P extends object>(
		Component: ComponentType<P>,
		options: WithRoleBasedRedirectOptions
	): FC<P> =>
	(props: P): JSX.Element => {
		const [isAuthorized, setIsAuthorized] = useState(false)
		const navigate = useNavigate()
		const loggedInUserPermissions = useSelector(
			(state: RootState) =>
				state.RootReducer.loggedInUserRolesPermissionsReducer.value
		)
		useEffect(() => {
			async function checkRoles(permissionID: number) {
				const isAuthorized = loggedInUserPermissions.find(
					(perm) => Number(perm.PermissionID) === permissionID
				)
				if (!isAuthorized) {
					navigate('/Error')
				} else {
					setIsAuthorized(true)
				}
			}
			if (loggedInUserPermissions.length === 0) {
				navigate('/')
			} else {
				checkRoles(options.permissionID)
			}
		})

		return isAuthorized ? <Component {...props} /> : <div></div>
	}
