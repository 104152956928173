import {
	Box,
	Divider,
	MenuItem,
	Modal,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material'
import { StyledTextField } from '../../../../../../styles/styledComponents/inputs/StyledTextField'
import { useEffect, useState } from 'react'
import {
	BetaAPIMutation,
	BetaObject,
	DataResponse,
	ValidateAddressRequestModel,
	ValidateAddressResponseModel,
} from '../../../../../../utils/interfaces/APIModels'
import { StyledSelect } from '../../../../../../styles/styledComponents/inputs/StyledSelect'
import { AddressTypes, ReturnTypes } from '../../../../../../utils/enums/enums'
import { StyledLoadingButton } from '../../../../../../styles/styledComponents/inputs/StyledLoadingButton'
import { AddressType } from '../../../../../../utils/interfaces/ComponentModels'
import {
	Address,
	AddressMap,
	Country,
	State,
} from '../../../../../../utils/interfaces/DBModels'
import './AddAddressForm.scss'
import {
	hexToRgba,
	objectToStringForGet,
	showErrorToast,
	toAlphaString,
	toBeta,
	validateField,
} from '../../../../../../utils/helperFunctions/helperFunctions'
import UseCrud from '../../../../../../utils/customHooks/APICalls/UseCrud'
import { RootState } from '../../../../../../store/store'
import { useSelector } from 'react-redux'
import { Autocomplete, Libraries, LoadScript } from '@react-google-maps/api'
import { usePostBetaObjectWithoutRefetchMutation } from '../../../../../../services/proxyAPIData'
import StyledModal from '../../../../../../styles/styledComponents/displays/StyledModal'
import AddressValidationResultView from './AddressValidationResultView'

const AddUpdateAddressForm = ({
	stateList,
	countryList,
	editAddress,
	selectedRow,
	getAddresses,
	clearSelectedRow,
}: {
	stateList: State[]
	countryList: Country[]
	editAddress: boolean
	selectedRow: any
	getAddresses: any
	clearSelectedRow: any
}) => {
	const [addressName, setAddressName] = useState('')
	const [houseNumber, setHouseNumber] = useState('')
	const [addressLine1, setAddressLine1] = useState('')
	const [addressLine2, setAddressLine2] = useState('')
	const [city, setCity] = useState('')
	const [postalCode, setPostalCode] = useState('')

	const [selectedAddressTypeID, setSelectedAddressTypeID] = useState(0)
	const [selectedCountryID, setSelectedCountryID] = useState(0)
	const [selectedStateID, setSelectedStateID] = useState(0)

	const [loadingButton, setLoadingButton] = useState(false)

	const [isMicrosoftAddress, setIsMicrosoftAddress] = useState(false)

	//lists
	const [addressTypeList, setAddressTypeList] = useState<AddressType[]>([])
	const [stateListDisplay, setStateDisplayList] = useState([] as State[])
	const [countryListDisplay, setCountryDisplayList] = useState([] as Country[])

	// errorMessages
	const [addressNameErrMsg, setAddressNameErrMsg] = useState('')
	const [houseNumberErrMsg, setHouseNumberErrMsg] = useState('')
	const [addressLine1ErrMsg, setAddressLine1ErrMsg] = useState('')
	const [addressLine2ErrMsg, setAddressLine2ErrMsg] = useState('')
	const [cityErrMsg, setCityErrMsg] = useState('')
	const [postalCodeErrMsg, setPostalCodeErrMsg] = useState('')

	// GoogleMaps
	const libraries: Libraries = ['places']
	const [autocomplete, setAutocomplete] = useState(
		{} as google.maps.places.Autocomplete
	)

	const [addressSubmitted, setAddressSubmitted] = useState('')
	const [recommendedAddress, setRecommendedAddress] = useState('')
	const [validationResult, setValidationResult] = useState(
		{} as ValidateAddressResponseModel
	)
	const [openModal, setOpenModal] = useState(false)

	const theme = useTheme()

	const { fetchData, modifyData } = UseCrud()
	const [postValidateAddress] = usePostBetaObjectWithoutRefetchMutation()

	var loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	useEffect(() => {
		var _addressTypes: AddressType[] = []

		for (var addressType in AddressTypes) {
			if (!isNaN(Number(addressType))) {
				_addressTypes.push({
					AddressTypeID: Number(addressType),
					AddressTypeName: AddressTypes[addressType],
				})
			}
		}

		// Sort Address, Country and State Lists
		setAddressTypeList(
			_addressTypes.sort((a, b) =>
				a.AddressTypeName!.localeCompare(b.AddressTypeName!, undefined, {
					sensitivity: 'base',
				})
			)
		)

		var sortedCountry = countryList.sort((a, b) =>
			a.CountryName!.localeCompare(b.CountryName!, undefined, {
				sensitivity: 'base',
			})
		)
		setCountryDisplayList(sortedCountry)

		setStateDisplayList(
			stateList
				.filter((x) => x.CountryID === sortedCountry[0].CountryID)
				.sort((a, b) =>
					a.StateName!.localeCompare(b.StateName!, undefined, {
						sensitivity: 'base',
					})
				)
		)
		// Clear Form & initialize (addressType, Country and State)
		clearAutoComplete()
		clearForm(_addressTypes)
		if (selectedRow) {
			// Initialize form with selected Customer Address
			setAddressName(selectedRow?.AddressName ? selectedRow?.AddressName : '')
			setCity(selectedRow?.City ? selectedRow?.City : '')
			setHouseNumber(selectedRow?.HouseNumber ? selectedRow?.HouseNumber : '')
			setAddressLine1(selectedRow?.AddressLine1)
			setAddressLine2(
				selectedRow?.AddressLine2 ? selectedRow?.AddressLine2 : ''
			)

			var selectedAddress = _addressTypes.find(
				(address: AddressType) =>
					address.AddressTypeName === selectedRow?.AddressType
			)

			if (selectedAddress?.AddressTypeID === AddressTypes.Microsoft) {
				setIsMicrosoftAddress(true)
			} else {
				setIsMicrosoftAddress(false)
			}

			setSelectedAddressTypeID(Number(selectedAddress?.AddressTypeID))

			const selectedCountry = countryList.find(
				(country: Country) => country.CountryName === selectedRow.Country
			)

			setSelectedCountryID(Number(selectedCountry?.CountryID))

			var statesForCountry = stateList.filter(
				(x) => x.CountryID === Number(selectedCountry?.CountryID)
			)

			setStateDisplayList(statesForCountry)
			const selectedState = statesForCountry.find(
				(state: State) => state.StateName === selectedRow.State
			)

			setSelectedStateID(Number(selectedState?.StateID))

			setPostalCode(selectedRow?.PostalCode)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countryList, stateList, selectedRow])

	const initAutoComplete = (
		autocompleteInstance: google.maps.places.Autocomplete
	) => {
		setAutocomplete(autocompleteInstance)
	}

	const clearAutoComplete = () => {
		var addressFinder = document.getElementById('searchBox') as HTMLInputElement
		if (addressFinder) {
			addressFinder.value = ''
		}
	}

	const onPlaceChanged = () => {
		if (autocomplete !== null) {
			const place = autocomplete.getPlace()
			if (place && place.formatted_address) {
				clearForm(addressTypeList)
				for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
					// @ts-ignore remove once typings fixed
					const componentType = component.types[0]

					switch (componentType) {
						case 'street_number': {
							setHouseNumber(`${component.long_name}`)
							break
						}

						case 'route': {
							setAddressLine1(component.short_name)
							break
						}

						case 'postal_code': {
							setPostalCode(`${component.long_name}`)
							break
						}

						case 'locality':
							setCity(component.long_name)
							break

						case 'postal_town':
							if (
								!place.address_components?.find((x) =>
									x.types.includes('locality')
								) &&
								component.long_name
							) {
								setCity(component.long_name)
							}
							break

						case 'administrative_area_level_1': {
							var stateName = ''
							if (
								component.long_name === 'England' &&
								place.address_components?.find((x) =>
									x.types.includes('administrative_area_level_2')
								)
							) {
								stateName =
									place.address_components?.find((x) =>
										x.types.includes('administrative_area_level_2')
									)?.long_name + ''
							} else {
								stateName = component.long_name + ''
							}
							var filteredStates = stateList.filter(
								(x) => x.StateName === stateName
							)
							setStateDisplayList(filteredStates)
							setSelectedStateID(Number(filteredStates[0].StateID))
							break
						}

						case 'country':
							var filteredCountries = countryList.filter(
								(x) => x.CountryName === component.long_name + ''
							)
							setCountryDisplayList(filteredCountries)
							setSelectedCountryID(Number(filteredCountries[0].CountryID))
							break
					}
				}
			}
		}
	}

	const handleAddressName = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setAddressName(newValue)
		setAddressNameErrMsg(validateField('addressName', newValue + ''))
	}

	const handleHouseNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setHouseNumber(newValue)

		setHouseNumberErrMsg(validateField('houseNumber', newValue + ''))
	}

	const handleAddressLine1 = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setAddressLine1(newValue)
		setAddressLine1ErrMsg(validateField('addressLine1', newValue + ''))
	}

	const handleAddressLine2 = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setAddressLine2(newValue)
		setAddressLine2ErrMsg(validateField('addressLine2', newValue + ''))
	}

	const handleAddressType = (e: { target: any }) => {
		var newValue = e.target.value
		setSelectedAddressTypeID(Number(newValue))
	}

	const handleCity = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setCity(newValue)
		setCityErrMsg(validateField('city', newValue + ''))
	}

	const handleState = (e: { target: any }) => {
		var newValue = e.target.value
		setSelectedStateID(newValue)
	}

	const handleCountry = (e: { target: any }) => {
		var newValue = e.target.value
		setSelectedCountryID(Number(newValue))

		var filterStateList = stateList
			.filter((x) => x.CountryID === newValue)
			.sort((a, b) =>
				a.StateName!.localeCompare(b.StateName!, undefined, {
					sensitivity: 'base',
				})
			)

		setStateDisplayList(filterStateList)
		setSelectedStateID(Number(filterStateList[0]?.StateID))
	}

	const handlePostalCode = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setPostalCode(newValue)
		setPostalCodeErrMsg(validateField('postalCode', newValue + ''))
	}

	const disableAddAddressBtn = () => {
		if (
			selectedAddressTypeID === 0 ||
			selectedStateID === 0 ||
			selectedCountryID === 0 ||
			!postalCode ||
			postalCode?.length === 0 ||
			!addressLine1 || // Checks if addressLine1 is null, undefined, or empty
			addressNameErrMsg?.length > 0 || // Safely checks the length of error messages
			houseNumberErrMsg?.length > 0 ||
			addressLine1ErrMsg?.length > 0 ||
			addressLine2ErrMsg?.length > 0 ||
			cityErrMsg?.length > 0 ||
			postalCodeErrMsg?.length > 0
		) {
			return true
		} else {
			return false
		}
	}

	const handleCloseModal = () => {
		setOpenModal(false)
	}

	const clearForm = (_addressTypeList: AddressType[]) => {
		setAddressName('')
		setHouseNumber('')
		setAddressLine1('')
		setAddressLine2('')
		setCity('')
		setPostalCode('')
		// Initialize dropdowns to 1st items in respective lists
		setSelectedAddressTypeID(Number(_addressTypeList[0].AddressTypeID))
		setSelectedCountryID(Number(countryList[0].CountryID))
		setSelectedStateID(Number(stateList[0].StateID))
		// Reset error messages
		setAddressNameErrMsg('')
		setHouseNumberErrMsg('')
		setAddressLine1ErrMsg('')
		setAddressLine2ErrMsg('')
		setCityErrMsg('')
		setPostalCodeErrMsg('')
		setIsMicrosoftAddress(false)
	}

	const validateAddress = async () => {
		setLoadingButton(true)
		var selectedCountry = countryListDisplay.find(
			(x) => x.CountryID === selectedCountryID
		)

		var selectedState = stateListDisplay.find(
			(x) => x.StateID === selectedStateID
		)

		var validationRequestObj: ValidateAddressRequestModel = {
			address: {
				regionCode: selectedCountry
					? selectedCountry.CountryISO2Code
					: undefined,
				postalCode: postalCode,
				locality: city,
				administrativeArea: selectedState ? selectedState.StateCode : undefined,
				addressLines: [
					`${houseNumber} ${addressLine1} ${city} ${
						selectedState ? selectedState.StateCode : ''
					} ${postalCode} ${
						selectedCountry ? selectedCountry.CountryISO2Code : ''
					}`,
				],
			},
		}

		setAddressSubmitted(
			`${houseNumber} ${addressLine1} ${city ?? ''} ${
				selectedState?.StateCode ?? ''
			} ${postalCode} ${selectedCountry?.CountryISO2Code}`
		)

		var betaDataObj = await toBeta(validationRequestObj)

		var betaObj: BetaObject = {
			Content: betaDataObj,
		}

		var betaApiMutation: BetaAPIMutation = {
			BetaObject: betaObj,
			QueryParam: 'address/validate',
		}

		await postValidateAddress(betaApiMutation)
			.unwrap()
			.then(async (response) => {
				if (response?.Content) {
					var dataResponse = JSON.parse(
						await toAlphaString(response?.Content)
					) as ValidateAddressResponseModel

					//set recommended address
					var recommendedAdd = ''
					var addressComToDisplay = [
						'street_number',
						'route',
						'postal_code',
						'postal_code_suffix',
						'country',
						'locality',
						'administrative_area_level_1',
					]
					dataResponse.result.address.addressComponents.map((x) => {
						if (
							x.componentName &&
							x.componentType &&
							addressComToDisplay.includes(x.componentType) &&
							x.componentName.text &&
							x.componentName.text !== 'null'
						)
							recommendedAdd +=
								x.componentType === 'postal_code_suffix'
									? `-${x.componentName.text} `
									: `${x.componentName.text} `
					})
					setRecommendedAddress(recommendedAdd)
					setValidationResult(dataResponse)
					setOpenModal(true)
				}
			})
			.catch(() => {
				showErrorToast('Failed to validate address')
				setRecommendedAddress('')
				setOpenModal(true)
			})
		clearAutoComplete()
		setLoadingButton(false)
	}

	const addAddress = async (useRecommendedAddress: number) => {
		//show loading indicator
		setLoadingButton(true)
		var _houseNumber = houseNumber
		var _addressLine1 = addressLine1
		var _city = city
		var _postalCode = postalCode
		var _postalCodeSuffix = ''
		var _selectedCountryID = selectedCountryID
		var _selectedStateID = selectedStateID
		if (useRecommendedAddress === 1) {
			var addressComponents = validationResult.result.address.addressComponents

			var streetNumberComp = addressComponents.find(
				(x) => x.componentType === 'street_number'
			)
			if (streetNumberComp) {
				_houseNumber = streetNumberComp.componentName.text
				if (_houseNumber.length > 0) {
					setHouseNumber(_houseNumber)
				}
			}

			var routeComp = addressComponents.find((x) => x.componentType === 'route')
			if (routeComp) {
				_addressLine1 = routeComp.componentName.text
				if (_addressLine1.length > 0) {
					setAddressLine1(_addressLine1)
				}
			}

			var localityComp = addressComponents.find(
				(x) => x.componentType === 'locality'
			)
			if (localityComp) {
				_city = localityComp.componentName.text
				if (_city.length > 0) {
					setCity(_city)
				}
			}

			var stateComp = addressComponents.find(
				(x) => x.componentType === 'administrative_area_level_1'
			)
			if (stateComp) {
				var stateName = stateComp.componentName.text
				if (stateName.length > 0) {
					var matchingState = stateListDisplay.find(
						(x) => x.StateName === stateName || x.StateCode === stateName
					)
					if (matchingState) {
						_selectedStateID = Number(matchingState.StateID)
						_selectedCountryID = Number(matchingState.CountryID)
						setSelectedStateID(_selectedStateID)
						setSelectedCountryID(_selectedCountryID)
					}
				}
			}

			var postalCodeComp = addressComponents.find(
				(x) => x.componentType === 'postal_code'
			)
			var postalCodeSuffixComp = addressComponents.find(
				(x) => x.componentType === 'postal_code_suffix'
			)
			if (postalCodeComp) {
				_postalCode = postalCodeComp.componentName.text
				var _postalCodeSuffix = ''
				if (postalCodeSuffixComp) {
					_postalCodeSuffix = postalCodeSuffixComp.componentName.text
				}
				if (_postalCode.length > 0 && _postalCodeSuffix.length > 0) {
					_postalCode = `${_postalCode}-${_postalCodeSuffix}`
					setPostalCode(_postalCode)
				} else if (_postalCode.length > 0) {
					setPostalCode(_postalCode)
				}
			}
		}

		//create empty variables
		var addressObj = {} as Address
		var dataResponse
		var addressRequestObj = {} as any
		var addressRequestURL = ''
		if (editAddress === true) {
			const selectedAddressType = AddressTypes[selectedAddressTypeID]
			const selectedCountry = countryList.find(
				(country: Country) => country.CountryID === _selectedCountryID
			)?.CountryName
			const selectedState = stateList.find(
				(state: State) => state.StateID === _selectedStateID
			)?.StateName
			//check if something has changed
			if (
				(addressName.length > 0 && addressName !== selectedRow.AddressName) ||
				selectedAddressType !== selectedRow.AddressTypeID ||
				_houseNumber !== selectedRow.HouseNumber ||
				_addressLine1 !== selectedRow.AddressLine1 ||
				addressLine2 !== selectedRow.AddressLine2 ||
				_city !== selectedRow.City ||
				selectedCountry !== selectedRow.Country ||
				selectedState !== selectedRow.State ||
				_postalCode !== selectedRow.PostalCode
			) {
				//update existing address
				addressObj = {
					AddressID: selectedRow.AddressID,
					AddressTypeID: selectedAddressTypeID,
					HouseNumber: _houseNumber?.trim(),
					AddressLine1: _addressLine1.trim(),
					AddressLine2: addressLine2?.trim(),
					City: _city,
					StateID: _selectedStateID,
					PostalCode: _postalCode?.trim(),
				}

				addressRequestObj = {
					Address: addressObj,
				}

				dataResponse = (await modifyData({
					FileAndFunctionName: 'AddAddressForm.tsx addAddress',
					QueryURL: `UpdateV2?Params=Address`,
					QueryObj: addressRequestObj,
					ErrorMessage: 'Could not update address',
					ShowErrorMessage: true,
					ShowSuccessMessage: false,
					SuccessMessage: 'Address Successfully Updated',
					LogErrorToDB: true,
					UserName: loggedInUser.email,
				})) as Boolean

				if (dataResponse) {
					if (selectedRow.addressName !== addressName) {
						addressRequestURL = objectToStringForGet(
							'AddressMap',
							addressRequestObj.Address
						)

						if (addressRequestURL) {
							if (addressRequestURL) {
								await addAddressMap(addressRequestURL)
							}
						}
					}
				}
			}
		} else {
			//add new address
			addressObj = {
				AddressTypeID: selectedAddressTypeID,
				HouseNumber: _houseNumber?.trim(),
				AddressLine1: _addressLine1.trim(),
				AddressLine2: addressLine2?.trim(),
				City: _city,
				StateID: _selectedStateID,
				PostalCode: _postalCode?.trim(),
			}

			addressRequestObj = {
				Address: addressObj,
			}

			dataResponse = (await modifyData({
				FileAndFunctionName: 'AddAddressForm.tsx addAddress',
				QueryURL: `AddV2?Params=Address`,
				QueryObj: addressRequestObj,
				ErrorMessage: 'Could not add address',
				ShowErrorMessage: true,
				ShowSuccessMessage: false,
				SuccessMessage: 'Address Successfully added',
				LogErrorToDB: true,
				UserName: loggedInUser.email,
			})) as Boolean

			if (dataResponse) {
				addressRequestURL = objectToStringForGet(
					'Address',
					addressRequestObj.Address
				)
				if (addressRequestURL) {
					if (addressRequestURL) {
						await addAddressMap(addressRequestURL)
					}
				}
			}
		}
		setLoadingButton(false)
		clearAutoComplete()
	}

	const addAddressMap = async (addressRequestURL: string) => {
		var addressMapObj = {} as AddressMap
		var addressMapRequest = {} as any
		var postSuccess

		if (editAddress === true) {
			//update address map
			addressMapObj = {
				AddressMapID: selectedRow.AddressMapID,
				AddressID: selectedRow.AddressID,
				CarrierID: 1,
				CustomerID: loggedInUser.customerID,
				AddressName: addressName, //friendly name
			}

			addressMapRequest = {
				AddressMap: addressMapObj,
			}

			//post to DB
			postSuccess = await modifyData({
				FileAndFunctionName: 'AddAddressForm.tsx addAddressMap',
				QueryURL: 'UpdateV2?Params=AddressMap',
				QueryObj: addressMapRequest,
				ErrorMessage: 'Failed to update address',
				ShowErrorMessage: false,
				ShowSuccessMessage: true,
				SuccessMessage: 'Updated Address successfully',
				LogErrorToDB: true,
				UserName: loggedInUser.email,
			})

			if (postSuccess) {
				getAddresses()
				clearForm(addressTypeList)
				clearAutoComplete()
				clearSelectedRow()
			}
		} else {
			//add address map
			var requestURL = `GetV2?Params=Address.Where(${addressRequestURL}).Pagination(NumberOfRows = '1' & PageNumber = '1' & Column = 'Address.AddressID' & SortType = 'DESC')`

			var dataResponse = (await fetchData({
				FileAndFunctionName: 'AddAddressForm.tsx: addAddressMap',
				QueryURL: requestURL,
				ErrorMessage: 'An error occurred while fetching the address map record',
				ShowErrorToast: true,
				LogErrorToDB: true,
				ReturnType: ReturnTypes.ObjectOrList,
			})) as DataResponse

			if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
				var addressID1 = dataResponse.Obj.AddressList[0].AddressID

				addressMapObj = {
					AddressID: addressID1,
					CarrierID: 1,
					CustomerID: loggedInUser.customerID,
					AddressName: addressName, //friendly name
				}

				addressMapRequest = {
					AddressMap: addressMapObj,
				}

				//post to DB
				postSuccess = await modifyData({
					FileAndFunctionName: 'AddAddressForm.tsx addAddressMap',
					QueryURL: `AddV2?Params=AddressMap`,
					QueryObj: addressMapRequest,
					ErrorMessage: 'Failed to add address',
					ShowErrorMessage: true,
					ShowSuccessMessage: true,
					SuccessMessage: 'Added Address successfully',
					LogErrorToDB: true,
					UserName: loggedInUser.email,
				})

				if (postSuccess) {
					getAddresses()
					clearForm(addressTypeList)
					clearAutoComplete()
				}
			}
		}
	}

	return (
		<>
			<Box>
				<Modal open={openModal}>
					<StyledModal width={500}>
						<AddressValidationResultView
							addressSubmitted={addressSubmitted}
							recommendedAddress={recommendedAddress}
							isEditAddress={editAddress}
							handleConfirmAddress={addAddress}
							handleCloseModal={handleCloseModal}
						/>
					</StyledModal>
				</Modal>

				{/* form heading  */}
				<Box className='header'>
					<Typography component='h4'>Customer Address</Typography>
				</Box>

				{/* add/update address form */}
				<Box
					sx={{
						backgroundColor: hexToRgba(theme.palette.secondary.main, 0.3),
						border: `2px solid${theme.palette.secondary.main}`,
						borderRadius: '10px',
					}}>
					<Box className='add-address-box'>
						{!isMicrosoftAddress && (
							<>
								<Typography component='p'>Address Finder:</Typography>
								<Box>
									<LoadScript
										googleMapsApiKey={
											process.env.REACT_APP_GOOGLE_MAPS_API_KEY + ''
										}
										libraries={libraries}>
										<Autocomplete
											onLoad={initAutoComplete}
											onPlaceChanged={onPlaceChanged}
											fields={[
												'place_id',
												'geometry',
												'address_components',
												'formatted_address',
											]}
											restrictions={{ country: ['US', 'GB'] }}>
											<input
												type='text'
												placeholder='Enter an address'
												id='searchBox'
												style={{
													boxSizing: 'border-box',
													border: '0',
													width: '240px',
													height: '32px',
													padding: '0 12px',
													borderRadius: '3px',
													boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
													fontSize: '14px',
													outline: 'none',
													textOverflow: 'ellipses',
												}}
											/>
										</Autocomplete>
									</LoadScript>
								</Box>
							</>
						)}

						<Typography component='p'>Address Name:</Typography>
						<Box>
							<StyledTextField
								fullWidth
								type='text'
								onChange={handleAddressName}
								error={addressNameErrMsg.length > 0}
								helperText={addressNameErrMsg}
								value={addressName}
							/>
						</Box>

						<Typography component='p'>
							Address Type:
							<Typography component='span' className='required'>
								*
							</Typography>
						</Typography>
						<Box>
							<StyledSelect
								fullWidth
								disabled={isMicrosoftAddress}
								onChange={handleAddressType}
								type='text'
								value={selectedAddressTypeID}>
								{addressTypeList.map((item: AddressType, index: any) => {
									return (
										<MenuItem key={index} value={item.AddressTypeID}>
											{item.AddressTypeName}
										</MenuItem>
									)
								})}
							</StyledSelect>
						</Box>

						<Typography component='p'>House number:</Typography>
						<Box>
							<StyledTextField
								fullWidth
								disabled={isMicrosoftAddress}
								type='text'
								onChange={handleHouseNumber}
								error={houseNumberErrMsg.length > 0}
								helperText={houseNumberErrMsg}
								value={houseNumber}
							/>
						</Box>

						<Typography component='p'>City: </Typography>
						<Box>
							<StyledTextField
								fullWidth
								disabled={isMicrosoftAddress}
								type='text'
								onChange={handleCity}
								value={city}
								error={cityErrMsg.length > 0}
								helperText={cityErrMsg}
							/>
						</Box>

						<Typography component='p'>
							Address Line 1:
							<Typography component='span' className='required'>
								*
							</Typography>
						</Typography>
						<Box>
							<StyledTextField
								fullWidth
								disabled={isMicrosoftAddress}
								type='text'
								onChange={handleAddressLine1}
								error={addressLine1ErrMsg.length > 0}
								helperText={addressLine1ErrMsg}
								value={addressLine1}
							/>
						</Box>

						<Typography component='p'>
							Country:
							<Typography component='span' className='required'>
								*
							</Typography>
						</Typography>
						<Box>
							<StyledSelect
								fullWidth
								disabled={isMicrosoftAddress}
								type='text'
								onChange={handleCountry}
								value={selectedCountryID}>
								{countryListDisplay.map((item: Country, index: any) => {
									return (
										<MenuItem key={index} value={item.CountryID}>
											{item.CountryName}
										</MenuItem>
									)
								})}
							</StyledSelect>
						</Box>

						<Typography component='p'>Address Line 2:</Typography>
						<Box>
							<StyledTextField
								fullWidth
								disabled={isMicrosoftAddress}
								type='text'
								onChange={handleAddressLine2}
								error={addressLine2ErrMsg.length > 0}
								helperText={addressLine2ErrMsg}
								value={addressLine2}
							/>
						</Box>

						<Typography component='p'>
							State:
							<Typography component='span' className='required'>
								*
							</Typography>
						</Typography>
						<Box>
							<StyledSelect
								fullWidth
								disabled={isMicrosoftAddress}
								type='text'
								onChange={handleState}
								value={selectedStateID}>
								{stateListDisplay.map((item: State, index: any) => {
									return (
										<MenuItem key={index} value={item.StateID}>
											{item.StateName}
										</MenuItem>
									)
								})}
							</StyledSelect>
						</Box>

						<Box></Box>
						<Box></Box>

						<Typography component='p'>
							Postal Code:{' '}
							<Typography component='span' className='required'>
								*
							</Typography>{' '}
						</Typography>
						<Box>
							<StyledTextField
								fullWidth
								disabled={isMicrosoftAddress}
								type='text'
								onChange={handlePostalCode}
								value={postalCode}
								error={postalCodeErrMsg.length > 0}
								helperText={postalCodeErrMsg}
							/>
						</Box>
					</Box>

					<Divider />

					<br />

					<Tooltip
						title={
							disableAddAddressBtn() &&
							'Please ensure all the required fields are filled in'
						}>
						<span>
							<StyledLoadingButton
								className='address-btn'
								loading={loadingButton}
								onClick={(e) =>
									isMicrosoftAddress ? addAddress(0) : validateAddress()
								}
								disabled={disableAddAddressBtn()}>
								{isMicrosoftAddress ? 'Update' : 'Validate'} Address
							</StyledLoadingButton>
						</span>
					</Tooltip>

					<br />
				</Box>
			</Box>
		</>
	)
}

export default AddUpdateAddressForm
