import { v4 as uuidv4 } from 'uuid'
import { usePostBetaObjectWithoutRefetchMutation } from '../../../services/proxyAPIData'
import { BetaObject, ErrorRequest } from '../../interfaces/APIModels'
import { Log } from '../../interfaces/DBModels'
import {
	getEmptyAPIMutationObject,
	getFormattedDate,
	toBetaString,
} from '../../helperFunctions/helperFunctions'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'

export function ErrorHandling() {
	// Global variables
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	// API Call
	const [postWithoutRefetch] = usePostBetaObjectWithoutRefetchMutation()

	// POST Call - Log error to DB
	async function addErrorLog(errorRequest: ErrorRequest) {
		// Custom UUID and formatted data
		var requestID = uuidv4()
		var logTime = new Date(getFormattedDate())

		// Log object to add to DB
		var log: Log = {
			RequestID: requestID,
			ActionPerformed: errorRequest.ActionPerformed,
			ErrorInfo: errorRequest.ErrorInfo,
			LogTime: logTime,
		}

		var errorLog = {
			Log: log,
		}

		var addLogObj = {
			Obj: errorLog,
			UserID: loggedInUser.userID,
			UserName: loggedInUser.email,
		}

		var apiMutation = getEmptyAPIMutationObject()
		apiMutation.QueryParam = 'AddV2?Params=Log'
		var betaObj: BetaObject = {
			Content: await toBetaString(JSON.stringify(addLogObj)),
		}

		apiMutation.BetaObject = betaObj

		// post to DB
		await postWithoutRefetch(apiMutation).unwrap().catch()
	}

	return addErrorLog
}
