import { Box, Button, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { initialRouteList } from '../../../../utils/constants/constants'
import { useLocation, useNavigate } from 'react-router'
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined'
import './Header.scss'

const Header = () => {
	// Display constant
	const [pageTitle, setPageTitle] = useState('')
	const [pageIcon, setPageIcon] = useState<any>(null)

	// Theme
	const theme = useTheme()

	// General
	const location = useLocation()
	const navigate = useNavigate()

	useEffect(() => {
		// Get the title of page from the menu item constant
		var currentPage = initialRouteList.find(
			(item) => item.RouteLink === location.pathname
		)
		if (currentPage) {
			setPageTitle(currentPage.RouteLabel)
			setPageIcon(currentPage.RouteIcon)
		} else {
			setPageTitle('Page Title')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location])

	return (
		<>
			<Box id='header' component='header'>
				<Box className='header-title'>
					{/* Breadcrumb - Simple return */}
					{navigate && location.pathname !== '/' && (
						<Button
							startIcon={<KeyboardReturnOutlinedIcon />}
							onClick={() => navigate(-1)}>
							Back
						</Button>
					)}
					{/* Header Title */}
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							columnGap: '20px',
							alignItems: 'center',
							svg: {
								color: theme.palette.primary.main,
								fontSize: '30px',
							},
						}}>
						{pageIcon}
						<Typography component='h1'>{pageTitle}</Typography>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default Header
