import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from './store/store'
import { Provider } from 'react-redux'
import { Auth0Provider } from '@auth0/auth0-react'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

// Get Organization ID
var organizationID = ''
switch (window.location.origin) {
	// Advania
	case process.env.REACT_APP_ADVANIA_PORTAL_URL + '':
		organizationID = process.env.REACT_APP_ADVANIA_ORG_ID + ''
		break

	// Software One
	case process.env.REACT_APP_SO_PORTAL_URL + '':
		organizationID = process.env.REACT_APP_SO_ORG_ID + ''
		break
	// Mirus
	case process.env.REACT_APP_MIRUS_PORTAL_URL + '':
		organizationID = process.env.REACT_APP_MIRUS_ORG_ID + ''
		break
	// Digital Voice
	case process.env.REACT_APP_DIGITALVOICE_PORTAL_URL + '':
		organizationID = process.env.REACT_APP_DIGITALVOICE_ORG_ID + ''
		break
	// Sipcom
	case process.env.REACT_APP_SIPCOM_PORTAL_URL + '':
		organizationID = process.env.REACT_APP_SIPCOM_ORG_ID + ''
		break

	// Default is using Sipcom
	default:
		organizationID = process.env.REACT_APP_SIPCOM_ORG_ID + ''
		break
}

// Redirect Callback
const onRedirectCallback = (appState: any) => {
	// Redirect to the homepage after successful login
	window.location.replace(appState?.returnTo || window.location.origin)
}

root.render(
	// Need to add organisation here for all different partners
	<Auth0Provider
		domain='auth.ucplatform.io'
		clientId={process.env.REACT_APP_AUTH0_CLIENT_ID + ''}
		authorizationParams={{
			redirect_uri: window.location.origin,
			organization: organizationID,
		}}
		onRedirectCallback={onRedirectCallback}>
		<Provider store={store}>
			<App />
		</Provider>
	</Auth0Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
