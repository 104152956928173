import { Box, Tab } from '@mui/material'
import UseCrud from '../../utils/customHooks/APICalls/UseCrud'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { useAppDispatch } from '../../store/hooks'
import './Settings.scss'
import { PartnerCustomization } from '../../utils/interfaces/DBModels'
import { setInitializeSiteTheme } from '../../store/reducers/reducers'
import { useState } from 'react'
import { StyledTabPanel } from '../../styles/styledComponents/displays/StyledTabPanel'
import AppearanceSettings from '../../components/distinct/settings/AppearanceSettings'
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings'
import PortalUserManagementIcon from '@mui/icons-material/People'
import CommunicationManagementIcon from '@mui/icons-material/Mail'
import { StyledTabs } from '../../styles/styledComponents/displays/StyledTabs'
import { Permissions, ReturnTypes } from '../../utils/enums/enums'
import { DataResponse } from '../../utils/interfaces/APIModels'
import PortalUserManagement from '../../components/distinct/settings/PortalUserManagement/PortalUserManagement'
import CommunicationManagement from '../../components/distinct/settings/CommunicationManagement/CommunicationManagement'

function Settings() {
	// Global variables
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const loggedInUserPermissions = useSelector(
		(state: RootState) =>
			state.RootReducer.loggedInUserRolesPermissionsReducer.value
	)
	const partnerID = useSelector(
		(state: RootState) => state.RootReducer.partnerIDReducer.value
	)

	// Tabs Array
	const initialTabItems = [
		{
			permission: Permissions.ViewAppearanceSettings,
			label: 'Appearance',
			icon: <DisplaySettingsIcon />,
			component: (
				<AppearanceSettings
					modifyPartnerCustomization={modifyPartnerCustomization}
				/>
			),
		},
		{
			permission: Permissions.ViewPortalUserSettings,
			label: 'Portal Users',
			icon: <PortalUserManagementIcon />,
			component: <PortalUserManagement />,
		},
		{
			permission: Permissions.ViewCommunicationSettings,
			label: 'Communication',
			icon: <CommunicationManagementIcon />,
			component: <CommunicationManagement />,
		},
	]
	// Filter out tabs the user doesn't have permission to view
	const visibleTabs = initialTabItems.filter((tab) =>
		loggedInUserPermissions.some((perm) => perm.PermissionID === tab.permission)
	)

	// Flags
	const [tabValue, setTabValue] = useState(0)

	// Hooks
	const { fetchData, modifyData } = UseCrud()

	// General
	const dispatch = useAppDispatch()

	// POST: Add/Update Partner Customization
	async function modifyPartnerCustomization(
		partnerCustomizationObj: PartnerCustomization
	) {
		// Build URL - Add (default) or update
		var queryURL = 'AddV2?Params=PartnerCustomization'
		// Check if it exists first
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'Settings.tsx: modifyPartnerCustomization()',
			QueryURL: `GetV2?Params=PartnerCustomization.First(PartnerCustomization.CustomerPartnerID = '${partnerID}')`,
			ShowErrorToast: false,
			ErrorMessage:
				'An error occurred when checking for an existing partner customization',
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var partnerCustomizationResponse = dataResponse.Obj
				.PartnerCustomization as PartnerCustomization

			if (
				partnerCustomizationResponse &&
				partnerCustomizationResponse.PartnerCustomizationID
			) {
				// Set the ID and the query URL
				partnerCustomizationObj.PartnerCustomizationID =
					partnerCustomizationResponse.PartnerCustomizationID
				queryURL = 'UpdateV2?Params=PartnerCustomization'
			}
		}
		// Object to send to be updated
		var modifyThemeObj = {
			PartnerCustomization: partnerCustomizationObj,
		}
		// Post to DB
		var postSuccess = await modifyData({
			UserName: loggedInUser.email,
			FileAndFunctionName: 'Settings.tsx: modifyPartnerCustomization()',
			QueryURL: queryURL,
			QueryObj: modifyThemeObj,
			ShowSuccessMessage: true,
			SuccessMessage: 'The theme for this site has been updated successfully.',
			ShowErrorMessage: false,
			ErrorMessage: 'An error occurred when updating the theme for the partner',
			LogErrorToDB: true,
		})
		// If success, post to global state
		if (postSuccess) {
			dispatch(setInitializeSiteTheme(false))
		}
	}

	// Handle Function - Tab change
	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue)
	}

	return (
		<>
			{/* Settings Tabs - Appearance, Communication */}
			<Box className='settings-container'>
				{/* Tabs */}
				<StyledTabs
					value={tabValue}
					onChange={handleTabChange}
					variant='fullWidth'>
					{visibleTabs.map((tab, index) => (
						<Tab key={index} icon={tab.icon} label={tab.label} />
					))}
				</StyledTabs>
				{/* Tab Panels */}
				{visibleTabs.map((tab, index) => (
					<StyledTabPanel key={index} value={tabValue} index={index}>
						{tab.component}
					</StyledTabPanel>
				))}
			</Box>
		</>
	)
}

export default Settings
