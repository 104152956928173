import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { useEffect, useState } from 'react'
import { PricingTypes, ReturnTypes, Roles } from '../../../../utils/enums/enums'
import UseCrud from '../../../../utils/customHooks/APICalls/UseCrud'
import {
	DataResponse,
	FilteredOrderDisplay,
	FilteredOrderResponse,
	FilteredOrderSKUDisplay,
	FilterRequest,
	OrderResponse,
} from '../../../../utils/interfaces/APIModels'
import { Customer, SKUPartnerMap } from '../../../../utils/interfaces/DBModels'
import OrdersOverviewDisplay from './OrdersOverviewDisplay/OrdersOverviewDisplay'
import LoadingBox from '../../displays/LoadingBox/LoadingBox'
import './OrdersOverview.scss'
import { ErrorDisplay } from '../../displays/ErrorDisplay/ErrorDisplay'
import FilterOrdersHook from '../../../../utils/customHooks/APICalls/FilterOrdersHook/FilterOrdersHook'

const OrdersOverview = () => {
	// Global variables
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const roleID = useSelector(
		(state: RootState) => state.RootReducer.roleIDReducer.value
	)
	const partnerID = useSelector(
		(state: RootState) => state.RootReducer.partnerIDReducer.value
	)

	// Hooks
	const { fetchData } = UseCrud()

	const { retrieveFilteredOrders } = FilterOrdersHook()

	// Flags
	const [orderCallMade, setOrderCallMade] = useState(false)
	const [apiCallsDone, setAPICallsDone] = useState(false)
	const [pageNo, setPageNo] = useState(0)
	const [pageSize, setPageSize] = useState(0)
	const [isPartner, setIsPartner] = useState(false)

	// Display constants
	const [orderResponse, setOrderResponse] = useState(
		{} as FilteredOrderResponse
	)

	// Error display
	const [errorTitle, setErrorTitle] = useState('')
	const [errorMessage, setErrorMessage] = useState('')

	// Arrays
	const [orderDisplay, setOrderDisplay] = useState([] as FilteredOrderDisplay[])
	const [customerList, setCustomerList] = useState([] as Customer[])
	const [skuPartnerMapList, setSkuPartnerMapList] = useState([] as any[])
	const [orderSKUList, setOrderSKUList] = useState(
		[] as FilteredOrderSKUDisplay[]
	)
	const [standardNoRangeNoTermSKUs, setStandardNoRangeNoTermSKUs] = useState(
		[] as SKUPartnerMap[]
	)

	// 1 - Controls customer ID change to reload component on customer overview
	useEffect(() => {
		resetFlagsAndArrays()
		handleOrderCall()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Reset flags and array - Mainly for when the customerID changes
	const resetFlagsAndArrays = () => {
		// Flags
		setOrderCallMade(false)
		setAPICallsDone(false)
		//Objects
		setOrderResponse({})
		// Arrays
		setCustomerList([])
		setSkuPartnerMapList([])
		setOrderSKUList([])
	}

	// Handle call to get orders
	const handleOrderCall = () => {
		if (errorMessage) return

		if (!orderCallMade) {
			setOrderCallMade(true)
			getOrders({
				pageNo: 1,
				pageSize: 10,
			})
			getSkuPartnerList()
		}
	}

	// Validation to make call
	const validateCall = () => {
		let isValid = true

		if (roleID === Roles.CustomerAdmin) {
			if (!loggedInUser.customerID) {
				isValid = false
				setError(
					'No customer ID assigned',
					'Please contact support for assistance.'
				)
			} else if (!checkIfCustomerExists()) {
				isValid = false
				setError(
					'Invalid customer ID assigned',
					'Please contact support for assistance.'
				)
			}
		}

		return isValid
	}

	// Handle set of error
	const setError = (title: string, message: string) => {
		setErrorTitle(title)
		setErrorMessage(message)
	}

	// *** API Calls *** //
	// GET: Validate that the customerID exists
	const checkIfCustomerExists = async () => {
		// Make hook call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'OrdersOverview.tsx: getOrders()',
			QueryURL: `GetV2?Params=Customer.Exists(Customer.CustomerID = '${loggedInUser.customerID}')`,
			ErrorMessage: 'An error occurred when getting order information',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && Number(dataResponse.Count) > 0) {
			return true
		}

		return false
	}

	// GET: Return orders for customer or partner depending on role and build display array
	const getOrders = async (filterRequest: FilterRequest) => {
		try {
			// Check if all is valid
			const isValid = validateCall()

			if (!isValid) return

			// Set the page number and size
			setPageNo(filterRequest.pageNo)
			setPageSize(filterRequest.pageSize)

			// Ensure filters is initialized
			const filters = filterRequest.filters ? { ...filterRequest.filters } : {}

			//check which filters to use
			switch (roleID) {
				case Roles.CustomerAdmin:
					filters['CustomerPartnerID'] = `${partnerID}`
					filters['CustomerID'] = `${loggedInUser.customerID}`
					break
				case Roles.PartnerAdmin:
					filters['CustomerPartnerID'] = `${partnerID}`
					filters['SipcomDirect'] = 'false'
					setIsPartner(true)
					break
				default:
					break
			}

			// Replace filters in filterRequest
			const updatedFilterRequest = { ...filterRequest, filters }

			var orderResponse: OrderResponse = await retrieveFilteredOrders(
				updatedFilterRequest
			)

			if (orderResponse) {
				//modify the list
				modifyOrderResponseList(
					orderResponse?.FilteredOrderResponse as FilteredOrderResponse
				)
			}
			// Once all is done
			setAPICallsDone(true)
		} catch {
			// Set error display
			setError('An error occurred', 'An error occurred when retrieving orders')
		}
	}

	const modifyOrderResponseList = async (
		orderResponse: FilteredOrderResponse
	) => {
		if (orderResponse?.FilteredOrdersResponse) {
			//if the nrc value or current product is null, make it empty for display
			var modifiedOrderResponse = orderResponse.FilteredOrdersResponse?.map(
				(order) => ({
					...order,
					OrderSKUs: order.OrderSKUs?.map((sku) => ({
						...sku,
						NRC: sku.NRC === null ? 0 : sku.NRC,
						CurrentProduct:
							sku.CurrentProduct === null ? '' : sku.CurrentProduct,
					})),
				})
			)

			if (modifiedOrderResponse) {
				//map the order currency + order id to each sku object in orderskus[]
				modifiedOrderResponse.forEach((order) =>
					order.OrderSKUs?.forEach((sku) => {
						sku.CurrencyName = order.CurrencyName
						sku.OrderID = order.OrderID
					})
				)

				//set the response && pass it through for the total pages count
				setOrderResponse(orderResponse as FilteredOrderResponse)
				//set the display
				setOrderDisplay(modifiedOrderResponse as FilteredOrderDisplay[])

				//seperate order sku list
				//flat map used to extract inner array
				var skuList = modifiedOrderResponse.flatMap(
					(ordersku) => ordersku.OrderSKUs
				)
				setOrderSKUList(skuList as FilteredOrderSKUDisplay[])
			}
		} else {
			setOrderDisplay([] as FilteredOrderDisplay[])
		}
	}

	//Get the sku partner list
	const getSkuPartnerList = async () => {
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'OrdersOverview.tsx: getOrders()',
			QueryURL: `GetV2?Params=SKUPartnerMap.PriceBook.Where(SKUPartnerMap.CustomerPartnerID = '${partnerID}' & SKUPartnerMap.SKUTypeID !~ 'POC'), Customer.Where(Customer.CustomerPartnerID  = '${partnerID}')`,
			ErrorMessage: 'An error occurred when getting order information',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse

		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var _skuPartnerMapList = dataResponse.Obj
				.SKUPartnerMapList as SKUPartnerMap[]
			var _customerList = dataResponse.Obj.CustomerList as Customer[]

			if (_skuPartnerMapList && _skuPartnerMapList.length > 0) {
				var modifiedSkuPartnerMapList: any[] = _skuPartnerMapList.map(
					(x) => x.SKUTypeID
				)
				setSkuPartnerMapList(modifiedSkuPartnerMapList)

				var standardNoRangeNoTermSKUList = _skuPartnerMapList.filter(
					(x) =>
						x.PricingTypeID === PricingTypes['Standard No Range No Term'] &&
						x.PriceBookList?.every((priceBook) => priceBook.SipcomMRC === 0.0)
				)

				setStandardNoRangeNoTermSKUs(standardNoRangeNoTermSKUList)
			}

			if (_customerList && _customerList.length > 0) {
				setCustomerList(_customerList)
			}
		}
	}

	return apiCallsDone ? (
		// Display datagrid
		<OrdersOverviewDisplay
			orderResponse={orderResponse}
			orderDisplay={orderDisplay}
			pageNo={pageNo}
			pageSize={pageSize}
			customerList={customerList}
			skuPartnerMapList={skuPartnerMapList}
			orderSKUList={orderSKUList}
			standardNoRangeNoTermSKUs={standardNoRangeNoTermSKUs}
			getOrderDisplay={getOrders}
			isPartner={isPartner}
		/>
	) : errorMessage.length > 0 ? (
		<ErrorDisplay errorTitle={errorTitle} errorDescription={errorMessage} />
	) : (
		// Show loading
		<LoadingBox title='Getting Orders' />
	)
}

export default OrdersOverview
