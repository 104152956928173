import { Box, Tooltip, Typography, useTheme } from '@mui/material'
import { TUMBuildStatuses } from '../../../../../../utils/enums/enums'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import Hourglass from '../../../../../shared/displays/Preloader/Hourglass/Hourglass'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import './TUMLoadingRow.scss'

const TUMLoadingRow = ({
	heading,
	description,
	status,
}: {
	heading: string
	description: string
	status: number
}) => {
	// Theme
	const theme = useTheme()

	// Words or phrases to be colorized
	const wordsToColorize = ['error', 'invalid'] // Add more words/phrases as needed

	// Generate regular expression pattern dynamically using wordsToColorize array
	const pattern = new RegExp(`\\b(${wordsToColorize.join('|')})\\b`, 'gi') // 'i' flag added for case-insensitivity

	// Replace matched words with colored spans
	const coloredDescription = description.replace(
		pattern,
		`<span style="color: ${theme.palette.error.main};">$1</span>`
	)

	return (
		<>
			<Box className='tum-loading-row-container'>
				{/* Text Box */}
				<Box className='row-text'>
					<Typography component='h4'>{heading}</Typography>
					<Typography
						component='p'
						dangerouslySetInnerHTML={{ __html: coloredDescription }}
					/>
				</Box>
				{/* Loading / Icon box */}
				<Box className='row-icon'>
					{status === TUMBuildStatuses.Loading ? (
						<Hourglass width='1em' height='2em' />
					) : status === TUMBuildStatuses.Success ? (
						<CheckCircleIcon sx={{ color: theme.palette.success.main }} />
					) : status === TUMBuildStatuses.Failure ? (
						<Tooltip title={description}>
							<ErrorIcon sx={{ color: theme.palette.error.main }} />
						</Tooltip>
					) : (
						<Tooltip title='Pending action'>
							<HourglassEmptyIcon
								sx={{
									color: theme.palette.primary.main,
								}}
							/>
						</Tooltip>
					)}
				</Box>
			</Box>
		</>
	)
}

export default TUMLoadingRow
