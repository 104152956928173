import { styled, TextField } from '@mui/material'
import { hexToRgba } from '../../../utils/helperFunctions/helperFunctions'

export const StyledTextField = styled(TextField)(({ theme }) => ({
	color: theme.palette.mode === 'light' ? '#000' : '#fff',
	'& fieldset': {
		borderColor: hexToRgba(theme.palette.primary.main, 0.2),
	},
	'&:hover .MuiOutlinedInput-notchedOutline': {
		borderColor: `${theme.palette.primary.main}!important`,
	},
	'& input': {
		padding: '10px 24px 10px 10px',
	},
	'& .MuiInputBase-root': {
		borderRadius: 10,
		height: '100%',
	},
}))
