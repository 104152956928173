import { Box, IconButton, MenuItem, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { OrderSKU } from '../../../../../../../utils/interfaces/DBModels'
import { StyledSelect } from '../../../../../../../styles/styledComponents/inputs/StyledSelect'
import { StyledTextField } from '../../../../../../../styles/styledComponents/inputs/StyledTextField'
import { SkuDetails } from '../../../../../../../utils/interfaces/APIModels'
import { OrderSKUAutoRenewTerm } from '../../../../../../../utils/enums/enums'

const ModifyOrderType = ({
	skuDetailsArray,
	skuDropDownList,
	disabledSkus,
	handleSkuChange,
	index,
	skuDetailsErrorArray,
	handleQuantityChange,
	deleteConfirmation,
	numberOfSkus,
	autoRenewPeriodList,
	handleAutoRenewTermChange,
	disableDeleteButtonForModifyOrTrueUpOrderType,
}: {
	skuDetailsArray: SkuDetails[]
	skuDropDownList: OrderSKU[]
	disabledSkus: any
	handleSkuChange: any
	index: number
	skuDetailsErrorArray: any[]
	handleQuantityChange: any
	deleteConfirmation: any
	numberOfSkus: number
	autoRenewPeriodList: string[]
	handleAutoRenewTermChange: any
	disableDeleteButtonForModifyOrTrueUpOrderType: any
}) => {
	return (
		<>
			<Box key={index} className='order-form-pd' style={{ padding: '0px' }}>
				{/* SKU Dropdown */}
				<Box className='order-form-input'>
					<Typography component='p'>
						SKU Name:{' '}
						<Typography component='span' className='required'>
							*
						</Typography>
					</Typography>

					<StyledSelect
						fullWidth
						value={skuDetailsArray[index]?.SkuTypeID || 'Please select a SKU'}
						type='text'
						onChange={(e) => handleSkuChange(e, index)}>
						<MenuItem disabled value={'Please select a SKU'}>
							Please select a SKU
						</MenuItem>
						{skuDropDownList.map((item, index) => {
							return (
								<MenuItem
									key={index}
									value={item.SKUTypeID}
									disabled={disabledSkus.includes(item.SKUTypeID)}>
									{item.SKUTypeID}
								</MenuItem>
							)
						})}
					</StyledSelect>
				</Box>

				{/* Quantity */}
				<Box className='order-form-input'>
					<Typography component='p'>
						Additional Quantity:{' '}
						<Typography component='span' className='required'>
							*
						</Typography>
					</Typography>

					<StyledTextField
						fullWidth
						required
						error={skuDetailsErrorArray[index]?.Quantity}
						helperText={
							skuDetailsErrorArray[index]?.Quantity &&
							'The quantity must be at least one and should not be a decimal value.'
						}
						value={skuDetailsArray[index]?.Quantity}
						type='number'
						placeholder='0'
						onChange={(e) => handleQuantityChange(e, index)}
					/>
				</Box>

				<Box className='order-form-input'>
					{/* Auto Renew Term (months) */}
					<Typography component='p'>Auto Renew Term: </Typography>
					<StyledSelect
						fullWidth
						required
						value={
							skuDetailsArray[index]?.AutoRenewMonths ||
							OrderSKUAutoRenewTerm[3]
						}
						placeholder='SKU Term'
						onChange={(e) => handleAutoRenewTermChange(e, index)}>
						{autoRenewPeriodList.map((item, index) => {
							return (
								<MenuItem key={index} value={item}>
									{item}
								</MenuItem>
							)
						})}
					</StyledSelect>
				</Box>

				{numberOfSkus === 1 ? (
					<>
						<Box className='order-form-input'></Box>
					</>
				) : (
					<>
						<IconButton
							className='order-form-delete-icon'
							disabled={disableDeleteButtonForModifyOrTrueUpOrderType(index)}
							onClick={() => {
								deleteConfirmation(index)
							}}>
							<Typography
								component='p'
								style={{
									color: disableDeleteButtonForModifyOrTrueUpOrderType(index)
										? '#CCCCCC'
										: '#CE3434',
								}}>
								Delete SKU
							</Typography>
							<DeleteIcon
								className='delete-icon'
								style={{
									color: disableDeleteButtonForModifyOrTrueUpOrderType(index)
										? '#CCCCCC'
										: '#CE3434',
								}}
							/>
						</IconButton>
					</>
				)}
			</Box>
		</>
	)
}

export default ModifyOrderType
