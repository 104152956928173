import { Tooltip } from '@mui/material'
import './CustomColourPicker.scss'

interface ColourPickerProps {
	value: string
	onChange: (color: string) => void
}

const CustomColourPicker: React.FC<ColourPickerProps> = ({
	value,
	onChange,
}) => {
	// Handle change of colour
	const handleColourChange = (colour: string) => {
		onChange(colour)
	}

	return (
		<Tooltip title='Click to pick a colour'>
			<input
				className='colour-picker'
				value={value}
				type='color'
				onChange={(colour) => handleColourChange(colour.target.value)}
			/>
		</Tooltip>
	)
}

export default CustomColourPicker
