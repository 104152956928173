import {
	Box,
	FormControl,
	FormControlLabel,
	IconButton,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material'
import { ModalForm } from '../../../../inputs/ModalForm/ModalForm'
import './Modals.scss'
import { useState } from 'react'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { StyledAnimatedBox } from '../../../../../../styles/styledComponents/displays/StyledAnimatedBox'

function AddressValidationResultView({
	addressSubmitted,
	recommendedAddress,
	isEditAddress,
	handleConfirmAddress,
	handleCloseModal,
}: {
	addressSubmitted: string
	recommendedAddress: string
	isEditAddress: boolean
	handleConfirmAddress: any
	handleCloseModal: any
}) {
	const [selectedAddress, setSelectedAddress] = useState(
		recommendedAddress.length > 0 ? 1 : 0
	)

	return (
		<ModalForm
			icon=''
			title='Confirm Address'
			description='Review the recommended changes'
			buttonText={`${isEditAddress ? `Update` : `Add`} Address`}
			buttonAction={() => {
				handleConfirmAddress(selectedAddress)
				handleCloseModal()
			}}
			buttonLoading={false}
			buttonDisabled={false}
			closeModal={handleCloseModal}>
			<FormControl sx={{ display: 'flex', flexDirection: 'row' }}>
				<RadioGroup
					row
					name='row-radio-buttons-group'
					defaultValue={0}
					onChange={(e) => setSelectedAddress(Number(e.target.value))}>
					<StyledAnimatedBox
						className='val-results'
						children={
							<>
								<Box className='form-group'>
									<FormControlLabel
										value={0}
										control={<Radio checked={selectedAddress === 0} />}
										label='What you entered'
										labelPlacement='end'
									/>
								</Box>
								<Typography>{addressSubmitted}</Typography>
							</>
						}
					/>

					{recommendedAddress.length > 0 ? (
						<StyledAnimatedBox
							className='val-results'
							children={
								<>
									<Box className='form-group'>
										{' '}
										<FormControlLabel
											value={1}
											control={<Radio checked={selectedAddress === 1} />}
											label='Recommended'
											labelPlacement='end'
										/>
									</Box>
									<Typography>{recommendedAddress}</Typography>
								</>
							}
						/>
					) : (
						<StyledAnimatedBox
							className='val-results'
							children={
								<Box className='form-group'>
									<span>
										<IconButton>
											<WarningAmberIcon sx={{ color: 'orange' }} />
											Failed to validate address
										</IconButton>
									</span>
									<Typography>
										Update entered address or proceed with address entered.
									</Typography>
								</Box>
							}
						/>
					)}
				</RadioGroup>
			</FormControl>
		</ModalForm>
	)
}

export default AddressValidationResultView
