import { Box, Typography, useTheme } from '@mui/material'

export const ErrorDisplay = ({
	errorTitle,
	errorDescription,
}: {
	errorTitle: string
	errorDescription: string
}) => {
	// Theme
	const theme = useTheme()

	return (
		<>
			<Box display='grid' gridTemplateColumns='1fr 2fr' columnGap='30px'>
				{/* SVG */}
				<svg
					version='1.1'
					xmlns='http://www.w3.org/2000/svg'
					xmlnsXlink='http://www.w3.org/1999/xlink'
					x='0px'
					y='0px'
					viewBox='0 0 500 500'
					enableBackground='new 0 0 500 500'
					xmlSpace='preserve'>
					<g id='Background_Simple'>
						<g>
							<path
								fill={theme.palette.primary.main}
								d='M467.605,251.913c-4.936,41.801-24.022,90.403-62.141,112.73
			c-57.595,33.742-52.774,85.638-165.193,108.199c-17.527,3.519-34.161,5-49.846,4.708c-8.432-0.156-16.593-0.836-24.465-1.995
			C94.512,465.119,46.43,415.455,28.79,355.505C5.575,276.66,51.928,225.042,82.652,193.941
			c30.724-31.1,53.226-128.004,163.567-151.052c16.824-3.507,34.029-5.296,51.229-4.867c39.259,0.963,82.08,13.855,111.71,40.761
			c2.225,2.022,4.388,4.118,6.497,6.278C456.838,127.332,474.338,194.925,467.605,251.913z'
							/>
							<path
								fill='#fff'
								opacity='0.7'
								d='M467.605,251.913c-4.936,41.801-24.022,90.403-62.141,112.73
			c-57.595,33.742-52.774,85.638-165.193,108.199c-17.527,3.519-34.161,5-49.846,4.708c-8.432-0.156-16.593-0.836-24.465-1.995
			C94.512,465.119,46.43,415.455,28.79,355.505C5.575,276.66,51.928,225.042,82.652,193.941
			c30.724-31.1,53.226-128.004,163.567-151.052c16.824-3.507,34.029-5.296,51.229-4.867c39.259,0.963,82.08,13.855,111.71,40.761
			c2.225,2.022,4.388,4.118,6.497,6.278C456.838,127.332,474.338,194.925,467.605,251.913z'
							/>
						</g>
					</g>
					<g id='Plant'>
						<g>
							<g>
								<path
									fill='#263238'
									d='M22.257,265.784c3.109,1.358,10.714,1.187,10.72,1.187c0.079-0.002-1.635-4.978-1.779-5.28
				c-1.668-3.491-6.945-9.255-11.239-8.674c-3.093,0.418-4.813,3.514-4.115,6.426C16.557,262.417,19.609,264.626,22.257,265.784z'
								/>
								<path
									fill='#263238'
									d='M24.882,287.813c3.225,0.47,10.013-1.498,10.552-1.657c1.072,3.8,2.479,7.612,4.117,11.39
				c0.933,2.162,1.941,4.31,2.978,6.451c-0.619-0.907-1.179-1.713-1.269-1.818c-2.532-2.926-9.143-7.091-13.131-5.397
				c-2.874,1.22-3.715,4.659-2.272,7.284c1.472,2.681,5.001,4.006,7.859,4.423c2.902,0.423,8.722-1.139,10.257-1.573
				c0.406,0.817,0.803,1.638,1.213,2.453c1.965,3.914,3.943,7.81,5.767,11.692c0.643,1.367,1.226,2.738,1.815,4.107
				c-0.551-0.805-1.021-1.479-1.104-1.574c-2.532-2.925-9.143-7.091-13.131-5.397c-2.873,1.22-3.715,4.66-2.272,7.284
				c1.474,2.681,5.001,4.006,7.861,4.422c2.769,0.404,8.154-0.99,9.98-1.496c0.56,1.42,1.124,2.842,1.603,4.243
				c0.306,0.964,0.667,1.872,0.919,2.857c0.271,0.971,0.539,1.932,0.804,2.883c0.444,1.851,0.914,3.758,1.302,5.575
				c0.279,1.262,0.522,2.469,0.77,3.685c-0.624-1.369-1.903-4.023-2.035-4.233c-2.058-3.276-7.962-8.394-12.161-7.325
				c-3.025,0.771-4.378,4.044-3.349,6.856c1.05,2.872,4.335,4.717,7.098,5.562c3.043,0.93,9.779,0.08,10.62-0.032
				c0.404,2.013,0.777,3.974,1.1,5.831c0.722,4.097,1.267,7.77,1.682,10.879h2.84c-0.495-3.216-1.142-7.048-1.999-11.35
				c-0.401-2.046-0.871-4.223-1.375-6.455c0.506-0.201,3.38-1.363,5.925-2.786c2.217,2.402,7.9,5.212,8.636,5.57
				c-0.773,1.902-1.544,3.744-2.299,5.47c-1.571,3.616-3.064,6.823-4.381,9.552h3.071c1.132-2.515,2.378-5.379,3.675-8.552
				c0.794-1.928,1.604-4.004,2.414-6.143c0.815,0.17,7.483,1.525,10.592,0.823c2.818-0.637,6.232-2.232,7.495-5.018
				c1.235-2.728,0.129-6.092-2.831-7.086c-4.106-1.38-10.376,3.285-12.673,6.398c-0.136,0.183-1.384,2.34-2.148,3.714
				c0.326-0.875,0.653-1.723,0.979-2.628c0.656-1.786,1.257-3.589,1.911-5.525c0.283-0.946,0.569-1.902,0.858-2.868
				c0.306-0.952,0.509-1.995,0.772-3.001c0.559-2.346,1.01-4.749,1.412-7.181c1.566,0.57,7.156,2.515,10.052,2.311
				c2.883-0.203,6.498-1.261,8.167-3.825c1.635-2.509,1.051-6.003-1.723-7.434c-3.851-1.986-10.755,1.676-13.497,4.405
				c-0.171,0.17-1.918,2.349-2.855,3.565c0.23-1.463,0.466-2.923,0.651-4.411c0.534-4.296,0.939-8.661,1.364-13.014
				c0.129-1.334,0.274-2.661,0.414-3.989c0.555,0.209,7.149,2.664,10.393,2.436c2.882-0.202,6.499-1.262,8.168-3.825
				c1.634-2.509,1.051-6.003-1.723-7.433c-3.851-1.986-10.754,1.675-13.496,4.404c-0.206,0.205-2.717,3.345-3.316,4.175
				c0.309-2.927,0.645-5.841,1.092-8.7c0.611-3.945,1.43-7.783,2.497-11.442c0.258,0.098,7.129,2.691,10.451,2.458
				c2.883-0.203,6.499-1.262,8.168-3.825c1.634-2.509,1.052-6.002-1.723-7.434c-3.851-1.986-10.754,1.675-13.497,4.404
				c-0.214,0.214-2.914,3.594-3.359,4.242c0.064-0.217,0.106-0.45,0.173-0.666c1.19-3.892,2.83-7.513,4.533-10.828
				c0.986-1.928,2.036-3.716,3.095-5.433c1.231,0.614,6.884,3.359,9.896,3.48c2.888,0.115,6.598-0.54,8.538-2.905
				c1.901-2.315,1.705-5.851-0.896-7.578c-3.61-2.397-10.873,0.484-13.899,2.896c-0.164,0.131-1.689,1.65-2.784,2.771
				c0.49-0.768,0.982-1.569,1.466-2.294c1.812-2.73,3.64-5.117,5.276-7.22c0.94-1.187,1.813-2.242,2.641-3.221
				c1.521-0.026,7.652-0.213,10.332-1.471c2.615-1.229,5.607-3.52,6.24-6.512c0.619-2.93-1.185-5.979-4.287-6.313
				c-4.308-0.464-9.427,5.439-11.001,8.974c-0.136,0.306-1.714,5.327-1.635,5.327c0,0,0.074-0.001,0.092-0.001
				c-0.816,0.926-1.679,1.927-2.6,3.042c-1.686,2.07-3.57,4.424-5.449,7.125c-1.885,2.694-3.856,5.675-5.65,9.001
				c-1.803,3.332-3.528,6.929-4.826,10.865c-0.015,0.044-0.024,0.093-0.039,0.137c0.023-1.269-0.033-4.82-0.069-5.092
				c-0.497-3.837-3.713-10.959-7.974-11.747c-3.07-0.567-5.669,1.838-5.914,4.823c-0.249,3.049,1.961,6.099,4.116,8.025
				c2.43,2.171,9.177,4.273,9.715,4.44c-1.203,3.759-2.131,7.715-2.85,11.77c-0.414,2.318-0.758,4.666-1.074,7.024
				c-0.016-1.098-0.038-2.08-0.057-2.216c-0.498-3.838-3.713-10.958-7.974-11.746c-3.07-0.568-5.669,1.838-5.913,4.823
				c-0.25,3.048,1.96,6.099,4.115,8.024c2.188,1.953,7.903,3.862,9.422,4.346c-0.112,0.906-0.233,1.81-0.341,2.716
				c-0.52,4.348-1.019,8.689-1.64,12.932c-0.218,1.496-0.487,2.96-0.752,4.426c-0.015-0.975-0.036-1.796-0.052-1.921
				c-0.497-3.837-3.713-10.959-7.973-11.747c-3.07-0.567-5.67,1.837-5.915,4.823c-0.25,3.047,1.962,6.099,4.117,8.024
				c2.086,1.864,7.347,3.673,9.149,4.257c-0.316,1.494-0.63,2.99-1.004,4.424c-0.278,0.973-0.477,1.928-0.81,2.89
				c-0.309,0.958-0.615,1.908-0.919,2.848c-0.651,1.789-1.312,3.639-1.99,5.369c-0.463,1.206-0.927,2.347-1.39,3.498
				c0.234-1.486,0.632-4.405,0.637-4.652c0.059-2.57-0.853-6.748-2.67-9.686c0.011-0.38-0.011-0.758-0.071-1.134
				c-0.475-2.957-3.253-5.153-6.27-4.35c-4.187,1.114-6.842,8.464-7.04,12.327c-0.012,0.228,0.137,2.715,0.257,4.283
				c-0.211-0.911-0.405-1.797-0.633-2.732c-0.439-1.852-0.932-3.687-1.454-5.664c-0.285-0.945-0.575-1.9-0.867-2.865
				c-0.269-0.962-0.675-1.944-1.012-2.928c-0.828-2.264-1.777-4.519-2.784-6.768c1.622-0.388,7.356-1.85,9.659-3.618
				c2.293-1.759,4.724-4.638,4.703-7.695c-0.022-2.995-2.434-5.587-5.538-5.25c-4.308,0.468-8.046,7.33-8.828,11.119
				c-0.049,0.237-0.304,3.018-0.415,4.549c-0.615-1.347-1.224-2.696-1.891-4.039c-1.925-3.878-3.994-7.742-6.042-11.607
				c-0.628-1.184-1.239-2.37-1.856-3.555c0.579-0.133,7.433-1.723,10.013-3.702c2.292-1.759,4.724-4.637,4.702-7.696
				c-0.021-2.994-2.434-5.587-5.538-5.249c-4.308,0.468-8.046,7.329-8.828,11.119c-0.058,0.285-0.42,4.289-0.462,5.311
				c-1.356-2.611-2.684-5.227-3.889-7.858c-1.666-3.628-3.101-7.28-4.229-10.921c0.27-0.061,7.431-1.689,10.073-3.716
				c2.293-1.759,4.724-4.637,4.702-7.696c-0.021-2.995-2.434-5.586-5.538-5.249c-4.308,0.467-8.045,7.33-8.827,11.119
				c-0.062,0.298-0.448,4.605-0.462,5.39c-0.067-0.216-0.16-0.433-0.224-0.65c-1.154-3.903-1.784-7.828-2.192-11.532
				c-0.242-2.151-0.352-4.221-0.414-6.238c1.365-0.167,7.594-0.996,10.173-2.557c2.473-1.497,5.206-4.09,5.52-7.133
				c0.307-2.979-1.807-5.821-4.928-5.826c-4.333-0.008-8.802,6.402-9.996,10.083c-0.065,0.2-0.5,2.308-0.794,3.847
				c-0.014-0.91-0.046-1.85-0.042-2.722c0.006-3.277,0.213-6.277,0.418-8.932c0.129-1.509,0.275-2.872,0.426-4.144
				c1.254-0.863,6.264-4.399,7.806-6.928c1.504-2.468,2.734-6.028,1.611-8.873c-1.1-2.786-4.285-4.333-7.058-2.9
				c-3.849,1.99-4.862,9.738-4.225,13.555c0.056,0.329,1.509,5.388,1.575,5.345c0,0,0.061-0.042,0.075-0.052
				c-0.169,1.222-0.336,2.534-0.49,3.971c-0.264,2.656-0.537,5.66-0.614,8.949c-0.085,3.286-0.086,6.861,0.253,10.624
				c0.335,3.772,0.88,7.725,1.968,11.724c0.013,0.045,0.031,0.091,0.044,0.135c-0.682-1.071-2.688-4.001-2.868-4.209
				c-2.531-2.926-9.142-7.092-13.131-5.399c-2.873,1.221-3.715,4.661-2.272,7.285C18.496,286.071,22.023,287.396,24.882,287.813z'
								/>
								<path
									fill='#263238'
									d='M99.181,267.457c0.067,0.042,1.383-5.055,1.43-5.386c0.533-3.832-0.689-11.55-4.59-13.435
				c-2.81-1.358-5.952,0.275-6.976,3.09c-1.046,2.874,0.28,6.4,1.85,8.826C92.738,263.401,99.176,267.453,99.181,267.457z'
								/>
							</g>
							<polygon
								fill='#fff'
								stroke='#263238'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit='10'
								points='
			89.331,406.303 47.762,406.303 42.566,360.137 94.528,360.137 		'
							/>
							<g>
								<polygon
									fill='#263238'
									points='43.518,368.595 44.021,373.068 93.072,373.068 93.576,368.595 			'
								/>
								<polygon
									fill='#263238'
									points='91.965,382.908 92.468,378.435 44.625,378.435 45.129,382.908 			'
								/>
							</g>
						</g>
					</g>
					<g id='Tools'>
						<g>
							<g>
								<path
									opacity='0.3'
									fill='none'
									stroke='#000'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M78.801,131.023l36.126-36.126c6.799,3.063,15.065,1.822,20.652-3.764c4.633-4.633,6.274-11.11,4.958-17.066l-14.375,14.375
				c-0.43,0.43-1.127,0.43-1.557,0l-12.463-12.463c-0.43-0.43-0.43-1.127,0-1.557l14.375-14.375
				c-5.956-1.316-12.433,0.325-17.066,4.958c-5.586,5.586-6.827,13.852-3.764,20.652L69.56,121.783
				c-6.8-3.063-15.066-1.822-20.652,3.764c-4.633,4.633-6.274,11.11-4.958,17.066l14.375-14.375c0.43-0.43,1.127-0.43,1.557,0
				L72.347,140.7c0.43,0.43,0.43,1.127,0,1.557l-14.375,14.375c5.956,1.316,12.433-0.325,17.066-4.958
				C80.623,146.089,81.864,137.823,78.801,131.023z'
								/>
								<path
									opacity='0.3'
									fill='none'
									stroke='#000'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M150.572,50.278l-11.002-3.607l-8.625,7.725l2.377,11.332l11.002,3.607l8.625-7.725L150.572,50.278z M142.887,62.488
				l-4.355-1.428l-0.941-4.486l3.414-3.058l4.355,1.428l0.941,4.486L142.887,62.488z'
								/>
							</g>
							<g>
								<path
									opacity='0.3'
									fill='none'
									stroke='#000'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M397.778,294.383l-36.126,36.126c-6.799-3.064-15.066-1.822-20.652,3.764c-4.633,4.633-6.274,11.11-4.958,17.066l14.375-14.375
				c0.43-0.43,1.127-0.43,1.557,0l12.463,12.463c0.43,0.43,0.43,1.127,0,1.557l-14.375,14.375
				c5.956,1.316,12.433-0.325,17.066-4.958c5.586-5.586,6.827-13.852,3.764-20.652l36.126-36.126
				c6.799,3.064,15.066,1.822,20.652-3.764c4.633-4.633,6.274-11.11,4.958-17.066l-14.375,14.375c-0.43,0.43-1.127,0.43-1.557,0
				l-12.463-12.463c-0.43-0.43-0.43-1.127,0-1.557l14.375-14.375c-5.956-1.316-12.433,0.325-17.066,4.958
				C395.956,279.318,394.714,287.584,397.778,294.383z'
								/>
								<path
									opacity='0.3'
									fill='none'
									stroke='#000'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M326.007,375.129l11.002,3.607l8.625-7.725l-2.377-11.332l-11.002-3.607l-8.625,7.725L326.007,375.129z M333.691,362.918
				l4.355,1.428l0.941,4.486l-3.414,3.058l-4.355-1.428l-0.941-4.486L333.691,362.918z'
								/>
							</g>
							<g>
								<path
									opacity='0.3'
									fill='none'
									stroke='#000'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M378.253,131.023l36.126-36.126c6.799,3.063,15.065,1.822,20.651-3.764c4.633-4.633,6.274-11.11,4.958-17.066l-14.375,14.375
				c-0.43,0.43-1.127,0.43-1.557,0l-12.463-12.463c-0.43-0.43-0.43-1.127,0-1.557l14.375-14.375
				c-5.956-1.316-12.433,0.325-17.066,4.958c-5.586,5.586-6.827,13.852-3.764,20.652l-36.126,36.126
				c-6.799-3.063-15.066-1.822-20.652,3.764c-4.633,4.633-6.274,11.11-4.958,17.066l14.375-14.375c0.43-0.43,1.127-0.43,1.557,0
				l12.463,12.463c0.43,0.43,0.43,1.127,0,1.557l-14.375,14.375c5.956,1.316,12.433-0.325,17.066-4.958
				C380.076,146.089,381.317,137.823,378.253,131.023z'
								/>
								<path
									opacity='0.3'
									fill='none'
									stroke='#000'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M450.024,50.278l-11.002-3.607l-8.625,7.725l2.377,11.332l11.002,3.607l8.625-7.725L450.024,50.278z M442.34,62.488l-4.355-1.428
				l-0.941-4.486l3.414-3.058l4.355,1.428l0.941,4.486L442.34,62.488z'
								/>
								<path
									opacity='0.3'
									fill='none'
									stroke='#000'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M469.883,133.683l-11.002-3.608l-8.625,7.725l2.377,11.332l11.002,3.607l8.625-7.725L469.883,133.683z M462.198,145.893
				l-4.355-1.428l-0.941-4.486l3.414-3.058l4.355,1.428l0.941,4.486L462.198,145.893z'
								/>
								<path
									opacity='0.3'
									fill='none'
									stroke='#000'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M219.674,82.6l-11.002-3.608l-8.625,7.725l2.377,11.332l11.002,3.607l8.625-7.725L219.674,82.6z M211.99,94.811l-4.355-1.428
				l-0.941-4.486l3.414-3.058l4.355,1.428l0.941,4.486L211.99,94.811z'
								/>
								<path
									opacity='0.3'
									fill='none'
									stroke='#000'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M199.816,342.745l-11.002-3.607l-8.625,7.725l2.377,11.332l11.002,3.608l8.625-7.725L199.816,342.745z M192.132,354.956
				l-4.355-1.428l-0.941-4.486l3.414-3.058l4.355,1.428l0.941,4.486L192.132,354.956z'
								/>
								<path
									opacity='0.3'
									fill='none'
									stroke='#000'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M60.145,202.413l-11.002-3.607l-8.625,7.725l2.377,11.332l11.002,3.607l8.625-7.725L60.145,202.413z M52.461,214.623
				l-4.355-1.428l-0.941-4.486l3.414-3.058l4.355,1.428l0.941,4.486L52.461,214.623z'
								/>
							</g>
							<g>
								<path
									opacity='0.3'
									fill='none'
									stroke='#000'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M138.629,352.688l36.126-36.126c6.799,3.064,15.066,1.822,20.652-3.764c4.633-4.633,6.274-11.11,4.958-17.066l-14.375,14.375
				c-0.43,0.43-1.127,0.43-1.557,0l-12.463-12.463c-0.43-0.43-0.43-1.127,0-1.557l14.375-14.375
				c-5.956-1.316-12.433,0.325-17.066,4.958c-5.586,5.586-6.827,13.852-3.764,20.652l-36.126,36.126
				c-6.799-3.064-15.065-1.822-20.652,3.764c-4.633,4.633-6.274,11.11-4.958,17.066l14.375-14.375c0.43-0.43,1.127-0.43,1.557,0
				l12.463,12.463c0.43,0.43,0.43,1.127,0,1.557l-14.375,14.375c5.956,1.316,12.433-0.325,17.066-4.958
				C140.451,367.754,141.692,359.487,138.629,352.688z'
								/>
								<path
									opacity='0.3'
									fill='none'
									stroke='#000'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M210.399,271.942l-11.002-3.607l-8.625,7.725l2.377,11.332l11.002,3.608l8.625-7.725L210.399,271.942z M202.715,284.153
				l-4.355-1.428l-0.941-4.486l3.414-3.058l4.355,1.428l0.941,4.486L202.715,284.153z'
								/>
							</g>
						</g>
					</g>
					<g id='Device'>
						<g>
							<rect
								x='232.004'
								y='295.886'
								fill='#263238'
								stroke='#263238'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit='10'
								width='27.626'
								height='111.457'
							/>

							<rect
								x='78.511'
								y='112.029'
								fill='#263238'
								stroke='#263238'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit='10'
								width='348.662'
								height='211.484'
							/>

							<rect
								x='71.487'
								y='112.029'
								fill={theme.palette.primary.main}
								stroke='#263238'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit='10'
								width='348.662'
								height='211.484'
							/>
							<g>
								<rect
									x='84.051'
									y='119.65'
									fill='#fff'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									width='323.533'
									height='196.242'
								/>

								<rect
									x='84.051'
									y='304.073'
									fill='#fff'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									width='323.533'
									height='11.818'
								/>
							</g>
							<polygon
								fill='#263238'
								stroke='#263238'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit='10'
								points='
			247.326,394.84 144.898,401.459 144.898,408.128 346.737,408.128 346.737,401.459 		'
							/>

							<rect
								x='84.051'
								y='119.65'
								fill='#fff'
								stroke='#263238'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit='10'
								width='323.533'
								height='196.242'
							/>
						</g>
					</g>
					<g id='Floor'>
						<g>
							<line
								fill='none'
								stroke='#263238'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit='10'
								x1='462.874'
								y1='406.303'
								x2='21.936'
								y2='406.303'
							/>

							<line
								fill='none'
								stroke='#263238'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit='10'
								x1='485.768'
								y1='406.303'
								x2='473.897'
								y2='406.303'
							/>
						</g>
					</g>
					<g id='Character'>
						<g>
							<g>
								<path
									fill='#263238'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='M398.33,282.825
				c0,0,1.665,58.977,2.5,69.276c0.835,10.299,3.34,37.3,3.897,42.31c0.557,5.01-0.557,19.207-0.557,19.207
				c-1.392,1.392-1.392,1.392-1.392,1.392l1.113,6.959c0,0-8.907,5.567-13.361,7.237c-4.454,1.67,1.113,3.897,5.01,4.454
				s6.959,0.557,11.413-0.557c4.454-1.113,10.021-3.062,11.969-3.34c1.948-0.278,3.062-2.784,3.062-5.845
				c0-3.062-0.278-3.34,0-4.732c0.278-1.392-1.392-7.237-1.67-8.629c-0.278-1.392,3.897-32.846,3.062-42.867
				c-0.835-10.021-1.113-23.66-1.113-25.052c0-1.392-0.557-15.866-0.557-15.866s4.732,32.011,5.289,33.124
				s9.742,47.599,10.021,49.826c0.278,2.227-0.835,4.732-0.557,6.402c0.278,1.67,1.67,6.681,1.67,6.681s-0.835,7.516-3.34,10.856
				c-2.505,3.34-3.062,6.124-1.67,7.237c1.392,1.114,4.454,1.114,8.072,0.278c3.619-0.835,3.619-0.278,6.959-3.062
				c3.34-2.784,4.454-10.856,4.454-11.691c0-0.835,1.392-3.619,1.392-6.681c0-3.062,0-1.67-1.113-6.124
				c-1.113-4.454-0.835-21.712-1.113-31.733c-0.278-10.021-3.619-30.063-3.619-33.96s-1.67-62.073-1.67-62.073
				S419.769,293.994,398.33,282.825z'
								/>
								<g>
									<path
										fill='#fff'
										stroke='#263238'
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeMiterlimit='10'
										d='
					M410.728,424.349c-4.445-0.202-6.836-2.38-6.836-2.38s-8.908,5.567-13.361,7.237c-4.454,1.67,1.113,3.897,5.01,4.454
					c3.897,0.557,6.959,0.557,11.413-0.557c4.454-1.113,10.021-3.062,11.969-3.34c1.948-0.278,3.062-2.784,3.062-5.845
					c0-1.285-0.049-2.078-0.085-2.67C418.818,422.549,413.694,424.483,410.728,424.349z'
									/>
									<path
										fill='#fff'
										stroke='#263238'
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeMiterlimit='10'
										d='
					M417.799,426.571c-5.051,0.404-10.845,6.532-18.587,5.657c-5.179-0.585-8.303-1.742-9.809-2.444
					c-2.097,1.569,2.676,3.382,6.138,3.876c3.897,0.557,6.959,0.557,11.413-0.557c4.454-1.113,10.021-3.062,11.969-3.34
					c1.411-0.202,2.377-1.58,2.806-3.483C420.822,426.339,419.56,426.43,417.799,426.571z'
									/>
								</g>
								<g>
									<path
										fill='#fff'
										stroke='#263238'
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeMiterlimit='10'
										d='
					M446.891,426.369c-5.713-0.053-8.761-3.565-8.761-3.565s-0.835,7.516-3.34,10.856c-2.505,3.34-3.062,6.124-1.67,7.237
					c1.392,1.114,4.454,1.114,8.072,0.278c3.619-0.835,3.619-0.278,6.959-3.062c3.34-2.784,4.454-10.856,4.454-11.691
					c0-0.25,0.13-0.69,0.305-1.243C451.396,425.827,449.363,426.392,446.891,426.369z'
									/>
									<path
										fill='#fff'
										stroke='#263238'
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeMiterlimit='10'
										d='
					M448.151,438.113c1.484-1.236,2.526-3.516,3.229-5.741c-0.015,0.021-0.029,0.037-0.044,0.058
					c-1.818,2.626-4.243,5.657-10.303,7.071c-4.314,1.007-7.288,0.07-8.65-0.554c-0.037,0.852,0.205,1.525,0.737,1.95
					c1.392,1.114,4.454,1.114,8.072,0.278C444.81,440.34,444.81,440.897,448.151,438.113z'
									/>
								</g>
							</g>
							<path
								fill={theme.palette.primary.main}
								stroke='#263238'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit='10'
								d='M397.694,281.06
			c0,0-0.808,3.88,0.808,5.82c1.617,1.94,8.083,6.143,23.441,6.305c15.358,0.162,21.824-3.233,24.088-4.688
			c2.263-1.455,1.293-7.921,1.293-7.921s-10.67,4.85-22.956,5.335C412.081,286.395,397.694,281.06,397.694,281.06z'
							/>
							<g>
								<path
									fill={theme.palette.primary.main}
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M410.996,210.137l-14.106-7.341c0,0-17.272-15.114-25.765-20.727c-8.492-5.614-15.689-3.742-16.265,3.167
				c-0.576,6.909,28.644,29.795,33.969,34.113c5.326,4.318,6.477,6.477,6.477,6.477s0.72,38.575,1.008,44.765
				c0.288,6.189,0,10.364,2.015,12.235c2.015,1.871,11.371,7.629,24.469,6.909c13.098-0.72,19-2.015,21.447-4.03
				c2.447-2.015,3.886-3.742,3.886-7.197s3.742-49.514,3.742-49.514s25.909-17.704,32.53-23.606
				c6.621-5.901,7.053-14.25,2.735-15.113c-4.318-0.864-15.257,4.462-19.576,7.197c-4.318,2.735-18.136,7.053-22.886,8.348
				c-4.75,1.295-9.5,2.735-9.5,2.735S423.231,211.864,410.996,210.137z'
								/>

								<line
									fill='none'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									x1='395.307'
									y1='225.826'
									x2='390.933'
									y2='209.977'
								/>

								<line
									fill='none'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									x1='393.425'
									y1='220.155'
									x2='386.778'
									y2='205.822'
								/>

								<line
									fill='none'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									x1='451.875'
									y1='228.993'
									x2='455.534'
									y2='215.377'
								/>

								<line
									fill='none'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									x1='453.041'
									y1='225.556'
									x2='458.027'
									y2='216.624'
								/>

								<line
									fill='none'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									x1='376.808'
									y1='190.866'
									x2='367.668'
									y2='195.852'
								/>

								<line
									fill='none'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									x1='469.036'
									y1='202.706'
									x2='473.398'
									y2='206.238'
								/>
							</g>
							<path
								fill='#fff'
								stroke='#263238'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit='10'
								d='M411.716,226.978
			l-3.598,23.249c-0.08,0.515,0.273,0.997,0.789,1.076l0,0c0.517,0.08,1-0.277,1.076-0.795l3.316-22.523L411.716,226.978z'
							/>
							<path
								fill='#fff'
								stroke='#263238'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit='10'
								d='M425.678,227.841
			l-1.912,22.808c-0.054,0.647,0.48,1.191,1.128,1.147l0,0c0.531-0.035,0.954-0.459,0.988-0.99l1.523-23.397L425.678,227.841z'
							/>
							<g>
								<path
									fill={theme.palette.primary.main}
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M410.133,209.129c0,0-5.182,5.758-5.614,8.061c-0.432,2.303,3.598,5.182,3.598,5.182s-2.015,0.576-0.576,1.871
				c1.439,1.295,9.788,7.773,12.235,7.773c2.447,0,5.47-3.886,12.954-5.614c7.485-1.727,12.81-9.5,12.235-13.098
				c-0.576-3.598-3.742-2.879-3.742-2.879s1.439-3.167-0.864-4.606c-2.303-1.439-7.629-1.439-10.22-1.151
				C427.549,204.955,416.61,205.531,410.133,209.129z'
								/>
								<path
									fill='none'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='M431.854,223.013
				c-1.584,1.273-2.866,2.094-2.866,2.094'
								/>
								<path
									fill='none'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='M441.223,210.425
				c0,0-3.454,6.621-6.045,9.5'
								/>

								<line
									fill='none'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									x1='408.118'
									y1='222.372'
									x2='411.716'
									y2='224.387'
								/>
							</g>
							<g>
								<path
									fill='#BDBDBD'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M357.883,191.281c-1.583-1.439-5.613-5.47-2.447-10.364c3.167-4.894,8.061-3.167,12.091-3.167c4.03,0,33.106,7.053,33.106,7.053
				s6.189-10.651,7.485-12.523c1.296-1.871,6.333-2.015,6.333-2.015s1.151,0.432,0.576,1.871c-0.576,1.439-1.583,0.72-1.871,4.03
				c-0.288,3.311-1.871,15.114-4.606,16.553c-2.735,1.439-9.932-0.288-9.932-0.288l-32.674,1.583'
								/>
								<path
									fill={theme.palette.primary.main}
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M365.944,194.016l14.06-0.681c-1.336-1.213-2.545-2.999-2.545-5.508c0-3.324,1.411-6.084,2.564-7.789
				c-5.803-1.265-10.962-2.286-12.495-2.286c-4.03,0-8.924-1.727-12.091,3.167c-3.167,4.894,0.864,8.924,2.447,10.364'
								/>
							</g>
							<g>
								<path
									fill='#BDBDBD'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M412.724,171.274c0,0-6.045,11.947-5.758,13.386c0.288,1.439,1.008,3.167,1.008,3.742c0,0.576-3.167,7.917-2.591,9.932
				c0.576,2.015,3.023,11.371,5.326,14.682c2.303,3.311,3.886,3.311,3.886,3.311s-1.151,7.917-0.288,10.22
				c0.864,2.303,3.742,6.045,5.47,5.47c1.727-0.576,11.227-11.659,12.091-14.969c0.864-3.311,1.871-14.682,1.871-14.682
				s1.727-3.167,2.735-4.606c1.008-1.439,4.894-5.902,5.182-13.386c0.288-7.485-6.909-14.394-13.386-16.265
				C421.792,166.236,415.027,169.978,412.724,171.274z'
								/>
								<path
									fill='none'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='M411.572,189.842
				c0,0-3.167,7.053-2.591,7.341c0.576,0.288,2.303,1.727,5.038,1.008s2.735-2.159,2.735-2.159'
								/>
								<path
									fill='#263238'
									d='M414.371,204.809c0.395,1.04,0.203,2.078-0.428,2.318c-0.632,0.24-1.464-0.409-1.859-1.449
				c-0.395-1.04-0.204-2.078,0.428-2.318C413.144,203.119,413.976,203.768,414.371,204.809z'
								/>
								<path
									fill='#263238'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M414.595,216.326c0,0,2.159-0.288,3.886-0.72c1.727-0.432,8.636-6.189,8.636-6.189l-13.098,15.113L414.595,216.326z'
								/>
								<path
									fill='none'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='M418.913,183.652
				c0,0,3.742-2.303,5.758,2.159'
								/>
								<path
									fill='none'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='M412.868,183.796
				c0,0-2.159-1.583-4.03,0.576'
								/>
								<path
									fill='#263238'
									d='M421.216,190.346c0,0.835-0.419,1.511-0.936,1.511c-0.517,0-0.936-0.677-0.936-1.511
				s0.419-1.511,0.936-1.511C420.797,188.834,421.216,189.511,421.216,190.346z'
								/>
								<ellipse
									fill='#263238'
									cx='410.053'
									cy='189.569'
									rx='0.856'
									ry='1.383'
								/>
								<path
									fill='#263238'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M432.155,200.925c0,0,0.824,0.617,1.763,1.113c0.467-0.843,1.744-3.12,2.555-4.28c1.008-1.439,4.894-5.901,5.182-13.386
				c0.288-7.485-6.909-14.394-13.386-16.265c-3.726-1.076-7.542-0.294-10.554,0.811c0.188,0.375,0.841,0.794,2.638,1.061
				c3.886,0.576,8.492-1.295,10.939,0.432c2.447,1.727,3.454,6.909,1.295,8.78s-6.621,4.894-4.75,7.341
				c1.871,2.447,2.879,4.894,2.879,5.47c0,0.576-0.864,1.152,0,1.296c0.864,0.144,2.015,0.432,2.447-0.864
				c0.432-1.295,2.735-3.311,3.886-1.008c1.151,2.303-0.288,5.038-1.583,6.765C434.171,199.917,432.155,200.925,432.155,200.925z'
								/>
							</g>
							<g>
								<path
									fill='#BDBDBD'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M479.079,204.667l-11.371-0.432c0,0-33.393-8.204-35.84-9.5c-2.447-1.296-1.871-5.038-2.159-5.902
				c-0.288-0.864-1.008-4.75-0.288-5.757c0.72-1.008,6.765-5.614,7.197-6.189c0.432-0.576-0.72-3.311-0.432-4.03
				c0.288-0.72,1.008-0.864,1.727-0.432c0.72,0.432,0.864,1.152,0.864,1.152s1.439,0.288,2.735,2.591
				c1.295,2.303,1.583,4.174,1.295,5.038c-0.288,0.864,1.295,2.591,1.008,3.886c-0.288,1.295-2.159,4.606-2.159,4.606
				s27.204-1.152,31.81-1.152c4.606,0,15.401-0.576,16.697,2.015c1.296,2.591,0,7.053-2.159,10.076'
								/>

								<line
									fill='none'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									x1='441.655'
									y1='189.698'
									x2='437.67'
									y2='189.204'
								/>
								<path
									fill='none'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='M438.776,173.577
				c0,0,1.802,3.58,1.386,4.618c-0.415,1.039-0.415,1.039-0.415,1.039'
								/>
								<path
									fill='none'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='M442.807,181.206
				c0,0-1.605,1.56-2.644,2.183'
								/>
								<path
									fill={theme.palette.primary.main}
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M488.003,200.637c2.159-3.023,3.454-7.485,2.159-10.076c-1.287-2.574-11.946-2.023-16.602-2.015
				c0.729,1.581,1.418,4.275,0.193,7.773c-1.284,3.667-4.897,6.219-7.356,7.593c0.83,0.205,1.311,0.323,1.311,0.323l11.371,0.432'
								/>
							</g>
						</g>
					</g>
					<g id='Smoke'>
						<g opacity='0.3'>
							<path
								d='M243.98,166.67c0.899-1.719,1.416-3.662,1.416-5.727c0-6.944-5.74-12.574-12.82-12.574s-12.82,5.63-12.82,12.574
			c0,4.246,2.152,7.993,5.438,10.269c-4.492,0.809-7.904,4.729-7.904,9.454c0,2.346,0.842,4.494,2.239,6.164
			c-4.295,2.345-7.17,6.552-7.17,11.367c0,7.367,6.707,13.339,14.98,13.339c8.273,0,14.98-5.972,14.98-13.339
			c0-1.777-0.398-3.47-1.107-5.021c0.485,0.039,0.974,0.064,1.471,0.064c8.578,0,15.532-5.961,15.532-13.313
			C258.217,172.949,251.951,167.235,243.98,166.67z'
							/>
							<circle cx='216.551' cy='142.206' r='8.136' />
							<path
								d='M194.608,131.604c-1.362,0-2.465,1.104-2.465,2.465s1.104,2.465,2.465,2.465c1.362,0,2.465-1.104,2.465-2.465
			S195.97,131.604,194.608,131.604z'
							/>
							<path
								d='M244.41,137.028c3.813,0,6.903-3.091,6.903-6.903c0-3.813-3.091-6.903-6.903-6.903c-3.813,0-6.903,3.091-6.903,6.903
			C237.507,133.937,240.598,137.028,244.41,137.028z'
							/>
						</g>
						<g opacity='0.3'>
							<path
								d='M267.581,82.955c-1.362,0-2.465,1.104-2.465,2.465c0,1.362,1.104,2.465,2.465,2.465c1.362,0,2.465-1.104,2.465-2.465
			C270.046,84.059,268.943,82.955,267.581,82.955z'
							/>
							<path
								d='M262.296,62.202c-3.982,2.083-5.521,6.999-3.438,10.98c2.083,3.982,6.999,5.521,10.98,3.438
			c3.982-2.083,5.521-6.999,3.438-10.98C271.194,61.658,266.278,60.119,262.296,62.202z'
							/>
							<path
								d='M316.953,118.021c0.899-1.719,1.416-3.662,1.416-5.727c0-4.091-2.001-7.714-5.085-10.01
			c6.064-3.372,9.304-9.656,8.322-15.356c1.628-1.263,2.68-3.232,2.68-5.452c0-3.813-3.091-6.903-6.903-6.903
			c-2.229,0-4.205,1.061-5.467,2.7c-3.2-0.699-6.794-0.386-10.204,1.101c0-1.94-0.442-3.901-1.4-5.731
			c-3.219-6.153-10.914-8.481-17.188-5.199c-6.274,3.282-8.75,10.931-5.532,17.084c1.143,2.184,2.859,3.865,4.867,5.003
			c-0.679,1.188-1.072,2.56-1.072,4.026c0,0.894,0.153,1.75,0.421,2.554c-2.488-0.556-5.177-0.273-7.614,1.001
			c-5.472,2.862-7.587,9.619-4.725,15.091c2.062,3.942,6.145,6.129,10.313,5.98c-3.114,3.285-4.014,8.255-1.957,12.529
			c-4.535,3.175-6.126,9.292-3.491,14.328c2.189,4.185,6.656,6.397,11.084,5.93c0.797,6.699,7.159,11.919,14.893,11.919
			c8.273,0,14.98-5.972,14.98-13.339c0-1.777-0.398-3.47-1.107-5.021c0.485,0.039,0.974,0.064,1.471,0.064
			c8.578,0,15.532-5.961,15.532-13.313C331.19,124.3,324.924,118.587,316.953,118.021z'
							/>
							<path
								d='M241.711,70.188c-1.206,0.631-1.673,2.121-1.042,3.327c0.631,1.207,2.121,1.673,3.327,1.042
			c1.207-0.631,1.673-2.121,1.042-3.327C244.407,70.024,242.917,69.557,241.711,70.188z'
							/>
							<path
								d='M288.354,51.91c3.378-1.767,4.684-5.938,2.917-9.317c-1.767-3.378-5.939-4.684-9.317-2.917
			c-3.378,1.767-4.684,5.938-2.917,9.317C280.804,52.371,284.975,53.677,288.354,51.91z'
							/>
						</g>
					</g>
					<g id='Screws'>
						<g>
							<path
								fill='#fff'
								stroke='#263238'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit='10'
								d='M215.479,174.833
			c0-3.799-3.08-6.879-6.879-6.879c-3.799,0-6.879,3.08-6.879,6.879c0,3.799,3.08,6.879,6.879,6.879
			C212.399,181.713,215.479,178.633,215.479,174.833z'
							/>

							<line
								fill='#fff'
								stroke='#263238'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit='10'
								x1='213.559'
								y1='169.898'
								x2='204.161'
								y2='179.296'
							/>
						</g>
						<g>
							<path
								fill='#263238'
								stroke='#263238'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit='10'
								d='M279.331,247.37
			c0-3.799-3.08-6.879-6.879-6.879c-3.799,0-6.879,3.08-6.879,6.879c0,3.799,3.08,6.879,6.879,6.879
			C276.25,254.249,279.331,251.169,279.331,247.37z'
							/>
						</g>
						<g>
							<path
								fill='#fff'
								stroke='#263238'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit='10'
								d='M336.879,174.833
			c0-3.799-3.08-6.879-6.879-6.879c-3.799,0-6.879,3.08-6.879,6.879c0,3.799,3.08,6.879,6.879,6.879
			C333.799,181.713,336.879,178.633,336.879,174.833z'
							/>

							<line
								fill='#fff'
								stroke='#263238'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit='10'
								x1='334.96'
								y1='169.898'
								x2='325.562'
								y2='179.296'
							/>
						</g>
						<g>
							<g>
								<path
									fill='#fff'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M243.634,409.823l-23.962,8.333c-0.811,0.282-1.316,1.091-1.212,1.943l0,0c0.116,0.96,0.961,1.662,1.926,1.602l24.61-1.475
				L243.634,409.823z'
								/>

								<line
									fill='none'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									x1='220.386'
									y1='421.701'
									x2='224.341'
									y2='416.851'
								/>

								<line
									fill='none'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									x1='224.88'
									y1='421.349'
									x2='230.073'
									y2='415.03'
								/>

								<line
									fill='none'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									x1='229.386'
									y1='421.227'
									x2='236.521'
									y2='412.479'
								/>

								<line
									fill='none'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									x1='234.124'
									y1='420.975'
									x2='242.57'
									y2='410.62'
								/>
							</g>
							<g>
								<path
									fill='#fff'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M251.829,414.462c-0.457-3.772-3.885-6.459-7.657-6.002c-3.772,0.457-6.459,3.885-6.002,7.657
				c0.457,3.772,3.885,6.459,7.657,6.002C249.599,421.662,252.286,418.234,251.829,414.462z'
								/>

								<line
									fill='#fff'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									x1='249.329'
									y1='409.793'
									x2='241.131'
									y2='420.254'
								/>
							</g>
						</g>
					</g>
					<g id='Gears'>
						<g>
							<path
								fill='#263238'
								stroke='#263238'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit='10'
								d='M246.748,244.129
			c0-1.777-0.135-3.523-0.388-5.229l9.223-2.997l1.256-3.014l-2.044,0.589l-2.853-8.78l-9.25,3.006
			c-1.616-3.066-3.667-5.866-6.076-8.315l5.704-7.852l1.256-3.015l-2.02,2.46l-8.768-6.37l-5.716,7.867
			c-3.046-1.506-6.339-2.583-9.804-3.157v-9.702h-9.773l-0.753-3.015l-1.256,3.015v9.73c-3.461,0.59-6.749,1.682-9.789,3.201
			l-5.701-7.848l-6.408,4.656l-1.868-0.745l-0.202,0.486l-1.054,2.529l5.721,7.875c-2.397,2.46-4.436,5.267-6.038,8.341l-6.19-2.011
			l-1.78-4.001l-1.256,3.014l-3.641,11.205l9.251,3.006c-0.238,1.657-0.368,3.348-0.368,5.071c0,1.777,0.135,3.523,0.388,5.229
			l-5.027,1.633l-2.94-1.651l-1.256,3.015l3.641,11.205l9.25-3.006c1.616,3.066,3.667,5.866,6.076,8.315l-3.542,4.875l-0.907-0.038
			l-1.256,3.014l9.532,6.925l5.716-7.867c3.046,1.506,6.339,2.583,9.804,3.157v9.702h11.782l1.256-3.014l-1.256,0.009v-6.725
			c3.461-0.59,6.749-1.682,9.789-3.202l5.701,7.848l9.532-6.925l1.256-3.014l-2.557,1.224l-4.421-6.085
			c2.397-2.46,4.436-5.267,6.038-8.341l9.226,2.998l3.641-11.205l-9.251-3.006C246.618,247.543,246.748,245.852,246.748,244.129z
			 M211.456,266.795c-12.518,0-22.666-10.148-22.666-22.666c0-12.518,10.148-22.666,22.666-22.666
			c12.518,0,22.666,10.148,22.666,22.666C234.122,256.647,223.974,266.795,211.456,266.795z'
							/>
							<path
								fill='#BDBDBD'
								stroke='#263238'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit='10'
								d='M253.246,260.397
			l3.641-11.205l-9.251-3.006c0.238-1.657,0.368-3.348,0.368-5.071c0-1.777-0.135-3.523-0.388-5.229l9.223-2.997l-3.641-11.205
			l-9.25,3.006c-1.616-3.066-3.667-5.866-6.076-8.315l5.704-7.852l-9.532-6.925l-5.716,7.867c-3.046-1.506-6.339-2.583-9.804-3.157
			v-9.702h-11.782v9.73c-3.461,0.59-6.749,1.682-9.789,3.201l-5.701-7.848l-9.532,6.925l5.721,7.875
			c-2.397,2.46-4.436,5.267-6.038,8.341l-9.227-2.998l-3.641,11.205l9.251,3.006c-0.238,1.657-0.368,3.348-0.368,5.071
			c0,1.777,0.135,3.523,0.388,5.229l-9.223,2.997l3.641,11.205l9.25-3.006c1.616,3.066,3.667,5.866,6.076,8.315l-5.704,7.852
			l9.532,6.925l5.716-7.867c3.046,1.506,6.339,2.583,9.804,3.157v9.702h11.782v-9.73c3.461-0.59,6.749-1.682,9.789-3.202
			l5.702,7.848l9.532-6.925l-5.721-7.875c2.397-2.46,4.436-5.267,6.038-8.341L253.246,260.397z M190.045,241.114
			c0-12.518,10.148-22.666,22.666-22.666s22.666,10.148,22.666,22.666c0,12.518-10.148,22.666-22.666,22.666
			S190.045,253.633,190.045,241.114z'
							/>
						</g>
						<g>
							<path
								fill='#263238'
								stroke='#263238'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit='10'
								d='M308.173,181.45
			c0-1.777-0.135-3.523-0.388-5.229l9.223-2.997l1.256-3.015l-2.044,0.589l-2.853-8.78l-9.25,3.006
			c-1.616-3.066-3.667-5.866-6.076-8.315l5.704-7.852l1.256-3.014l-2.02,2.46l-8.768-6.37l-5.716,7.867
			c-3.046-1.506-6.339-2.583-9.804-3.157v-9.702h-9.772l-0.753-3.014l-1.256,3.014v9.73c-3.461,0.59-6.749,1.682-9.789,3.201
			l-5.701-7.848l-6.408,4.656l-1.868-0.745l-0.202,0.486l-1.054,2.529l5.721,7.875c-2.397,2.46-4.436,5.267-6.038,8.341l-6.19-2.011
			l-1.78-4.001l-1.256,3.014l-3.641,11.205l9.251,3.006c-0.238,1.657-0.368,3.348-0.368,5.071c0,1.777,0.135,3.523,0.388,5.229
			l-5.027,1.633l-2.94-1.651l-1.256,3.014l3.641,11.205l9.25-3.006c1.616,3.066,3.667,5.866,6.076,8.315l-3.542,4.875l-0.906-0.038
			l-1.256,3.014l9.532,6.925l5.716-7.867c3.046,1.506,6.339,2.583,9.804,3.157v9.702h11.782l1.256-3.014l-1.256,0.01v-6.725
			c3.461-0.59,6.749-1.682,9.789-3.202l5.702,7.848l9.532-6.925l1.256-3.014l-2.557,1.224l-4.421-6.085
			c2.397-2.46,4.436-5.267,6.038-8.341l9.226,2.998l3.641-11.205l-9.251-3.006C308.043,184.865,308.173,183.173,308.173,181.45z
			 M272.881,204.117c-12.518,0-22.666-10.148-22.666-22.666c0-12.518,10.148-22.666,22.666-22.666
			c12.518,0,22.666,10.148,22.666,22.666C295.547,193.969,285.399,204.117,272.881,204.117z'
							/>
							<path
								fill='#BDBDBD'
								stroke='#263238'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit='10'
								d='M314.671,197.718
			l3.641-11.205l-9.251-3.006c0.238-1.657,0.368-3.349,0.368-5.071c0-1.777-0.135-3.523-0.388-5.229l9.223-2.997l-3.641-11.205
			l-9.25,3.006c-1.616-3.066-3.667-5.866-6.076-8.315l5.704-7.852l-9.532-6.925l-5.716,7.867c-3.046-1.506-6.339-2.583-9.804-3.157
			v-9.702h-11.782v9.73c-3.461,0.59-6.749,1.682-9.789,3.202l-5.702-7.848l-9.532,6.925l5.721,7.875
			c-2.397,2.46-4.436,5.267-6.038,8.341l-9.227-2.998l-3.641,11.205l9.251,3.006c-0.238,1.657-0.368,3.348-0.368,5.071
			c0,1.777,0.135,3.523,0.388,5.229l-9.223,2.997l3.641,11.205l9.251-3.006c1.616,3.066,3.667,5.866,6.076,8.315l-5.704,7.851
			l9.532,6.925l5.716-7.867c3.046,1.506,6.339,2.583,9.804,3.157v9.702h11.782v-9.73c3.461-0.59,6.749-1.682,9.789-3.201
			l5.702,7.847l9.532-6.925l-5.721-7.875c2.397-2.46,4.436-5.267,6.038-8.341L314.671,197.718z M251.47,178.436
			c0-12.518,10.148-22.666,22.666-22.666c12.518,0,22.666,10.148,22.666,22.666c0,12.518-10.148,22.666-22.666,22.666
			C261.619,201.102,251.47,190.954,251.47,178.436z'
							/>
						</g>
						<g>
							<g>
								<path
									fill='#263238'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='M368.47,408.459
				l-2.389,3.033c-0.286-0.179-0.561-0.36-0.878-0.536c-1.024-0.572-2.162-1.109-3.419-1.615l5.983-1.777l2.32-0.542v-0.147
				l0.053-0.016l-0.053-0.015v-6.588l-11.433,2.184l1.976,1.687l-0.904-0.26l-8.374,2.492c-3.525-0.717-2.274-3.53-6.313-3.919
				l-3.376-1.205l-14.389-0.646l-2.411,0.061c-4.982-2.876-9.599-3.215-11.829,4.01l-5.566-3.109l-6.774,1.024l3.83-2.901
				l-9.927-2.943v6.766l-7.646,1.221l-12.795-0.008v6.766l10.773,1.581c-0.395,0.386-0.702,0.779-0.968,1.175l-10.182,0.484
				l-2.459-4.191v6.766l2.459,4.191l9.41-0.447v5.664l10.405,2.994l8.374-2.492c3.518,0.716,7.36,1.273,11.389,1.661l-1.701,3.463
				l14.389,0.646l1.724-3.467c4.188-0.02,8.41-0.217,12.526-0.605l5.566,3.109l12.862-1.944l-0.958-4.306
				c1.542-0.456,2.958-0.942,4.231-1.454l10.726,1.575l6.441-3.794v-5.679l2.836-0.136v-6.766L368.47,408.459z M297.927,405.356
				l2.255,1.26c-1.414,0.311-2.727,0.651-3.977,1.006L297.927,405.356z M343.536,423.339v-3.431c0,0-1.539,0.046-3.865,0.116v-0.501
				c-1.334,0.202-2.704,0.412-4.087,0.623c-7.563,0.226-17.844,0.531-18.207,0.531l-0.85,1.732
				c-6.069,0.316-11.354-0.063-14.619-1.887c-7.186-4.014-1.934-8.944,11.741-11.011c13.675-2.067,30.583-0.486,37.769,3.528
				C357.876,416.647,354.273,420.996,343.536,423.339z M375.958,417.206l-4.026-0.456l5.224-0.25L375.958,417.206z'
								/>
								<polygon
									fill='#263238'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									points='
				341.664,394.468 338.368,399.51 341.664,398.697 			'
								/>
							</g>
							<g>
								<path
									fill='#BDBDBD'
									stroke='#263238'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit='10'
									d='
				M339.672,417.282c-13.675,2.067-30.583,0.487-37.764-3.525c-7.186-4.014-1.934-8.944,11.741-11.011
				c13.675-2.067,30.583-0.486,37.769,3.528C358.599,410.285,353.346,415.215,339.672,417.282 M307.468,394.784l-12.872,1.946
				l5.585,3.12c-3.442,0.758-6.403,1.653-8.858,2.641l-10.726-1.575l-6.441,3.794l10.773,1.581c-1.21,1.185-1.825,2.421-1.808,3.669
				l-11.801,0.565l2.459,4.191l11.829-0.562c0.693,0.57,1.525,1.133,2.514,1.686c1.019,0.569,2.157,1.107,3.414,1.612l-8.347,2.481
				l10.405,2.994l8.374-2.492c3.525,0.717,7.375,1.275,11.414,1.664l-1.725,3.46l14.389,0.646l1.724-3.467
				c4.188-0.02,8.41-0.217,12.526-0.605l5.566,3.109l12.862-1.944l-5.585-3.12c3.442-0.758,6.403-1.653,8.858-2.641l10.726,1.575
				l6.441-3.794l-10.773-1.581c1.21-1.185,1.825-2.421,1.808-3.669L382,409.503l-2.459-4.191l-11.829,0.562
				c-0.692-0.57-1.524-1.133-2.509-1.683c-1.024-0.572-2.162-1.109-3.419-1.615l8.356-2.482l-10.414-2.992l-8.374,2.492
				c-3.525-0.717-7.375-1.275-11.413-1.664l1.725-3.46l-14.389-0.646l-1.724,3.467c-4.188,0.02-8.41,0.217-12.516,0.604
				L307.468,394.784'
								/>
							</g>
						</g>
						<g>
							<polygon
								opacity='0.2'
								points='296.232,256.29 296.318,256.293 297.829,254.917 		'
							/>
							<path
								opacity='0.2'
								d='M294.519,261.04l-0.48,0.247C294.056,261.377,294.149,261.387,294.519,261.04z'
							/>
							<path
								opacity='0.2'
								d='M295.352,259.273c0.138-0.181-0.964,1.015-1.383,1.478
			C294.347,260.57,295.428,259.558,295.352,259.273z'
							/>
							<path
								opacity='0.2'
								d='M292.754,263.56l4.225-1.373c0.316-0.32,0.488-0.593,0.264-0.755
			c-0.727,1.018-0.633,0.36-1.155,0.727l-0.013,0.093c-0.129,0.091-0.516,0.362-0.368,0.091c0.909-0.411,2.067-1.396,2.948-1.99
			c-0.424,0.223,0.116-0.608,0.135-0.765c0.796-0.645,0.207,0.192,0.298,0.382c0.405-0.368,1.279-0.73,1.356-1.29
			c0.336-0.741-0.882,0.45-1.236,0.446l2.481-1.825l-0.116-0.002c0.249-0.091,0.638-0.364,0.796-0.644
			c0.051-0.374-0.483,0.085-0.767,0.453c0.886-1.374,2.515-1.45,2.979-2.907l-0.384,0.243c0.146-0.189,0.273-0.276,0.393-0.277
			c-0.001,0.01-0.007,0.022-0.009,0.034l0.772-0.484c0.017-0.098,0.04-0.284-0.088-0.194l0.767-0.453
			c1.314-1.007-0.028-0.655,0.277-1.124l-0.055,0.06c0.298-0.41,0.419-0.821,0.129-0.618l1.431-1.004l-0.014-0.002
			c-0.158,0.037-0.284,0.031-0.323-0.1l0.323,0.1c0.767-0.178,2.623-1.895,3.133-1.342c0.052-0.067,0.094-0.132,0.136-0.196
			c-0.023-0.106-0.044-0.213-0.065-0.32c-0.101,0.102-0.214,0.193-0.399,0.324c0.172-0.136,0.292-0.239,0.398-0.332
			c-0.225-1.148-0.362-2.328-0.407-3.531c-0.329,0.267-0.568,0.505-0.578,0.689c0.047-0.028,0.092-0.06,0.139-0.085l-0.042,0.285
			c-0.075-0.056-0.102-0.124-0.097-0.199c-0.945,0.561-1.64,1.503-2.384,2.026l0.195,0.285c-0.39,0.274-0.898,0.546-1.181,0.913
			c0.038,0.034,0.115,0.025,0.21-0.012c0.185-0.146,0.353-0.287,0.555-0.44c-0.184,0.237-0.393,0.38-0.555,0.44
			c-1.383,1.095-2.388,2.089-3.46,3.137c0.22,0.099,0.324,0.196,0.984-0.352c-0.751,1.202-2.894,2.283-4.285,3.851l0.229,0.01
			l-1.022,0.63c0.247-0.088,0.216,0.103,0.323,0.197c-1.377,1.474-3.145,3.221-4.354,3.478c0.51-0.274-0.363,0.931,0.663,0.296
			c-1.134,0.72-2.267,1.767-3.377,2.821L292.754,263.56z M300.584,257.652l0.925-0.734l-0.951,0.921L300.584,257.652z
			 M298.165,259.857l-0.819,0.827l-0.504,0.27L298.165,259.857z'
							/>
							<path
								opacity='0.2'
								d='M294.038,261.286c-0.024-0.184,0.264-0.709-0.402,0.203L294.038,261.286z'
							/>
							<path
								opacity='0.2'
								d='M294.087,258.217l1.662-0.997l-0.622,0.267l1.036-0.727
			C295.827,256.659,294.686,257.291,294.087,258.217z'
							/>
							<path
								opacity='0.2'
								d='M295.027,258.141c-0.08,0.026-0.221,0.119-0.511,0.368
			C294.668,258.371,294.849,258.246,295.027,258.141z'
							/>
							<path
								opacity='0.2'
								d='M295.027,258.141c0.209-0.064-0.058,0.389,0.604-0.076
			C295.586,257.885,295.319,257.97,295.027,258.141z'
							/>
							<path
								opacity='0.2'
								d='M328.603,283.443l0.504-0.787C328.374,282.617,328.709,283.055,328.603,283.443z'
							/>
							<polygon
								opacity='0.2'
								points='296.694,256.306 296.318,256.293 296.217,256.385 		'
							/>
							<path
								opacity='0.2'
								d='M337.185,218.46c0.418-0.172,1.058-0.193,1.274-1.006C337.69,217.524,337.845,218.09,337.185,218.46
			z'
							/>
							<path
								opacity='0.2'
								d='M328.44,283.699c0.092-0.079,0.14-0.167,0.163-0.256L328.44,283.699z'
							/>
							<path
								opacity='0.2'
								d='M342.528,210.103c-0.053-0.014-0.105-0.03-0.158-0.045c-0.152,0.267-0.225,0.491-0.142,0.525
			C342.241,210.475,342.359,210.306,342.528,210.103z'
							/>
							<path
								opacity='0.2'
								d='M338.289,220.974l-0.235-0.005c1.43-1.004,2.536-3.047,3.778-4.423
			c-0.022,0.183-1.085,1.097-0.645,1.296l1.745-1.563c0.022-0.183,0.71-0.92,0.087-0.652c-0.263,0.184-0.421,0.464-0.559,0.646
			l0.054-0.377c1.712-1.369,0.389,0.572,0.703,0.858c0.714-0.923,1.301-0.913,1.848-1.466c-0.041-0.562-1.397,0.728-1.019-0.297
			c0.326-0.652,2.158-2.022,3.014-2.284c0.018-0.288,0.142-0.588,0.326-0.889c-0.45-0.197-0.902-0.391-1.362-0.57
			c-2.546,2.343-5.479,3.94-7.447,7.05l0.116,0.004l-2.194,1.454c-0.291,0.374-0.104,0.75-0.545,1.395
			c-0.023-0.306-0.346,0.025-0.73,0.402c0.732,0.064,1.454,0.163,2.165,0.294C337.52,221.62,337.765,221.342,338.289,220.974z'
							/>
							<path
								opacity='0.2'
								d='M299.677,254.296c0.285-0.368-0.054-0.47,0.714-0.923c0.077,0.285,0.754,0.484,1.486-0.537
			c-0.993,0.358-1.213,0.26-2.253,0.99L299.677,254.296z'
							/>
							<path
								opacity='0.2'
								d='M301.812,253.455l0.971-1.255C301.8,252.413,302.097,252.887,301.812,253.455z'
							/>
							<path
								opacity='0.2'
								d='M301.812,253.455l-0.314,0.407C301.652,253.721,301.75,253.583,301.812,253.455z'
							/>
							<polygon
								opacity='0.2'
								points='310.821,240.732 310.828,240.726 310.829,240.72 		'
							/>
							<path
								opacity='0.2'
								d='M361.227,222.67c-0.068-0.088-0.138-0.174-0.206-0.261c-0.146,0.132-0.297,0.272-0.464,0.435
			C360.584,223.079,360.935,222.875,361.227,222.67z'
							/>
							<path
								opacity='0.2'
								d='M350.406,259.126c0.37-0.579,0.836-0.448,1.151-0.77c-0.084-0.252-0.367-0.14-0.589-0.113
			c-0.204,0.256-0.414,0.507-0.629,0.754C350.355,259.048,350.373,259.095,350.406,259.126z'
							/>
							<path
								opacity='0.2'
								d='M351.519,258.072c-0.031-0.21,0.369-0.576,0.675-0.97
			C351.729,256.969,351.322,257.951,351.519,258.072z'
							/>
							<path
								opacity='0.2'
								d='M345.673,265.931c-0.335,0.18-1.152,0.083-1.411,0.832l0.5-0.267l-0.071,0.114l0.277,0.08
			C344.38,266.318,345.429,266.138,345.673,265.931z'
							/>
							<polygon
								opacity='0.2'
								points='354.128,231.158 353.684,231.807 354.085,231.441 		'
							/>
							<path
								opacity='0.2'
								d='M311.645,251.025c-0.388,0.373-0.75,0.743-1.125,1.006l0.195,0.285
			c-0.39,0.274-0.898,0.546-1.181,0.913c0.038,0.034,0.115,0.025,0.21-0.012c0.184-0.146,0.353-0.287,0.555-0.44
			c-0.184,0.237-0.393,0.38-0.555,0.44c-1.383,1.095-2.388,2.089-3.46,3.137c0.22,0.099,0.324,0.196,0.984-0.352
			c-0.751,1.203-2.894,2.283-4.285,3.851l0.229,0.01l-1.022,0.63c0.247-0.088,0.216,0.103,0.323,0.197
			c-0.087,0.093-0.179,0.188-0.268,0.283c1.579,2.9,3.539,5.561,5.835,7.897l-5.704,7.851l9.532,6.925l5.716-7.867
			c3.046,1.506,6.339,2.583,9.804,3.157v2.295c0.178-0.143,0.31-0.332,0.328-0.646l-0.252,0.111
			c0.076-0.119,0.158-0.161,0.252-0.136c0.002,0.008-0.001,0.016,0,0.025l0.508-0.22c-0.007-0.074-0.028-0.216-0.11-0.171
			l0.51-0.197c0.826-0.52-0.16-0.523-0.018-0.83l-0.031,0.036c0.149-0.261,0.158-0.561-0.028-0.461l0.919-0.492l-0.011-0.005
			c-0.117-0.004-0.218-0.035-0.276-0.147l0.276,0.147c0.568,0.021,1.674-0.946,2.193-0.401c0.146-0.229,0.197-0.475,0.036-0.47
			c-0.01,0.009-0.028,0.021-0.038,0.029c-0.067,0.088-0.146,0.139-0.298,0.221c0.136-0.09,0.224-0.16,0.298-0.221
			c0.01-0.013,0.021-0.018,0.03-0.032c0.004-0.001,0.003,0.003,0.007,0.003c0.555-0.49-0.536-0.289,0.387-1.053
			c-0.103-0.097-0.175,0.018-0.343,0.109c0.21-0.232,0.336-0.286,0.429-0.266c-0.057-0.058-0.106-0.14-0.14-0.271
			c0.204,0.195,0.511-0.198,0.658-0.429l-0.634-0.041c0.353-0.036,0.52-0.126,0.828-0.519l-0.446,0.01
			c0.648-0.499,0.501,0.417,1.009-0.463l-0.426,0.837c0.808-0.661,0.987,0.005,1.508-0.122c1.103-0.437,1.436-1.987,2.698-1.902
			c-0.131-0.311-0.306-0.294-0.27-0.695c-0.575,0.386-0.909,0.564-1.457,0.477c-1.264,0.603,0.589,1.057-0.954,1.578
			c-0.225-0.335,0.091-0.657,0.518-0.811c-0.214-0.266-0.725-0.068-1.067,0.038c0.723-0.462,1.265-1.612,1.669-1.946
			c-0.014,0.002-0.032,0.007-0.047,0.01l0.084-0.044c-0.013,0.01-0.024,0.024-0.036,0.034c0.307-0.052,0.947-0.34,1.085-0.619
			c-0.025-0.039-0.031-0.098,0.006-0.208c0.044,0.065,0.031,0.134-0.006,0.208c0.154,0.211,1.211-0.578,1.3,0.088
			c0.733-0.544-0.123-0.924,0.927-1.106c-0.55-0.086,0.183-0.631-0.356-0.647c0.068,0.496-1.067,0.724-1.588,0.85l0.753-1.089
			c-0.142,0.306,0.38,0.178,0.547,0.089l-0.139-0.383c0.78,0.497,1.157-2.069,2.356-1.796c-0.323,0.249-0.352,0.722-0.828,0.522
			c-0.35,0.719,0.589,0.369,0.561,0.841c1.087-0.583,1.937-2.257,3.311-2.689c-0.362-0.034-0.75,0.403-1.05,0.182
			c0.521-0.813,1.921-1.029,2.925-1.568l-0.478-0.199c0.941-0.353,0.132-0.377,0.946-0.965c0.012,0.069,0.038,0.285,0.198,0.122
			c0.545-0.596-0.224-0.337,0.07-0.799c0.591-0.097,0.613-0.26,0.607-0.472c-3.788,2.845-8.496,4.531-13.598,4.531
			C323.127,266.795,314.564,260.171,311.645,251.025z M344.764,263.827c0.392,0.247-0.507,0.88-0.647,1.186
			C343.868,264.458,344.862,264.535,344.764,263.827z'
							/>
							<path
								opacity='0.2'
								d='M361.24,224.011c0.074-0.128,0.132-0.24,0.14-0.308C361.257,223.838,361.229,223.934,361.24,224.011
			z'
							/>
							<path
								opacity='0.2'
								d='M361.457,224.141c0.115,0.001,0.254-0.048,0.35-0.146
			C361.712,223.989,361.586,224.053,361.457,224.141z'
							/>
							<path
								opacity='0.2'
								d='M361.457,224.141c-0.115,0.004-0.199-0.044-0.217-0.13c-0.415,0.718-1.743,2.222-2.537,2.922
			c-0.184,0.468,0.975-0.261,1.349-0.439c-1.236-0.4,1.081-1.012,1.449-1.948c-0.249,0.091-0.667,0.553-0.901,0.549
			C360.721,224.827,361.117,224.372,361.457,224.141z'
							/>
							<path
								opacity='0.2'
								d='M328.524,282.968c-0.507,0.053-0.754,0.01-1.096,0.11v0.018c0.013-0.006,0.021-0.011,0.036-0.017
			C327.583,283.321,328.16,283.62,328.524,282.968z'
							/>
							<path
								opacity='0.2'
								d='M359.26,224.6l1.623-1.561c-0.417-0.385-0.677,0.643-1.157,0.727
			c0.074-0.524,0.701-0.945,1.233-1.435c-0.805-1.023-1.648-2.016-2.56-2.943l5.704-7.852l-6.867-4.99
			c-0.589,0.563-1.264,1.328-1.954,1.91l0.207,0.192l-0.781,0.548l0.364-0.086c-0.677,0.642-1.794,1.09-2.388,2.011
			c0.427-0.552,0.863-0.35,0.959-0.164l-2.342,1.644c0.092,0.188-0.738,1.107,0.029,0.654l-1.015,0.543l0.22,0.1
			c-2.814,3.409-6.667,4.931-9.07,8.729l0.116,0.004l-0.398,0.264c4.683,1.753,8.628,5.011,11.253,9.184
			c1.025-0.721,2.108-1.515,2.963-2.493c-0.234-0.004-0.637,0.363-0.753,0.361c1.195-1.009,3.292-1.72,3.578-2.934
			c-0.684,0.341-1.48,0.765-2.211,1.167c-0.146,0.141-0.268,0.194-0.389,0.217c-0.46,0.257-0.892,0.504-1.213,0.699
			c0.589-0.806,0.88-0.635,1.213-0.699c0.129-0.073,0.257-0.146,0.389-0.217c0.142-0.138,0.302-0.356,0.506-0.73l-0.611,0.175
			c1.25-1.386,1.482-0.533,2.487-0.987l0.505-1.113c0.798-1.031,1.708-1.88,2.608-2.503c-0.073-0.098-0.154-0.191-0.228-0.289
			C360.656,223.357,359.902,224.371,359.26,224.6z'
							/>
							<path
								opacity='0.2'
								d='M339.571,271.181c-0.086,0.062-0.147,0.118-0.201,0.168
			C339.436,271.317,339.503,271.272,339.571,271.181z'
							/>
							<path
								opacity='0.2'
								d='M338.976,271.424c0.17,0.211,0.191,0.119,0.394-0.075c-0.303,0.143-0.583-0.185-0.802,0.37
			C338.744,271.7,338.8,271.441,338.976,271.424z'
							/>
							<path
								opacity='0.2'
								d='M340.477,269.935c-0.176-0.669-0.947,0.279-1.242,0.74
			C339.495,270.613,339.994,269.659,340.477,269.935z'
							/>
							<polygon
								opacity='0.2'
								points='339.343,269.475 339.392,269.829 339.696,269.437 		'
							/>
							<polygon
								opacity='0.2'
								points='342.754,266.199 343.258,265.929 343.245,265.86 342.746,266.126 		'
							/>
							<path
								opacity='0.2'
								d='M342.374,270.06c-0.494-0.345-0.204,0.491-0.806,0.661c0.206,0.194,0.685-0.215,0.957-0.208
			c0.148-0.233-0.289-0.15-0.475-0.202C342.123,270.196,342.205,270.151,342.374,270.06z'
							/>
							<path
								opacity='0.2'
								d='M341.052,272.004l-0.187,0.16C340.96,272.135,341.013,272.075,341.052,272.004z'
							/>
							<path
								opacity='0.2'
								d='M341.052,272.004l0.295-0.252C341.13,271.595,341.153,271.824,341.052,272.004z'
							/>
							<path
								opacity='0.2'
								d='M337.863,216.687l0.409-0.453c-0.437,0.643,0.995-0.362,0.276,0.565
			c-0.285,0.37,0.755-0.36,1.057-0.827c-0.222-0.098-0.922,0.731-0.864,0.269l0.288-0.371
			C338.6,216.426,338.339,215.764,337.863,216.687z'
							/>
							<path
								opacity='0.2'
								d='M360.579,251.543c-0.249,0.132-0.342,0.107-0.462,0.553
			C360.368,251.962,360.599,251.685,360.579,251.543z'
							/>
							<path
								opacity='0.2'
								d='M353.743,255.967c-0.084,0.044-0.629,0.64-0.836,0.448l0.15,0.452
			c0.221-0.347,0.751-0.401,0.686-0.214C353.782,256.25,354.123,256.145,353.743,255.967z'
							/>
							<path
								opacity='0.2'
								d='M357.331,251.986c-0.222,0.347-1.023,0.396-0.779,0.874l0.741-0.473
			C357.266,252.173,357.609,252.066,357.331,251.986z'
							/>
							<path
								opacity='0.2'
								d='M368.532,244.129c0-1.777-0.135-3.523-0.388-5.229l9.223-2.997l-3.641-11.205l-9.25,3.006
			c-0.523-0.993-1.108-1.946-1.72-2.881c-0.347,0.383-0.713,0.717-1.064,0.857c-0.023,0.185,0.394-0.276,0.225,0.096
			c-2.031,1.931-5.315,4.489-7.438,7.079c-0.299-0.154-0.762,0.08-1.305,0.478c1.742,3.21,2.732,6.888,2.732,10.797
			c0,4.741-1.458,9.14-3.946,12.779c0.23-0.097,0.453-0.164,0.631-0.171c-0.243-1.165,1.706-1.982,1.184-2.539
			c0.01,0.07,0.373,0.106,0.511-0.197l-0.268-0.009c0.415-0.908,1.144-0.157,1.43-0.691c-0.106-0.095-0.272-0.006-0.457-0.059
			c0.761-1.018,2.053-0.72,2.606-1.93c-0.093-0.026-0.184-0.054-0.257,0.06c-0.095-0.024,0.062-0.184,0.491-0.338l-0.112,0.518
			c1.099,0.173,0.966-1.509,1.872-2.07c-0.188-0.051-0.743,0.475-0.621,0.027c0.471-0.48,1.104,0.247,1.344-0.644
			c-0.334,0.179-0.573-0.216-0.881-0.036c-0.071,0.092-0.152,0.182-0.252,0.262c0.091-0.142,0.172-0.215,0.252-0.262
			c0.323-0.411,0.395-0.855,0.776-0.744c0.079-0.123,0.028-0.258-0.042-0.339c-0.057,0.007-0.118,0.012-0.179-0.001
			c0.028-0.087,0.113-0.067,0.179,0.001c0.428-0.049,0.672-0.56,0.865-0.865l-0.183-0.055c1.097,0.174,0.796-0.73,1.436-1.302
			l-0.278-0.08c0.333-0.178,0.94-0.351,1.111-1.051l-0.446,0.01c0.511-0.198,0.825-1.204,1.458-1.163
			c0.065-0.187-0.726-0.069-0.179-0.667c0.272,0.007,0.853-0.304,0.807-0.66c-0.101-0.098-0.289-0.15-0.341,0.107
			c-0.076,0.117,0.005,0.074,0.1,0.098l-0.353,0.038c0.277-0.607,0.511-0.2,0.877-0.775c-0.026-0.215-0.358-0.039-0.546-0.089
			l2.146-1.38c0.195,0.125,0.058,0.427,0.416,0.465l-0.534,0.665c-0.018,0.546,0.622-0.028,0.955-0.206l-0.389,0.438
			c0.007,0.048,0.051,0.072,0.103,0.048l-0.325,0.298c1.187-0.483-0.36,1.337,0.808,0.711c-0.242,0.207-0.677,0.285-0.946,0.278
			c-0.491,0.339-0.212,0.418-0.269,0.678l0.848-0.379c-1.049,0.867,0.378,0.18-0.128,1.059c-0.995,0.61-0.647,1.872-1.038,2.31
			c-0.879,0.774,0.012-0.617-0.657-0.26c-0.484,0.414-0.039,0.405-0.261,0.751l-0.558-0.158c-0.566,0.456,0.318,0.363-0.109,0.516
			c-0.185,0.633,0.268,0.009,0.648,0.187c-0.176,0.018-0.759,0.329-0.807,0.661c0.105,0.095,0.662-0.431,0.357-0.04
			c-1.465,1.089-1.375,1.805-2.568,2.9l0.278,0.079c-0.168,0.091-0.345,0.11-0.408,0.296l0.187,0.051
			c-0.16,0.164-1.562,1.069-2.255,1.895l0.603-0.171l-0.93,0.423l0.395,0.245c-0.552-0.085-1.135,0.913-1.866,1.457l0.204,0.195
			l-0.502,0.269l0.27,0.009c-0.399,0.365-1.188,0.484-1.463,1.087c0.221-0.346,0.609-0.095,0.723,0.072l-1.505,0.807
			c0.113,0.168-0.35,0.72,0.16,0.522l-0.688,0.216l0.195,0.125c-1.507,2.102-4.23,2.494-5.33,4.989l0.092,0.027l-1.427,0.688
			c-0.151,0.234,0.075,0.57-0.138,0.988c-0.152-0.452-0.642,0.573-1.098,0.513c-0.126,0.371,0.206,0.194,0.393,0.245
			c-0.724,1.305-2.023,1.619-2.922,2.938c0.694-1.514,0.798-1.418,1.389-2.344c-1.065,0.722-0.915,1.176-1.964,2.043l0.584-0.313
			c-0.052,0.941-1.715,1.223-1.361,1.872c-0.648,0.5-1.635,0.495-2.246,1.281l0.735,0.139l-0.63,0.643l-0.309-0.292
			c-0.223,0.189-0.27,0.351-0.221,0.451c0.141-0.015,0.295-0.012,0.466,0.027c-0.222,0.118-0.41,0.089-0.466-0.027
			c-0.777,0.094-1.195,0.893-2.058,1.228l0.08,0.217c0.109-0.008,0.188,0.012,0.058,0.166l-0.058-0.166
			c-0.085,0.006-0.19,0.028-0.232,0.017c0.142,0.38-0.431,0.764-0.216,1.03c0.251-0.135,0.832-0.444,0.637-0.569
			c0.782-0.191-0.432,0.764-0.747,1.085c0.202-0.489-0.279-0.079-0.531,0.056c0.009,0.071,0.093,0.026,0.185,0.055
			c-0.432,0.764-1.401,0.215-1.278,1.141l-0.384-0.175l-0.268,0.677c-0.602-0.518-0.721,0.615-1.318,0.171
			c-0.555,0.527,0.392,0.247,0.141,0.382c-0.393,0.615-1.787,0.994-1.695,1.429c0.031-0.012,0.06-0.028,0.092-0.038l0.026,0.216
			c-0.071-0.06-0.107-0.12-0.118-0.178c-0.628,0.245-0.979,0.842-1.457,1.099l0.214,0.266c-0.25,0.135-0.595,0.243-0.741,0.473
			c0.037,0.035,0.096,0.043,0.164,0.034c0.115-0.077,0.218-0.153,0.346-0.231c-0.095,0.149-0.23,0.218-0.346,0.231
			c-0.862,0.574-1.448,1.148-2.074,1.751c0.195,0.125,0.297,0.223,0.704-0.071c-0.341,0.792-1.806,1.195-2.576,2.142l0.183,0.056
			l-0.437,0.183v0.253c0.017,0.024,0.035,0.048,0.058,0.07c-0.019,0.021-0.039,0.043-0.058,0.064v3.297h11.782v-9.73
			c3.461-0.59,6.749-1.682,9.789-3.202l5.702,7.848l9.532-6.925l-5.721-7.875c2.397-2.46,4.436-5.267,6.038-8.341l9.226,2.998
			l3.641-11.205l-9.251-3.006C368.402,247.543,368.532,245.852,368.532,244.129z M357.857,250.561
			c0.442-0.692,0.157-0.159,0.805-0.659L357.857,250.561z'
							/>
							<path
								opacity='0.2'
								d='M297.948,244.129c0,1.777,0.135,3.523,0.388,5.229l-9.223,2.997l2.825,8.693
			c0.857-0.822,1.618-1.693,2.12-2.64l-1.533,0.907c0.701-0.83,2.365-1.827,1.831-2.215c-0.727,1.018-0.633,0.36-1.155,0.727
			l-0.013,0.093c-0.129,0.091-0.516,0.362-0.368,0.091c0.909-0.411,2.067-1.396,2.948-1.99c-0.424,0.223,0.116-0.608,0.135-0.765
			c0.796-0.645,0.207,0.192,0.298,0.382c0.405-0.368,1.28-0.73,1.356-1.29c0.336-0.741-0.882,0.45-1.236,0.446l2.481-1.826
			l-0.116-0.002c0.249-0.091,0.638-0.364,0.796-0.644c0.052-0.374-0.483,0.085-0.767,0.453c0.886-1.374,2.515-1.45,2.979-2.907
			l-0.384,0.243c0.146-0.189,0.273-0.276,0.393-0.277c-0.001,0.01-0.007,0.022-0.009,0.034l0.772-0.484
			c0.017-0.098,0.04-0.284-0.088-0.194l0.766-0.453c1.314-1.007-0.028-0.655,0.277-1.124l-0.055,0.06
			c0.298-0.41,0.419-0.822,0.129-0.618l1.431-1.004l-0.013-0.002c-0.158,0.037-0.284,0.031-0.323-0.1l0.323,0.1
			c0.767-0.178,2.623-1.895,3.133-1.342c0.281-0.365,0.441-0.718,0.22-0.654c-0.017,0.016-0.046,0.038-0.062,0.053
			c-0.123,0.144-0.249,0.242-0.486,0.409c0.217-0.172,0.363-0.299,0.486-0.409c0.019-0.022,0.035-0.033,0.053-0.054
			c0.006-0.003,0.003,0.003,0.009,0.001c0.934-0.869-0.626-0.199,0.909-1.575c-0.105-0.095-0.245,0.088-0.507,0.273
			c0.371-0.393,0.561-0.511,0.68-0.518c-0.057-0.058-0.094-0.152-0.092-0.319c0.208,0.191,0.768-0.455,1.052-0.823l-0.849,0.174
			c0.494-0.177,0.754-0.36,1.316-1.007l-0.611,0.175c0.509-0.438,0.652-0.368,0.775-0.328c1.74-9.713,9.663-17.275,19.553-18.472
			c0.219-0.204,0.407-0.442,0.45-0.762c0.207,0.09-0.001,0.397-0.308,0.744c0.198-0.023,0.397-0.043,0.597-0.06l-0.144-0.024
			c1.41-0.822,0.316-0.562,1.639-1.658c-0.009,0.09-0.051,0.374,0.225,0.094c0.959-1.01-0.183-0.378,0.385-1.114
			c1.747-0.721,0.245-0.932,2.068-2.212l-0.235-0.004c1.43-1.004,2.536-3.047,3.778-4.423c-0.022,0.183-1.085,1.097-0.645,1.296
			l1.745-1.563c0.022-0.183,0.71-0.92,0.087-0.652c-0.263,0.185-0.421,0.465-0.559,0.646l0.054-0.377
			c1.712-1.369,0.389,0.572,0.703,0.858c0.714-0.923,1.301-0.913,1.848-1.466c-0.041-0.562-1.397,0.728-1.019-0.298
			c0.1-0.201,0.352-0.472,0.666-0.756c-0.912-0.228-1.835-0.427-2.773-0.583v-9.702h-11.782v9.73
			c-3.461,0.59-6.749,1.682-9.789,3.201l-5.702-7.847l-9.532,6.925l5.721,7.875c-2.397,2.46-4.436,5.267-6.038,8.341l-9.227-2.998
			l-3.641,11.205l9.251,3.006C298.077,240.714,297.948,242.406,297.948,244.129z M292.201,259.119l-0.833,0.077
			C292.018,258.74,292.326,259.034,292.201,259.119z M294.458,256.353l-0.504,0.27l1.323-1.097L294.458,256.353z M297.669,253.508
			l0.027-0.187l0.925-0.734L297.669,253.508z'
							/>
							<path
								opacity='0.2'
								d='M350.579,261.777c-0.195-0.124-0.575,0.384-0.626,0.031l0.149-0.233
			c-0.222,0.349-0.567-0.229-0.75,0.4l0.228-0.272c-0.21,0.416,0.71-0.077,0.337,0.505
			C349.771,262.44,350.439,262.082,350.579,261.777z'
							/>
							<path
								opacity='0.2'
								d='M361.685,247.748l0.096,0.025c-0.01-0.071,0.025-0.169,0.073-0.275
			C361.753,247.521,361.661,247.565,361.685,247.748z'
							/>
							<path
								opacity='0.2'
								d='M364.851,245.29l0.249-0.132c0.132-0.377-0.297-0.223-0.538-0.017
			C364.655,245.166,364.674,245.309,364.851,245.29z'
							/>
							<path
								opacity='0.2'
								d='M360.701,251.783c0.41-0.298,0.736,0.139,0.929-0.422
			C361.306,251.61,360.968,251.106,360.701,251.783z'
							/>
							<path
								opacity='0.2'
								d='M361.159,247.115c0.218,0.008,0.756-0.252,0.893,0.015c0.279-0.464,0.571-0.931-0.109-0.816
			l-0.303,0.392c0.156-0.161,0.165-0.089,0.258-0.062C361.769,247.018,361.407,246.984,361.159,247.115z'
							/>
							<path
								opacity='0.2'
								d='M362.085,247.383c0.019-0.122,0-0.197-0.033-0.253c-0.075,0.127-0.145,0.253-0.198,0.369
			C361.95,247.477,362.055,247.471,362.085,247.383z'
							/>
							<path
								opacity='0.2'
								d='M349.985,262.707c-0.592-0.106-0.351,0.373-0.795,0.527
			C349.485,263.186,349.986,263.304,349.985,262.707z'
							/>
							<path
								opacity='0.2'
								d='M355.042,205.079c0.005-0.034,0.017-0.068,0.034-0.102l-0.183-0.133
			c-0.496,0.385-1.157,0.703-1.031,1.147L355.042,205.079z'
							/>
							<polygon
								opacity='0.2'
								points='299.119,260.622 299.205,260.624 300.717,259.248 		'
							/>
							<path
								opacity='0.2'
								d='M298.016,261.819l1.036-0.727c-0.273-0.082-1.071,0.322-1.684,0.969l0.918-0.298l0.351-0.211
			L298.016,261.819z'
							/>
							<path
								opacity='0.2'
								d='M340.33,222.6c0.052,0.017,0.104,0.035,0.156,0.053c0.347-0.116,0.709-0.295,0.861-0.867
			C340.701,221.844,340.702,222.251,340.33,222.6z'
							/>
							<path
								opacity='0.2'
								d='M342.493,220.303c-0.221-0.098-0.922,0.731-0.864,0.269l0.288-0.371
			c-0.429,0.556-0.69-0.106-1.166,0.817l0.409-0.453c-0.437,0.643,0.995-0.362,0.276,0.565
			C341.15,221.5,342.191,220.77,342.493,220.303z'
							/>
							<path
								opacity='0.2'
								d='M304.386,258.193c0.154-0.141,0.252-0.279,0.314-0.407L304.386,258.193z'
							/>
							<path
								opacity='0.2'
								d='M304.764,257.167c-0.993,0.358-1.213,0.26-2.253,0.99l0.054,0.47
			c0.285-0.367-0.054-0.47,0.714-0.923C303.355,257.989,304.032,258.188,304.764,257.167z'
							/>
							<path
								opacity='0.2'
								d='M354.572,204.612l-0.341,0.47c0.167-0.111,0.331-0.232,0.493-0.36L354.572,204.612z'
							/>
							<path
								opacity='0.2'
								d='M304.7,257.786l0.971-1.255C304.688,256.744,304.985,257.219,304.7,257.786z'
							/>
							<path
								opacity='0.2'
								d='M347.938,212.066c-0.146,0.093-0.27,0.142-0.331,0.089l0.04,0.562c0.175-0.226,0.4-0.4,0.596-0.513
			C348.143,212.156,348.04,212.113,347.938,212.066z'
							/>
							<path
								opacity='0.2'
								d='M345.115,214.915c0.034-0.275,0.711-0.918,1.271-1.566
			C345.802,213.335,344.891,214.822,345.115,214.915z'
							/>
							<polygon
								opacity='0.2'
								points='299.582,260.638 299.205,260.624 299.104,260.716 		'
							/>
						</g>
					</g>
				</svg>
				{/* Text */}
				<Box
					display='flex'
					flexDirection='column'
					rowGap='15px'
					justifyContent='center'>
					<Typography
						component='h1'
						sx={{ fontSize: '36px!important', textTransform: 'uppercase' }}>
						{errorTitle}
					</Typography>
					<Typography component='p' sx={{ fontSize: '16px!important' }}>
						{errorDescription}
					</Typography>
				</Box>
			</Box>
		</>
	)
}
