import './CustomersOverview.scss'
import { Chip, IconButton, Tooltip } from '@mui/material'
import { CustomersDisplay } from '../../../../utils/interfaces/ComponentModels'
import { useEffect, useState } from 'react'
import {
	GridColDef,
	GridRenderCellParams,
	GridToolbar,
} from '@mui/x-data-grid-pro'
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined'
import {
	CustomPagination,
	StyledDataGrid,
} from '../../../../styles/styledComponents/displays/StyledDataGrid'
import { useNavigate } from 'react-router'

const CustomersOverview = ({
	customersDisplay,
}: {
	customersDisplay: CustomersDisplay[]
}) => {
	// Display constants
	const [customerRows, setCustomerRows] = useState([] as CustomersDisplay[])

	// General
	const navigate = useNavigate()

	// Column Definition: Customer Table
	const customerColumns: GridColDef[] = [
		{
			field: 'CustomerID',
			headerName: 'Customer ID',
			hideable: false,
			flex: 1,
		},
		{
			field: 'CustomerName',
			headerName: 'Customer Name',
			hideable: false,
			flex: 1,
		},
		{
			field: 'CustomerStatus',
			headerName: 'Customer Status',
			hideable: false,
			flex: 1,
			// Display a outlined chip
			renderCell: (params: GridRenderCellParams<any>) => (
				<>
					{params.value ? (
						<Chip
							label={params.value}
							variant='outlined'
							color={params.value.includes('Not') ? 'error' : 'success'}
						/>
					) : (
						''
					)}
				</>
			),
		},
		{
			field: 'CustomerPartnerReference',
			headerName: 'Customer Reference',
			hideable: false,
			flex: 1,
		},
		{
			field: 'actions',
			type: 'actions',
			cellClassName: 'actions',
			headerName: '',
			width: 50,
			editable: false,
			align: 'center',
			hideable: false,
			// Render actions - Display a cog (VC Order) or no entry (Other)
			getActions: ({ id }) => {
				return [
					<>
						{/* Main icon button - Opens menu to add DDIs, Trunks or E911 */}
						<Tooltip title='Proceed to Customer Overview'>
							<span>
								<IconButton
									id='action-button'
									onClick={() =>
										navigate(
											`/customer-management/customer-overview?customerID=${id}`
										)
									}>
									<PersonSearchOutlinedIcon />
								</IconButton>
							</span>
						</Tooltip>
					</>,
				]
			},
		},
	]

	// ** Datagrid Specific Constants
	// Pagination
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 10,
		page: 0,
	})

	useEffect(() => {
		// Set the rows
		if (customersDisplay) {
			setCustomerRows(customersDisplay)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customersDisplay])

	return (
		<>
			{/* Datagrid: Customers */}
			<StyledDataGrid
				rows={customerRows}
				columns={customerColumns}
				editMode='row'
				checkboxSelection={false}
				pagination
				paginationModel={paginationModel}
				onPaginationModelChange={setPaginationModel}
				pageSizeOptions={[5]}
				slots={{
					pagination: CustomPagination,
					toolbar: GridToolbar,
				}}
				slotProps={{
					toolbar: {
						showQuickFilter: true,
						quickFilterProps: { debounceMs: 500 },
					},
				}}
				getRowId={(row) => row.CustomerID}
				autoHeight={true}
				rowSelection={false}
			/>
		</>
	)
}

export default CustomersOverview
