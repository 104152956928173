import { styled, Button } from '@mui/material'

export const StyledLinkButton = styled(Button)(({ theme }) => ({
	background: 'none!important',
	border: 'none',
	color: theme.palette.primary.main,
	cursor: 'pointer',
	padding: '0',
	font: 'inherit',
	maxWidth: 'max-content',
}))
