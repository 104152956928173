import { useNavigate } from 'react-router'
import AnimatedBox from '../../../shared/displays/AnimatedBox/AnimatedBox'
import SimpleCard from '../../../shared/displays/AnimatedBox/SimpleCardContent/SimpleCardContent'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Box, useTheme } from '@mui/material'

const LearnMore = () => {
	const navigate = useNavigate()
	const theme = useTheme()

	return (
		<>
			<AnimatedBox
				success
				children={
					<SimpleCard
						icon={
							<HelpOutlineIcon style={{ color: theme.palette.primary.main }} />
						}
						title='Require Assistance?'
						description='Click on the button to proceed to our knowledge base.'
						showAction={true}
						actionTooltip='Proceed to knowledge base to view our tutorials, documentation and frequently asked questions'
						action={() => {
							navigate('/knowledge-base')
						}}
					/>
				}
			/>
		</>
	)
}

export default LearnMore
