import { Box, Typography } from '@mui/material'
import { Invitation } from '../../../../../utils/interfaces/APIModels'
import { ModalForm } from '../../../../shared/inputs/ModalForm/ModalForm'
import SwitchAccessShortcutAddOutlinedIcon from '@mui/icons-material/SwitchAccessShortcutAddOutlined'
import { StyledTextField } from '../../../../../styles/styledComponents/inputs/StyledTextField'

function ResendInvite({
	invite,
	handleResendInvite,
	cancel,
}: {
	invite: Invitation
	handleResendInvite: (invite: Invitation) => Promise<void>
	cancel: () => void
}) {
	return (
		<ModalForm
			icon={<SwitchAccessShortcutAddOutlinedIcon />}
			title='Re-invite member'
			description='Resend an invite to the following member'
			buttonText='Re-invite'
			buttonAction={() => {
				handleResendInvite(invite)
				cancel()
			}}
			buttonLoading={false}
			buttonDisabled={false}
			closeModal={cancel}>
			{/* Form Group - Invited by */}
			<Box className='form-group'>
				<Typography component='p' className='form-label'>
					Invited By
				</Typography>
				<StyledTextField
					className='text'
					id='outlined-read-only-input'
					defaultValue={invite.inviterEmail}
					InputProps={{
						readOnly: true,
					}}
				/>
			</Box>
			{/* Form Group - Email */}
			<Box className='form-group'>
				<Typography component='p' className='form-label'>
					Email
				</Typography>
				<StyledTextField
					className='text'
					id='outlined-read-only-input'
					defaultValue={invite.invitedEmail}
					InputProps={{
						readOnly: true,
					}}
				/>
			</Box>
		</ModalForm>
	)
}

export default ResendInvite
