import { useEffect, useState } from 'react'
import {
	AddonLicenseResponse,
	BaseLicenseResponse,
	TeamsUserManagementLicenseResponse,
	TeamsUsersResponse,
} from '../../../../../../utils/interfaces/APIModels'
import { GridColDef, GridRowId } from '@mui/x-data-grid-pro'
import { StyledDataGrid } from '../../../../../../styles/styledComponents/displays/StyledDataGrid'
import {
	Box,
	Button,
	IconButton,
	Skeleton,
	Tooltip,
	useTheme,
	Modal,
} from '@mui/material'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import './TUMUserDisplay.scss'
import { TUMPagination } from '../../../../../../utils/enums/enums'
import { showErrorToast } from '../../../../../../utils/helperFunctions/helperFunctions'
import { TUMVRPList } from '../../../../../../utils/interfaces/ComponentModels'
import StyledModal from '../../../../../../styles/styledComponents/displays/StyledModal'
import TUMEditForm from './TUMEditForm/TUMEditForm'

const TUMUserDisplay = ({
	customerID,
	usersDisplay,
	licenseDisplay,
	vrpList,
	currentPageNo,
	isLastPage,
	handlePageNavigation,
	getDDIRanges,
	getUserAssignedNumbers,
	getDDIsForAssignment,
	getDDIServiceTypeID,
	configureTeamsUser,
}: {
	customerID: string
	usersDisplay: TeamsUsersResponse[]
	licenseDisplay: TeamsUserManagementLicenseResponse
	vrpList: TUMVRPList[]
	currentPageNo: number
	isLastPage: boolean
	handlePageNavigation: any
	getDDIRanges: any
	getUserAssignedNumbers: any
	getDDIsForAssignment: any
	getDDIServiceTypeID: any
	configureTeamsUser: any
}) => {
	// Display constant
	const [teamsUsersDisplay, setTeamsUsersDisplay] = useState(
		[] as TeamsUsersResponse[]
	)
	const [baseLicenseList, setBaseLicenseList] = useState(
		[] as BaseLicenseResponse[]
	)
	const [addonLicenseList, setAddonLicenseList] = useState(
		[] as AddonLicenseResponse[]
	)
	const [tumVRPList, setTUMVRPList] = useState([] as TUMVRPList[])

	// Edit Row Constants
	const [currentEditRow, setCurrentEditRow] = useState({} as TeamsUsersResponse)

	// Flags
	const [rowsLoading, setRowsLoading] = useState(false)
	const [openModal, setOpenModal] = useState(false)

	// Theme
	const theme = useTheme()

	// Column Definition
	const teamsUserColumns: GridColDef[] = [
		{
			field: 'upn',
			headerName: 'Username',
			hideable: false,
			flex: 1,
			renderCell: (params) => (
				<>
					{rowsLoading ? (
						<Skeleton variant='text' width={100} />
					) : (
						<div>{params.value}</div>
					)}
				</>
			),
		},
		{
			field: 'name',
			headerName: 'Name',
			hideable: false,
			flex: 1,
			renderCell: (params) => (
				<>
					{rowsLoading ? (
						<Skeleton variant='text' width={100} />
					) : (
						<div>{params.value}</div>
					)}
				</>
			),
		},
		{
			field: 'baseLicenseName',
			headerName: 'Base License',
			hideable: false,
			flex: 1,
			renderCell: (params) => (
				<>
					{rowsLoading ? (
						<Skeleton variant='text' width={100} />
					) : (
						<div>{params.value}</div>
					)}
				</>
			),
		},
		{
			field: 'addonLicenseName',
			headerName: 'Add-on License',
			hideable: false,
			flex: 1,
			renderCell: (params) => (
				<>
					{rowsLoading ? (
						<Skeleton variant='text' width={100} />
					) : (
						<div>{params.value}</div>
					)}
				</>
			),
		},
		{
			field: 'phoneNumber',
			headerName: 'Phone Number',
			hideable: false,
			flex: 1,
			renderCell: (params) => (
				<>
					{rowsLoading ? (
						<Skeleton variant='text' width={100} />
					) : (
						<div>{params.value}</div>
					)}
				</>
			),
		},
		{
			field: 'voiceRoutingPolicy',
			headerName: 'Voice Routing Policy',
			hideable: false,
			flex: 1,
			renderCell: (params) => (
				<>
					{rowsLoading ? (
						<Skeleton variant='text' width={100} />
					) : (
						<div>{params.value}</div>
					)}
				</>
			),
		},
		{
			field: 'actions',
			type: 'actions',
			cellClassName: 'actions',
			hideable: false,
			width: 80,
			getActions: ({ id }) => {
				return [
					<>
						<Tooltip title='Edit this user'>
							<IconButton onClick={() => handleEditUser(id)}>
								<SettingsOutlinedIcon
									sx={{
										color: theme.palette.primary.main,
									}}
								/>
							</IconButton>
						</Tooltip>
					</>,
				]
			},
		},
	]

	useEffect(() => {
		// User Display List
		if (usersDisplay) {
			setTeamsUsersDisplay(usersDisplay)
		}
		// License List
		if (licenseDisplay) {
			// Base License List
			if (licenseDisplay.baseLicenses) {
				setBaseLicenseList(licenseDisplay.baseLicenses)
			}
			// Add-on License List
			if (licenseDisplay.addonLicenses) {
				setAddonLicenseList(licenseDisplay.addonLicenses)
			}
		}
		// VRP list
		if (vrpList) {
			setTUMVRPList(vrpList)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [usersDisplay, vrpList])

	// This will save the current row to edit and open the drawer for assignment/unassignment
	const handleEditUser = (id: GridRowId) => {
		// Get the current row and open the drawer
		var currentRow = teamsUsersDisplay.find((user) => user.upn + '' === id)

		if (currentRow) {
			// Set current row and open modal
			setCurrentEditRow(currentRow)
			setOpenModal(true)
		}
	}

	// Handle page nav and loading
	const handleNavigation = async (navType: number) => {
		// Loading
		setRowsLoading(true)

		// Save old rows
		var oldRows = teamsUsersDisplay

		// Make call
		var success = await handlePageNavigation(navType)

		if (!success) {
			// Show error and set it back to old rows
			showErrorToast(
				`An error occurred when trying to retrieve the ${TUMPagination[
					navType
				].toLowerCase()} page`
			)
			setTeamsUsersDisplay(oldRows)
		}

		// End loading
		setRowsLoading(false)
	}

	// Handle close drawer
	const handleCloseForm = () => {
		// Close drawer
		setOpenModal(false)
	}

	return (
		<>
			<Box className='tum-users-container'>
				{/* Filter */}
				{/* Display Table */}
				<StyledDataGrid
					rows={teamsUsersDisplay}
					columns={teamsUserColumns}
					editMode='row'
					checkboxSelection={false}
					pagination={false}
					getRowId={(row) => row.upn}
					autoHeight
					rowSelection={false}
					hideFooter
					hideFooterPagination
					hideFooterRowCount
				/>
				{/* Custom Pagination */}
				<Box className='tum-pagination'>
					<Button
						disabled={currentPageNo === 0}
						startIcon={<ArrowBackIcon />}
						variant='contained'
						onClick={() => handleNavigation(TUMPagination.Previous)}>
						Previous
					</Button>
					<Button
						disabled={isLastPage}
						endIcon={<ArrowForwardIcon />}
						variant='contained'
						onClick={() => handleNavigation(TUMPagination.Next)}>
						Next
					</Button>
				</Box>
			</Box>
			{/* Modal Form - Edit and Configure User */}
			<Modal open={openModal}>
				<StyledModal width={600}>
					<TUMEditForm
						customerID={customerID}
						currentUser={currentEditRow}
						baseLicenses={baseLicenseList}
						addonLicenses={addonLicenseList}
						vrpList={tumVRPList}
						closeForm={handleCloseForm}
						getDDIRanges={getDDIRanges}
						getUserAssignedNumbers={getUserAssignedNumbers}
						getDDIsForAssignment={getDDIsForAssignment}
						getDDIServiceTypeID={getDDIServiceTypeID}
						configureTeamsUser={configureTeamsUser}
					/>
				</StyledModal>
			</Modal>
		</>
	)
}

export default TUMUserDisplay
