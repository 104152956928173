import { useState } from 'react'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'

import './Modals.scss'
import { User } from '../../../../../utils/interfaces/APIModels'
import LoadingBox from '../../../../shared/displays/LoadingBox/LoadingBox'
import { UserManagementType } from '../../../../../utils/enums/enums'
import PersonRemoveAlt1OutlinedIcon from '@mui/icons-material/PersonRemoveAlt1Outlined'
import { ModalForm } from '../../../../shared/inputs/ModalForm/ModalForm'
import { StyledTextField } from '../../../../../styles/styledComponents/inputs/StyledTextField'

function RemoveUser({
	user,
	apiCall,
	cancel,
}: {
	user: User
	apiCall: (type: Number, id: string) => Promise<string>
	cancel: () => void
}) {
	const [deletingUser, setDeletingUser] = useState(false)
	const [confirmDelete, setConfirmDelete] = useState(false)

	const deleteUser = async () => {
		setDeletingUser(true)
		await apiCall(UserManagementType.DeleteUser, user.userID)

		setDeletingUser(false)
		cancel()
	}

	return user.userID ? (
		<ModalForm
			icon={<PersonRemoveAlt1OutlinedIcon />}
			title='Remove member'
			description='Remove current member from the portal'
			buttonText='Remove member'
			buttonAction={deleteUser}
			buttonLoading={deletingUser}
			buttonDisabled={!confirmDelete}
			closeModal={cancel}>
			{/* Form Group - Name */}
			<Box className='form-group'>
				<Typography component='p' className='form-label'>
					Name
				</Typography>
				<StyledTextField
					className='text'
					id='outlined-read-only-input'
					defaultValue={user.fullName}
					InputProps={{
						readOnly: true,
					}}
				/>
			</Box>
			{/* Form Group - Email */}
			<Box className='form-group'>
				<Typography component='p' className='form-label'>
					Email
				</Typography>
				<StyledTextField
					className='text'
					id='outlined-read-only-input'
					defaultValue={user.email}
					InputProps={{
						readOnly: true,
					}}
				/>
			</Box>
			{/* Form Checkbox */}
			<FormControlLabel
				control={
					<Checkbox
						checked={confirmDelete}
						onChange={(e) => setConfirmDelete(e.target.checked)}
					/>
				}
				label='Confirm that you want to delete this user'
			/>
		</ModalForm>
	) : (
		<LoadingBox
			title='Getting your data'
			description='Please wait while your data is being retrieved'
		/>
	)
}

export default RemoveUser
