import { Skeleton } from '@mui/material'
import { TUMUserDDIRangeList } from '../../../../../../../utils/interfaces/ComponentModels'
import { useEffect, useState } from 'react'
import { GridColDef } from '@mui/x-data-grid-pro'
import { StyledDataGrid } from '../../../../../../../styles/styledComponents/displays/StyledDataGrid'
import { ModalForm } from '../../../../../../shared/inputs/ModalForm/ModalForm'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'

const TUMDDIOverview = ({
	handleCloseModal,
	getDDIRanges,
}: {
	handleCloseModal: any
	getDDIRanges: any
}) => {
	// Display constants
	const [tumUserDDIRangeList, setTUMUserDDIRangeList] = useState(
		[] as TUMUserDDIRangeList[]
	)

	// Flags
	const [initialCallMade, setInitialCallMade] = useState(false)
	const [loading, setLoading] = useState(false)

	// Column Definition
	const tumDDIRangeColumns: GridColDef[] = [
		{
			field: 'DDIRangeStart',
			headerName: 'Range Start',
			hideable: false,
			flex: 1,
			renderCell: (params) => (
				<>
					{loading ? (
						<Skeleton variant='text' width={100} />
					) : (
						<div>{params.value}</div>
					)}
				</>
			),
		},
		{
			field: 'DDIRangeEnd',
			headerName: 'Range End',
			hideable: false,
			flex: 1,
			renderCell: (params) => (
				<>
					{loading ? (
						<Skeleton variant='text' width={100} />
					) : (
						<div>{params.value}</div>
					)}
				</>
			),
		},
		{
			field: 'Total',
			headerName: 'Total DDIs in Range',
			hideable: false,
			flex: 1,
			renderCell: (params) => (
				<>
					{loading ? (
						<Skeleton variant='text' width={100} />
					) : (
						<div>{params.value}</div>
					)}
				</>
			),
		},
	]

	useEffect(() => {
		// Check if initial call made
		if (!initialCallMade) {
			setInitialCallMade(true)
			buildDDIRangeDisplay()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialCallMade])

	// API Call
	// GET: Retrieve first 10 ranges from DB
	const buildDDIRangeDisplay = async () => {
		// Loading
		setLoading(true)

		// Build ranges
		var ranges = await getDDIRanges()
		if (ranges) {
			setTUMUserDDIRangeList(ranges)
		}

		// Loading
		setLoading(false)
	}

	return (
		<>
			<ModalForm
				icon={<LocalPhoneOutlinedIcon />}
				title='Telephone Number Ranges'
				description='View your owned telephone number ranges list below'
				closeModal={handleCloseModal}>
				{/* Datagrid */}
				<StyledDataGrid
					loading={loading}
					rows={tumUserDDIRangeList}
					columns={tumDDIRangeColumns}
					editMode='row'
					checkboxSelection={false}
					pagination={false}
					getRowId={(row) => row.DDIRangeID}
					autoHeight
					rowSelection={false}
				/>
			</ModalForm>
		</>
	)
}

export default TUMDDIOverview
