import { Box, styled } from '@mui/material'

const Preloader = () => {
	// Styled Box - Need to get the theme colours
	const StyledPreloader = styled(Box)(({ theme }) => ({
		position: 'relative',
		minHeight: 'calc(100vh - 130px)',
		'.socket': {
			width: '200px',
			height: '200px',
			position: 'absolute',
			left: '50%',
			marginLeft: '-100px',
			top: '50%',
			marginTop: '-100px',
		},
		'.hex-brick': {
			background: theme.palette.secondary.main,
			width: '30px',
			height: '17px',
			position: 'absolute',
			top: '5px',
			animationName: 'fade',
			animationDuration: '2s',
			animationIterationCount: 'infinite',
			WebkitAnimationName: 'fade',
			WebkitAnimationDuration: '2s',
			WebkitAnimationIterationCount: 'infinite',
		},
		'.h2': {
			transform: 'rotate(60deg)',
			WebkitTransform: 'rotate(60deg)',
		},
		'.h3': {
			transform: 'rotate(-60deg)',
			WebkitTransform: 'rotate(-60deg)',
		},
		'.gel': {
			height: '30px',
			width: '30px',
			transition: 'all 0.3s',
			WebkitTransition: 'all 0.3s',
			position: 'absolute',
			top: '50%',
			left: '50%',
		},
		'.center-gel': {
			marginLeft: '-15px',
			marginTop: '-15px',
			animationName: 'pulse',
			animationDuration: '2s',
			animationIterationCount: 'infinite',
			WebkitAnimationName: 'pulse',
			WebkitAnimationDuration: '2s',
			WebkitAnimationIterationCount: 'infinite',
		},
		'.c1': {
			marginLeft: '-47px',
			marginTop: '-15px',
		},
		'.c2': {
			marginLeft: '-31px',
			marginTop: '-43px',
		},
		'.c3': {
			marginLeft: '1px',
			marginTop: '-43px',
		},
		'.c4 ': {
			marginLeft: '17px',
			marginTop: '-15px',
		},
		'.c5': {
			marginLeft: '-31px',
			marginTop: '13px',
		},
		'.c6': {
			marginLeft: '1px',
			marginTop: '13px',
		},
		'.c7': {
			marginLeft: '-63px',
			marginTop: '-43px',
		},
		'.c8': {
			marginLeft: '33px',
			marginTop: '-43px',
		},
		'.c9': {
			marginLeft: '-15px',
			marginTop: '41px',
		},
		'.c10': {
			marginLeft: '-63px',
			marginTop: '13px',
		},
		'.c11': {
			marginLeft: '33px',
			marginTop: '13px',
		},
		'.c12': {
			marginLeft: '-15px',
			marginTop: '-71px',
		},
		'.c13': {
			marginLeft: '-47px',
			marginTop: '-71px',
		},
		'.c14': {
			marginLeft: '17px',
			marginTop: '-71px',
		},
		'.c15': {
			marginLeft: '-47px',
			marginTop: '41px',
		},
		'.c16': {
			marginLeft: '17px',
			marginTop: '41px',
		},
		'.c17': {
			marginLeft: '-79px',
			marginTop: '-15px',
		},
		'.c18': {
			marginLeft: '49px',
			marginTop: '-15px',
		},
		'.c19': {
			marginLeft: '-63px',
			marginTop: '-99px',
		},
		'.c20': {
			marginLeft: '33px',
			marginTop: '-99px',
		},
		'.c21': {
			marginLeft: '1px',
			marginTop: '-99px',
		},
		'.c22': {
			marginLeft: '-31px',
			marginTop: '-99px',
		},
		'.c23': {
			marginLeft: '-63px',
			marginTop: '69px',
		},
		'.c24': {
			marginLeft: '33px',
			marginTop: '69px',
		},
		'.c25': {
			marginLeft: '1px',
			marginTop: '69px',
		},
		'.c26': {
			marginLeft: '-31px',
			marginTop: '69px',
		},
		'.c27': {
			marginLeft: '-79px',
			marginTop: '-15px',
		},
		'.c28': {
			marginLeft: '-95px',
			marginTop: '-43px',
		},
		'.c29': {
			marginLeft: '-95px',
			marginTop: '13px',
		},
		'.c30': {
			marginLeft: '49px',
			marginTop: '41px',
		},
		'.c31': {
			marginLeft: '-79px',
			marginTop: '-71px',
		},
		'.c32': {
			marginLeft: '-111px',
			marginTop: '-15px',
		},
		'.c33': {
			marginLeft: '65px',
			marginTop: '-43px',
		},
		'.c34': {
			marginLeft: ' 65px',
			marginTop: '13px',
		},
		'.c35': {
			marginLeft: '-79px',
			marginTop: '41px',
		},
		'.c36': {
			marginLeft: '49px',
			marginTop: '-71px',
		},
		'.c37': {
			marginLeft: '81px',
			marginTop: '-15px',
		},
		'.r1': {
			animationName: 'pulse',
			animationDuration: '2s',
			animationIterationCount: 'infinite',
			animationDelay: '0.2s',
			WebkitAnimationName: 'pulse',
			WebkitAnimationDuration: '2s',
			WebkitAnimationIterationCount: 'infinite',
			WebkitAnimationDelay: '0.2s',
		},
		'.r2': {
			animationName: 'pulse',
			animationDuration: '2s',
			animationIterationCount: 'infinite',
			animationDelay: '0.4s',
			WebkitAnimationName: 'pulse',
			WebkitAnimationDuration: '2s',
			WebkitAnimationIterationCount: 'infinite',
			WebkitAnimationDelay: '0.4s',
		},
		'.r3': {
			animationName: 'pulse',
			animationDuration: '2s',
			animationIterationCount: 'infinite',
			animationDelay: '0.6s',
			WebkitAnimationName: 'pulse',
			WebkitAnimationDuration: '2s',
			WebkitAnimationIterationCount: 'infinite',
			WebkitAnimationDelay: '0.6s',
		},
		'.r1 > .hex-brick': {
			animationName: 'fade',
			animationDuration: '2s',
			animationIterationCount: 'infinite',
			animationDelay: '0.2s',
			WebkitAnimationName: 'fade',
			WebkitAnimationDuration: '2s',
			WebkitAnimationIterationCount: 'infinite',
			WebkitAnimationDelay: '0.2s',
		},
		'.r2 > .hex-brick': {
			animationName: 'fade',
			animationDuration: '2s',
			animationIterationCount: 'infinite',
			animationDelay: '0.4s',
			WebkitAnimationName: 'fade',
			WebkitAnimationDuration: '2s',
			WebkitAnimationIterationCount: 'infinite',
			WebkitAnimationDelay: '0.4s',
		},
		'.r3 > .hex-brick': {
			animationName: 'fade',
			animationDuration: '2s',
			animationIterationCount: 'infinite',
			animationDelay: '0.6s',
			WebkitAnimationName: 'fade',
			WebkitAnimationDuration: '2s',
			WebkitAnimationIterationCount: 'infinite',
			WebkitAnimationDelay: '0.6s',
		},
		'@keyframes pulse': {
			'0%': {
				WebkitTransform: 'scale(1)',
				transform: 'scale(1)',
			},
			'50%': {
				WebkitTransform: 'scale(0.01)',
				transform: 'scale(0.01)',
			},
			'100%': {
				WebkitTransform: 'scale(1)',
				transform: 'scale(1)',
			},
		},
		'@keyframes fade': {
			'0%': {
				background: theme.palette.secondary.main,
			},
			'50%': {
				background: theme.palette.primary.main,
			},
			'100%': {
				background: theme.palette.secondary.main,
			},
		},
		'@-webkit-keyframes pulse': {
			'0%': {
				WebkitTransform: 'scale(1)',
				transform: 'scale(1)',
			},
			'50%': {
				WebkitTransform: 'scale(0.01)',
				transform: 'scale(0.01)',
			},
			'100%': {
				WebkitTransform: 'scale(1)',
				transform: 'scale(1)',
			},
		},
		'@-webkit-keyframes fade': {
			'0%': {
				background: theme.palette.secondary.main,
			},
			'50%': {
				background: theme.palette.primary.main,
			},
			'100%': {
				background: theme.palette.secondary.main,
			},
		},
	}))

	return (
		<StyledPreloader>
			<Box className='socket'>
				<Box className='gel center-gel'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c1 r1'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c2 r1'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c3 r1'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c4 r1'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c5 r1'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c6 r1'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>

				<Box className='gel c7 r2'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>

				<Box className='gel c8 r2'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c9 r2'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c10 r2'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c11 r2'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c12 r2'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c13 r2'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c14 r2'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c15 r2'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c16 r2'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c17 r2'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c18 r2'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c19 r3'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c20 r3'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c21 r3'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c22 r3'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c23 r3'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c24 r3'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c25 r3'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c26 r3'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c28 r3'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c29 r3'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c30 r3'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c31 r3'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c32 r3'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c33 r3'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c34 r3'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c35 r3'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c36 r3'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
				<Box className='gel c37 r3'>
					<Box className='hex-brick h1'></Box>
					<Box className='hex-brick h2'></Box>
					<Box className='hex-brick h3'></Box>
				</Box>
			</Box>
		</StyledPreloader>
	)
}

export default Preloader
