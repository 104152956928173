import { Avatar, Box, Tooltip, Typography } from '@mui/material'
import './AvatarBox.scss'
import { stringAvatar } from '../../../../utils/helperFunctions/helperFunctions'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { useEffect, useState } from 'react'

const AvatarBox = () => {
	// Global variables
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const loggedInRoleAndPermissions = useSelector(
		(state: RootState) =>
			state.RootReducer.loggedInUserRolesPermissionsReducer.value
	)

	// Display constants
	const [userFullName, setUserFullName] = useState('')
	const [userRole, setUserRole] = useState('')

	useEffect(() => {
		if (loggedInUser && loggedInRoleAndPermissions) {
			// Full name
			if (loggedInUser.fullName) {
				setUserFullName(loggedInUser.fullName)
			}
			// Role
			if (
				loggedInRoleAndPermissions[0] &&
				loggedInRoleAndPermissions[0].Role &&
				loggedInRoleAndPermissions[0].Role?.Description
			) {
				setUserRole(loggedInRoleAndPermissions[0].Role?.Description)
			}
		}
	}, [loggedInUser, loggedInRoleAndPermissions])

	return userFullName && userRole ? (
		<>
			<Box className='avatar-box-container'>
				{/* Avatar */}
				<Tooltip
					title={
						<Box className='avatar-tooltip'>
							{/* Name and Surname */}
							<Typography component='h4'>{userFullName}</Typography>
							{/* Position */}
							<Typography component='p'>{userRole}</Typography>
						</Box>
					}>
					<Avatar className='avatar' {...stringAvatar(userFullName[0])} />
				</Tooltip>
				{/* Text */}
				<Box className='avatar-box-text'>
					{/* Name and Surname */}
					<Typography component='h4'>{userFullName}</Typography>
					{/* Position */}
					<Typography component='p'>{userRole}</Typography>
				</Box>
			</Box>
		</>
	) : null
}

export default AvatarBox
