import { styled, Button } from '@mui/material'

export const StyledButton = styled(Button)(({ theme }) => ({
	borderRadius: 10,
	padding: '10px 30px',
	color: theme.palette.primary.contrastText,
	background: theme.palette.primary.main,
	transition: '0.7s',
	':hover': {
		background: theme.palette.primary.main,
		opacity: '0.7',
	},
}))
