//Thakgalang was here
// Styling
import './App.scss'
import './styles/root-style.scss'
// Other
import { useEffect, useState } from 'react'
import { Box, ThemeProvider } from '@mui/material'
import { SideMenu } from './components/shared/navigation/SideMenu/SideMenu'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import SetSiteTheme from './utils/customHooks/SetSiteTheme/SetSiteTheme'
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from 'react-router-dom'
import Home from './pages/home/Home'
import Header from './components/shared/navigation/Header/Header'
import { useAuth0 } from '@auth0/auth0-react'
import { useSelector } from 'react-redux'
import { RootState } from './store/store'
import { useAppDispatch } from './store/hooks'
import { setInitializeSiteTheme } from './store/reducers/reducers'
import Preloader from './components/shared/displays/Preloader/Preloader'
import { initialTheme } from './styles/theme'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ProtectedRoute } from './components/shared/protected/ProtectedRoute'
import { initialRouteList } from './utils/constants/constants'
import { RouteItems } from './utils/interfaces/ComponentModels'
import LoginRedirect from './pages/login/LoginRedirect/LoginRedirect'
import { Configuration, PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { clarity } from 'react-microsoft-clarity'

function App() {
	// MUI License
	LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY + '')

	// Global variables
	const initializeSiteTheme = useSelector(
		(state: RootState) => state.RootReducer.initializeSiteThemeReducer.value
	)

	// Theme Hook
	const { getPartnerCustomizationOptions } = SetSiteTheme()

	// Auth0
	const { isAuthenticated, user } = useAuth0()

	// Theme constants
	const [mode] = useState<'light' | 'dark'>('light')
	const [theme, setTheme] = useState(initialTheme(mode))
	const [themeCustomized, setThemeCustomized] = useState(false)
	const [routeList] = useState<RouteItems[]>(initialRouteList)

	// MUI License
	LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY + '')

	// Sidemenu - Width used so the content does not overlap
	const [isSideMenuCollapsed, setIsSideMenuCollapsed] = useState(false)
	const [isSmallerScreen, setIsSmallerScreen] = useState(false)

	// General
	const dispatch = useAppDispatch()

	// Use Effect 1: Initialize theme
	useEffect(() => {
		// Initialize theme
		if (!initializeSiteTheme) {
			dispatch(setInitializeSiteTheme(true))
			setSiteTheme()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated, user, theme, initializeSiteTheme])

	// Use Effect 2: Check for change in screen size
	useEffect(() => {
		const checkScreenSize = () => {
			// Check for specific size screen for responsiveness
			if (window.matchMedia('(max-width: 968px)').matches) {
				setIsSmallerScreen(true)
				setIsSideMenuCollapsed(true)
			} else {
				setIsSmallerScreen(false)
				setIsSideMenuCollapsed(false)
			}
		}
		checkScreenSize()

		window.addEventListener('resize', checkScreenSize)
		return () => {
			window.removeEventListener('resize', checkScreenSize)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		// Initialize MS Clarity
		clarity.init(process.env.REACT_APP_MS_CLARITY_ID + '')
	}, [])

	// MSAL configuration
	const configuration: Configuration = {
		auth: {
			clientId: process.env.REACT_APP_CLIENT_ID + '',
		},
	}

	const pca = new PublicClientApplication(configuration)

	// Get the theme
	async function setSiteTheme() {
		// Make call
		var theme = await getPartnerCustomizationOptions(mode)

		if (theme) {
			setTheme(theme)
		}

		setThemeCustomized(true)
	}

	// Side menu logic
	// Toggle collapse menu
	const toggleSideMenu = () => {
		setIsSideMenuCollapsed((prevState) => !prevState)
	}

	// Padding for the content area based on the side menu's collapse state
	const getContentPadding = () => {
		return isSideMenuCollapsed
			? '30px 30px 30px calc(84px + 30px)'
			: '30px 30px 30px calc(300px + 30px)'
	}

	return themeCustomized ? (
		<>
			<MsalProvider instance={pca}>
				<ThemeProvider theme={theme}>
					<Router>
						{/* This is a 1:4 layout with the 1 being the sidebar and 4 being the content */}
						<Box id='main' component='main'>
							{/* Sidebar Menu */}
							<SideMenu
								isCollapsed={isSideMenuCollapsed}
								toggleSideMenu={toggleSideMenu}
								isSmallerScreen={isSmallerScreen}
							/>
							{/* Content */}
							<Box
								id='content'
								className='content'
								padding={getContentPadding()}>
								{/* Header */}
								<Header />
								{/* Page Content */}
								<Routes>
									{/* Routes - Map from constants */}
									{routeList.map((item, index) => {
										return (
											<Route
												key={index}
												path={item.RouteLink}
												element={
													item.PermissionRequired === 0 ? (
														item.RouteElement
													) : (
														<ProtectedRoute
															component={item.RouteElement}
															permissionID={item.PermissionRequired}
														/>
													)
												}
											/>
										)
									})}
									{/* Login */}
									<Route path='/login' element={<LoginRedirect />} />
									<Route
										path='/'
										element={
											isAuthenticated ? <Home /> : <Navigate to='/login' />
										}
									/>
									{/* Error Page */}
									<Route
										path='/Error'
										element={<h1>Something went wrong</h1>}
									/>
									{/* Redirect(s) */}
									{/* If not logged in, redirect to login page */}
									{!isAuthenticated && (
										<Route path='/*' element={<Navigate to='/login' />} />
									)}
								</Routes>
							</Box>
						</Box>
					</Router>
					{/* Toast display style */}
					{
						<ToastContainer
							style={{
								width: '350px',
								paddingLeft: '10px',
							}}
							position='top-right'
							autoClose={6000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick={false}
							rtl={false}
							pauseOnFocusLoss
							draggable={false}
							pauseOnHover
						/>
					}
				</ThemeProvider>
			</MsalProvider>
		</>
	) : (
		<Preloader />
	)
}

export default App
