import { TableCell, TableRow } from '@mui/material'
import { EditableTableCell } from '../EditableTableCell/EditableTableCell'
import { GenericColumn } from '../../../../../../../../../utils/interfaces/ComponentModels'

interface EditableTableRowProps {
	row: Record<string, any>
	columns: GenericColumn[]
	isInEditMode: boolean
	onChange: (rowId: any, field: string, newValue: any) => void
	setHasValidationError: React.Dispatch<React.SetStateAction<boolean>>
}

export const EditableTableRow: React.FC<EditableTableRowProps> = ({
	row,
	columns,
	isInEditMode,
	onChange,
	setHasValidationError,
}) => {
	return (
		<TableRow>
			{columns.map((column) => (
				<TableCell key={column.ID}>
					<EditableTableCell
						isInEditMode={
							column.ID === 'MRCPerUnit' && row[column.ID] === '0.00'
								? false
								: isInEditMode
						}
						isColumnEditable={column.IsEditable}
						value={
							column.ID === 'SkuTerm' && row.MRCPerUnit == '0.00'
								? 'No Term'
								: row[column.ID]
						}
						setHasValidationError={setHasValidationError}
						onChange={(newValue: any) => {
							onChange(row[column.ID], column.ID, newValue)
						}}
					/>
				</TableCell>
			))}
		</TableRow>
	)
}
