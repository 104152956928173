import { PaletteMode } from '@mui/material'
import UseCrud from '../APICalls/UseCrud'
import { Partners, ReturnTypes } from '../../enums/enums'
import { DataResponse } from '../../interfaces/APIModels'
import {
	CustomerPartner,
	PartnerCustomization,
} from '../../interfaces/DBModels'
import { getTheme } from '../../../styles/theme'
import {
	setLogo,
	setOrganizationID,
	setPartnerID,
	setPartnerName,
	setCustomerPartner,
} from '../../../store/reducers/reducers'
import { useAppDispatch } from '../../../store/hooks'

export default function SetSiteTheme() {
	// Hook Calls
	const { fetchData } = UseCrud()

	// General
	const dispatch = useAppDispatch()

	// Function to determine if colour received from DB is in correct format
	const isValidColorHex = (inputString: string) => {
		// Define a regular expression pattern to match valid color hex format
		const hexColorPattern = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/

		return hexColorPattern.test(inputString)
	}

	async function getPartnerCustomizationOptions(mode: PaletteMode) {
		// This is the generic theme if the user does not have any colours found for the partner
		var primaryColour = '#5341B2'
		var primaryTextColour = '#fff'
		var secondaryColour = '#EDEAFF'
		var secondaryTextColour = '#000'

		// Get the partnerID based on the URL and then dispatch to state
		var partnerID = 0
		switch (window.location.origin) {
			// Advania
			case process.env.REACT_APP_ADVANIA_PORTAL_URL + '':
				partnerID = Partners.Advania
				break

			// Software One
			case process.env.REACT_APP_SO_PORTAL_URL + '':
				partnerID = Partners['Software One']
				break

			// Mirus
			case process.env.REACT_APP_MIRUS_PORTAL_URL + '':
				partnerID = Partners.Mirus
				break

			case process.env.REACT_APP_SIPCOM_PORTAL_URL + '':
				partnerID = Partners.Sipcom
				break

			// Digital Voice
			case process.env.REACT_APP_DIGITALVOICE_PORTAL_URL + '':
				partnerID = Partners['Digital Voice']
				break

			// Default is Sipcom
			default:
				partnerID = Partners.Sipcom
			//partnerID = Partners.Lumen
		}
		dispatch(setPartnerID(partnerID))

		// Make hook call
		if (partnerID !== 0) {
			var dataResponse = (await fetchData({
				FileAndFunctionName:
					'SetSiteTheme.tsx: getPartnerCustomizationOptions()',
				QueryURL: `GetV2?Params=PartnerCustomization.First(PartnerCustomization.CustomerPartnerID = '${partnerID}'),CustomerPartner.first(CustomerPartner.CustomerPartnerID = '${partnerID}'))`,
				ErrorMessage:
					'An error occurred when getting the customer partner customization options',
				ShowErrorToast: false,
				LogErrorToDB: true,
				ReturnType: ReturnTypes.ObjectOrList,
			})) as DataResponse
			if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
				// Get object
				var partnerCustomizationObj = dataResponse.Obj
					.PartnerCustomization as PartnerCustomization

				if (partnerCustomizationObj) {
					// Get the primary and secondary colours - Ensure it is in the correct format
					if (
						partnerCustomizationObj.PrimaryColour &&
						isValidColorHex(partnerCustomizationObj.PrimaryColour)
					) {
						primaryColour = partnerCustomizationObj.PrimaryColour
					}
					if (
						partnerCustomizationObj.SecondaryColour &&
						isValidColorHex(partnerCustomizationObj.SecondaryColour)
					) {
						secondaryColour = partnerCustomizationObj.SecondaryColour
					}

					// Get the font colours from the DB
					if (
						partnerCustomizationObj.PrimaryTextColour &&
						isValidColorHex(partnerCustomizationObj.PrimaryTextColour)
					) {
						primaryTextColour = partnerCustomizationObj.PrimaryTextColour
					}
					if (
						partnerCustomizationObj.SecondaryTextColour &&
						isValidColorHex(partnerCustomizationObj.SecondaryTextColour)
					) {
						secondaryTextColour = partnerCustomizationObj.SecondaryTextColour
					}

					// Then check for a logo URL and pass to state
					if (
						partnerCustomizationObj.PartnerLogoURL &&
						partnerCustomizationObj.PartnerLogoURL.length > 0
					) {
						dispatch(setLogo(partnerCustomizationObj.PartnerLogoURL))
					}
				}
				var customerPartner = dataResponse.Obj
					.CustomerPartner as CustomerPartner
				dispatch(setPartnerName(customerPartner.CustomerPartnerName ?? ''))
				dispatch(setOrganizationID(customerPartner.Auth0OrganizationID + ''))
				dispatch(setCustomerPartner(customerPartner))
			}
		}

		// After all is completed, then create the new theme and return it
		var theme = getTheme(
			mode,
			primaryColour,
			primaryTextColour,
			secondaryColour,
			secondaryTextColour
		)
		return theme
	}

	return { getPartnerCustomizationOptions }
}
