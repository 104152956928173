import { Badge, Box, Button, Popover, Tab, Tabs } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import { LoadingButton } from '@mui/lab'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import {
	DDIDisplay,
	DDIRangeDisplay,
	FilterComponent,
} from '../../../../utils/interfaces/ComponentModels'
import { usePostBetaObjectWithoutRefetchMutation } from '../../../../services/proxyAPIData'
import {
	DDIStatuses,
	Roles,
	ServiceTypes,
	TNFilterKeyMappings,
	TNRangesFilterKeyMappings,
} from '../../../../utils/enums/enums'
import {
	BetaAPIMutation,
	BetaObject,
	DataResponse,
	DDIPaginationResponse,
	DDIPaginationResponseResult,
	DDIRangesPaginationResponse,
	DDIRangesPaginationResponseResult,
	FilterRequest,
} from '../../../../utils/interfaces/APIModels'
import {
	getEnumKeyByValue,
	getEnumKeysAsArray,
	showErrorToast,
	toAlphaString,
	toBeta,
} from '../../../../utils/helperFunctions/helperFunctions'
import FiltersComponent from '../../filter/FiltersComponent'
import { TabPanel } from '../../displays/TabPanel/TabPanel'
import Preloader from '../../displays/Preloader/Preloader'
import TNTable from './TNTable'
import TNRangeDisplay from './TNRangeDisplay'
import { AddressMap } from '../../../../utils/interfaces/DBModels'
import './TNDisplay.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'

const TNDisplay = () => {
	//Constants
	const [currentTab, setCurrentTab] = useState('Numbers')
	const pageSize = 10
	const [currentPageNo, setCurrentPageNo] = useState(0)
	const [tabValue, setTabValue] = useState(0)
	const [maxPageNo, setMaxPageNo] = useState(0)
	const [totalRecords, setTotalRecords] = useState(0)
	const [ddiRangesMaxPageNo, setDDIRangesMaxPageNo] = useState(0)
	const [ddiRangesTotalRecords, setDDIRangesTotalRecords] = useState(0)
	const [addressMapList, setAddressMapList] = useState([] as AddressMap[])

	const [tnFilters, setTNFilters] = useState([] as FilterComponent[])
	const [sortByColumn, setSortByColumn] = useState<FilterComponent | undefined>(
		undefined
	)
	const [tnFilterQuery, setFilterQuery] = useState({} as Record<string, string>)
	const [filterAnchorEl, setFilterAnchorEl] =
		useState<HTMLButtonElement | null>(null)
	const openFilter = Boolean(filterAnchorEl)
	const filterID = openFilter ? 'simple-popover' : undefined
	const roleID = useSelector(
		(state: RootState) => state.RootReducer.roleIDReducer.value
	)
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const partnerID = useSelector(
		(state: RootState) => state.RootReducer.partnerIDReducer.value
	)

	//Lists
	const tnTableList = useRef([] as DDIDisplay[])
	const tnRangeDisplayList = useRef([] as DDIRangeDisplay[])

	//Flags
	const [filterLoading, setFilterLoading] = useState(false)
	const [navLoadingType, setNavLoadingType] = useState('')
	const [getFirstTNCallMade, setGetFirstTNCallMade] = useState(false)

	//Hooks
	const [postFilterDDIs] = usePostBetaObjectWithoutRefetchMutation()
	const [postFilterDDIRanges] = usePostBetaObjectWithoutRefetchMutation()

	//track switching tabs
	useEffect(() => {
		setFilterLoading(true)
		setTNFilters([] as FilterComponent[])
		setFilterQuery({} as Record<string, string>)
		if (!getFirstTNCallMade || tabValue === 0) {
			setCurrentTab('Numbers')
			getDDIData({
				pageNo: 1,
				pageSize: pageSize,
			})
		}

		if (tabValue === 1) {
			setCurrentTab('NumberRanges')
			getDDIRangeData({
				pageNo: 1,
				pageSize: pageSize,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabValue])

	const getAddressStringForPaginatedDDI = (
		ddiPaginatedResponseResult: DDIPaginationResponseResult
	) => {
		var _address = ''

		//if address is default
		if (
			ddiPaginatedResponseResult.AddressLine1?.trim().toLowerCase() + '' ===
				'no_address' &&
			ddiPaginatedResponseResult?.City?.trim().toLowerCase() + '' === 'no_city'
		) {
			_address += 'Awaiting Address'
		} else {
			if ((ddiPaginatedResponseResult?.HouseNumber + '').length > 0) {
				_address += `${ddiPaginatedResponseResult.HouseNumber} `
			}

			_address += `${ddiPaginatedResponseResult.AddressLine1}, `

			if ((ddiPaginatedResponseResult?.AddressLine2 + '').length > 0) {
				_address += `${ddiPaginatedResponseResult.AddressLine2}, `
			}

			if ((ddiPaginatedResponseResult?.City + '').length > 0) {
				_address += `${ddiPaginatedResponseResult.City}, `
			}

			_address += `${ddiPaginatedResponseResult.StateName}, ${ddiPaginatedResponseResult.CountryName}`
		}

		return _address
	}

	const handleTabChange = (event: React.SyntheticEvent, newvalue: number) => {
		setTabValue(newvalue)
	}

	//get data for tns
	const getDDIData = async (filterRequest: FilterRequest) => {
		tnTableList.current = []

		//get current page nr
		setCurrentPageNo(Number(filterRequest.pageNo))

		// Ensure filters is initialized
		const filters = filterRequest.filters ? { ...filterRequest.filters } : {}

		//check which filters to use
		switch (roleID) {
			case Roles.CustomerAdmin:
				filters['CustomerPartnerID'] = `${partnerID}`
				filters['CustomerID'] = `${loggedInUser.customerID}`
				break
			case Roles.PartnerAdmin:
				filters['CustomerPartnerID'] = `${partnerID}`
				break
			default:
				break
		}

		// Replace filters in filterRequest
		const updatedFilterRequest = { ...filterRequest, filters }

		var betaDataObj = await toBeta(updatedFilterRequest)

		var betaObj: BetaObject = {
			Content: betaDataObj,
		}

		var betaApiMutation: BetaAPIMutation = {
			BetaObject: betaObj,
			QueryParam: 'FilterDDIs',
		}

		var displayListTNs = [] as DDIDisplay[]
		var maxPageNo = 0
		var totalRecords = 0

		await postFilterDDIs(betaApiMutation)
			.unwrap()
			.then(async (response) => {
				if (response?.Content) {
					var dataResponse = JSON.parse(
						await toAlphaString(response?.Content)
					) as DataResponse

					if (dataResponse?.Obj) {
						var ddiPaginationResponse =
							dataResponse?.Obj as DDIPaginationResponse

						if (ddiPaginationResponse?.CustomerAddressMapList?.AddressMapList) {
							setAddressMapList(
								ddiPaginationResponse.CustomerAddressMapList
									.AddressMapList as AddressMap[]
							)
						} else {
							setAddressMapList([] as AddressMap[])
						}

						if (
							ddiPaginationResponse?.PaginatedResults &&
							ddiPaginationResponse.PaginatedResults.length > 0
						) {
							var ddiPaginationResponseResults =
								ddiPaginationResponse?.PaginatedResults as DDIPaginationResponseResult[]

							//build the display
							ddiPaginationResponseResults.forEach((x) => {
								var _address = getAddressStringForPaginatedDDI(x)

								var serviceTypeOut = ''
								var serviceTypeIn = ''

								if (
									x?.ServiceTypeOutID &&
									(x?.ServiceTypeOutID + '').length > 0
								) {
									serviceTypeOut = ServiceTypes[Number(x.ServiceTypeOutID)]
								}

								if (
									x?.ServiceTypeInID &&
									(x?.ServiceTypeInID + '').length > 0
								) {
									serviceTypeIn = ServiceTypes[Number(x.ServiceTypeInID)]
								}

								var _ddiDisplayListToAdd: DDIDisplay = {
									ID: Number(x.ID),
									DDI: x?.DDIValue + '',
									DDIRangeID: x.DDIRangeID,
									AddressID: x.AddressID,
									AddressFriendlyName: _address,
									IngressServiceID: x.ServiceInID,
									EgressServiceID: x.ServiceOutID,
									DDIStatus: DDIStatuses[Number(x.DDIStatusID)],
									CustomerName: x.CustomerName ?? 'No_Customer',
									CustomerID: x.CustomerID,
									CountryID: x.CountryID,
									CountryName: x.CountryName,
									ServiceTypeOut: serviceTypeOut,
									ServiceTypeIn: serviceTypeIn,
								}

								displayListTNs.push(_ddiDisplayListToAdd)
							})

							maxPageNo = Math.ceil(
								Number(ddiPaginationResponse?.TotalRecordsFound) / pageSize
							)

							totalRecords = Number(ddiPaginationResponse?.TotalRecordsFound)
						}
					}
				}
			})
			.catch(() => {
				showErrorToast('Unable to get Numbers')
			})

		tnTableList.current = displayListTNs
		setMaxPageNo(maxPageNo)
		setTotalRecords(totalRecords)

		setGetFirstTNCallMade(true)
		setFilterLoading(false)
	}

	//get data for tn ranges
	const getDDIRangeData = async (filterRequest: FilterRequest) => {
		setCurrentPageNo(Number(filterRequest.pageNo))
		tnRangeDisplayList.current = []

		// Ensure filters is initialized
		const filters = filterRequest.filters ? { ...filterRequest.filters } : {}

		//check which filters to use
		switch (roleID) {
			case Roles.CustomerAdmin:
				filters['CustomerPartnerID'] = `${partnerID}`
				filters['CustomerID'] = `${loggedInUser.customerID}`
				break
			case Roles.PartnerAdmin:
				filters['CustomerPartnerID'] = `${partnerID}`
				break
			default:
				break
		}

		// Replace filters in filterRequest
		const updatedFilterRequest = { ...filterRequest, filters }

		var betaDataObj = await toBeta(updatedFilterRequest)

		var betaObj: BetaObject = {
			Content: betaDataObj,
		}

		var betaApiMutation: BetaAPIMutation = {
			BetaObject: betaObj,
			QueryParam: 'FilterDDIRanges',
		}

		var _ddiRangesDisplayList = [] as DDIRangeDisplay[]
		var maxPageNo = 0
		var totalRecords = 0

		await postFilterDDIRanges(betaApiMutation)
			.unwrap()
			.then(async (response) => {
				if (response?.Content) {
					var dataResponse = JSON.parse(
						await toAlphaString(response?.Content)
					) as DataResponse

					if (dataResponse?.Obj) {
						var ddiRangesPaginationResponse =
							dataResponse?.Obj as DDIRangesPaginationResponse

						if (
							ddiRangesPaginationResponse?.CustomerAddressMapList
								?.AddressMapList
						) {
							setAddressMapList(
								ddiRangesPaginationResponse.CustomerAddressMapList
									.AddressMapList as AddressMap[]
							)
						} else {
							setAddressMapList([] as AddressMap[])
						}

						if (
							ddiRangesPaginationResponse?.PaginatedResults &&
							ddiRangesPaginationResponse.PaginatedResults.length > 0
						) {
							var ddiRangesPaginationResponseResults =
								ddiRangesPaginationResponse?.PaginatedResults as DDIRangesPaginationResponseResult[]

							//build list
							ddiRangesPaginationResponseResults.forEach((x) => {
								var _ddiRangeDisplayToAdd: DDIRangeDisplay = {
									DDIRangeID: x.DDIRangeID,
									DDIRangeStart: x.RangeStart + '',
									DDIRangeEnd: x.RangeEnd + '',
									CustomerName: x.CustomerName,
									CustomerID: x.CustomerID + '',
								}
								_ddiRangesDisplayList.push(_ddiRangeDisplayToAdd)
							})

							maxPageNo = Math.ceil(
								Number(ddiRangesPaginationResponse?.TotalRecordsFound) /
									pageSize
							)
							totalRecords = Number(
								ddiRangesPaginationResponse?.TotalRecordsFound
							)
						}
					}
				}
			})
			.catch(() => {
				showErrorToast('Unable to get Number Ranges')
			})

		tnRangeDisplayList.current = _ddiRangesDisplayList
		setDDIRangesMaxPageNo(maxPageNo)
		setDDIRangesTotalRecords(totalRecords)
		setFilterLoading(false)
	}

	const createFiltersRecord = (
		filtersArray: FilterComponent[],
		keyMappings: Record<string, string>
	): Record<string, string> => {
		return filtersArray.reduce((acc, filter) => {
			const returnKey = getEnumKeysAsArray(keyMappings, false, new Set())
			const findKey = returnKey.find((key) => keyMappings[key] === filter.Key)
			acc[findKey ?? ''] = filter.Value
			return acc
		}, {} as Record<string, string>)
	}

	const handleFilterCall = async (
		tnFilters: FilterComponent[],
		sortBy?: FilterComponent
	) => {
		setFilterLoading(true)
		//set the filters
		setTNFilters(tnFilters)

		//based on which tab they're on, call a different function
		const keyMappings =
			tabValue === 0 ? TNFilterKeyMappings : TNRangesFilterKeyMappings
		const builtFilters = createFiltersRecord(tnFilters, keyMappings)

		if (builtFilters) {
			//set and make call
			setFilterQuery(builtFilters)

			var applyFilters: FilterRequest = {
				pageNo: 1,
				pageSize: pageSize,
				filters: builtFilters,
			}

			if (sortBy?.Key !== undefined) {
				const _sortBy = getEnumKeyByValue(keyMappings, sortBy.Key)
				applyFilters = {
					...applyFilters,
					sortBy: `${_sortBy}`,
					sortAscending: sortBy.Value === 'Yes',
				}
				setSortByColumn({ Key: `${sortBy.Key}`, Value: sortBy.Value })
			} else {
				setSortByColumn(undefined)
			}

			//make api call to apply filter
			if (tabValue === 0) {
				await getDDIData(applyFilters)
			} else {
				await getDDIRangeData(applyFilters)
			}
		}

		setFilterLoading(false)
		handleCloseFilter()
	}

	// Handle open filter menu
	const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
		setFilterAnchorEl(event.currentTarget)
	}

	// Handle close filter menu
	const handleCloseFilter = () => {
		setFilterAnchorEl(null)
	}

	// Handle page navigation
	const handlePageNavigation = async (navType: string) => {
		setFilterLoading(true)
		// Set nav type loading
		setNavLoadingType(navType)

		// Page number
		var navPageNumber = currentPageNo
		var filtersToUse = tnFilterQuery

		// Check what was clicked
		switch (navType) {
			// Prev
			case 'prev':
				navPageNumber--
				break
			// Next
			case 'next':
				navPageNumber++
				break
			// Skip to first page1
			case 'first-page':
				navPageNumber = 1
				break
			// Skip to last page
			case 'last-page':
				if (tabValue === 0) {
					navPageNumber = maxPageNo
				} else {
					navPageNumber = ddiRangesMaxPageNo
				}
				break
			// Refresh
			case 'refresh':
				// Return to page 1 and clear filters
				navPageNumber = 1
				filtersToUse = {}
				setFilterQuery(filtersToUse)
				setTNFilters([])
				setSortByColumn(undefined)
				break
		}

		if (navType === 'refresh') {
			if (tabValue === 0) {
				//make ddi call
				await getDDIData({
					pageNo: navPageNumber,
					pageSize: pageSize,
					filters: filtersToUse,
				})
			} else {
				//make ddi range call
				await getDDIRangeData({
					pageNo: navPageNumber,
					pageSize: pageSize,
					filters: filtersToUse,
				})

				//refresh DDI values here as well since addresses may be updated
				await getDDIData({
					pageNo: navPageNumber,
					pageSize: pageSize,
					filters: filtersToUse,
				})
			}
		} else {
			// Make call
			if (tabValue === 0) {
				await getDDIData({
					pageNo: navPageNumber,
					pageSize: pageSize,
					filters: filtersToUse,
					sortBy: sortByColumn?.Key ?? undefined,
					sortAscending:
						sortByColumn?.Key !== undefined
							? sortByColumn?.Value === 'Yes'
							: undefined,
				})
			} else {
				await getDDIRangeData({
					pageNo: navPageNumber,
					pageSize: pageSize,
					filters: filtersToUse,
					sortBy: sortByColumn?.Key ?? undefined,
					sortAscending:
						sortByColumn?.Key !== undefined
							? sortByColumn?.Value === 'Yes'
							: undefined,
				})
			}
		}
		// End loading
		setNavLoadingType('')
		setFilterLoading(false)
	}

	return getFirstTNCallMade ? (
		<>
			<Box className='tn-management-details-tabs'>
				<Tabs value={tabValue} onChange={handleTabChange}>
					<Tab label='Numbers' />
					<Tab label='Number Ranges' />
				</Tabs>

				{/* Filter toolbar */}
				<Box className='filter-toolbar'>
					{/* Filter button */}
					<Box>
						{/* Button */}
						<Button
							startIcon={
								<Badge
									badgeContent={
										sortByColumn !== undefined
											? tnFilters.length + 1
											: tnFilters.length
									}
									color='primary'>
									<FilterListOutlinedIcon />
								</Badge>
							}
							variant='outlined'
							aria-describedby={filterID}
							onClick={handleOpenFilter}>
							Filter
						</Button>
						{/* Filter popover */}
						<Popover
							id={filterID}
							open={openFilter}
							anchorEl={filterAnchorEl}
							onClose={handleCloseFilter}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}>
							<FiltersComponent
								applyFiltersFor={currentTab}
								filtersApplied={tnFilters}
								sortFilterApplied={sortByColumn}
								handleFilterCall={handleFilterCall}
							/>
						</Popover>
					</Box>

					{/* Refresh */}
					<Box>
						<LoadingButton
							loading={navLoadingType.length > 0}
							startIcon={<RefreshOutlinedIcon />}
							loadingPosition='start'
							variant='outlined'
							onClick={() => handlePageNavigation('refresh')}>
							Refresh
						</LoadingButton>
					</Box>
				</Box>

				{tabValue === 0 ? (
					<TabPanel index={0} value={tabValue}>
						<TNTable
							tnDisplay={tnTableList.current}
							totalRecords={totalRecords}
							maxPageNo={maxPageNo}
							filterLoading={filterLoading}
							handlePageNavigation={handlePageNavigation}
							addressMapList={addressMapList}
							currentPageNo={currentPageNo}
						/>
					</TabPanel>
				) : tabValue === 1 ? (
					<TabPanel index={1} value={tabValue}>
						<TNRangeDisplay
							tnRangeDisplay={tnRangeDisplayList.current}
							filterLoading={filterLoading}
							ddiRangesMaxPageNo={ddiRangesMaxPageNo}
							ddiRangesTotalRecords={ddiRangesTotalRecords}
							handlePageNavigation={handlePageNavigation}
							currentPageNo={currentPageNo}
							addressMapList={addressMapList}
						/>
					</TabPanel>
				) : null}
			</Box>
		</>
	) : (
		<Preloader />
	)
}

export default TNDisplay
