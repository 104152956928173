import './Dashboard.scss'
import { Roles } from '../../../../utils/enums/enums'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { PartnerDashboard } from './PartnerDashboard/PartnerDashboard'
import { CustomerDashboard } from './CustomerDashboard/CustomerDashboard'

export const Dashboard = () => {
	// Global variables
	const roleID = useSelector(
		(state: RootState) => state.RootReducer.roleIDReducer.value
	)

	return (
		<>
			{roleID === Roles.PartnerAdmin && <PartnerDashboard />}
			{roleID === Roles.CustomerAdmin && <CustomerDashboard />}
		</>
	)
}
