import { useEffect, useState } from 'react'
import UseCrud from '../../utils/customHooks/APICalls/UseCrud'
import { ReturnTypes } from '../../utils/enums/enums'
import { DataResponse } from '../../utils/interfaces/APIModels'
import { CustomerComplianceAcceptance } from '../../utils/interfaces/DBModels'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import LoadingBox from '../../components/shared/displays/LoadingBox/LoadingBox'
import {
	Box,
	List,
	ListItem,
	ListItemText,
	Typography,
	useTheme,
} from '@mui/material'
import './Compliance.scss'
import { StyledLoadingButton } from '../../styles/styledComponents/inputs/StyledLoadingButton'

function Compliance() {
	// Global variables
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	// Hooks
	const { fetchData, modifyData } = UseCrud()

	// Theme
	const theme = useTheme()

	const [customerCompliance, setCustomerCompliance] = useState(
		{} as CustomerComplianceAcceptance
	)
	const [hasAcceptedCompliance, setHasAcceptedCompliance] = useState(false)

	const [isComplianceRetrieved, setIsComplianceRetrieved] = useState(false)
	const [apiCallsDone, setAPICallsDone] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		// Make call if not already made
		if (!isComplianceRetrieved) {
			getCustomerCompliance()
			setIsComplianceRetrieved(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isComplianceRetrieved, apiCallsDone])

	const getCustomerCompliance = async () => {
		// Make call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'Compliance.tsx: getCustomerCompliance()',
			QueryURL: `GetV2?Params=CustomerComplianceAcceptance.First(CustomerComplianceAcceptance.CustomerID = '${loggedInUser.customerID}')`,
			ErrorMessage: 'An error occurred when getting compliance information',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var customerComplianceResponse = dataResponse.Obj
				.CustomerComplianceAcceptance as CustomerComplianceAcceptance

			if (customerComplianceResponse) {
				setCustomerCompliance(customerComplianceResponse)
				setHasAcceptedCompliance(
					Number(customerComplianceResponse.HasCustomerAcceptedTerms) === 1
				)
			}
		}

		setAPICallsDone(true)
	}

	const handleAcceptCompliance = async () => {
		setIsLoading(true)
		if (loggedInUser.customerID) {
			var complianceObj: CustomerComplianceAcceptance = {}
			var requestUrl = ''

			if (
				customerCompliance &&
				customerCompliance.CustomerComplianceAcceptanceID
			) {
				requestUrl = `UpdateV2?Params=CustomerComplianceAcceptance`
				complianceObj = {
					...customerCompliance,
					HasCustomerAcceptedTerms: true,
				}
			} else {
				requestUrl = `AddV2?Params=CustomerComplianceAcceptance`
				complianceObj = {
					CustomerID: loggedInUser.customerID,
					HasCustomerAcceptedTerms: true,
				}
			}

			var requestObj = {
				CustomerComplianceAcceptance: complianceObj,
			}

			var isSuccessfullyAccepted = await modifyData({
				FileAndFunctionName: 'Compliance.tsx: handleAcceptCompliance()',
				QueryURL: requestUrl,
				ErrorMessage: `An error occurred when accepting compliance for customer ${loggedInUser.customerID}`,
				QueryObj: requestObj,
				LogErrorToDB: true,
				UserName: loggedInUser.email,
				SuccessMessage: `Compliance accepted successfully`,
				ShowErrorMessage: true,
				ShowSuccessMessage: true,
			})

			if (isSuccessfullyAccepted) {
				setHasAcceptedCompliance(true)
			}
		}
		setIsLoading(false)
	}

	return apiCallsDone ? (
		<>
			<Box className='accept-compliance-container'>
				<Box className='accept-compliance-content'>
					<Box
						className='accept-compliance-header'
						borderBottom={`1px solid ${theme.palette.primary.main}`}>
						<Typography component={'h2'}>Customer Compliance</Typography>
					</Box>

					<Box className='accept-compliance-text'>
						<Typography component={'h2'}>
							Customer Notice regarding Interconnected VoIP Services and 911
						</Typography>

						<Typography component={'p'}>
							PLEASE READ THIS NOTICE CAREFULLY. AS A USER OF SIPCOM'S SESSION
							INITIATION PROTOCOL (“SIP”) BASED VOICE OVER INTERNET PROTOCOL
							(“VOIP”) SERVICES (“INTERCONNECTED VOIP SERVICES”), YOU AGREE THAT
							YOU HAVE READ AND UNDERSTOOD THE LIMITATIONS ASSOCIATED WITH THE
							911 EMERGENCY SERVICES AVAILABLE THROUGH THE SIPCOM INTERCONNECTED
							VOIP SERVICES. IF YOU DO NOT AGREE, YOU ARE NOT AUTHORIZED TO USE
							ANY SIPCOM INTERCONNECTED VOIP SERVICES.
						</Typography>

						<Typography component={'h3'}>VoIP and 911 Services</Typography>
						<Typography component={'p'}>
							Portable interconnected Voice over Internet Protocol (VoIP)
							services can be used from virtually any internet connection
							anywhere, which raises challenges for the emergency services
							community in determining the location from which a 911 call has
							originated.
						</Typography>
						<Typography component={'p'}>You should be aware that:</Typography>
						<List sx={{ listStyle: 'circle', pl: 2 }}>
							<ListItem sx={{ display: 'list-item' }}>
								<ListItemText
									primary='
								VoIP 911 calls may not connect to the 911 call center serving
								the Users current location or may improperly ring to the
								administrative line of the 911 call center, which may not be
								staffed afterhours or by trained 911 operators.'
								/>
							</ListItem>

							<ListItem sx={{ display: 'list-item' }}>
								<ListItemText
									primary="
								VoIP 911 calls may correctly connect to the 911 call center but
								not automatically transmit the caller's phone number and/or
								location information."
								/>
							</ListItem>

							<ListItem sx={{ display: 'list-item' }}>
								<ListItemText
									primary='
								VoIP customers need to provide location or other information to
								their VoIP providers and update this information each time they
								change locations for their VoIP 911 service to function
								properly.'
								/>
							</ListItem>

							<ListItem sx={{ display: 'list-item' }}>
								<ListItemText
									primary='
								VoIP service may not work during a power outage or when the
								internet connection fails or becomes overloaded.'
								/>
							</ListItem>
						</List>

						<Typography component={'h3'}>
							Tips for subscribers to Sipcom's fully interconnected VoIP service
						</Typography>
						<Typography component={'p'}>
							As a subscriber to Sipcom's Interconnected VoIP Services, you
							should:
						</Typography>
						<List sx={{ listStyle: 'circle', pl: 2 }}>
							<ListItem sx={{ display: 'list-item' }}>
								<ListItemText
									primary="
								Provide, for each User, accurate physical address to us to
								ensure that emergency services can quickly be dispatched to the
								User's location."
								/>
							</ListItem>
							<ListItem sx={{ display: 'list-item' }}>
								<ListItemText
									primary='
								Follow the procedures set out on the portal for updating each
								User address, and promptly update address information in the
								event of a change.'
								/>
							</ListItem>
							<ListItem sx={{ display: 'list-item' }}>
								<ListItemText
									primary='
								Have a clear understanding of any limitations of the 911
								service.'
								/>
							</ListItem>
							<ListItem sx={{ display: 'list-item' }}>
								<ListItemText
									primary='
								Inform other users and visitors about your VoIP service and its
								911 limitations, if any.'
								/>
							</ListItem>
							<ListItem sx={{ display: 'list-item' }}>
								<ListItemText
									primary="
								If the User's power is out or the internet connection is down,
								be aware that the VoIP service may not work. Consider installing
								a backup power supply, maintaining a traditional phone line, or
								having a mobile phone as a backup."
								/>
							</ListItem>
							<ListItem sx={{ display: 'list-item' }}>
								<ListItemText
									primary='
								911 call centers currently lack the technical capability to
								receive photos and video. In addition, the ability to send text
								messages to 911 is only available in certain areas.'
								/>
							</ListItem>
						</List>

						<Typography component={'h3'}>
							Accurate and complete User emergency location information
						</Typography>
						<Typography component={'p'}>
							You acknowledge and agree that you are responsible for ensuring
							that:
						</Typography>
						{/* ordered list here */}
						<List sx={{ listStyle: 'decimal', pl: 2 }}>
							<ListItem sx={{ display: 'list-item' }}>
								<ListItemText
									primary="
								Your Users are regularly informed of any terms specifying how
								Sipcom's Interconnected VoIP Services should be used."
								/>
							</ListItem>
							<ListItem sx={{ display: 'list-item' }}>
								<ListItemText
									primary="
								The User information remains accurate and complete throughout
								the contract term that the Sipcom's Services are provided."
								/>
							</ListItem>
							<ListItem sx={{ display: 'list-item' }}>
								<ListItemText
									primary="
								Each Users accurate physical address details are provided to
								Sipcom in a timely manner. The User's accurate physical address
								means, that for each VoIP number, the accurate location
								information for the assigned User, which must include sufficient
								details to enable the emergency responders to geographically
								locate the User, including but not limited to street address,
								floor level, suite, apartment or similar."
								/>
							</ListItem>
						</List>

						<Typography component={'p'}>
							As you are subscribing to a Sipcom Interconnected VoIP Service,
							you must validate your phone number(s) held by Sipcom and ensure
							that each User's physical address details are accurate and in
							accordance with this Notice.
						</Typography>

						<Typography component={'h3'}>
							Emergency Service Number Limitations
						</Typography>
						<Typography component={'p'}>
							You expressly acknowledge and agree that Sipcom shall in no
							circumstance be liable for the accuracy of the information you
							provide and in no case will Sipcom, nor any third parties involved
							in the routing, handling, delivery or answering of or in
							responding to emergency calls, be held liable for any damages,
							losses, costs and/or expenses arising of the acts, omissions or
							failures of a third party to route, handle, deliver or answer an
							emergency call, or for any damage, loss, expense and/or cost
							arising of your failure to fulfil its obligations.
						</Typography>
						<Typography component={'p'}>
							SIPCOM UNDERSTANDS THAT YOU HAVE READ AND UNDERSTAND THE
							LIMITATIONS ASSOCIATED WITH THE 911 EMERGENCY SERVICES AVAILABLE
							THROUGH THE SIPCOM INTERCONNECTED VOIP SERVICES.
						</Typography>
						<Typography component={'p'}>
							Any obligations that may be imposed by federal and state law on
							operators of private branch exchange or multiline telephone
							systems are obligations imposed on you, the Customer, and not on
							Sipcom.
						</Typography>
						<Typography component={'p'}>
							If you have any questions about your Sipcom interconnected VoIP
							service, or if you require clarification on any of the contents of
							this Notice, then please contact{' '}
							<a href='mailto:support@sipcom.com'>support@sipcom.com</a> for
							further information and we will respond as soon as possible.
						</Typography>
					</Box>

					{/* Add button here based only if customer haven't accepted compliance */}
					<Box className='accept-compliance-button'>
						{hasAcceptedCompliance ? (
							<Typography component={'p'}>
								You have accepted the compliance
							</Typography>
						) : (
							<Typography component={'p'}>
								Please accept the compliance by clicking the button below
							</Typography>
						)}
						<StyledLoadingButton
							className='form-button top-margin'
							loading={isLoading}
							disabled={hasAcceptedCompliance}
							variant='outlined'
							onClick={handleAcceptCompliance}>
							Accept Compliance
						</StyledLoadingButton>
					</Box>
				</Box>
			</Box>
		</>
	) : (
		// Show loading
		<LoadingBox
			title='Getting Compliance Status'
			description='Please wait while we retrieve your compliance status'
		/>
	)
}

export default Compliance
