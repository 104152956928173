import { TUMBuildStatuses } from '../../../../../utils/enums/enums'
import Hourglass from '../../../../shared/displays/Preloader/Hourglass/Hourglass'
import { Box, Divider, Typography, useTheme } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import LockPersonIcon from '@mui/icons-material/LockPerson'
import TUMLoginBox from './TUMLoginBox/TUMLoginBox'
import './TUMLoading.scss'
import { TUMLoadingRowDisplay } from '../../../../../utils/interfaces/ComponentModels'
import TUMLoadingRow from './TUMLoadingRow/TUMLoadingRow'

const TUMLoading = ({
	generalStatus,
	loginStatus,
	loggedInAccount,
	tumLoadingRows,
	handleLogin,
}: {
	generalStatus: number
	loginStatus: number
	loggedInAccount: string
	tumLoadingRows: TUMLoadingRowDisplay[]
	handleLogin: any
}) => {
	// Theme
	const theme = useTheme()

	return (
		<Box className='tum-container'>
			<Box className='tum-loading-container'>
				{/* Section 1: Login */}
				<Box className='tum-login-content'>
					{/* Icon / Loading: Enum Flag (TUMBuildStatuses) */}
					{/* Icon Options: 1. Lock (If user is not logged in) | 2. Green Tick (If all checks are completed) | 3. Red cross (If anything fails) */}
					<Box className='icon'>
						{generalStatus === TUMBuildStatuses.Loading ? (
							<Hourglass width='2em' height='4em' />
						) : (
							<>
								{generalStatus === TUMBuildStatuses.Success ? (
									<CheckCircleIcon sx={{ color: theme.palette.success.main }} />
								) : generalStatus === TUMBuildStatuses.Failure ? (
									<ErrorIcon sx={{ color: theme.palette.error.main }} />
								) : (
									<LockPersonIcon sx={{ color: theme.palette.primary.main }} />
								)}
							</>
						)}
					</Box>
					<Box className='desc'>
						<Typography>
							Please choose to login with a valid account containing one of
							these roles:
							<br />
							<strong>Global Administrator, Teams Administrator</strong> or{' '}
							<strong>Teams Communications Administrator</strong>
							<br /> Upon logging in, the procedure will initiate to ensure the
							correct rights are in place. Upon confirmation, it will proceed to
							retrieve and construct this page.
						</Typography>
					</Box>
					{/* Login Button / Account: If logged in, show account */}
					<TUMLoginBox
						loggedInAccount={loggedInAccount}
						status={loginStatus}
						handleLogin={handleLogin}
					/>
				</Box>
				{/* Section 2: Loading rows */}
				<Box className='tum-loading-content'>
					{/* General: All loading rows contain header, description and icon */}
					{/* Description: Change depending on action - 1. Not started (Awaiting) | 2. Loading | 3. Completed step response */}
					{/* Divider */}
					<Divider />
					{/* Rows */}
					{tumLoadingRows.map((item, index) => {
						return (
							<>
								<TUMLoadingRow
									key={index}
									heading={item.Heading}
									description={item.Description}
									status={item.Status}
								/>
								<Divider />
							</>
						)
					})}
				</Box>
			</Box>
		</Box>
	)
}

export default TUMLoading
