import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'

// The purpose of this hook is to check for a permission and return true if found
export function PermissionChecker() {
	// Global variables
	const loggedInRoleAndPermissions = useSelector(
		(state: RootState) =>
			state.RootReducer.loggedInUserRolesPermissionsReducer.value
	)

	// POST Call - Log error to DB
	function checkForPermission(permissionID: number) {
		// Return boolean
		var permissionFound = false

		// Check if user has relevant permission
		if (
			loggedInRoleAndPermissions.find(
				(perm) => Number(perm.PermissionID) === permissionID
			)
		) {
			permissionFound = true
		}

		return permissionFound
	}

	return checkForPermission
}
