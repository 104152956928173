import { useEffect, useState } from 'react'
import './FXRatesOverview.scss'
import { Box } from '@mui/material'
import { FXConversionRateDisplay } from '../../../../utils/interfaces/ComponentModels'
import { GenericTable } from '../../displays/GenericTable/GenericTable'
import { GenericColumn } from '../../../../utils/interfaces/ComponentModels'

// Column Definition: FX Rates Table
const columns: GenericColumn[] = [
	{
		ID: 'FXRateID',
		Label: 'FX Rates ID',
		IsEditable: false,
	},
	{
		ID: 'FromCurrencyName',
		Label: 'From',
		IsEditable: false,
	},
	{
		ID: 'ToCurrencyName',
		Label: 'To',
		IsEditable: false,
	},
	{
		ID: 'FXRate',
		Label: 'Rate',
		IsEditable: false,
	},
	{
		ID: 'FXConversion',
		Label: 'Conversion',
		IsEditable: false,
	},
	{
		ID: 'DateFXSet',
		Label: 'Date set',
		IsEditable: false,
	},
]

const FXRatesOverview = ({
	fxRateConversionList,
}: {
	fxRateConversionList: FXConversionRateDisplay[]
}) => {
	// Search

	// This array manages the change in search, edit and more
	const [filteredData, setFilteredData] = useState<FXConversionRateDisplay[]>(
		[]
	)

	useEffect(() => {
		// Set the rows
		setFilteredData(fxRateConversionList)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredData])

	// Choose which columns you wish to hide
	const showColumn = (columnName: string) => {
		// FX Rate ID to be hidden
		if (columnName === columns[0].ID) {
			return false
		}
		return true // Logic for showing rest of the columns
	}

	return (
		<Box className='fxrate-overview-container'>
			{/* Generic Table */}
			<Box id='generictable'>
				<GenericTable
					data={filteredData}
					columns={columns}
					showColumn={showColumn}
					rowIdKey={columns[0].ID}
				/>
			</Box>
		</Box>
	)
}

export default FXRatesOverview
