import { Box, styled } from '@mui/material'

const AnimatedBox = ({
	success,
	children,
	styledClasses,
}: {
	success: boolean
	children: any
	styledClasses?: string
}) => {
	// Styling
	const StyledAnimatedBox = styled(Box)(({ theme }) => ({
		padding: '20px',
		backgroundColor: `${theme.palette.secondary.main}`,
		backgroundSize: '0% 100%',
		backgroundRepeat: 'no-repeat',
		borderRadius: '15px',
		':hover': {
			boxShadow: '0 0 2px rgba(33,33,33,.2)',
		},
		// Box Content - Icon and Text
		'& .box-content': {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			// Specific styling if number replaces the icon
			'& .box-number': {
				textAlign: 'center',
				padding: '15px',
				'& h2': {
					fontSize: '50px',
					lineHeight: 1,
					color: theme.palette.secondary.contrastText,
				},
			},
			'& h4': {
				fontSize: '18px',
				color: theme.palette.secondary.contrastText,
			},
			'& .box-icon-button': {
				display: 'flex',
				justifyContent: 'space-between',
				'& .box-icon': {
					width: '35px',
					height: '35px',
					borderRadius: '50%',
					background: theme.palette.background.default,
					padding: '5px',
					// Icon
					'& svg': {
						fontSize: '35px',
						color: success
							? theme.palette.success.main
							: theme.palette.error.main,
					},
				},
				'& .box-button .MuiButtonBase-root': {
					backgroundColor: theme.palette.primary.main,
					// Icon
					'& svg': {
						fontSize: '30px',
						color: theme.palette.primary.contrastText,
					},
				},
			},
			'& .box-text': {
				display: 'flex',
				flexDirection: 'column',
				rowGap: '5px',
			},
		},
	}))

	return (
		<>
			<StyledAnimatedBox className={styledClasses}>
				{children}
			</StyledAnimatedBox>
		</>
	)
}

export default AnimatedBox
