import { useMsal } from '@azure/msal-react'
import { Box, Button, Typography } from '@mui/material'
import './LoginCard.scss'
import { useEffect, useState } from 'react'
import { GraphScopes } from '../../../utils/constants/constants'
import CustomCard from '../displays/CustomCard/CustomCard'
import { showErrorToast } from '../../../utils/helperFunctions/helperFunctions'

const LoginCard = ({
	roleName,
	displayWithoutCard,
}: {
	roleName?: string
	displayWithoutCard?: boolean
}) => {
	// MSAL
	const { instance } = useMsal()
	// Constant
	const [cardDescription, setCardDescription] = useState('')

	useEffect(() => {
		if (roleName) {
			setCardDescription(
				`You need to login with Microsoft as a ${roleName} to view these actions.`
			)
		} else {
			setCardDescription(
				`You need to login with Microsoft to view these actions.`
			)
		}
	}, [roleName])

	const handleLogin = async () => {
		const logInRequest = {
			scopes: GraphScopes,
			prompt: 'select_account',
		}
		await instance.loginPopup(logInRequest).catch(() => {
			showErrorToast('Login cancelled')
		})
	}

	return (
		<>
			{displayWithoutCard ? (
				<>
					<Box className='login-card-header'>
						<Box className='centerBox'>
							<img
								src={require(`../../../assets/images/distinct/loginCard/card-lock.png`)}
								alt='Login Required'
							/>
						</Box>

						<Box className='centerBox'>
							<Typography
								className={'card-text'}
								fontSize={17}
								sx={{ marginTop: '15px' }}>
								{cardDescription}
							</Typography>
						</Box>
					</Box>

					<Box className='teamsDRloginButton'>
						<Button
							variant='contained'
							onClick={handleLogin}
							sx={{ marginBottom: '10px', width: '250px' }}>
							Login with Microsoft
						</Button>
					</Box>
				</>
			) : (
				<Box className='login-box'>
					<CustomCard
						cardTitle='Login Required'
						cardDescription={cardDescription}
						showButton={true}
						isActive={true}
						cardIconSrc='card-lock.png'
						cardButtonTitle='Login with Microsoft'
						cardButtonAction={handleLogin}
					/>
				</Box>
			)}
		</>
	)
}

export default LoginCard
