import { styled, Select } from '@mui/material'
import { hexToRgba } from '../../../utils/helperFunctions/helperFunctions'

export const StyledSelect = styled(Select)(({ theme }) => ({
	borderRadius: 10,
	color: theme.palette.mode === 'light' ? '#000' : '#fff',
	'& .MuiOutlinedInput-root': {
		borderRadius: 0,
		color: theme.palette.primary.main,
	},
	'& .MuiSelect-select': {
		padding: '10px 24px 10px 10px',
	},
	'& input.MuiOutlinedInput-input': {
		color: theme.palette.primary.main,
		backgroundColor: '#fff',
	},
	'& fieldset.MuiOutlinedInput-notchedOutline': {
		borderColor: hexToRgba(theme.palette.primary.main, 0.2),
	},
	'&:hover .MuiOutlinedInput-notchedOutline': {
		borderColor: `${theme.palette.primary.main}!important`,
	},
	'& .rmv fieldset.MuiOutlinedInput-notchedOutline': {
		borderColor: '#d32f2f',
	},
}))
