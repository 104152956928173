import AddressOverview from '../../../components/shared/pageShared/AddressOverview/AddressOverview'

function AddressManagement() {
	return (
		<>
			<AddressOverview />
		</>
	)
}

export default AddressManagement
