import React, { useState } from 'react'
import { TextField } from '@mui/material'
import { validateDecimalNumber } from '../../../../../../../../../utils/helperFunctions/helperFunctions'

interface EditableTableCellProps {
	isInEditMode: boolean
	isColumnEditable?: boolean
	value: any
	onChange: (newValue: any) => void
	setHasValidationError: React.Dispatch<React.SetStateAction<boolean>>
}

// Editable table cell to allow cell to be changed to a text field on edit mode
export function EditableTableCell({
	isInEditMode,
	isColumnEditable,
	value,
	onChange,
	setHasValidationError,
}: EditableTableCellProps) {
	//addOrderSummary input validation
	const [mrcNrcError, setMrcNrcError] = useState(false)

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value

		if (
			!validateDecimalNumber(newValue) ||
			isNaN(Number(newValue)) ||
			newValue === '' ||
			Number(newValue) < 0
		) {
			setMrcNrcError(true)
			setHasValidationError(true)
		} else {
			setMrcNrcError(false)
			setHasValidationError(false)
		}

		onChange(newValue)
	}

	return isInEditMode && isColumnEditable ? (
		<TextField
			value={value}
			error={mrcNrcError}
			helperText={
				mrcNrcError
					? 'Number is invalid or contains non numeric characters'
					: ''
			}
			onChange={handleInputChange}
		/>
	) : (
		value
	)
}
