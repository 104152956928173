import { BetaObject, CustomRequestAPIHandler } from '../../interfaces/APIModels'
import { usePostBetaObjectWithoutRefetchMutation } from '../../../services/proxyAPIData'
import {
	toAlphaString,
	toBetaString,
	showSuccessToast,
	showErrorToast,
} from '../../helperFunctions/helperFunctions'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { ErrorHandling } from '../ErrorHandling/ErrorHandling'

export default function UseAPIHandler() {
	// Global variables
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	// Error logging
	const addErrorLog = ErrorHandling()

	// API Calls
	const [postObjectAndReturnData] = usePostBetaObjectWithoutRefetchMutation()

	// GET Call - Function to get data using an ecrypted object
	async function handleAPICall<T>(
		customRequest: CustomRequestAPIHandler
	): Promise<void | Awaited<T> | null> {
		// Error handling
		var isError = false

		// Build request
		var requestBody = {}
		if (customRequest.IsMutations) {
			requestBody = {
				Obj: customRequest.RequestObj,
				UserID: loggedInUser.userID + '',
				UserName: loggedInUser.email + '',
			}
		} else {
			requestBody = customRequest.RequestObj
		}

		// Return value
		var returnValue: T | null = null

		// Encrypt the object
		var betaObject: BetaObject = {
			Content: await toBetaString(JSON.stringify(requestBody)),
		}

		return await postObjectAndReturnData({
			QueryParam: customRequest.RequestURL,
			BetaObject: betaObject,
		})
			.unwrap()
			.then(async (response) => {
				if (response) {
					//Show success toast if required
					if (customRequest.ShowSuccessMessage) {
						showSuccessToast(customRequest.SuccessMessage + '')
					}
					// Check content and decrypt
					if (response.Content) {
						returnValue = JSON.parse(await toAlphaString(response.Content)) as T

						// Return
						return returnValue
					} else {
						// General response
						returnValue = true as T
						return returnValue
					}
				}
			})
			.catch(async (error) => {
				if (error) {
					isError = true
				}
			})
			.finally(() => {
				if (
					isError &&
					customRequest.ErrorMessage &&
					customRequest.ErrorMessage.length > 0
				) {
					// Error handling
					if (customRequest.ShowErrorMessage) {
						showErrorToast(customRequest.ErrorMessage)
					}
					if (customRequest.LogErrorToDB && customRequest.Action) {
						// Log to DB
						addErrorLog({
							ActionPerformed: customRequest.Action,
							ErrorInfo: customRequest.ErrorMessage,
						})
					}
					// Return an empty object of type T
					returnValue = {} as T
				}
				// Return the final value, whether an empty object or valid data
				return returnValue
			})
	}

	return { handleAPICall }
}
