import { Box, Button, MenuItem, Select, Typography } from '@mui/material'
import { useState } from 'react'
import { MSTeamsUser, Service } from '../../../../../utils/interfaces/DBModels'
import './TeamsServiceSelection.css'
import { TeamsDRServiceValueDisplayOptions } from '../../../../../utils/enums/enums'

const TeamsServiceSelection = ({
	confirmSelection,
	teamsServiceList,
	teamsDRServiceValueDisplayOption,
	serviceList,
}: {
	confirmSelection: any
	teamsServiceList: MSTeamsUser[]
	teamsDRServiceValueDisplayOption: TeamsDRServiceValueDisplayOptions
	serviceList: Service[]
}) => {
	const [serviceChoice, setServiceChoice] = useState(
		teamsServiceList[0].ServiceID
	)

	const handleLicenseChoiceChange = (e: { target: any }) => {
		setServiceChoice(e.target.value)
	}

	function getServiceDisplayName(msTeamsService: MSTeamsUser) {
		var serviceDisplayName = ''

		switch (teamsDRServiceValueDisplayOption) {
			case TeamsDRServiceValueDisplayOptions.PartnerServiceID:
				var matchingService = serviceList.find(
					(x) =>
						x.ServiceID?.toLowerCase().trim() ===
						msTeamsService.ServiceID?.toLowerCase().trim()
				)
				if (matchingService) {
					serviceDisplayName = matchingService.PartnerServiceReference + ''
				}
				break

			case TeamsDRServiceValueDisplayOptions.DomainName:
				serviceDisplayName = msTeamsService.DomainName + ''
				break

			default:
				serviceDisplayName = msTeamsService.ServiceID + ''
				break
		}

		return serviceDisplayName
	}

	return (
		<>
			<Box className='license-selection-container'>
				<Box className='license-selection-label'>
					<Typography component='h6'>Select a service:</Typography>
				</Box>

				<Select
					variant='outlined'
					className='license-selection-select'
					value={serviceChoice}
					onChange={handleLicenseChoiceChange}>
					{teamsServiceList.map((item, index) => {
						return (
							<MenuItem key={index} value={item.ServiceID}>
								{getServiceDisplayName(item)}
							</MenuItem>
						)
					})}
				</Select>
				<Box className='license-selection-button'>
					<Button
						variant='contained'
						onClick={() => {
							confirmSelection(serviceChoice)
						}}>
						Submit service choice
					</Button>
				</Box>
			</Box>
		</>
	)
}

export default TeamsServiceSelection
