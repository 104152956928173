import { Box } from '@mui/material'
import TNDisplay from '../../../components/shared/pageShared/TNManagement/TNDisplay'

const TNManagement = () => {
	return (
		// Display TN overview table
		<Box id='TNs'>
			<TNDisplay />
		</Box>
	)
}

export default TNManagement
