import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import UseCrud from '../../../utils/customHooks/APICalls/UseCrud'
import { useAuth0 } from '@auth0/auth0-react'
import { CustomerPartner } from '../../../utils/interfaces/DBModels'
import { DataResponse } from '../../../utils/interfaces/APIModels'
import { ReturnTypes } from '../../../utils/enums/enums'

function LoginRedirect() {
	// Constants
	const [searchParams] = useSearchParams()
	const invitationTicketID = searchParams.get('invitation')
	const organizationID = searchParams.get('organization')

	// Hooks
	const { fetchData } = UseCrud()

	// Auth0
	const { loginWithRedirect } = useAuth0()

	useEffect(() => {
		if (invitationTicketID && organizationID) {
			const loginAndRedirect = async () => {
				var dataResponse = (await fetchData({
					FileAndFunctionName: 'Login.tsx: redirectToOrg()',
					QueryURL: `GetV2?Params=CustomerPartner.first(CustomerPartner.Auth0OrganizationID = '${organizationID}'))`,
					ErrorMessage: 'An error occurred when getting the customer partner',
					ShowErrorToast: false,
					LogErrorToDB: true,
					ReturnType: ReturnTypes.ObjectOrList,
				})) as DataResponse
				if (
					dataResponse &&
					Number(dataResponse.Count) > 0 &&
					dataResponse.Obj
				) {
					var customerPartner = dataResponse.Obj
						.CustomerPartner as CustomerPartner
					var url = customerPartner.CustomerPartnerPortalURL + 'login'
					loginWithRedirect({
						authorizationParams: {
							organization: organizationID,
							invitation: invitationTicketID,
							redirect_uri: url,
						},
					})
				}
			}
			// if SBCaaS Org redirect to other site
			if (organizationID === process.env.REACT_APP_SBCAAS_ORG_ID + '') {
				loginWithRedirect({
					authorizationParams: {
						organization: organizationID,
						invitation: invitationTicketID,
						redirect_uri: process.env.REACT_APP_SBCAAS_REDIRECT_URL + '',
					},
				})
			} else {
				loginAndRedirect()
			}
		} else {
			loginWithRedirect()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invitationTicketID, organizationID])

	return <></>
}
export default LoginRedirect
