import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material'
import { TUMBuildStatuses } from '../../../../../../utils/enums/enums'
import MicrosoftIcon from '@mui/icons-material/Microsoft'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import ShieldIcon from '@mui/icons-material/Shield'
import GppBadIcon from '@mui/icons-material/GppBad'
import './TUMLoginBox.scss'

const TUMLoginBox = ({
	loggedInAccount,
	status,
	handleLogin,
}: {
	loggedInAccount: string
	status: number
	handleLogin: any
}) => {
	// Theme
	const theme = useTheme()

	return (
		<>
			<Box className='tum-login-box'>
				{/* Section 1: Account Details */}
				<Box
					className='acc-details'
					border='1px solid'
					borderColor={theme.palette.primary.main}>
					{/* Icons: Plain Shield (Primary colour - if not logged in), Green Verified User once admin check is passed, Red Bad Icon if not valid account */}
					{status === TUMBuildStatuses.Success ? (
						<Tooltip title='Login to verify account'>
							<VerifiedUserIcon sx={{ color: theme.palette.success.main }} />
						</Tooltip>
					) : status === TUMBuildStatuses.Failure ? (
						<Tooltip title='Login to verify account'>
							<GppBadIcon sx={{ color: theme.palette.error.main }} />
						</Tooltip>
					) : (
						<Tooltip title='Login to verify account'>
							<ShieldIcon sx={{ color: theme.palette.primary.main }} />
						</Tooltip>
					)}
					{/* Account Chosen */}
					<Box className='acc-text'>
						<Typography component='h4'>Account</Typography>
						{loggedInAccount && loggedInAccount.length > 0 ? (
							<Typography component='p'>{loggedInAccount}</Typography>
						) : (
							<Typography component='p'>{'<No account logged in>'}</Typography>
						)}
					</Box>
				</Box>
				{/* Login Options */}
				{!loggedInAccount && (
					<Box className='login'>
						<Typography component='p'>
							Please login to start the process <strong>below</strong> and view
							this page.
						</Typography>
						{/* Button - Handle Login */}
						<Button
							startIcon={
								<MicrosoftIcon
									sx={{
										color: theme.palette.primary.contrastText,
									}}
								/>
							}
							variant='contained'
							onClick={handleLogin}>
							Login with Microsoft
						</Button>
					</Box>
				)}
			</Box>
		</>
	)
}

export default TUMLoginBox
