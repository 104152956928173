import { useCallback, useEffect, useState } from 'react'
import {
	LeadListDisplay,
	UpdateLeadDisplay,
} from '../../../../utils/interfaces/ComponentModels'
import {
	DataGridProProps,
	GridColDef,
	GridColumnVisibilityModel,
	GridToolbar,
} from '@mui/x-data-grid-pro'
import {
	Box,
	Divider,
	IconButton,
	Modal,
	Tooltip,
	Typography,
} from '@mui/material'
import {
	CustomPagination,
	StyledDataGrid,
} from '../../../../styles/styledComponents/displays/StyledDataGrid'
import EditIcon from '@mui/icons-material/Edit'
import UpdateLead from './UpdateLead'
import { UpdateLeadRequest } from '../../../../utils/interfaces/APIModels'
import UseCustomBackendCall from '../../../../utils/customHooks/APICalls/UseCustomBackendCall'
import { StyledButton } from '../../../../styles/styledComponents/inputs/StyledButton'
import WarmLead from './WarmLeads'
import {
	LeadSize,
	LeadType,
	LeadWeight,
} from '../../../../utils/interfaces/DBModels'
import {
	LeadModals,
	LeadStatus,
	OCLeadStatus,
	Partners,
} from '../../../../utils/enums/enums'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import StyledModal from '../../../../styles/styledComponents/displays/StyledModal'
import { v4 as uuidv4 } from 'uuid'
import EmptyDisplay from '../../../shared/displays/EmptyDisplay/EmptyDisplay'

function LeadDisplay({
	getPartnerLeadsList,
	partnerLeadList,
	leadTypeList,
	leadSizeList,
	leadWeightList,
}: {
	getPartnerLeadsList: () => void
	partnerLeadList: LeadListDisplay[]
	leadTypeList: LeadType[]
	leadSizeList: LeadSize[]
	leadWeightList: LeadWeight[]
}) {
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 10,
		page: 0,
	})
	const { fetchCustomData } = UseCustomBackendCall()
	const [columnVisibilityModel, setColumnVisibilityModel] =
		useState<GridColumnVisibilityModel>({} as GridColumnVisibilityModel)
	const [openUpdateLeadModal, setOpenUpdateLeadModal] = useState(false)
	const [gettingLeads, setGettingLeads] = useState(false)
	const [modalOpened, setModalOpened] = useState(0)
	const [lead, setLead] = useState({} as UpdateLeadDisplay)

	var partnerID = useSelector(
		(state: RootState) => state.RootReducer.partnerIDReducer.value
	)

	const isLumen = Partners.Lumen === partnerID

	useEffect(() => {
		setColumnVisibilityModel({
			LeadStatus: !isLumen,
			LeadType: !isLumen,
			ExternalSalesAgent: !isLumen,
			OCConsentStatus: false,
		})
	}, [isLumen, partnerLeadList])

	const handleOpenUpdateLeadModal = () => {
		setOpenUpdateLeadModal(true)
	}

	const handleCloseUpdateLeadModal = () => {
		setOpenUpdateLeadModal(false)
	}

	const leadColumns: GridColDef[] = [
		{
			field: 'CompanyName',
			headerName: 'Customer',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'LeadSize',
			headerName: 'Size',
			width: 150,
			hideable: false,
			flex: 1,
		},
		{
			field: 'LeadStatus',
			headerName: 'Status',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'LeadType',
			headerName: 'Type',
			width: 150,
			hideable: false,
			flex: 1,
		},
		{
			field: 'Stage',
			headerName: 'Stage',
			width: 150,
			hideable: false,
			flex: 1,
		},
		{
			field: 'Locations',
			headerName: 'Locations',
			width: 150,
			hideable: false,
			flex: 1,
		},
		{
			field: 'ExternalSalesAgent',
			headerName: 'Sales Agent',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'TenantID',
			headerName: 'Tenant ID',
			width: 250,
			hideable: false,
			flex: 1,
			renderCell: (params) => (
				<div
					style={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}>
					{params.value}
				</div>
			),
		},
		{
			field: 'OCLeadStatus',
			headerName: 'Operator Connect Status',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'OCConsentStatus',
			headerName: 'Consent Status',
			width: 120,
			hideable: false,
			flex: 1,
		},
		{
			field: 'actions',
			type: 'actions',
			cellClassName: 'actions',
			headerName: 'Update',
			width: 100,
			editable: false,
			align: 'center',
			hideable: false,
			getActions: ({ id }) => {
				return [
					<>
						{!isLumen && isValidLeadToUpdate(Number(id)) && (
							<Box className='lead-management-option'>
								<Tooltip title='Update lead'>
									<IconButton
										onClick={(e: any) => {
											const lead = partnerLeadList.find(
												(x) => x.LeadID === Number(id)
											)
											if (lead) {
												var _leadToUpdate: UpdateLeadDisplay = {
													TenantID: lead.TenantID + '' ?? null,
													LeadID: Number(lead.LeadID),
													LeadType: lead.LeadType + '',
													LeadStatus: lead.LeadStatus + '',
													OCRelationshipStatusID: lead.OCLeadStatusID ?? 0,
													SalesAgentEmail: lead.ExternalSalesAgent + '' ?? '',
													LeadStage: lead.Stage + '',
												}
												setLead(_leadToUpdate)
												setModalOpened(0)
												handleOpenUpdateLeadModal()
											}
										}}>
										<EditIcon />
									</IconButton>
								</Tooltip>
							</Box>
						)}
					</>,
				]
			},
		},
	]

	// build table for contact details
	function ContactInfoContent({ row: rowProp }: { row: LeadListDisplay }) {
		return (
			<Box id='oc-contact-info'>
				<StyledDataGrid
					sx={{
						py: 5,
						height: 1,
						boxSizing: 'border-box',
						width: '90%',
						margin: '0px 50px',
					}}
					initialState={{
						columns: {
							columnVisibilityModel: {},
						},
					}}
					columns={[
						{
							field: 'ContactName',
							headerName: 'Contact Name',
							width: 100,
							hideable: false,
							flex: 1,
						},
						{
							field: 'Email',
							headerName: 'Email',
							width: 100,
							hideable: false,
							flex: 1,
						},
						{
							field: 'PhoneNumber',
							headerName: 'Phone Number',
							width: 100,
							hideable: false,
							flex: 1,
						},
					]}
					rows={rowProp.LeadContactInfoDisplay!}
					getRowId={() => uuidv4()}
					autoHeight={true}
					hideFooter
				/>
			</Box>
		)
	}
	// Detail panel of main datagrid
	const getDetailPanelContent = useCallback<
		NonNullable<DataGridProProps['getDetailPanelContent']>
		// eslint-disable-next-line react-hooks/exhaustive-deps
	>(({ row }) => <ContactInfoContent row={row} />, [])

	const getDetailPanelHeight = useCallback(() => 'auto', [])

	const updateLeadStatus = async (
		updateLeadRequestModel: UpdateLeadRequest
	) => {
		if (updateLeadRequestModel) {
			await fetchCustomData<string>({
				QueryURL: 'UpdateLeadStatus',
				QueryObj: updateLeadRequestModel,
				ErrorMessage: 'Failed to update lead status',
				ShowErrorToast: true,
				LogErrorToDB: true,
				SuccessMessage: 'Successfully updated lead status',
				ShowSuccessToast: true,
				FileAndFunctionName: 'LeadDisplay.tsx UpdateLeadStatus',
			})
			setGettingLeads(true)
			await getPartnerLeadsList()
			setGettingLeads(false)
		}
	}

	const refetchLeads = async () => {
		handleCloseUpdateLeadModal()
		setGettingLeads(true)
		await getPartnerLeadsList()
		setGettingLeads(false)
	}

	const isValidLeadToUpdate = (leadID: number) => {
		const lead = partnerLeadList.find((x) => x.LeadID === leadID)

		return (
			(lead?.OCConsentStatus?.toLowerCase() === 'active' &&
				lead?.OCLeadStatusID !== OCLeadStatus.ConsentNotSubmitted &&
				lead?.LeadStatus?.toLowerCase() ===
					LeadStatus[LeadStatus.Live].toLowerCase()) ||
			lead?.LeadStatus?.toLowerCase() ===
				LeadStatus[LeadStatus.Live].toLowerCase()
		)
	}

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
				}}>
				<Box className='description'>
					<Typography component='p'>Manage and add leads</Typography>
				</Box>
				{!isLumen && (
					<Box>
						<StyledButton
							startIcon={
								<img
									src={require('../../../../assets/images/generic/icons/plus-icon.png')}
									alt='plus icon'
									style={{ width: '10px', height: '10px' }}
								/>
							}
							onClick={() => {
								setModalOpened(1)
								setOpenUpdateLeadModal(true)
							}}>
							Add Lead
						</StyledButton>
					</Box>
				)}
			</Box>
			<Divider />
			{partnerLeadList.length > 0 ? (
				<StyledDataGrid
					rows={partnerLeadList}
					columns={leadColumns}
					editMode='row'
					loading={gettingLeads}
					checkboxSelection={false}
					pagination
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					pageSizeOptions={[5]}
					columnVisibilityModel={columnVisibilityModel}
					slots={{
						pagination: CustomPagination,
						toolbar: GridToolbar,
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
						},
					}}
					getRowId={(row) => row.LeadID}
					autoHeight
					rowSelection={false}
					getDetailPanelHeight={getDetailPanelHeight}
					getDetailPanelContent={getDetailPanelContent}
				/>
			) : partnerLeadList.length === 0 ? (
				<EmptyDisplay
					title='No leads found'
					description='There were no leads found'
				/>
			) : (
				<EmptyDisplay
					title='No leads found'
					description='There were no leads found'
				/>
			)}

			<Modal open={openUpdateLeadModal}>
				<StyledModal width={700}>
					{modalOpened === 0 && (
						<UpdateLead
							leadToUpdate={lead}
							onUpdate={updateLeadStatus}
							handleCloseUpdateLeadModal={handleCloseUpdateLeadModal}
						/>
					)}
					{modalOpened === LeadModals.WarmLead && (
						<WarmLead
							onAdd={refetchLeads}
							leadTypes={leadTypeList}
							leadSizes={leadSizeList}
							leadWeights={leadWeightList}
							handleCloseUpdateLeadModal={handleCloseUpdateLeadModal}
							leads={partnerLeadList}
						/>
					)}
				</StyledModal>
			</Modal>
		</>
	)
}

export default LeadDisplay
