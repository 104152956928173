import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material'

export const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
	borderRadius: 10,
	padding: '10px 30px',
	color: theme.palette.primary.contrastText,
	background: theme.palette.primary.main,
	transition: '0.7s',
	':hover': {
		background: theme.palette.primary.main,
		opacity: '0.7',
	},
	'&.Mui-disabled': {
		color: 'grey',
		background: 'rgba(0, 0, 0, 0.18)',
		opacity: '0.7',
		cursor: 'not-allowed',
	},
}))
