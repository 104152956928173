import { useEffect, useState } from 'react'
import './PortalUserManagement.scss'
import { Box, Divider, Modal, Tab, Typography } from '@mui/material'
import BtnIcon from '@mui/icons-material/Add'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import Members from './Members'
import UseCustomBackendCall from '../../../../utils/customHooks/APICalls/UseCustomBackendCall'
import {
	GetByIDRequestModel,
	Invitation,
	InviteUsersRequestModel,
	Member,
} from '../../../../utils/interfaces/APIModels'
import { RootState } from '../../../../store/store'
import { useSelector } from 'react-redux'
import { UserManagementType } from '../../../../utils/enums/enums'
import { StyledButton } from '../../../../styles/styledComponents/inputs/StyledButton'
import Invites from './Invites'
import {
	emailValidation,
	showErrorToast,
} from '../../../../utils/helperFunctions/helperFunctions'
import InviteMember from './Modals/InviteMember'
import StyledModal from '../../../../styles/styledComponents/displays/StyledModal'

function PortalUserManagement() {
	const [value, setValue] = useState('1')
	const accessToken = useSelector(
		(state: RootState) => state.RootReducer.accessTokenReducer.value
	)
	const partnerID = useSelector(
		(state: RootState) => state.RootReducer.partnerIDReducer.value
	)
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const organizationID = useSelector(
		(state: RootState) => state.RootReducer.organizationIDReducer.value
	)
	const [openModal, setOpenModal] = useState(false)
	const customerRoleID = process.env.REACT_APP_CUSTOMER_ADMIN_ROLE_ID
	const [gettingMembers, setGettingMembers] = useState(false)
	const [memberList, setMemberList] = useState([] as Member[])
	const [invitationList, setInvitationList] = useState([] as Invitation[])
	const [apiCallDone, setApiCallDone] = useState(false)
	const [gettingInvites, setGettinginvites] = useState(false)
	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue)
		if (newValue === '1') getMembers()
		if (newValue === '2' || newValue === '3') getInvites()
	}
	const { fetchCustomData } = UseCustomBackendCall()
	// Handle Function - Tab change

	const apiCall = async (type: Number, id: string): Promise<string> => {
		var getMembersByOrgIDRequestModel: GetByIDRequestModel = {
			Type: type,
			ID: id,
			AuthorizationToken: accessToken,
		}

		var results = await fetchCustomData<string>({
			QueryURL: 'UMOperationsUsingID',
			QueryObj: getMembersByOrgIDRequestModel,
			ErrorMessage:
				type === UserManagementType.DeleteUser
					? 'User cannot be deleted'
					: 'An error occurred when getting data',
			ShowErrorToast: true,
			ShowSuccessToast: type === UserManagementType.DeleteUser ? true : false,
			SuccessMessage: 'User Deleted',
			LogErrorToDB: true,
			FileAndFunctionName: 'PortalUserManagement.tsx Generic Api call',
		})
		if (type === UserManagementType.DeleteUser) {
			await getMembers()
		}
		return JSON.stringify(results ?? '')
	}

	useEffect(() => {
		if (!apiCallDone) {
			getMembers()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleCloseModal = () => {
		setOpenModal(false)
	}
	const getMembers = async () => {
		setGettingMembers(true)
		setMemberList([])
		var res = await apiCall(UserManagementType.Members, organizationID)
		var members = JSON.parse(res) as Member[]
		// If env is PROD filter out sipcom users and UAT testing accounts
		if (process.env.REACT_APP_ENVIRONMENT + '' === 'Prod') {
			members = members.filter(
				(x) =>
					!x.email.toLowerCase().includes('@sipcom.com') &&
					!x.email.toLowerCase().includes('mattclegg') &&
					!x.email.toLowerCase().includes('sim') &&
					!x.email.toLowerCase().includes('mattjclegg')
			)
		}
		setMemberList(members ?? [])
		setApiCallDone(true)
		setGettingMembers(false)
	}
	const getInvites = async () => {
		setInvitationList([] as Invitation[])
		setGettinginvites(true)
		var alphaText = await apiCall(
			UserManagementType.PendingInvites,
			organizationID
		)
		var _invitation = JSON.parse(alphaText ?? '') as Invitation[]
		// If env is PROD filter out sipcom users and UAT testing accounts
		if (process.env.REACT_APP_ENVIRONMENT + '' === 'Prod') {
			_invitation = _invitation.filter(
				(x) =>
					!x.invitedEmail.toLowerCase().includes('@sipcom.com') &&
					!x.invitedEmail.toLowerCase().includes('mattclegg') &&
					!x.invitedEmail.toLowerCase().includes('sim') &&
					!x.invitedEmail.toLowerCase().includes('mattjclegg')
			)
		}
		setInvitationList(_invitation)
		setGettinginvites(false)
	}

	const handleSendInvite = async (
		email: string,
		customerID: string,
		role: string
	) => {
		if (!emailValidation(email)) {
			showErrorToast(`Email: ${email} not valid.`)
			return
		}

		var isRoleCustomerAdmin = role === customerRoleID
		var inviteUsersToPortal: InviteUsersRequestModel = {
			CustomerID: isRoleCustomerAdmin ? customerID : null,
			InvitedEmail: email,
			InviterEmail: loggedInUser.email,
			OrganizationID: organizationID,
			PartnerID: partnerID,
			RoleID: role,
		}
		await fetchCustomData<string>({
			QueryURL: 'InviteUsersToPortal',
			QueryObj: inviteUsersToPortal,
			ErrorMessage: 'An error occurred when sending an invite',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ShowSuccessToast: true,
			FileAndFunctionName: 'PortalUserManagement.tsx handleSendInvite',
		})
	}
	return (
		<>
			<Box>
				<Box className='description'>
					<Typography component='p'>
						Manage your portal users and their account permissions here.
					</Typography>
				</Box>
				<Divider />
				<Box className='admin-title-container'>
					<Box className='admin-title'>
						<Typography
							sx={{
								marginBottom: '1rem',
							}}
							component='h4'>
							Users
						</Typography>
						<Typography sx={{ color: '#000' }} component='p'>
							Admins can Add, Remove and Re-invite members
						</Typography>
					</Box>
					<Box>
						<StyledButton
							onClick={() => {
								setOpenModal(true)
							}}
							startIcon={<BtnIcon />}
							variant='outlined'>
							Add Members
						</StyledButton>
					</Box>
				</Box>
				<Box className='tabs'>
					<TabContext value={value}>
						<Box>
							<TabList
								onChange={handleChange}
								aria-label='lab API tabs example'>
								<Tab className='tab' label='Current Members' value='1' />
								<Tab className='tab' label='Pending Invites' value='2' />
								<Tab className='tab' label='Expired Invites' value='3' />
							</TabList>
						</Box>
						<TabPanel value='1'>
							<Members
								getMembers={getMembers}
								memberList={memberList}
								apiCall={apiCall}
								loading={gettingMembers}
							/>
						</TabPanel>
						<TabPanel value='2'>
							<Invites
								getInvites={getInvites}
								invitationList={invitationList}
								handleSendInvite={handleSendInvite}
								status='Pending'
								loading={gettingInvites}
							/>
						</TabPanel>
						<TabPanel value='3'>
							<Invites
								getInvites={getInvites}
								invitationList={invitationList}
								handleSendInvite={handleSendInvite}
								status='Expired'
								loading={gettingInvites}
							/>
						</TabPanel>
					</TabContext>
				</Box>
			</Box>
			<Modal open={openModal}>
				<StyledModal width={500}>
					<InviteMember
						handleSendInvite={handleSendInvite}
						handleCloseModal={handleCloseModal}
					/>
				</StyledModal>
			</Modal>
		</>
	)
}

export default PortalUserManagement
