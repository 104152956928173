import { useCallback, useEffect, useRef, useState } from 'react'
import { DDIRangeDisplay } from '../../../../utils/interfaces/ComponentModels'
import {
	AddressMap,
	CivicAddressTenantMap,
	DDI,
	MSTeamsUser,
} from '../../../../utils/interfaces/DBModels'
import {
	GridColDef,
	GridFilterModel,
	GridFooter,
	GridFooterContainer,
	GridRenderCellParams,
	GridValidRowModel,
} from '@mui/x-data-grid-pro'
import { StyledDataGrid } from '../../../../styles/styledComponents/displays/StyledDataGrid'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import {
	Backdrop,
	Box,
	IconButton,
	Modal,
	Tooltip,
	Typography,
} from '@mui/material'
import StyledModal from '../../../../styles/styledComponents/displays/StyledModal'
import { showErrorToast } from '../../../../utils/helperFunctions/helperFunctions'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import UseCrud from '../../../../utils/customHooks/APICalls/UseCrud'
import DDIAddressUpdate from './DDIAddressUpdate'
import { format } from 'date-fns'
import {
	AddressTypes,
	DDIStatuses,
	ReturnTypes,
	Roles,
} from '../../../../utils/enums/enums'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import SkipPreviousOutlinedIcon from '@mui/icons-material/SkipPreviousOutlined'
import SkipNextOutlinedIcon from '@mui/icons-material/SkipNextOutlined'
import { StyledLoadingButton } from '../../../../styles/styledComponents/inputs/StyledLoadingButton'
import { DataResponse } from '../../../../utils/interfaces/APIModels'

const TNRangeDisplay = ({
	tnRangeDisplay,
	addressMapList,
	filterLoading,
	ddiRangesMaxPageNo,
	ddiRangesTotalRecords,
	currentPageNo,
	handlePageNavigation,
}: {
	tnRangeDisplay: DDIRangeDisplay[]
	addressMapList: AddressMap[]
	filterLoading: boolean
	ddiRangesMaxPageNo: number
	ddiRangesTotalRecords: number
	currentPageNo: number
	handlePageNavigation: (navType: string) => void
}) => {
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const roleID = useSelector(
		(state: RootState) => state.RootReducer.roleIDReducer.value
	)

	// Hooks
	const { modifyData, fetchData } = UseCrud()

	// UseState
	const [selectedDDIRangeDisplay, setSelectedDDIRangeDisplay] = useState(
		{} as DDIRangeDisplay
	)
	const filterAddressMapList = useRef([] as AddressMap[])

	// Flags
	const [openModal, setOpenModal] = useState(false)
	const [allColumnsSet, setAllColumnsSet] = useState(false)

	// Set auto height to detail panel
	const getDetailPanelHeight = useCallback(() => 'auto', [])

	// Filters - Quick filters for the datagrid
	const [filterModel, setFilterModel] = useState<GridFilterModel>({
		items: [],
	})

	// Column Definition: Order Table
	const initialTNRangeColumns: GridColDef[] = [
		{
			field: 'DDIRangeStart',
			headerName: 'Number Start',
			hideable: false,
			flex: 1,
		},
		{
			field: 'DDIRangeEnd',
			headerName: 'Number End',
			hideable: false,
			flex: 1,
		},
		{
			field: 'PortDate',
			headerName: 'FOC Date',
			width: 220,
			hideable: false,
			flex: 1,
			renderCell: (params: GridRenderCellParams<GridValidRowModel>) => (
				<>{params.value ? format(params.value, 'MMMM dd, yyyy') : ''}</>
			),
		},
	]

	const [tnRangeColumns, setTNRangeColumns] = useState<GridColDef[]>(
		initialTNRangeColumns
	)

	useEffect(() => {
		// Set rows
		if (roleID === Roles.PartnerAdmin) {
			initialTNRangeColumns.push({
				field: 'CustomerName',
				headerName: 'Customer',
				width: 220,
				hideable: false,
				flex: 1,
			})
		}

		if (roleID === Roles.CustomerAdmin) {
			initialTNRangeColumns.push({
				field: 'DDIRangeID',
				type: 'actions',
				cellClassName: 'actions',
				headerName: '',
				hideable: false,
				flex: 1,
				filterable: false,
				sortable: false,
				getActions: ({ id }) => {
					return [
						<>
							<Tooltip title='Allocate Location to Number Range'>
								<span>
									<IconButton
										id='action-button'
										onClick={() => {
											handleUpdateTNRangeAddressClick(Number(id))
										}}>
										<SettingsOutlinedIcon />
									</IconButton>
								</span>
							</Tooltip>
						</>,
					]
				},
			})
		}

		setTNRangeColumns(initialTNRangeColumns)
		setAllColumnsSet(true)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tnRangeDisplay, addressMapList])

	const handleOpenModal = () => {
		setOpenModal(true)
	}

	const handleCloseModal = () => {
		setOpenModal(false)
	}

	// Handle functions
	const handleUpdateTNRangeAddressClick = async (ddiRangeID: number) => {
		if (ddiRangeID && ddiRangeID > 0) {
			var _selectedDDIRangeDisplay = tnRangeDisplay.find(
				(x) => x.DDIRangeID === ddiRangeID
			)
			var filteredAddresses
			var civicAddressTenantMapList: CivicAddressTenantMap[]
			var tenantIDsFromMSTeamsUser: MSTeamsUser[]
			var ddiResponseObj: DDI

			//make api call
			var data = (await fetchData({
				FileAndFunctionName:
					'TNRangeDisplay.tsx handleUpdateTNRangeAddressClick() ',
				QueryURL: `GetV2?Params=CivicAddressTenantMap.All(), MSTeamsUser.Where(MSTeamsUser.CustomerID='${_selectedDDIRangeDisplay?.CustomerID}'), DDI.Address.State.Country.First(DDI.DDIRangeID = '${ddiRangeID}')`,
				ErrorMessage:
					'An error occurred when fetching data from CivicAddressTenantMap and MSTeamsUser tables',
				ShowErrorToast: false,
				LogErrorToDB: true,
				ReturnType: ReturnTypes.ObjectOrList,
			})) as DataResponse

			if (_selectedDDIRangeDisplay) {
				setSelectedDDIRangeDisplay(_selectedDDIRangeDisplay)
			}

			if (data) {
				ddiResponseObj = data.Obj.DDI
				civicAddressTenantMapList = data.Obj.CivicAddressTenantMapList
				tenantIDsFromMSTeamsUser = data.Obj.MSTeamsUserList

				const findCountryID = ddiResponseObj?.Address?.State?.CountryID

				if (
					(ddiResponseObj?.ServiceOutID + '').includes('TOC') &&
					tenantIDsFromMSTeamsUser &&
					tenantIDsFromMSTeamsUser.length > 0
				) {
					var getTenantIDForEgressService = tenantIDsFromMSTeamsUser.find(
						(x) =>
							x.CustomerID === _selectedDDIRangeDisplay?.CustomerID &&
							x.ServiceID === ddiResponseObj.ServiceOutID
					)?.TenantID

					if (getTenantIDForEgressService !== null) {
						//use that tenant id to find all the addresses
						if (civicAddressTenantMapList.length > 0) {
							//filter through the list of civictenantmap and find all the civicaddress ids for that tenant id
							var listofCivicAddressIDs = civicAddressTenantMapList.filter(
								(x) => x.TenantID === getTenantIDForEgressService
							)

							//filter the addressmaplist where those civicaddressids are found
							const matchedAddresses = addressMapList.filter(
								(addressMap) =>
									listofCivicAddressIDs.some(
										(tenantMap) =>
											tenantMap.CivicAddressID ===
											addressMap.Address?.CivicAddressId
									) &&
									_selectedDDIRangeDisplay?.CustomerID ===
										addressMap.CustomerID &&
									addressMap?.Address?.AddressTypeID ===
										AddressTypes.Microsoft &&
									addressMap?.Address?.State?.CountryID === findCountryID
							)

							filterAddressMapList.current = matchedAddresses
						}
					} else {
						filterAddressMapList.current = []
					}
				} else {
					filteredAddresses = addressMapList.filter(
						(x) =>
							x?.Address?.State?.CountryID === findCountryID &&
							_selectedDDIRangeDisplay?.CustomerID === x.CustomerID
					)

					filterAddressMapList.current = filteredAddresses
				}
			}

			setOpenModal(true)
			handleOpenModal()
		}
	}

	const handleAddressUpdate = async (
		addressMapID: number,
		ddiRangeID?: number
	) => {
		if (addressMapID && ddiRangeID) {
			var matchingAddressMap = addressMapList.find(
				(x) => x.AddressMapID === Number(addressMapID)
			)

			var ddisInRange = (await fetchData({
				FileAndFunctionName: 'TNRangeDisplay.tsx handleAddressUpdate() ',
				QueryURL: `GetV2?Params=DDI.Where(DDI.DDIRangeID = '${ddiRangeID}')`,
				ErrorMessage:
					'An error occurred when fetching data from numbers when trying to update Address for Number Range',
				ShowErrorToast: true,
				LogErrorToDB: true,
				ReturnType: ReturnTypes.ObjectOrList,
			})) as DataResponse
			var matchingDDIs = [] as DDI[]

			if (
				ddisInRange &&
				ddisInRange.Count &&
				ddisInRange.Count > 0 &&
				ddisInRange.Obj
			) {
				matchingDDIs = ddisInRange.Obj.DDIList as DDI[]
				var ddisNotMatchingAddressID = matchingDDIs.filter(
					(x) => x.AddressID !== matchingAddressMap?.AddressID
				)
				if (ddisNotMatchingAddressID && ddisNotMatchingAddressID.length > 0) {
					var ddisToUpdate: DDI[] = []
					ddisNotMatchingAddressID.map(function (val) {
						ddisToUpdate.push({
							ID: val.ID,
							AddressID: matchingAddressMap?.AddressID,
							DDIStatusID:
								Number(val.DDIStatusID) ===
								Number(DDIStatuses.PendingEmergencyAddressData)
									? Number(DDIStatuses.ReadyForImplementation)
									: Number(val.DDIStatusID),
						})
					})

					try {
						// Post to DB
						var postSuccess = await modifyData({
							UserName: loggedInUser.email,
							FileAndFunctionName: `TNRangeDisplay.tsx: handleAddressUpdate()`,
							QueryURL: 'UpdateV2?Params=DDI:List',
							QueryObj: {
								DDIList: ddisToUpdate,
							},
							ShowSuccessMessage: true,
							SuccessMessage: `Successfully updated Number Range location`,
							ShowErrorMessage: false,
							ErrorMessage: `Failed to update Number Range location`,
							LogErrorToDB: true,
						})

						if (postSuccess) {
							handlePageNavigation('refresh')
							handleCloseModal()
						}
					} catch (error) {
						showErrorToast(
							`An error occurred when trying to update Number Range location`
						)
					}
				}
			}
		}
	}

	//1 - Custom Footer
	function CustomFooter() {
		return (
			<GridFooterContainer className='order-footer'>
				<GridFooter sx={{ borderTop: 'none' }} />
				<Box className='order-footer-content'>
					{/* Total rows */}
					<Box className='page-info'>
						<Typography>
							Page <strong>{currentPageNo}</strong> - {ddiRangesMaxPageNo} of{' '}
							{ddiRangesTotalRecords} Results
						</Typography>
					</Box>
					{/* Pagination */}
					<Box className='order-pagination'>
						{/* Skip to first page */}
						<Tooltip title='Skip to first page'>
							<span>
								<StyledLoadingButton
									disabled={currentPageNo === 1 || ddiRangesMaxPageNo === 0}
									onClick={() => handlePageNavigation('first-page')}>
									<SkipPreviousOutlinedIcon />
								</StyledLoadingButton>
							</span>
						</Tooltip>

						{/* Previous */}
						<Tooltip title='Previous page'>
							<span>
								<StyledLoadingButton
									disabled={currentPageNo === 1 || ddiRangesMaxPageNo === 0}
									onClick={() => handlePageNavigation('prev')}>
									<NavigateBeforeIcon />
								</StyledLoadingButton>
							</span>
						</Tooltip>
						{/* Next */}
						<Tooltip title='Next Page'>
							<span>
								<StyledLoadingButton
									disabled={
										currentPageNo === ddiRangesMaxPageNo ||
										ddiRangesMaxPageNo === 0
									}
									onClick={() => handlePageNavigation('next')}>
									<NavigateNextIcon />
								</StyledLoadingButton>
							</span>
						</Tooltip>
						{/* Skip to last page */}
						<Tooltip title='Skip to last page'>
							<span>
								<StyledLoadingButton
									disabled={
										currentPageNo === ddiRangesMaxPageNo ||
										ddiRangesMaxPageNo === 0
									}
									onClick={() => handlePageNavigation('last-page')}>
									<SkipNextOutlinedIcon />
								</StyledLoadingButton>
							</span>
						</Tooltip>
					</Box>
				</Box>
			</GridFooterContainer>
		)
	}

	return (
		<>
			{/* Datagrid: TNs */}
			{allColumnsSet && (
				<Box sx={{ display: 'flex', minHeight: '600px' }}>
					<StyledDataGrid
						rows={tnRangeDisplay}
						columns={tnRangeColumns}
						editMode='row'
						checkboxSelection={false}
						pageSizeOptions={[5]}
						slots={{
							footer: CustomFooter,
						}}
						slotProps={{
							toolbar: {
								showQuickFilter: true,
								quickFilterProps: { debounceMs: 500 },
							},
						}}
						getRowId={(row) => row.DDIRangeID}
						autoHeight={true}
						rowSelection={false}
						getDetailPanelHeight={getDetailPanelHeight}
						filterModel={filterModel}
						onFilterModelChange={(newFilterModel) =>
							setFilterModel(newFilterModel)
						}
						hideFooterRowCount
						disableColumnMenu
						loading={filterLoading}
					/>
				</Box>
			)}
			{/* Modal to update range locations */}
			<Modal open={openModal}>
				<StyledModal width={700}>
					<DDIAddressUpdate
						isRange={true}
						ddiRangeDisplay={selectedDDIRangeDisplay}
						addressMapList={filterAddressMapList.current}
						handleAddressUpdate={handleAddressUpdate}
						onClose={handleCloseModal}
					/>
				</StyledModal>
			</Modal>
		</>
	)
}

export default TNRangeDisplay
