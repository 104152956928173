import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
	Typography,
	useTheme,
} from '@mui/material'
import { useState } from 'react'
import { TextColourOptions } from '../../../utils/enums/enums'
import { StyledSelect } from '../../../styles/styledComponents/inputs/StyledSelect'
import CustomColourPicker from '../../shared/inputs/CustomColourPicker/CustomColourPicker'
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'
import { PartnerCustomization } from '../../../utils/interfaces/DBModels'
import { StyledLoadingButton } from '../../../styles/styledComponents/inputs/StyledLoadingButton'
import LogoUpload from './LogoUpload/LogoUpload'
import { ErrorHandling } from '../../../utils/customHooks/ErrorHandling/ErrorHandling'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'

const AppearanceSettings = ({
	modifyPartnerCustomization,
}: {
	modifyPartnerCustomization: any
}) => {
	// Global variables
	const partnerID = useSelector(
		(state: RootState) => state.RootReducer.partnerIDReducer.value
	)

	// Error Logging
	const addErrorLog = ErrorHandling()

	// Theme
	const theme = useTheme()

	// Display constants
	const [primaryColour, setPrimaryColour] = useState<string>(
		theme.palette.primary.main
	)
	const [primaryTextColour, setPrimaryTextColour] = useState<number>(
		theme.palette.primary.contrastText === '#000'
			? TextColourOptions.Black
			: TextColourOptions.White
	)
	const [secondaryColour, setSecondaryColour] = useState<string>(
		theme.palette.secondary.main
	)
	const [secondaryTextColour, setSecondaryTextColour] = useState<number>(
		theme.palette.secondary.contrastText === '#000'
			? TextColourOptions.Black
			: TextColourOptions.White
	)

	// Logo
	const [fileToUpload, setFileToUpload] = useState<File | null>(null)

	// Flags
	const [openDialog, setOpenDialog] = useState(false)
	const [dialogTitle, setDialogTitle] = useState('')
	const [dialogDescription, setDialogDescription] = useState('')
	const [loadingButton, setLoadingButton] = useState(false)

	// Handle primary and secondary colour change
	const handleColourChange = (colour: string) => {
		setPrimaryColour(colour)
	}

	const handleSecondaryColourChange = (colour: string) => {
		setSecondaryColour(colour)
	}

	// Handle primary and secondary text colour change
	const handlePrimaryTextColourChange = (e: { target: any }) => {
		setPrimaryTextColour(Number(e.target.value))
	}

	const handleSecondaryTextColourChange = (e: { target: any }) => {
		setSecondaryTextColour(Number(e.target.value))
	}

	// Handle saving of the settings for customization
	const handleSaveCustomization = () => {
		setDialogTitle(
			'Are you sure you wish to save these customization settings?'
		)
		setDialogDescription(
			'Please confirm the customization settings. Please note this will affect the display of the whole site.'
		)
		setOpenDialog(true)
	}

	// Handle Function: Save logo file
	const handleLogoChange = (file: File | null) => {
		setFileToUpload(file)
	}

	// POST: Upload to blob storage
	const uploadToBlobStorage = async () => {
		// Return variable
		var logoURL = ''

		// Error handling
		var isError = false

		// Environment variables
		var queryURL = `${process.env.REACT_APP_PROXY_FUNCTION_BASE_URL}image/upload`
		var apiKey = `${process.env.REACT_APP_API_PROXY_FUNCTION_KEY}`

		// Generate form data
		const formData = new FormData()
		if (fileToUpload !== null) {
			formData.append('storageName', 'GenericPortalStorage')
			formData.append('blobContainerName', 'logos')
			formData.append('imageFile', fileToUpload)
		}

		// Make call
		await fetch(queryURL, {
			method: 'POST',
			headers: new Headers({
				'x-functions-key': apiKey,
			}),
			body: formData,
		})
			.then(async (response) => {
				if (response.ok) {
					// Get the blob string
					const result = await response.text()

					if (result) {
						logoURL = result
					}
				}
			})
			.catch(async (error) => {
				if (error) {
					isError = true
				}
			})
			.finally(async () => {
				// If error, log to DB
				if (isError) {
					await addErrorLog({
						ActionPerformed: 'AppearanceSettings.tsx: uploadToBlobStorage()',
						ErrorInfo:
							'An error occurred when uploading the logo to blob storage and returning the string',
					})
				}
			})

		return logoURL
	}

	// POST: Saving the customization settings
	const confirmCustomizationSettings = async () => {
		// Close Dialog and set button to be loading
		setOpenDialog(false)
		setLoadingButton(true)

		// Check if file is not empty - Upload to blob storage and get string
		var logoURL = ''
		if (fileToUpload !== null) {
			logoURL = await uploadToBlobStorage()
		}

		// ** Get all the settings and save to the DB then refresh the site ** //
		// Generate Model
		var partnerCustomizationObj: PartnerCustomization = {
			CustomerPartnerID: partnerID,
			PartnerLogoURL: logoURL,
			PrimaryColour: primaryColour,
			PrimaryTextColour:
				primaryTextColour === TextColourOptions.Black ? '#000' : '#fff',
			SecondaryColour: secondaryColour,
			SecondaryTextColour:
				secondaryTextColour === TextColourOptions.Black ? '#000' : '#fff',
		}
		// Send to page where we will then complete update
		await modifyPartnerCustomization(partnerCustomizationObj)

		// Finish loading
		setLoadingButton(false)
	}

	return (
		<>
			{/* Dialog - When the user wishes to save */}
			<Dialog
				open={openDialog}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>{dialogTitle}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						{dialogDescription}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setOpenDialog(false)}
						sx={{ color: '#db3d47' }}>
						Cancel
					</Button>
					<Button onClick={confirmCustomizationSettings} autoFocus>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
			{/* Section - Appearance */}
			<Box className='settings-option'>
				<Box className='settings-header'>
					<Box className='settings-header-text'>
						{/* Description */}
						<Typography
							component='p'
							sx={{
								marginBottom: '20px',
							}}>
							Change the site logo, colours and styling.
						</Typography>
					</Box>
					{/* Button - To save the customization settings */}
					<StyledLoadingButton
						loading={loadingButton}
						onClick={handleSaveCustomization}>
						Save all changes
					</StyledLoadingButton>
				</Box>
				<Divider />
				{/* Form - Logo */}
				<Box className='settings-form'>
					{/* Form Label */}
					<Box className='settings-label'>
						{/* Header */}
						<Typography component='h4'>Logo</Typography>
						{/* Description */}
						<Typography component='p'>
							Change the logo of the site from the default one. Please note for
							the best quality you need to upload at least a{' '}
							<strong>300px x 150px</strong> image.
						</Typography>
					</Box>
					{/* Form input */}
					<Box className='settings-input image'>
						{/* Image Input */}
						<LogoUpload handleLogoChange={handleLogoChange} />
					</Box>
				</Box>
				<Divider />
				{/* Form - Primary Colour */}
				<Box className='settings-form'>
					{/* Form Label */}
					<Box className='settings-label'>
						{/* Header */}
						<Typography component='h4'>Primary Colour</Typography>
						{/* Description */}
						<Typography component='p'>
							Change the primary colour of the site which will change colours of
							elements such as buttons, menu links, etc. You can also change the
							colour of your text from white to black and vice versa.
						</Typography>
					</Box>
					{/* Form input */}
					<Box className='settings-input color'>
						{/* Inputs */}
						<Box className='dual-input'>
							{/* Colour Picker */}
							<CustomColourPicker
								value={primaryColour}
								onChange={handleColourChange}
							/>
							{/* Dropdown */}
							<Box className='label-input'>
								<Typography component='p'>Text Colour</Typography>
								<StyledSelect
									fullWidth
									required
									variant='outlined'
									className='form-input-select'
									value={primaryTextColour}
									onChange={handlePrimaryTextColourChange}>
									{/* Black */}
									<MenuItem
										key={TextColourOptions.Black}
										value={TextColourOptions.Black}>
										{TextColourOptions[TextColourOptions.Black]}
									</MenuItem>
									{/* White */}
									<MenuItem
										key={TextColourOptions.White}
										value={TextColourOptions.White}>
										{TextColourOptions[TextColourOptions.White]}
									</MenuItem>
								</StyledSelect>
							</Box>
						</Box>
						{/* Elements to change colour */}
						<Divider />
						<Typography component='h4'>Visual Representation</Typography>
						<Box className='settings-visual'>
							<Button
								sx={{
									background: primaryColour,
									color:
										primaryTextColour === TextColourOptions.Black
											? '#000'
											: '#fff',
									borderRadius: 10,
									padding: '10px 30px',
								}}>
								Button
							</Button>
							<MenuList>
								<MenuItem
									key={1}
									sx={{
										bgcolor: primaryColour,
										borderRadius: '10px',
										padding: '10px',
										maxWidth: '400px',
									}}>
									<ListItemIcon
										sx={{
											color:
												primaryTextColour === TextColourOptions.Black
													? '#000'
													: '#fff',
										}}>
										<AutoAwesomeOutlinedIcon />
									</ListItemIcon>
									<ListItemText>
										<Typography
											component='p'
											sx={{
												color:
													primaryTextColour === TextColourOptions.Black
														? '#000!important'
														: '#fff!important',
											}}>
											Active Menu Item
										</Typography>
									</ListItemText>
								</MenuItem>
							</MenuList>
						</Box>
					</Box>
				</Box>
				<Divider />
				{/* Form - Secondary Colour */}
				<Box className='settings-form'>
					{/* Form Label */}
					<Box className='settings-label'>
						{/* Header */}
						<Typography component='h4'>Secondary Colour</Typography>
						{/* Description */}
						<Typography component='p'>
							Change the secondary colour of the site which will change colours
							of elements such as table headings, etc. You can also change the
							colour of your text from white to black and vice versa.
						</Typography>
					</Box>
					{/* Form input */}
					<Box className='settings-input color'>
						{/* Inputs */}
						<Box className='dual-input'>
							{/* Colour Picker */}
							<CustomColourPicker
								value={secondaryColour}
								onChange={handleSecondaryColourChange}
							/>
							{/* Dropdown */}
							<Box className='label-input'>
								<Typography component='p'>Text Colour</Typography>
								<StyledSelect
									fullWidth
									required
									variant='outlined'
									className='form-input-select'
									value={secondaryTextColour}
									onChange={handleSecondaryTextColourChange}>
									{/* Black */}
									<MenuItem
										key={TextColourOptions.Black}
										value={TextColourOptions.Black}>
										{TextColourOptions[TextColourOptions.Black]}
									</MenuItem>
									{/* White */}
									<MenuItem
										key={TextColourOptions.White}
										value={TextColourOptions.White}>
										{TextColourOptions[TextColourOptions.White]}
									</MenuItem>
								</StyledSelect>
							</Box>
						</Box>
						{/* Elements to change colour */}
						<Divider />
						<Typography component='h4'>Visual Representation</Typography>
						<Box className='settings-visual'>
							<Box className='header' bgcolor={secondaryColour}>
								<Box className='four'>
									<Typography
										component='h6'
										sx={{
											color:
												secondaryTextColour === TextColourOptions.Black
													? '#000!important'
													: '#fff!important',
										}}>
										Header
									</Typography>
									<Typography
										component='h6'
										sx={{
											color:
												secondaryTextColour === TextColourOptions.Black
													? '#000!important'
													: '#fff!important',
										}}>
										Header
									</Typography>
									<Typography
										component='h6'
										sx={{
											color:
												secondaryTextColour === TextColourOptions.Black
													? '#000!important'
													: '#fff!important',
										}}>
										Header
									</Typography>
									<Typography
										component='h6'
										sx={{
											color:
												secondaryTextColour === TextColourOptions.Black
													? '#000!important'
													: '#fff!important',
										}}>
										Header
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default AppearanceSettings
