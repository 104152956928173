// This component is the simple card display (icon, title and description)
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import CallMadeIcon from '@mui/icons-material/CallMade'
import './SimpleCardContent.scss'

const SimpleCard = ({
	icon,
	title,
	description,
	showAction,
	actionTooltip,
	action,
}: {
	icon: any
	title: string
	description: string
	showAction: boolean
	actionTooltip?: string
	action?: any
}) => {
	return (
		<Box className='box-content'>
			<Box className='card-header'>
				<Box className='box-icon-button'>
					<Box className='box-icon'>{icon}</Box>
				</Box>

				<Box
					className='centerBox'
					sx={{
						paddingTop: '5px',
					}}>
					<Typography component='h3'>{title}</Typography>
				</Box>

				{showAction && (
					<Box className='box-icon-button'>
						<Box className='box-button'>
							<Tooltip title={actionTooltip + ''}>
								<IconButton onClick={action}>
									<CallMadeIcon />
								</IconButton>
							</Tooltip>
						</Box>
					</Box>
				)}
			</Box>

			<Box className='centerBox'>
				<Typography component='h6'>{description}</Typography>
			</Box>
		</Box>
	)
}

export default SimpleCard
