import { TableCell, TableRow } from '@mui/material'
import { EditableTableCell } from '../EditableTableCell/EditableTableCell'
import { GenericColumn } from '../../../../../utils/interfaces/ComponentModels'

interface EditableTableRowProps {
	row: Record<string, any>
	columns: GenericColumn[]
	isInEditMode: boolean
	onChange: (rowId: number, field: string, newValue: any) => void
	showColumn: (columnName: string) => boolean
}

export const EditableTableRow: React.FC<EditableTableRowProps> = ({
	row,
	columns,
	isInEditMode,
	onChange,
	showColumn,
}) => {
	return (
		<TableRow>
			{columns.map(
				(column) =>
					showColumn(column.ID) && (
						<TableCell key={column.ID}>
							<EditableTableCell
								isInEditMode={isInEditMode}
								isColumnEditable={column.IsEditable}
								value={row[column.ID]}
								onChange={(newValue: any) => 
									onChange(row.customId || row.id, column.ID, newValue)
								}
							/>
						</TableCell>
					)
			)}
		</TableRow>
	)
}
