import { Box, Button, Divider, MenuItem, Typography } from '@mui/material'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import './FiltersComponent.scss'
import { LoadingButton } from '@mui/lab'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'

import {
	dateSelectorFilter,
	filterKeys,
	selectBoxFilters,
	sortByKeys,
} from '../../../utils/constants/constants'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
	FilterComponent,
	SelectedFilters,
} from '../../../utils/interfaces/ComponentModels'
import {
	createSelectFiltersArray,
	isEmptyOrWhitespace,
} from '../../../utils/helperFunctions/helperFunctions'
import { StyledSelect } from '../../../styles/styledComponents/inputs/StyledSelect'
import { StyledTextField } from '../../../styles/styledComponents/inputs/StyledTextField'
import { StyledChip } from '../../../styles/styledComponents/inputs/StyledChip'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { Roles } from '../../../utils/enums/enums'

const FiltersComponent = ({
	applyFiltersFor,
	filtersApplied,
	sortFilterApplied,
	handleFilterCall,
}: {
	applyFiltersFor: string
	filtersApplied: FilterComponent[]
	sortFilterApplied: FilterComponent | undefined
	handleFilterCall: any
}) => {
	const roleID = useSelector(
		(state: RootState) => state.RootReducer.roleIDReducer.value
	)
	const sortAscendingList = ['Yes', 'No']
	// Filters
	const [applyFiltersList, setApplyFiltersList] = useState<FilterComponent[]>(
		[]
	)
	const [selectedFilterList, setSelectedFilterList] = useState<
		SelectedFilters[]
	>([])
	const [filterKey, setFilterKey] = useState('Please select a filter')
	const [filterValue, setFilterValue] = useState('')
	const [filterDisplayValue, setFilterDisplayValue] = useState<
		string | undefined
	>(undefined)

	// Sort
	const [applySortByColumn, setApplySortByColumn] = useState<
		FilterComponent | undefined
	>(undefined)
	const [sortByColumn, setSortByColumn] = useState('Please select a column')
	const [sortAscending, setSortAscending] = useState('')
	const disableSorting = useRef<boolean>(false)

	// Filters requiring a select box
	const filtersRequiringSelectBox = useRef<string[]>([])
	const filtersRequiringDateSelector = useRef<string[]>([])

	// Flags
	const [filterLoading, setFilterLoading] = useState(false)
	const [clearLoading, setClearLoading] = useState(false)
	const [showSelectFilter, setShowSelectFilter] = useState(false)
	const [showDateFilter, setShowDateFilter] = useState(false)

	const isAscendingOrder = useRef<boolean>(true)

	const filterByKeys = useRef<string[]>([])
	const sortByColumns = useRef<string[]>([])

	const isDisableApplyFilters = useRef<boolean>(true)
	const [dateErrorMessage, setDateErrorMessage] = useState<string>('')

	useEffect(() => {
		// Get filters and sort by columns
		filterByKeys.current = filterKeys[applyFiltersFor]
		sortByColumns.current = sortByKeys[applyFiltersFor]

		//remove Customer Name and CustomerPartnerRef filters for customers
		if (roleID === Roles.CustomerAdmin) {
			filterByKeys.current = filterKeys[applyFiltersFor].filter(
				(x) => x !== 'Customer Name' && x !== 'Customer Reference'
			)
			sortByColumns.current = sortByKeys[applyFiltersFor].filter(
				(x) => x !== 'Customer Name' && x !== 'Customer Reference'
			)
		}

		filtersRequiringSelectBox.current = selectBoxFilters[applyFiltersFor]
		filtersRequiringDateSelector.current = dateSelectorFilter[applyFiltersFor]

		// Filters
		if (filtersApplied) {
			setApplyFiltersList(filtersApplied)
		}

		if (sortFilterApplied) {
			// Get value of sort from enum
			setApplySortByColumn(sortFilterApplied)
			disableSorting.current = true
		}
		// Build select filter list
		setSelectedFilterList(
			createSelectFiltersArray(filterKey, selectedFilterList)
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersApplied])

	// Handle Functions
	// Handle change of select field for filter key
	const handleFilterKeyChange = (e: { target: any }) => {
		setDateErrorMessage('')
		var keyValue = e.target.value
		setFilterKey(keyValue)
		setFilterValue('')

		// Check if select needs to be shown
		const requiresSelect = handleShowSelectFilter(keyValue)
		setShowSelectFilter(requiresSelect)

		if (requiresSelect) {
			setSelectedFilterList(
				createSelectFiltersArray(keyValue, selectedFilterList)
			)
		}

		// Check if date selector needs to be shown
		if (
			filtersRequiringDateSelector.current &&
			filtersRequiringDateSelector.current.length > 0
		) {
			const requiresDateSelector = handleShowDateSelectorFilter(keyValue)
			setShowDateFilter(requiresDateSelector)
		}
	}

	// Handle change of select field for sort by column
	const handleSortColumnChange = (e: { target: any }) => {
		const column = e.target.value
		setSortByColumn(column)
		setSortAscending('')
	}

	// Check if filter is already applied
	const filterApplied = (filterKey: string) => {
		if (applyFiltersList.find((o) => o.Key === filterKey)) {
			return true
		} else {
			return false
		}
	}

	// Handle add filter
	const addFilter = () => {
		setApplyFiltersList((prev) => [
			...prev,
			{
				Key: filterKey,
				Value: filterValue,
				DisplayValue: filterDisplayValue,
			},
		])

		isDisableApplyFilters.current =
			isEmptyOrWhitespace(filterKey) && isEmptyOrWhitespace(filterValue)

		// Clear the constants
		setFilterKey('')
		setFilterValue('')
		setFilterDisplayValue(undefined)
	}

	const addSortingFilter = () => {
		setApplySortByColumn({
			Key: sortByColumn,
			Value: sortAscending,
			DisplayValue: sortByColumn,
		})
		disableSorting.current = true
		isDisableApplyFilters.current = false
	}

	// Handle remove filter
	const removeFilter = async (key: string) => {
		// Filter out the item with the matching key
		const filteredOrderFilterList = applyFiltersList.filter(
			(item) => item.Key !== key
		)
		// Update the state with the new list
		setApplyFiltersList(filteredOrderFilterList)

		// Make call ONLY if the filter has been applied
		if (filtersApplied.find((o) => o.Key === key)) {
			await handleFilterCall(filteredOrderFilterList, applySortByColumn)
		}

		isDisableApplyFilters.current = filteredOrderFilterList.length < 0
	}

	// Handle removing any applied sorting
	const removeSortingFilter = async () => {
		setApplySortByColumn(undefined)
		disableSorting.current = false
		await handleFilterCall(filtersApplied)
	}

	// Apply filters
	const applyFilters = async () => {
		// Loading
		setFilterLoading(true)

		if (applyFiltersList.length > 0) {
			if (applySortByColumn !== undefined) {
				await handleFilterCall(applyFiltersList, applySortByColumn)
			} else {
				await handleFilterCall(applyFiltersList)
			}
		} else {
			if (applySortByColumn !== undefined) {
				await handleFilterCall([], applySortByColumn)
			}
		}

		// Loading
		setFilterLoading(false)
	}

	// Clear filters
	const clearFilters = async () => {
		// Loading
		setClearLoading(true)

		setApplySortByColumn(undefined)
		setSortByColumn('')
		setSortAscending('')
		disableSorting.current = false
		// Make call
		await handleFilterCall([])

		// Loading
		setClearLoading(false)
	}

	// Check if select filter is needed
	const handleShowSelectFilter = (_filterBy: string): boolean => {
		const selectFilter = filtersRequiringSelectBox.current.find(
			(filter) => filter === _filterBy
		)

		if (!selectFilter) {
			return false
		}

		return true
	}

	// Check if date selector is needed
	const handleShowDateSelectorFilter = (_filterBy: string): boolean => {
		const filterFound = filtersRequiringDateSelector.current.find(
			(filter) => filter === _filterBy
		)

		if (!filterFound) {
			return false
		}

		return true
	}

	// Handle filter value change
	const handleFilterValueChange = (
		e: ChangeEvent<{ value: unknown }>
	): void => {
		var _filterValue = e.target.value as string

		var displayValue = selectedFilterList.find(
			(x) => x.FilterID === e.target.value
		)
		if (displayValue) {
			setFilterDisplayValue(displayValue.FilterDisplayName)
		} else {
			setFilterDisplayValue(undefined)
		}

		setFilterValue(_filterValue)
	}

	const handleDateChange = (date: Date | null) => {
		if (date !== null) {
			const filterByDate = new Date(date)

			const _dateErrorMessage = isNaN(filterByDate.getTime())
				? 'Please provide a valid date'
				: ''

			setDateErrorMessage(_dateErrorMessage)

			if (isEmptyOrWhitespace(_dateErrorMessage)) {
				setDateErrorMessage('')
				const year = filterByDate.getFullYear()
				const month = String(filterByDate.getMonth() + 1).padStart(2, '0')
				const day = String(filterByDate.getDate()).padStart(2, '0')
				setFilterValue(`${year}-${month}-${day}`)
			}
		}
	}

	// Handle sort by value change
	const handleSortByValueChange = (e: { target: any }) => {
		const value = e.target.value
		isAscendingOrder.current = value === 'Yes'
		setSortAscending(value)
	}

	return (
		<>
			<Box className='filter-container'>
				{/* Filter box */}
				<Box className='filter-content'>
					{/* Filter by */}
					<Box className='filter'>
						{/* Label */}
						<Typography component='p'>Filter by:</Typography>
						{/* Dropdown */}
						<StyledSelect
							fullWidth
							value={filterKey}
							type='text'
							onChange={handleFilterKeyChange}>
							<MenuItem disabled value='Please select a filter'>
								Please select a filter
							</MenuItem>
							{filterByKeys.current?.map((item) => {
								return (
									!filterApplied(item) && (
										<MenuItem key={item} value={item}>
											{item}
										</MenuItem>
									)
								)
							})}
						</StyledSelect>
					</Box>
					{/* Value */}
					<Box className='filter'>
						{/* Label */}
						<Typography component='p'>Value:</Typography>
						{/* Textfield */}
						{!showDateFilter ? (
							<StyledTextField
								fullWidth
								select={showSelectFilter}
								disabled={
									filterKey.length === 0 ||
									filterKey === 'Please select a filter'
								}
								value={filterValue}
								onChange={handleFilterValueChange}>
								{showSelectFilter &&
									selectedFilterList
										.filter((item) => item.FilterCategory === filterKey)
										.map((item) => (
											<MenuItem key={item.FilterID} value={item.FilterID}>
												{item.FilterDisplayName}
											</MenuItem>
										))}
							</StyledTextField>
						) : (
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									openTo='day'
									views={['year', 'month', 'day']}
									className='input-field'
									value={filterValue}
									onChange={handleDateChange}
									renderInput={(params) => (
										<StyledTextField
											{...params}
											fullWidth
											disabled={
												filterKey.length === 0 ||
												filterKey === 'Please select a filter'
											}
										/>
									)}
									label='Select Date'
								/>
							</LocalizationProvider>
						)}
					</Box>
					{/* Add filter button */}
					<Box className='add-filter'>
						<Button
							disabled={filterValue.length === 0 || dateErrorMessage.length > 0}
							startIcon={<AddIcon />}
							variant='outlined'
							onClick={addFilter}>
							Add filter
						</Button>
					</Box>

					{/* Sort by */}
					<Box sx={{ marginTop: 2 }} className='filter'>
						{/* Label */}
						<Typography component='p'>Sort by:</Typography>
						{/* Dropdown */}
						<StyledSelect
							fullWidth
							disabled={disableSorting.current}
							value={sortByColumn}
							type='text'
							onChange={handleSortColumnChange}>
							<MenuItem disabled value='Please select a column'>
								Please select a column
							</MenuItem>
							{sortByColumns.current?.map((item) => {
								return (
									<MenuItem key={item} value={item}>
										{item}
									</MenuItem>
								)
							})}
						</StyledSelect>
					</Box>

					{/* Value */}
					<Box sx={{ marginTop: 2 }} className='filter'>
						{/* Label */}
						<Typography component='p'>Ascending Order:</Typography>
						{/* Textfield */}
						<StyledSelect
							fullWidth
							disabled={
								isEmptyOrWhitespace(sortByColumn) ||
								sortByColumn === 'Please select a column' ||
								disableSorting.current
							}
							value={sortAscending}
							onChange={handleSortByValueChange}>
							{sortAscendingList.map((item: string, index: number) => (
								<MenuItem key={index} value={item}>
									{item}
								</MenuItem>
							))}
						</StyledSelect>
					</Box>

					{/* Add filter button */}
					<Box className='add-filter'>
						<Button
							disabled={sortAscending.length === 0 || disableSorting.current}
							startIcon={<AddIcon />}
							variant='outlined'
							onClick={addSortingFilter}>
							Add filter
						</Button>
					</Box>

					{/*  */}
				</Box>

				{/* Filters applied */}
				{applyFiltersList.length > 0 && (
					<Box className='filters-applied'>
						{/* Divider */}
						<Divider />
						{/* Header */}
						<Typography component='h4'>Filters applied:</Typography>
						{/* Chips */}
						<Box className='filter-chips'>
							{applyFiltersList.map((item, index) => (
								<StyledChip
									className='applied-filteres'
									variant='outlined'
									key={index}
									label={`${item.Key}: ${
										item.DisplayValue ? item.DisplayValue : item.Value
									}`}
									onDelete={() => removeFilter(item.Key)}
								/>
							))}
						</Box>
					</Box>
				)}

				{/* Sorting applied */}
				{applySortByColumn !== undefined && (
					<Box className='filters-applied'>
						{/* Divider */}
						<Divider />
						{/* Header */}
						<Typography component='h4'>Sorting applied:</Typography>
						{/* Chips */}
						<Box className='filter-chips'>
							<StyledChip
								className='applied-filteres'
								variant='outlined'
								key={applySortByColumn?.Key}
								label={`${applySortByColumn?.Key}: ${
									applySortByColumn?.Value === 'Yes'
										? 'Ascending'
										: 'Descending'
								} Order`}
								onDelete={removeSortingFilter}
							/>
						</Box>
					</Box>
				)}

				{/* Divider */}
				<Divider />
				{/* Buttons */}
				<Box className='filter-buttons'>
					{/* Date Error */}
					{showDateFilter && (
						<Typography sx={{ paddingTop: 1, color: 'red' }} component='p'>
							{dateErrorMessage}
						</Typography>
					)}
					{/* Clear filters */}
					<LoadingButton
						disabled={applyFiltersList.length === 0}
						loading={clearLoading}
						variant='outlined'
						onClick={clearFilters}>
						Clear Filters
					</LoadingButton>
					{/* Apply */}
					<LoadingButton
						disabled={isDisableApplyFilters.current}
						loading={filterLoading}
						variant='contained'
						onClick={applyFilters}>
						Apply Filters
					</LoadingButton>
				</Box>
			</Box>
		</>
	)
}

export default FiltersComponent
