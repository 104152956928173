import { Box, styled } from '@mui/material'

export const StyledAnimatedBox = styled(Box)(({ theme }) => ({
	padding: '30px',
	background: `linear-gradient(to right, ${theme.palette.secondary.main} 0%, ${theme.palette.secondary.main} 0%)`,
	backgroundSize: '0% 100%',
	backgroundRepeat: 'no-repeat',
	animation: 'fillAnimation 3s ease forwards',
	borderRadius: '15px',
	'@keyframes fillAnimation': {
		'0%': {
			backgroundSize: '0% 100%',
		},
		'100%': {
			backgroundSize: '100% 100%',
		},
	},
	// Box Content - Icon and Text
	'& .box-content': {
		display: 'flex',
		flexDirection: 'column',
		'& .box-number': {
			textAlign: 'center',
			padding: '15px',
			'& h2': {
				fontSize: '50px',
				lineHeight: 1,
				color: theme.palette.secondary.contrastText,
			},
		},
	},
}))
