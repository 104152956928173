import UseCrud from '../UseCrud'
import {
	CustomPostHookObj,
	DataResponse,
	MSLineURIAndRoutingPlanUsers,
	MSRequest,
	MSResponse,
	TenantConfigurationInfo,
	UserDDIs,
	UserLicenseListResponse,
	UserManagementLicenseResponse,
	UserManagementResponseList,
} from '../../../interfaces/APIModels'
import {
	Endpoints,
	OrderTypes,
	ProductActionStatus,
	ReturnTypes,
	SDAStatus,
	ServiceManagementStatusCheck,
	ServiceTypes,
	TokenType,
} from '../../../enums/enums'
import {
	MSTeamsLicenseSKU,
	MSTeamsUser,
	OrderSKUProductMap,
	Service,
	ServiceManagementReport,
	ServiceManagementStatus,
} from '../../../interfaces/DBModels'
import {
	MicrosoftLineURIOverview,
	SDALicenseOverview,
	SDAOrderSKUInfo,
	TeamsServicesAndCustomerServices,
	TokenRequest,
} from '../../../interfaces/ComponentModels'
import { toAlphaString } from '../../../helperFunctions/helperFunctions'
import PostCustomEndpointHook from '../PostCustomEndpointHook'

// Hook call to manage the prechecks for dashboard, teams user management and SDA
export const CustomerDashboardPrechecks = (customerID: string) => {
	// Hooks
	const { fetchData } = UseCrud()

	const {
		postCustomEndpoint,
		postCustomEndpointError,
		postCustomEndpointLoading,
	} = PostCustomEndpointHook()

	// ******************************* API Calls ******************************* //

	// GET: Check for teams services and customer services
	const getTeamsServicesAndCustomerServices = async () => {
		// Return obj
		var teamsServicesAndCustomerServices =
			{} as TeamsServicesAndCustomerServices

		var dataResponse = (await fetchData({
			FileAndFunctionName:
				'CustomerDashboardPrechecks.tsx: getTeamsServicesAndCustomerServices()',
			QueryURL: `GetV2?Params=MSTeamsUser.Where(MSTeamsUser.CustomerID = '${customerID}' & MSTeamsUser.ServiceTypeID = '${ServiceTypes.MSTeamsDirectRouting}'),Service.Where(Service.ServiceID ~ '${customerID}' & Service.ServiceTypeID = '${ServiceTypes.MSTeamsDirectRouting}')`,
			ErrorMessage:
				'Unable to retrieve MS Teams services and customer services',
			ShowErrorToast: true,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse

		if (Number(dataResponse.Count) > 0) {
			var msTeamsResponseList = dataResponse.Obj
				?.MSTeamsUserList as MSTeamsUser[]

			var servicesResponseList = dataResponse.Obj?.ServiceList as Service[]

			if (msTeamsResponseList && msTeamsResponseList.length > 0) {
				teamsServicesAndCustomerServices.TeamsServices = msTeamsResponseList
			}

			if (servicesResponseList && servicesResponseList.length > 0) {
				teamsServicesAndCustomerServices.Services = servicesResponseList
			}
		}

		return teamsServicesAndCustomerServices
	}

	// GET: Check for teams order and validate the status
	const getSDAOrderSKU = async (msTeamsServiceID: string) => {
		var teamsOrderStatus = SDAStatus.NoTeamsOrderSKUFound
		var orderSKUID = 0
		var orderID = 0
		var externalOrderID = ''
		var orderSKUProductMapID = 0
		var actionLogID = 0

		var matchingOrderSKUProductMapResponse = (await fetchData({
			FileAndFunctionName: 'CustomerDashboardPrechecks.tsx: getSDAOrderSKU()',
			QueryURL: `GetV2?Params=OrderSKUProductMap.First(OrderSKUProductMap.ServiceID = '${msTeamsServiceID}')`,
			ErrorMessage: 'Unable to retrieve MS Teams orders',
			ShowErrorToast: true,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse

		if (
			matchingOrderSKUProductMapResponse &&
			Number(matchingOrderSKUProductMapResponse.Count) > 0 &&
			matchingOrderSKUProductMapResponse.Obj
		) {
			var teamsOrderSKUProductMap = matchingOrderSKUProductMapResponse.Obj
				.OrderSKUProductMap as OrderSKUProductMap
			if (teamsOrderSKUProductMap && teamsOrderSKUProductMap.OrderSKUID) {
				var orderSKUProductMapForSDAResponse = (await fetchData({
					FileAndFunctionName:
						'CustomerDashboardPrechecks.tsx: getSDAOrderSKU()',
					QueryURL: `GetV2?Params=OrderSKUProductMap.OrderSKU.Order.First(OrderSKUProductMap.OrderSKUID = '${teamsOrderSKUProductMap.OrderSKUID}' & OrderSKUProductMap.ProductID = 'SDA' & Order.OrderTypeID = '${OrderTypes.New}'), ActionLog.First(ActionLog.ProductID = 'SDA' & ActionLog.OrderSKUID = '${teamsOrderSKUProductMap.OrderSKUID}')`,
					ErrorMessage: 'Unable to retrieve MS Teams orders',
					ShowErrorToast: true,
					LogErrorToDB: true,
					ReturnType: ReturnTypes.ObjectOrList,
				})) as DataResponse

				if (
					orderSKUProductMapForSDAResponse &&
					Number(orderSKUProductMapForSDAResponse.Count) > 0 &&
					orderSKUProductMapForSDAResponse.Obj
				) {
					externalOrderID =
						orderSKUProductMapForSDAResponse.Obj?.OrderSKUProductMap?.OrderSKU
							?.Order?.ExternalOrderID + ''

					orderID = Number(
						orderSKUProductMapForSDAResponse.Obj?.OrderSKUProductMap?.OrderSKU
							?.Order?.OrderID
					)

					actionLogID = Number(
						orderSKUProductMapForSDAResponse.Obj?.ActionLog?.ActionLogID
					)

					var teamsOrderSKUProductMapForSDA = orderSKUProductMapForSDAResponse
						.Obj.OrderSKUProductMap as OrderSKUProductMap

					if (
						teamsOrderSKUProductMapForSDA &&
						teamsOrderSKUProductMapForSDA.ProductActionStatusID
					) {
						orderSKUID = Number(teamsOrderSKUProductMapForSDA.OrderSKUID)
						orderSKUProductMapID = Number(
							teamsOrderSKUProductMapForSDA.OrderSKUProductMapID
						)

						switch (teamsOrderSKUProductMapForSDA.ProductActionStatusID) {
							case ProductActionStatus[ProductActionStatus['NOT-START']]:
								teamsOrderStatus = SDAStatus.NotReadyForSDA
								break
							case ProductActionStatus[ProductActionStatus['IN-PROG']]:
								teamsOrderStatus = SDAStatus.ReadyForSDA
								break
							case ProductActionStatus[ProductActionStatus.COMP]:
								teamsOrderStatus = SDAStatus.SDACompleted
								break
						}
					}
				}
			}
		}

		var sdaOrderSKUInfo: SDAOrderSKUInfo = {
			OrderSKUID: orderSKUID,
			SDAStatus: teamsOrderStatus,
			ExternalOrderID: externalOrderID,
			OrderID: orderID,
			OrderSKUProductMapID: orderSKUProductMapID,
			ActionLogID: actionLogID,
		}

		return sdaOrderSKUInfo
	}

	// GET: Check the Service Management Status
	// Return - serviceManagementCheck: ServiceManagementCheck
	const getServiceManagementStatus = async (msTeamsServiceID: string) => {
		// Return variables
		var serviceManagementCheck = 0

		// Make status call
		var smStatusDataResponse = (await fetchData({
			FileAndFunctionName:
				'CustomerDashboardPrechecks.tsx: getServiceManagementStatus()',
			QueryURL: `GetV2?Params=ServiceManagementStatus.First(ServiceManagementStatus.ServiceID = '${msTeamsServiceID}')`,
			ErrorMessage:
				'Unable to retrieve Service Management Status for selected MS Teams service',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse

		if (
			smStatusDataResponse &&
			Number(smStatusDataResponse.Count) > 0 &&
			smStatusDataResponse.Obj
		) {
			var smStatusResponse = smStatusDataResponse.Obj
				.ServiceManagementStatus as ServiceManagementStatus

			if (smStatusResponse && smStatusResponse.JobStatus + '' === 'Success') {
				serviceManagementCheck =
					ServiceManagementStatusCheck.ServiceManagementSuccess
			} else {
				if (
					smStatusResponse &&
					(smStatusResponse.JobStatus + '').includes('Fail')
				) {
					if (
						smStatusResponse.Description + '' !==
						'Unable to connect to Tenant through Teams Module'
					) {
						serviceManagementCheck =
							ServiceManagementStatusCheck.ServiceManagementFailure
					}
				}
			}
		}

		return serviceManagementCheck
	}

	// GET: Check the Service Management Report
	const getServiceManagementReport = async (msTeamsServiceID: string) => {
		// Return variables
		var serviceManagementReportToFind = {} as ServiceManagementReport

		// Make report call
		var smReportDataResponse = (await fetchData({
			FileAndFunctionName:
				'CustomerDashboardPrechecks.tsx: getServiceManagementReport()',
			QueryURL: `GetV2?Params=ServiceManagementReport.Where(ServiceManagementReport.ServiceID = '${msTeamsServiceID}').Pagination(NumberOfRows = '1' & PageNumber = '1' & Column = 'ServiceManagementReport.LogTime' & SortType = 'DESC')`,
			ErrorMessage:
				'Unable to retrieve Service Management Report for selected MS Teams service',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse

		if (
			smReportDataResponse &&
			Number(smReportDataResponse.Count) > 0 &&
			smReportDataResponse.Obj
		) {
			var smReportResponse = smReportDataResponse.Obj
				.ServiceManagementReportList as ServiceManagementReport[]

			if (smReportResponse && smReportResponse.length > 0) {
				serviceManagementReportToFind = smReportResponse[0]
			}
		}

		return serviceManagementReportToFind
	}

	// ******************************* END API Calls ******************************* //

	// ******************************* Validate Functions ******************************* //

	// GET: Return graph token, then use it to return the licenses from Microsoft and DB and validate
	// Return - licenseOverview: MicrosoftLicenseOverview
	const getUserLicenses = async (msTeamsUser: MSTeamsUser) => {
		// Return variables
		var licenseOverview = {} as SDALicenseOverview

		// Variables
		var graphToken = ''

		// Get graph token from tenant ID
		if (msTeamsUser.TenantID && msTeamsUser.TenantID.length > 0) {
			if (!postCustomEndpointLoading && !postCustomEndpointError) {
				// Make call
				// Request Model
				var tokenRequest: TokenRequest = {
					TokenType: TokenType.GraphToken,
					TenantID: msTeamsUser.TenantID + '',
				}

				var customPostHookObj: CustomPostHookObj = {
					Action: 'Graph Token',
					RequestURL: 'GetTokenForTeamsUserManagement',
					RequestObj: tokenRequest,
					ShowSuccessMessage: false,
					ShowErrorMessage: false,
					ErrorMessage: 'An error occurred when retrieving the graph token',
					LogErrorToDB: true,
				}

				var customPostResponse = await postCustomEndpoint(customPostHookObj)

				if (customPostResponse) {
					var graphTokenResponse = JSON.parse(
						await toAlphaString(customPostResponse)
					)
					if (graphTokenResponse) {
						graphToken = graphTokenResponse
					}
				}
			}
		}

		// Use graph token to make call to get user licenses from microsoft
		if (graphToken.length > 0) {
			// Request Obj
			var getLicenseObj: MSRequest = {
				token: graphToken,
				Endpoint: Endpoints.GET_LICENSES,
			}

			var msLicensesHookObj: CustomPostHookObj = {
				Action: 'Microsoft Licenses',
				RequestURL: 'MakeMSCall',
				RequestObj: getLicenseObj,
				ShowSuccessMessage: false,
				ShowErrorMessage: false,
				ErrorMessage:
					'An error occurred when retrieving the licenses from microsoft',
				LogErrorToDB: true,
			}

			var msLicensesDataResponse = await postCustomEndpoint(msLicensesHookObj)

			if (msLicensesDataResponse) {
				// Decrypt to string
				var msLicensesResponseString = await toAlphaString(
					msLicensesDataResponse
				)

				if (msLicensesResponseString && msLicensesResponseString.length > 0) {
					// Convert string to JSON
					var msLicensesResponse = JSON.parse(
						msLicensesResponseString
					) as MSResponse

					if (msLicensesResponse) {
						var msLicenseInfo = JSON.parse(
							msLicensesResponse.MSResponseObject
						) as UserManagementLicenseResponse

						var msUserLicenseList =
							msLicenseInfo.value as UserLicenseListResponse[]

						if (msUserLicenseList && msUserLicenseList.length > 0) {
							// Make call to DB to retrieve MS License SKUs
							var msTeamsLicenseSKUsResponse = (await fetchData({
								FileAndFunctionName:
									'CustomerDashboardPrechecks.tsx: getUserLicenses()',
								QueryURL: `GetV2?Params=MSTeamsLicenseSKU.All()`,
								ErrorMessage: 'Unable to retrieve MSTeams License SKUs',
								ShowErrorToast: false,
								LogErrorToDB: true,
								ReturnType: ReturnTypes.ObjectOrList,
							})) as DataResponse

							if (
								msTeamsLicenseSKUsResponse &&
								Number(msTeamsLicenseSKUsResponse.Count) > 0 &&
								msTeamsLicenseSKUsResponse.Obj
							) {
								var msTeamsLicenseSKUResponse = msTeamsLicenseSKUsResponse.Obj
									.MSTeamsLicenseSKUList as MSTeamsLicenseSKU[]

								// Check response
								if (
									msTeamsLicenseSKUResponse &&
									msTeamsLicenseSKUResponse.length > 0
								) {
									var licenseOverviewResponse = filterLicenses(
										msUserLicenseList,
										msTeamsLicenseSKUResponse
									)

									if (licenseOverviewResponse) {
										licenseOverview = licenseOverviewResponse
									}
								}
							}
						}
					}
				}
			}
		}

		return licenseOverview
	}

	// GET: Return the teams token, then use it to return the line URI and routing plan details
	// Return - lineURIOverview: MicrosoftLineURIOverview
	const getLineURIAndRoutingPlanDetails = async (
		msTeamsUser: MSTeamsUser,
		userDDIAssignmentList: UserDDIs[],
		tenantConfigurationInfo: TenantConfigurationInfo
	) => {
		// Return variable
		var lineURIOverview = {} as MicrosoftLineURIOverview

		// Variables
		var teamsToken = ''

		// Get graph token using the MS Teams Tenant ID
		if (msTeamsUser.TenantID && msTeamsUser.TenantID.length > 0) {
			// Make call
			if (!postCustomEndpointLoading && !postCustomEndpointError) {
				// Request Model
				var tokenRequest: TokenRequest = {
					TokenType: TokenType.TeamsToken,
					TenantID: msTeamsUser.TenantID + '',
				}

				var customPostHookObj: CustomPostHookObj = {
					Action: 'Teams Token',
					RequestURL: 'GetTokenForTeamsUserManagement',
					RequestObj: tokenRequest,
					ShowSuccessMessage: false,
					ShowErrorMessage: false,
					ErrorMessage: 'An error occurred when retrieving the graph token',
					LogErrorToDB: true,
				}

				var customPostResponse = await postCustomEndpoint(customPostHookObj)

				if (customPostResponse) {
					var teamsTokenResponse = JSON.parse(
						await toAlphaString(customPostResponse)
					)
					if (teamsTokenResponse) {
						teamsToken = teamsTokenResponse
					}
				}
			}
		}

		if (teamsToken.length > 0) {
			// Get the line URI and Routing Plan from microsoft
			// Request Obj
			var getLineURIAndRoutingPlanObj = {
				TeamsToken: teamsToken,
			}

			var msLineURIHookObj: CustomPostHookObj = {
				Action: 'Microsoft Line URI and Routing Plans',
				RequestURL: 'GetTeamsLineURIAndVRP',
				RequestObj: getLineURIAndRoutingPlanObj,
				ShowSuccessMessage: false,
				ShowErrorMessage: false,
				ErrorMessage:
					'An error occurred when retrieving the line URI and routing plans from Microsoft',
				LogErrorToDB: true,
			}

			var msLineURIDataResponse = await postCustomEndpoint(msLineURIHookObj)

			if (msLineURIDataResponse) {
				// Decrypt to object list
				var msLineURIResponseList = JSON.parse(
					await toAlphaString(msLineURIDataResponse)
				) as MSLineURIAndRoutingPlanUsers[]

				if (msLineURIResponseList && msLineURIResponseList.length > 0) {
					// Assign the lists
					lineURIOverview.LineURIAndRoutingPlanList = msLineURIResponseList

					// Validate the VRP and DDIs
					lineURIOverview.HasVRPIssue = validateDDIsAndVRP(
						msLineURIResponseList,
						userDDIAssignmentList,
						tenantConfigurationInfo
					)
				}
			}
		}

		return lineURIOverview
	}

	// GET: Return the teams users list from Microsoft
	// Return - msUserList: UserManagementResponseList[]
	const getUserManagementDetails = async (msTeamsUser: MSTeamsUser) => {
		// Return variables
		var msUserList = [] as UserManagementResponseList[]

		// Get the graph token first
		var graphToken = ''

		// Get graph token using the MS Teams Tenant ID
		if (msTeamsUser.TenantID && msTeamsUser.TenantID.length > 0) {
			// Make call
			if (!postCustomEndpointLoading && !postCustomEndpointError) {
				// Request Model
				var tokenRequest: TokenRequest = {
					TokenType: TokenType.GraphToken,
					TenantID: msTeamsUser.TenantID + '',
				}

				var customPostHookObj: CustomPostHookObj = {
					Action: 'Teams Token',
					RequestURL: 'GetTokenForTeamsUserManagement',
					RequestObj: tokenRequest,
					ShowSuccessMessage: false,
					ShowErrorMessage: false,
					ErrorMessage: 'An error occurred when retrieving the graph token',
					LogErrorToDB: true,
				}

				var customPostResponse = await postCustomEndpoint(customPostHookObj)

				if (customPostResponse) {
					var graphTokenResponse = JSON.parse(
						await toAlphaString(customPostResponse)
					)
					if (graphTokenResponse) {
						graphToken = graphTokenResponse
					}
				}
			}
		}

		// Use graph token to make call
		if (graphToken && graphToken.length > 0) {
			// Request Obj
			var getUsersObj = {
				GraphToken: graphToken,
			}

			var msUsersHookObj: CustomPostHookObj = {
				Action: 'Get Microsoft Teams Users',
				RequestURL: 'GetTeamsUsers',
				RequestObj: getUsersObj,
				ShowSuccessMessage: false,
				ShowErrorMessage: false,
				ErrorMessage:
					'An error occurred when retrieving your teams users from microsoft',
				LogErrorToDB: true,
			}

			var msUsersDataResponse = await postCustomEndpoint(msUsersHookObj)

			if (msUsersDataResponse) {
				// Decrypt to object list
				msUserList = JSON.parse(
					await toAlphaString(msUsersDataResponse)
				) as UserManagementResponseList[]
			}
		}

		return msUserList
	}

	const filterLicenses = (
		userLicenseList: UserLicenseListResponse[],
		msTeamsLicenseSKUList: MSTeamsLicenseSKU[]
	) => {
		// Return variables
		const teamsPhoneStandardSKUID = 'e43b5b99-8dfb-405f-9987-dc307f34bcbd'
		var totalPurchased = 0
		var totalAvailable = 0
		var totalTeamsPhoneStandard = 0
		var availableTeamsPhoneStandard = 0

		// Loop through user licenses and only assign the ones that match the MS License SKUs
		for (var l = 0; l < userLicenseList.length; l++) {
			// Check if it exists in CDS
			if (userLicenseList[l].skuId) {
				var existsInCDS = msTeamsLicenseSKUList.find(
					// eslint-disable-next-line no-loop-func
					(sku) =>
						sku.MSTeamsLicenseSKUID + '' === userLicenseList[l].skuId + ''
				)

				if (existsInCDS) {
					var numberLicenseAvailable = 0

					var numberLicensePurchased = Number(
						userLicenseList[l].prepaidUnits?.enabled
					)
					totalPurchased += numberLicensePurchased

					if (Number(userLicenseList[l].consumedUnits) >= 0) {
						numberLicenseAvailable =
							numberLicensePurchased - Number(userLicenseList[l].consumedUnits)
						totalAvailable += numberLicenseAvailable
					}

					//Get Teams Phone standard licences
					if (userLicenseList[l]?.skuId + '' === teamsPhoneStandardSKUID) {
						totalTeamsPhoneStandard = Number(
							userLicenseList[l].prepaidUnits?.enabled
						)

						if (Number(userLicenseList[l].consumedUnits) >= 0) {
							availableTeamsPhoneStandard =
								totalTeamsPhoneStandard -
								Number(userLicenseList[l].consumedUnits)
						}
					}
				}
			}
		}

		var microsoftLicenseOverview: SDALicenseOverview = {
			TotalAvailableLicenses: totalAvailable,
			TotalPurchasedLicenses: totalPurchased,
			TeamsPhoneStandardLicencesAvailable: availableTeamsPhoneStandard,
			TeamsPhoneStandardLicencesTotal: totalTeamsPhoneStandard,
		}

		return microsoftLicenseOverview
	}

	// Validate the DDIs and VRP
	const validateDDIsAndVRP = (
		msLineURIAndRoutingPlanList: MSLineURIAndRoutingPlanUsers[],
		userDDIAssignmentList: UserDDIs[],
		TenantConfigurationInfo: TenantConfigurationInfo
	) => {
		// Return variable
		var hasVRPIssue = false

		// Check for our VRP
		for (var i = 0; i < msLineURIAndRoutingPlanList.length; i++) {
			if (
				TenantConfigurationInfo?.Regions &&
				TenantConfigurationInfo.Regions.length > 0
			) {
				var isOurVRP = TenantConfigurationInfo?.Regions.find(
					// eslint-disable-next-line no-loop-func
					(region) =>
						region.DomVoiceRoutingPolicy + '' ===
							msLineURIAndRoutingPlanList[i].onlineVoiceRoutingPolicy + '' ||
						region.IntVoiceRoutingPolicy + '' ===
							msLineURIAndRoutingPlanList[i].onlineVoiceRoutingPolicy + '' ||
						region.EmergencyVoiceRoutingPolicy + '' ===
							msLineURIAndRoutingPlanList[i].onlineVoiceRoutingPolicy + ''
				)

				if (isOurVRP) {
					// Check if number belongs to us
					var isOurDDI = userDDIAssignmentList.find(
						// eslint-disable-next-line no-loop-func
						(ddi) =>
							ddi.ddi + '' === msLineURIAndRoutingPlanList[i].lineUri + ''
					)
					if (!isOurDDI) {
						hasVRPIssue = true
					}
				}
			}
		}

		// Return
		return hasVRPIssue
	}

	// Return
	return {
		getTeamsServicesAndCustomerServices,
		getSDAOrderSKU,
		getServiceManagementStatus,
		getServiceManagementReport,
		getUserLicenses,
		getLineURIAndRoutingPlanDetails,
		getUserManagementDetails,
	}
}
