import { Box, Divider, Modal, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { useEffect, useState } from 'react'
import UseCrud from '../../../utils/customHooks/APICalls/UseCrud'
import {
	CustomerStatuses,
	ReturnTypes,
	Permissions,
} from '../../../utils/enums/enums'
import { DataResponse } from '../../../utils/interfaces/APIModels'
import { Customer } from '../../../utils/interfaces/DBModels'
import { CustomersDisplay } from '../../../utils/interfaces/ComponentModels'
import CustomersOverview from '../../../components/shared/pageShared/CustomersOverview/CustomersOverview'
import LoadingBox from '../../../components/shared/displays/LoadingBox/LoadingBox'
import { StyledButton } from '../../../styles/styledComponents/inputs/StyledButton'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import StyledModal from '../../../styles/styledComponents/displays/StyledModal'
import AddCustomer from '../../../components/shared/pageShared/CustomersOverview/AddCustomers/AddCustomer'
import { PermissionChecker } from '../../../utils/customHooks/PermissionChecker/PermissionChecker'

function CustomerManagement() {
	// Global variables
	const partnerID = useSelector(
		(state: RootState) => state.RootReducer.partnerIDReducer.value
	)

	// Permission Checker
	const hasPermission = PermissionChecker()

	// Hooks
	const { fetchData } = UseCrud()

	// Flags
	const [customerCallMade, setCustomerCallMade] = useState(false)
	const [apiCallsDone, setAPICallsDone] = useState(false)
	const [openAddCustomerFormModal, setOpenAddCustomerFormModal] =
		useState(false)

	// Display constants
	const [customersDisplay, setCustomersDisplay] = useState(
		[] as CustomersDisplay[]
	)

	useEffect(() => {
		// Make call if not already made
		if (!customerCallMade) {
			setCustomerCallMade(true)
			getCustomers()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerCallMade, apiCallsDone])

	// *** API Calls *** //
	// GET: Return customers based on partnerID
	const getCustomers = async () => {
		// Make call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'CustomerManagement.tsx: getCustomers()',
			QueryURL: `GetV2?Params=Customer.CustomerPartner.Where(Customer.CustomerPartnerID = '${partnerID}')`,
			ErrorMessage: 'An error occurred when getting order information',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var customerResponse = dataResponse.Obj.CustomerList as Customer[]

			if (customerResponse && customerResponse.length > 0) {
				// Display list to build
				var customersDisplay = [] as CustomersDisplay[]

				// Loop through response list
				for (let c = 0; c < customerResponse.length; c++) {
					// Push to list
					customersDisplay.push({
						CustomerID: customerResponse[c].CustomerID,
						CustomerName: customerResponse[c].CustomerName,
						CustomerStatus:
							CustomerStatuses[Number(customerResponse[c].CustomerStatusID)],
						CustomerPartnerReference:
							customerResponse[c].CustomerPartnerReference,
					})
				}

				// Set list
				setCustomersDisplay(customersDisplay)
			}
		}

		// Stop loading
		setAPICallsDone(true)
	}

	//handle functions
	//add customer modal form

	const handleOpenAddCustomerFormModal = () => {
		setOpenAddCustomerFormModal(true)
	}

	const handleCloseAddCustomerFormModal = () => {
		setOpenAddCustomerFormModal(false)
	}

	const handleReloadData = () => {
		setCustomerCallMade(false)
	}

	return apiCallsDone ? (
		<>
			{/* add new customer section */}
			{/* Check permissions and display only if role is Partner Sales or Partner Admin */}
			{hasPermission(Permissions.AddCustomer) && (
				<Box className='add-customer-section'>
					<Typography component='h2' className='add-customer-heading'>
						Add new customer
					</Typography>
					<Box className='add-customer-description-button'>
						<Typography component='p' className='description'>
							You can add a new customer here.
						</Typography>
						<StyledButton
							startIcon={<PersonAddAlt1Icon />}
							variant='outlined'
							onClick={handleOpenAddCustomerFormModal}>
							Add New Customer
						</StyledButton>
					</Box>
					<Divider />
				</Box>
			)}
			<Box id='customers'>
				<CustomersOverview customersDisplay={customersDisplay} />
			</Box>
			{/* open add modal */}
			<Modal
				open={openAddCustomerFormModal}
				onClose={handleCloseAddCustomerFormModal}>
				<StyledModal width={700}>
					<Box className='order-form-content'>
						<AddCustomer
							reloadData={handleReloadData}
							handleCloseAddCustomerFormModal={handleCloseAddCustomerFormModal}
						/>
					</Box>
				</StyledModal>
			</Modal>
		</>
	) : (
		// Show loading
		<LoadingBox title='Getting Customers' />
	)
}

export default CustomerManagement
