import { Box, MenuItem, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import {
	LeadStatus,
	LeadTypes,
	LeadWeightEnum,
	OCLeadStatus,
	Partners,
} from '../../../../utils/enums/enums'
import { UpdateLeadRequest } from '../../../../utils/interfaces/APIModels'
import { StyledSelect } from '../../../../styles/styledComponents/inputs/StyledSelect'
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined'
import { ModalForm } from '../../../shared/inputs/ModalForm/ModalForm'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { UpdateLeadDisplay } from '../../../../utils/interfaces/ComponentModels'
import { validateField } from '../../../../utils/helperFunctions/helperFunctions'
import { StyledTextField } from '../../../../styles/styledComponents/inputs/StyledTextField'

const UpdateLead = ({
	leadToUpdate,
	handleCloseUpdateLeadModal,
	onUpdate,
}: {
	leadToUpdate: UpdateLeadDisplay
	handleCloseUpdateLeadModal: any
	onUpdate: (updateLeadRequestModel: UpdateLeadRequest) => Promise<void>
}) => {
	var partnerID = useSelector(
		(state: RootState) => state.RootReducer.partnerIDReducer.value
	)

	const [relationshipStatus, setRelationshipStatus] = useState('')
	const [relationshipStatusId, setRelationshipStatusId] = useState(0)
	const [comment, setComment] = useState('')
	const [btnLoading, setBtnLoading] = useState(false)
	const [partnerName] = useState(Partners[partnerID])
	const [leadStatus, setLeadStatus] = useState(leadToUpdate.LeadStatus + '')
	const [salesAgentEmail, setSalesAgentEmail] = useState(
		leadToUpdate.SalesAgentEmail + ''
	)
	const [externalSalesAgentError, setExternalSalesAgentError] = useState('')
	const [hasChanged, setHasChanged] = useState(false)

	var isOCLead = leadToUpdate.LeadType === LeadTypes[LeadTypes.OC]

	const [ocRelationshipOptions, setOCRelationshipOptions] = useState([
		{ label: 'Consent Not Submitted', value: OCLeadStatus.ConsentNotSubmitted },
		{ label: 'Consent Acknowledged', value: OCLeadStatus.ConsentAcknowledged },
		{ label: 'Customer Contacted', value: OCLeadStatus.CustomerContacted },
		{ label: 'Consent Declined', value: OCLeadStatus.ConsentDeclined },
		{ label: 'Contract Terminated', value: OCLeadStatus.ContractTerminated },
	])

	const [leadStatusOptions, setLeadStatusOptions] = useState([
		{ label: LeadStatus[LeadStatus.Live], value: LeadStatus[LeadStatus.Live] },
		{ label: LeadStatus[LeadStatus.Dead], value: LeadStatus[LeadStatus.Dead] },
	])

	const [leadStageId, setLeadStageId] = useState(0)
	const [leadStageValue, setLeadStageValue] = useState('')
	const [initialLeadStageId, setInitialLeadStageId] = useState(0)
	const [initialLeadStageValue, setInitialLeadStageValue] = useState('')
	const [leadWeightOptions, setLeadWeightOptions] = useState([
		{ value: LeadWeightEnum.Pipeline, label: 'Pipeline - 25%' },
		{ value: LeadWeightEnum['Best Case'], label: 'Best Case - 50%' },
		{ value: LeadWeightEnum.Commit, label: 'Commit - 75%' },
		{ value: LeadWeightEnum['Closed Won'], label: 'Closed Won - 100%' },
		{ value: LeadWeightEnum['Closed Lost'], label: 'Closed Lost - 0%' },
	])

	const handleOCLeadStatusIDChange = (e: { target: any }) => {
		var value = e.target.value
		setRelationshipStatusId(value)
		setRelationshipStatus(OCLeadStatus[value].replace(/\s/g, ''))

		if (
			value === OCLeadStatus.ConsentDeclined ||
			value === OCLeadStatus.ContractTerminated
		) {
			setLeadStatus('Dead')

			// only allowed option for consent declined and contract terminated
			setLeadWeightOptions([
				{ value: LeadWeightEnum['Closed Lost'], label: 'Closed Lost - 0%' },
			])
			setLeadStageId(LeadWeightEnum['Closed Lost'])
			setLeadStageValue(LeadWeightEnum[LeadWeightEnum['Closed Lost']])
		} else {
			setLeadStatus('Live')

			setComment('')
			// reset lead weight options
			setLeadWeightOptions([
				{ value: LeadWeightEnum.Pipeline, label: 'Pipeline - 25%' },
				{ value: LeadWeightEnum['Best Case'], label: 'Best Case - 50%' },
				{ value: LeadWeightEnum.Commit, label: 'Commit - 75%' },
				{ value: LeadWeightEnum['Closed Won'], label: 'Closed Won - 100%' },
				{ value: LeadWeightEnum['Closed Lost'], label: 'Closed Lost - 0%' },
			])
			setLeadStageId(initialLeadStageId)
			setLeadStageValue(initialLeadStageValue)
		}
		setHasChanged(true)
	}

	const handleLeadStageIDChange = (e: { target: any }) => {
		var value = e.target.value
		setLeadStageId(value)
		setLeadStageValue(
			(leadWeightOptions.find((x) => x.value === value)?.label + '')
				.split('-')[0]
				.trim()
		)
		setHasChanged(true)
		if (value === LeadWeightEnum['Closed Lost']) {
			// remove live from dropdown
			setLeadStatusOptions([
				{
					label: LeadStatus[LeadStatus.Dead],
					value: LeadStatus[LeadStatus.Dead],
				},
			])
			setLeadStatus('Dead')
		} else {
			setLeadStatusOptions([
				{
					label: LeadStatus[LeadStatus.Live],
					value: LeadStatus[LeadStatus.Live],
				},
				{
					label: LeadStatus[LeadStatus.Dead],
					value: LeadStatus[LeadStatus.Dead],
				},
			])

			setLeadStatus('Live')
		}
	}

	const handleCommentChange = (e: { target: any }) => {
		setComment(e.target.value + '')
		setHasChanged(true)
	}

	const handleLeadStatusChange = (e: { target: any }) => {
		setLeadStatus(e.target.value + '')
		setHasChanged(true)

		if (e.target.value === LeadStatus[LeadStatus.Dead]) {
			// only allowed option for lead stage is closed lost
			setLeadWeightOptions([
				{ value: LeadWeightEnum['Closed Lost'], label: 'Closed Lost - 0%' },
			])
			setLeadStageId(LeadWeightEnum['Closed Lost'])
			setLeadStageValue(LeadWeightEnum[LeadWeightEnum['Closed Lost']])
		} else {
			setLeadWeightOptions([
				{ value: LeadWeightEnum.Pipeline, label: 'Pipeline - 25%' },
				{ value: LeadWeightEnum['Best Case'], label: 'Best Case - 50%' },
				{ value: LeadWeightEnum.Commit, label: 'Commit - 75%' },
				{ value: LeadWeightEnum['Closed Won'], label: 'Closed Won - 100%' },
				{ value: LeadWeightEnum['Closed Lost'], label: 'Closed Lost - 0%' },
			])
			setLeadStageId(initialLeadStageId)
			setLeadStageValue(initialLeadStageValue)
		}
	}

	const handleSalesAgentEmailChange = (e: { target: any }) => {
		setSalesAgentEmail(e.target.value + '')
		setExternalSalesAgentError(
			validateField('externalSalesAgent', e.target.value + '')
		)
		setHasChanged(true)
	}

	const updateStatus = async () => {
		if (!hasChanged) {
			return
		}

		setBtnLoading(true)
		var updateRequestModel: UpdateLeadRequest = {
			PartnerName: partnerName,
			LeadID: leadToUpdate.LeadID,
			SalesAgentEmail:
				salesAgentEmail.trim().length > 1 ? salesAgentEmail : null,
			Comment: comment,
			RelationshipStatus:
				relationshipStatus !== OCLeadStatus[OCLeadStatus.ConsentNotSubmitted] &&
				leadToUpdate.LeadType === LeadTypes[LeadTypes.OC]
					? relationshipStatus
					: null,
			LeadStatus: leadStatus,
			LeadStage: leadStageValue.split('-')[0].trim(),
		}
		await onUpdate(updateRequestModel)
		setBtnLoading(false)
		setHasChanged(false)
		handleCloseUpdateLeadModal()
	}

	useEffect(() => {
		if (leadToUpdate.SalesAgentEmail!.length < 5) {
			setExternalSalesAgentError(
				'Kindly provide a sales agent email address for this lead.'
			)
		}

		if (
			leadToUpdate.LeadType === LeadTypes[LeadTypes.OC] &&
			leadToUpdate.OCRelationshipStatusID !== null
		) {
			const relationshipStatusID: number = Number(
				leadToUpdate.OCRelationshipStatusID
			)
			const relationshipStatusLabel: string = OCLeadStatus[relationshipStatusID]

			setRelationshipStatusId(relationshipStatusID)
			setRelationshipStatus(relationshipStatusLabel)
			setLeadStatus(`${leadToUpdate.LeadStatus}`)

			let ocLeadStatusOptions: { label: string; value: number }[] = []

			if (relationshipStatusID === OCLeadStatus.ConsentNotSubmitted) {
				ocLeadStatusOptions = [
					{
						label: 'Consent Not Submitted',
						value: OCLeadStatus.ConsentNotSubmitted,
					},
					{
						label: 'Consent Acknowledged',
						value: OCLeadStatus.ConsentAcknowledged,
					},
				]
			} else if (relationshipStatusID === OCLeadStatus.ConsentAcknowledged) {
				ocLeadStatusOptions = [
					{
						label: 'Consent Acknowledged',
						value: OCLeadStatus.ConsentAcknowledged,
					},
				]
			}

			ocLeadStatusOptions = [
				...ocLeadStatusOptions,
				{ label: 'Customer Contacted', value: OCLeadStatus.CustomerContacted },
				{ label: 'Consent Declined', value: OCLeadStatus.ConsentDeclined },
				{
					label: 'Contract Terminated',
					value: OCLeadStatus.ContractTerminated,
				},
			]

			setOCRelationshipOptions(ocLeadStatusOptions)
		}

		if ((leadToUpdate.LeadStage + '').length > 0) {
			var leadOption = leadWeightOptions.find(
				(x) => x.label.split('-')[0].trim() === leadToUpdate.LeadStage + ''
			)

			if (leadOption) {
				setLeadStageId(leadOption.value)
				setLeadStageValue(leadOption.label)

				// set initial lead stage
				setInitialLeadStageId(leadOption.value)
				setInitialLeadStageValue(leadOption.label)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [leadToUpdate])

	const canUpdateLead = () => {
		if (hasChanged === false || externalSalesAgentError.length > 0) {
			return false
		}

		// Check for OC Lead specific conditions
		if (leadToUpdate.LeadType === LeadTypes[LeadTypes.OC]) {
			if (
				(comment.trim().length === 0 &&
					(relationshipStatus === OCLeadStatus[OCLeadStatus.ConsentDeclined] ||
						relationshipStatus ===
							OCLeadStatus[OCLeadStatus.ContractTerminated])) ||
				relationshipStatus.length === 0
			) {
				return false
			}
		}
		return true
	}

	return (
		<ModalForm
			icon={<SettingsApplicationsOutlinedIcon />}
			title='Update Lead'
			description='Update current lead information'
			buttonText='Update Lead'
			buttonAction={updateStatus}
			buttonLoading={btnLoading}
			buttonDisabled={!canUpdateLead()}
			closeModal={handleCloseUpdateLeadModal}>
			{/* Form Group - Sales Agent */}
			<Box className='form-group'>
				<Typography component='p' className='form-label'>
					Sales Agent
				</Typography>
				<StyledTextField
					fullWidth
					type='text'
					placeholder='Sales Agent'
					value={salesAgentEmail}
					onChange={handleSalesAgentEmailChange}
				/>
				{externalSalesAgentError && (
					<Typography variant='body2' color='error'>
						{externalSalesAgentError}
					</Typography>
				)}
			</Box>

			{/* Form Group - Lead Type */}
			<Box className='form-group'>
				<Typography component='p' className='form-label'>
					Lead Type
				</Typography>
				<StyledTextField
					disabled
					fullWidth
					type='text'
					placeholder='Lead Type'
					value={leadToUpdate.LeadType}
					onChange={handleSalesAgentEmailChange}
				/>
			</Box>

			{/* Form Group - Tenant ID */}
			{isOCLead && (
				<Box className='form-group'>
					<Typography component='p' className='form-label'>
						Tenant ID
					</Typography>
					<StyledTextField
						disabled
						fullWidth
						type='text'
						placeholder='Tenant ID'
						value={leadToUpdate.TenantID}
					/>
				</Box>
			)}

			{/* Form Group - Lead Stage */}
			<Box className='lead-form-input'>
				<Typography component='p'>Lead Stage</Typography>
				<StyledSelect
					fullWidth
					type='text'
					value={leadStageId}
					placeholder='Lead Stage'
					onChange={handleLeadStageIDChange}>
					{leadWeightOptions.map((option, index) => (
						<MenuItem key={index} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</StyledSelect>
			</Box>

			{/* Form Group - Lead Status */}
			<Box className='form-group'>
				<Typography component='p' className='form-label'>
					Lead Status
				</Typography>
				<StyledSelect
					fullWidth
					type='text'
					disabled={
						LeadTypes[LeadTypes.OC] === leadToUpdate.LeadType &&
						relationshipStatusId !== OCLeadStatus.ConsentNotSubmitted
					}
					value={leadStatus}
					placeholder='Lead Status'
					onChange={handleLeadStatusChange}>
					{leadStatusOptions.map((option, index) => (
						<MenuItem key={index} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</StyledSelect>
				{LeadTypes[LeadTypes.OC] === leadToUpdate.LeadType &&
					relationshipStatusId !== OCLeadStatus.ConsentNotSubmitted && (
						<Typography variant='body2' color='gray'>
							Lead status will be updated based on Operator Connect Status or
							Lead Stage
						</Typography>
					)}
			</Box>

			{/* Form Group - Operator Connect Status */}
			{isOCLead && (
				<Box className='form-group'>
					<Typography component='p' className='form-label'>
						Operator Connect Status
					</Typography>
					<StyledSelect
						fullWidth
						type='text'
						disabled={relationshipStatusId === OCLeadStatus.ConsentNotSubmitted}
						value={relationshipStatusId}
						placeholder='Operator Connect Status'
						onChange={handleOCLeadStatusIDChange}>
						{ocRelationshipOptions.map((option, index) => (
							<MenuItem key={index} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</StyledSelect>
				</Box>
			)}

			{/* Form Group - Comment */}
			{(relationshipStatus === OCLeadStatus[OCLeadStatus.ConsentDeclined] ||
				relationshipStatus === OCLeadStatus[OCLeadStatus.ContractTerminated]) &&
				isOCLead && (
					<Box className='form-group'>
						<Typography component='p' className='form-label'>
							Comment
							<Typography component='span' className='required'>
								*
							</Typography>
						</Typography>
						<StyledTextField
							fullWidth
							type='text'
							placeholder='Comment'
							multiline
							maxRows={4}
							inputProps={{ maxLength: 255 }}
							value={comment}
							onChange={(e) => handleCommentChange(e)}
						/>
					</Box>
				)}
		</ModalForm>
	)
}

export default UpdateLead
