// This file is for the theme of the site - manages all colours and font sizes
import { PaletteMode, createTheme } from '@mui/material'

// Initial theme - Only used for initial load
export const initialTheme = (mode: PaletteMode) =>
	createTheme({
		palette: {
			mode,
			primary: {
				main: '#5341B2',
				contrastText: '#fff',
			},
			secondary: {
				main: '#EDEAFF',
				contrastText: '#000',
			},
			// General - All the colours and font sizes on the site we will use
			background: {
				// Colours
				...(mode === 'light'
					? {
							default: '#fff',
							paper: '#f9f9fb',
					  }
					: {
							default: '#121212',
							paper: '#2e2e2e',
					  }),
			},
			text: {
				primary: '#000',
			},
			error: {
				main: '#FF3333',
			},
			success: {
				main: '#4BB543',
			},
		},
	})

// Create new theme based on returned values
export const getTheme = (
	mode: PaletteMode,
	primaryColour: string,
	primaryTextColour: string,
	secondaryColour: string,
	secondaryTextColour: string
) =>
	createTheme({
		palette: {
			mode,
			primary: {
				main: primaryColour,
				contrastText: primaryTextColour,
			},
			secondary: {
				main: secondaryColour,
				contrastText: secondaryTextColour,
			},
			// General - All the colours and font sizes on the site we will use
			background: {
				// Colours
				...(mode === 'light'
					? {
							default: '#fff',
							paper: '#f9f9fb',
					  }
					: {
							default: '#121212',
							paper: '#2e2e2e',
					  }),
			},
			text: {
				primary: '#000',
			},
			error: {
				main: '#FF3333',
			},
			success: {
				main: '#4BB543',
			},
		},
	})
