import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import rootReducer from './reducers/reducers'
import { proxyAPIData } from '../services/proxyAPIData'

export const store = configureStore({
	reducer: {
		// Add the generated reducer as a specific top-level slice
		[proxyAPIData.reducerPath]: proxyAPIData.reducer,
		RootReducer: rootReducer,
	},
	devTools: true,
	// Adding the api middleware enables caching, invalidation, polling,
	// and other useful features of `rtk-query`.
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false }).concat([
			proxyAPIData.middleware,
		]),
})
setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: e.g. {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
