import React from 'react'
import {
	Pagination,
	PaginationItem,
	PaginationRenderItemParams,
} from '@mui/material'

interface GenericPaginationProps {
	page: number
	pageCount: number
	onChange: (page: number) => void
}

export function GenericPagination({
	page,
	pageCount,
	onChange,
}: GenericPaginationProps) {
	const renderItem = (params: PaginationRenderItemParams) => {
		const { page, ...other } = params

		return <PaginationItem {...other} page={page} />
	}

	return (
		<Pagination
			color='primary'
			variant='outlined'
			shape='rounded'
			page={page + 1}
			count={pageCount}
			renderItem={renderItem}
			onChange={(event: React.ChangeEvent<unknown>, value: number) =>
				onChange(value - 1)
			}
		/>
	)
}
