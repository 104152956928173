import {
	Backdrop,
	Badge,
	Box,
	Divider,
	MenuItem,
	Modal,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material'
import {
	TeamsUserManagementFilter,
	TeamsUserManagementLicenseResponse,
	TeamsUsersResponse,
} from '../../../../../utils/interfaces/APIModels'
import './TUMDisplay.scss'
import TUMUserDisplay from './TUMUserDisplay/TUMUserDisplay'
import {
	TUMUpdateList,
	TUMVRPList,
} from '../../../../../utils/interfaces/ComponentModels'
import TUMOverview from './TUMOverview/TUMOverview'
import { StyledTextField } from '../../../../../styles/styledComponents/inputs/StyledTextField'
import { useEffect, useRef, useState } from 'react'
import { StyledSelect } from '../../../../../styles/styledComponents/inputs/StyledSelect'
import { TUMUserFilterOptions } from '../../../../../utils/enums/enums'
import { LoadingButton } from '@mui/lab'
import SearchIcon from '@mui/icons-material/Search'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import UpdateIcon from '@mui/icons-material/Update'
import {
	hexToRgba,
	showErrorToast,
} from '../../../../../utils/helperFunctions/helperFunctions'
import StyledModal from '../../../../../styles/styledComponents/displays/StyledModal'
import TUMUpdateHandler from './TUMUpdateHandler/TUMUpdateHandler'
import UseLocalStorage from '../../../../../utils/customHooks/LocalStorage/LocalStorage'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'

const TUMDisplay = ({
	customerID,
	licenseDisplay,
	usersDisplay,
	tumVRPList,
	currentPageNo,
	isLastPage,
	handlePageNavigation,
	getTeamsUsers,
	getTeamsLicenses,
	getDDIRanges,
	getUserAssignedNumbers,
	getDDIsForAssignment,
	getDDIServiceTypeID,
	configureTeamsUser,
}: {
	customerID: string
	licenseDisplay: TeamsUserManagementLicenseResponse
	usersDisplay: TeamsUsersResponse[]
	tumVRPList: TUMVRPList[]
	currentPageNo: number
	isLastPage: boolean
	handlePageNavigation: any
	getTeamsUsers: any
	getTeamsLicenses: any
	getDDIRanges: any
	getUserAssignedNumbers: any
	getDDIsForAssignment: any
	getDDIServiceTypeID: any
	configureTeamsUser: any
}) => {
	// Hooks
	const { data } = UseLocalStorage(`tum-${customerID}`)

	// Theme
	const theme = useTheme()

	// Display constants
	const [tumSearchFilter, setTUMSearchFilter] = useState('')
	const [tumSearchBy, setTUMSearchBy] = useState(
		TUMUserFilterOptions['Username (UPN)']
	)
	const [tumFilterObj, setTUMFilterObj] = useState(
		{} as TeamsUserManagementFilter
	)
	const [tumUpdateList, setTUMUpdateList] = useState([] as TUMUpdateList[])

	// Flags
	const [searchLoading, setSearchLoading] = useState(false)
	const [enableButton, setEnableButton] = useState(false)
	const [clearSearchLoading, setClearSearchLoading] = useState(false)
	const [openModal, setOpenModal] = useState(false)
	const [refreshLoading, setRefreshLoading] = useState(false)
	const searchButtonRef = useRef(null)

	// Use Effect handle check of updates
	useEffect(() => {
		// Get data for tum update
		if (data) {
			setTUMUpdateList(data as TUMUpdateList[])
		}
	}, [data])

	// Handle Functions
	const handleFilterBySelection = (e: { target: any }) => {
		setTUMSearchBy(e.target.value)
	}

	// Handle typing on search field
	const handleSearch = (e: { target: any }) => {
		var value = e.target.value
		setTUMSearchFilter(value)

		// Check if user typed 3 letters
		if (value && value.length >= 3) {
			setEnableButton(true)
		} else {
			setEnableButton(false)
		}
	}

	// Handle creating filter and making call
	const handleFilterUsers = async (filterValue?: string) => {
		// Start loading
		setSearchLoading(true)

		// If filter value is passed, use it
		var value = tumSearchFilter
		if (filterValue && filterValue.length > 0) {
			value = filterValue
		}

		// Create the filter by to start the fetch
		var filterField =
			tumSearchBy === TUMUserFilterOptions['Username (UPN)']
				? 'userPrincipalName'
				: 'displayName'
		var filter: TeamsUserManagementFilter = {
			FilterField: filterField,
			FilterValue: value,
		}
		setTUMFilterObj(filter)

		// Make call
		var userStatus = await getTeamsUsers('', filter)

		if (!userStatus) {
			// Show an error
			showErrorToast(
				'An error occurred when attempting to filter using your search. Please try again later.'
			)
		}

		// End loading
		setSearchLoading(false)
	}

	// Handle refresh of page
	const handlePageRefresh = async () => {
		// Start loading
		setRefreshLoading(true)

		// Make calls
		var userStatus = await getTeamsUsers('')
		var licenseStatus = await getTeamsLicenses()

		if (!userStatus || !licenseStatus) {
			// Show an error
			showErrorToast(
				'An error occurred when attempting to refresh your information. Please try again later.'
			)
		}

		// End loading
		setRefreshLoading(false)
	}

	// Handle clear of search
	const handleClearSearch = async () => {
		// Start loading
		setClearSearchLoading(true)

		//  Clear filter and make API Call
		var userStatus = await getTeamsUsers()

		if (!userStatus) {
			// Show error
		}

		// End loading
		setClearSearchLoading(false)
		clearVariables()
	}

	// Handle clear of all variables
	const clearVariables = () => {
		setTUMFilterObj({})
		setTUMSearchFilter('')
	}

	// Handle open of update handler popup
	const handleOpenUpdateHandler = () => {
		setOpenModal(true)
	}

	// Handle open of update handler popup
	const handleCloseUpdateHandler = () => {
		setOpenModal(false)
	}

	// Trigger search - This basically triggers the button without clicking it so it can search when the user clicks 'View User'
	const triggerSearch = (filterValue: string) => {
		handleCloseUpdateHandler()
		setTUMSearchFilter(filterValue)
		setEnableButton(true)
		handleFilterUsers(filterValue)
	}

	return (
		<>
			<Box className='tum-display-container'>
				{/* Teams User MGT Overview */}
				<TUMOverview
					licenseDisplay={licenseDisplay}
					getDDIRanges={getDDIRanges}
				/>
				{/* Divider */}
				<Divider />
				{/* Custom Toolbar */}
				<Box className='tum-display-toolbar'>
					{/* Filter options */}
					<Box className='tum-filters'>
						{/* Label */}
						<Typography component='p'>
							Filter <br />
							by:
						</Typography>
						{/* Filter by options */}
						<StyledSelect
							fullWidth
							type='text'
							value={tumSearchBy}
							onChange={handleFilterBySelection}>
							{/* Username */}
							<MenuItem
								key={TUMUserFilterOptions['Username (UPN)']}
								value={TUMUserFilterOptions['Username (UPN)']}>
								{TUMUserFilterOptions[TUMUserFilterOptions['Username (UPN)']]}
							</MenuItem>
							{/* Name */}
							<MenuItem
								key={TUMUserFilterOptions.Name}
								value={TUMUserFilterOptions.Name}>
								{TUMUserFilterOptions[TUMUserFilterOptions.Name]}
							</MenuItem>
						</StyledSelect>
						{/* Search field */}
						<StyledTextField
							fullWidth
							id='tum-search-field'
							type='text'
							value={tumSearchFilter}
							onChange={handleSearch}
						/>
						{/* Search button */}
						<Box className='tum-search-button'>
							<Tooltip
								title={
									enableButton
										? 'Search for user(s)'
										: 'You need to type in at least 3 letters to search'
								}>
								<span>
									<LoadingButton
										ref={searchButtonRef}
										disabled={!enableButton}
										loading={searchLoading}
										loadingPosition='start'
										startIcon={<SearchIcon />}
										variant='contained'
										onClick={() => handleFilterUsers()}>
										Search
									</LoadingButton>
								</span>
							</Tooltip>
						</Box>
						{/* Clear filters */}
						{tumFilterObj.FilterValue && tumFilterObj.FilterField && (
							<LoadingButton
								loading={clearSearchLoading}
								startIcon={<ClearOutlinedIcon />}
								variant='text'
								onClick={handleClearSearch}>
								Clear Search
							</LoadingButton>
						)}
					</Box>
					{/* Updates and refresh */}
					<Box className='tum-update-refresh'>
						{/* Refresh button */}
						<Box>
							<LoadingButton
								loading={refreshLoading}
								loadingPosition='start'
								startIcon={<RefreshOutlinedIcon />}
								onClick={handlePageRefresh}>
								Refresh Data
							</LoadingButton>
						</Box>
						{/* Updates handler */}
						<Tooltip title='View status of updates for actions performed on this page'>
							<Box
								className='tum-updates'
								borderColor={`${hexToRgba(
									theme.palette.primary.main,
									0.2
								)}!important`}
								onClick={handleOpenUpdateHandler}>
								{/* Icon */}
								<Badge badgeContent={tumUpdateList.length} color='primary'>
									<UpdateIcon />
								</Badge>
								{/* Text */}
								<Typography component='p'>View Update Status</Typography>
							</Box>
						</Tooltip>
					</Box>
				</Box>
				{/* Users Table */}
				<Box className='tum-users-content'>
					<TUMUserDisplay
						customerID={customerID}
						usersDisplay={usersDisplay}
						licenseDisplay={licenseDisplay}
						vrpList={tumVRPList}
						currentPageNo={currentPageNo}
						isLastPage={isLastPage}
						handlePageNavigation={handlePageNavigation}
						getDDIRanges={getDDIRanges}
						getUserAssignedNumbers={getUserAssignedNumbers}
						getDDIsForAssignment={getDDIsForAssignment}
						getDDIServiceTypeID={getDDIServiceTypeID}
						configureTeamsUser={configureTeamsUser}
					/>
				</Box>
			</Box>
			{/* Modal - Update checker */}
			<Modal open={openModal}>
				<StyledModal width={600}>
					<TUMUpdateHandler
						customerID={customerID}
						handleCloseModal={handleCloseUpdateHandler}
						triggerSearch={triggerSearch}
					/>
				</StyledModal>
			</Modal>
			{/* Backdrop */}
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={refreshLoading}
			/>
		</>
	)
}

export default TUMDisplay
