import {
	Avatar,
	Box,
	Divider,
	Typography,
	Checkbox,
	FormControlLabel,
	IconButton,
	Tooltip,
	useTheme,
	Popover,
	Skeleton,
	MenuItem,
	Alert,
} from '@mui/material'
import { ModalForm } from '../../../../../../shared/inputs/ModalForm/ModalForm'
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined'
import {
	findArrayValue,
	stringAvatar,
} from '../../../../../../../utils/helperFunctions/helperFunctions'
import { useState, ChangeEvent, useEffect } from 'react'
import {
	AddonLicenseResponse,
	BaseLicenseResponse,
	DataResponse,
	TeamsUserManagementConfigurationRequest,
	TeamsUsersResponse,
	TUMRangeResponse,
} from '../../../../../../../utils/interfaces/APIModels'
import BaseLicenseAutocomplete from './BaseLicenseAutocomplete/BaseLicenseAutocomplete'
import PhoneAutocomplete from './PhoneAutocomplete/PhoneAutocomplete'
import AddonLicenseAutocomplete from './AddonLicenseAutocomplete/AddonLicenseAutocomplete'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import './TUMEditForm.scss'
import BaseLicenseTip from './Tooltips/BaseLicenseTip'
import PhoneNumberTip from './Tooltips/PhoneNumberTip'
import {
	ReturnTypes,
	TUMConfigurationTypes,
	TUMPhoneNumberTypes,
} from '../../../../../../../utils/enums/enums'
import UseCrud from '../../../../../../../utils/customHooks/APICalls/UseCrud'
import { DDI, DDIRange } from '../../../../../../../utils/interfaces/DBModels'
import {
	TUMDDIsForAssignment,
	TUMUserDDIRangeList,
	TUMVRPList,
} from '../../../../../../../utils/interfaces/ComponentModels'
import { v4 as uuidv4 } from 'uuid'
import { StyledSelect } from '../../../../../../../styles/styledComponents/inputs/StyledSelect'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const TUMEditForm = ({
	customerID,
	currentUser,
	baseLicenses,
	addonLicenses,
	vrpList,
	closeForm,
	getDDIRanges,
	getUserAssignedNumbers,
	getDDIsForAssignment,
	getDDIServiceTypeID,
	configureTeamsUser,
}: {
	customerID: string
	currentUser: TeamsUsersResponse
	// Lists
	baseLicenses: BaseLicenseResponse[]
	addonLicenses: AddonLicenseResponse[]
	vrpList: TUMVRPList[]
	// Actions
	closeForm: any
	getDDIRanges: any
	getUserAssignedNumbers: any
	getDDIsForAssignment: any
	getDDIServiceTypeID: any
	configureTeamsUser: any
}) => {
	// Hooks
	const { fetchData } = UseCrud()

	// Theme
	const theme = useTheme()

	// User Information
	const [user] = useState<TeamsUsersResponse>(currentUser)

	// Form Constants
	const [baseLicense, setBaseLicense] = useState('')
	const [addonLicense, setAddonLicense] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [internationCalling, setInternationalCalling] = useState(false)
	const [removeBaseLicense, setRemoveBaseLicense] = useState(false)
	const [removeAddonLicense, setRemoveAddonLicense] = useState(false)
	const [removePhoneNumber, setRemovePhoneNumber] = useState(false)
	const [ddiRangeID, setDDIRangeID] = useState(0)
	const [tumUserDDIRangeList, setTUMUserDDIRangeList] = useState(
		[] as TUMUserDDIRangeList[]
	)
	const [assignedNumbersString, setAssignedNumbersString] = useState('')
	const [phoneError, setPhoneError] = useState('')

	// Lists
	const [tumVRPList] = useState<TUMVRPList[]>(vrpList)
	const [tumDDIAssignmentList, setTUMAssignmentList] = useState(
		[] as TUMDDIsForAssignment[]
	)

	// Flags
	const [btnLoading, setBtnLoading] = useState(false)
	const [isAddonRequired, setIsAddonRequired] = useState(false)
	const [showPhoneNumber, setShowPhoneNumber] = useState(false)
	const [phoneLoading, setPhoneLoading] = useState(false)
	const [initialDDIBuildLoading, setInitialDDIBuildLoading] = useState(false)
	const [buildDDILoading, setBuildDDILoading] = useState(false)
	const [phoneCallDone, setPhoneCallDone] = useState(false)

	// Popover Flags
	const [baseAnchorEl, setBaseAnchorEl] = useState(null)
	const [phoneAnchorEl, setPhoneAnchorEl] = useState(null)
	const openBaseInfo = Boolean(baseAnchorEl)
	const openPhoneInfo = Boolean(phoneAnchorEl)
	const baseInfoID = openBaseInfo ? 'base-popover' : undefined
	const phoneInfoID = openPhoneInfo ? 'phone-popover' : undefined

	useEffect(() => {
		// Validate
		if (currentUser) {
			setFormConstants()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser])

	// Set form constants based on current fields
	const setFormConstants = () => {
		// Validate licenses to check if we can show the phone number fields
		validateLicenseForPhone(
			'baseLicenseSKUID',
			currentUser.baseLicenseSKUID,
			'addonLicenseSKUID',
			currentUser.addonLicenseSKUID,
			true
		)
	}

	// Validate licenses for phone number display
	const validateLicenseForPhone = async (
		baseLicenseKey?: keyof BaseLicenseResponse,
		baseLicenseValue?: string,
		addonLicenseKey?: keyof AddonLicenseResponse,
		addonLicenseValue?: string,
		initialCheck?: boolean
	) => {
		// Check if initial check to start loading
		if (initialCheck) {
			setPhoneLoading(true)
		}

		// Set flag initially to false
		setShowPhoneNumber(false)

		// Check licenses if it is valid
		if (baseLicenseValue && baseLicenseKey && baseLicenses.length > 0) {
			// Check if base exists
			var baseExists = findArrayValue(
				baseLicenses,
				baseLicenseKey,
				baseLicenseValue
			)
			if (baseExists) {
				// Set the base license value
				if (baseExists.baseLicenseName) {
					setBaseLicense(baseExists.baseLicenseName)
				}
				// If add-on is required
				if (baseExists.addonRequired === false) {
					setShowPhoneNumber(true)
				} else {
					// Ensure add-on field shows
					setIsAddonRequired(true)
					// Validate add-on
					if (
						addonLicenseValue &&
						addonLicenseKey &&
						addonLicenses.length > 0
					) {
						// Check if add-on exists
						var addonExists = findArrayValue(
							addonLicenses,
							addonLicenseKey,
							addonLicenseValue
						)
						if (addonExists) {
							if (addonExists.addonLicenseName) {
								setAddonLicense(addonExists.addonLicenseName)
							}
							setShowPhoneNumber(true)
						}
					}
				}
			}
		}

		// Get the ranges
		var tumRangeList = await getDDIRanges()
		if (tumRangeList) {
			setTUMUserDDIRangeList(tumRangeList)
		}

		// Set other values
		// Phone number
		if (user.phoneNumber) {
			setPhoneNumber(user.phoneNumber)

			// Check if number exists in any range
			assignedNumberInRange(user.phoneNumber, tumRangeList)
		}

		// Internation calling
		if (user.internationalCalling) {
			setInternationalCalling(user.internationalCalling)
		}

		// Check if initial check to end loading
		if (initialCheck) {
			setPhoneLoading(false)
		}
	}

	// #region - API Calls

	// Get Customer DDIs
	const validateDDI = async (value: string) => {
		// Build the user DDI list that can be assigned
		var ddiInformation = {
			ddi: '',
			countryISO2Code: '',
			serviceTypeID: 1,
		}

		// Make call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'PhoneAutocomplete.tsx: getCustomerDDIs()',
			QueryURL: `GetV2?Params=DDIRange.DDI.Address.State.Country.First(DDIRange.CustomerID = '${customerID}' & DDI.DDI = '${value}')`,
			ErrorMessage: 'An error occurred when validating DDI',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			// Check response and filter out the list
			var ddiRangeResponse = dataResponse.Obj.DDIRange as DDIRange

			if (ddiRangeResponse) {
				// Assign the DDI list and adjust
				var ddiList = ddiRangeResponse.DDIList as DDI[]

				if (ddiList) {
					// Get the DDI
					var ddi = ddiList.find((ddi) => ddi.DDI + '' === value)

					if (ddi) {
						ddiInformation.ddi = ddi.DDI + ''
						ddiInformation.countryISO2Code =
							ddi.Address?.State?.Country?.CountryISO2Code + ''
						ddiInformation.serviceTypeID = Number(ddi.ServiceTypeOutID)
					}
				}
			}
		}

		// Set list
		return ddiInformation
	}

	// Make call to get all assigned numbers then use that list to get the DDIs that are not assigned
	const buildDDIAssignment = async (rangeID: number) => {
		// Loading
		setInitialDDIBuildLoading(true)

		// Variables
		var concatenatedAssignedNumbers = await retrieveAssignedNumbers(rangeID)
		setAssignedNumbersString(concatenatedAssignedNumbers)

		// Get latest 10 DDIs
		await buildDDIAssignmentList(rangeID, concatenatedAssignedNumbers)

		// Loading
		setInitialDDIBuildLoading(false)
	}

	// Retrieve the DDIs that are already assigned based on DDI Range
	const retrieveAssignedNumbers = async (rangeID: number) => {
		// Variables
		var concatenatedAssignedNumbers: string = ''

		// Get assigned DDIs
		var assignedDDIList: TUMRangeResponse[] = await getUserAssignedNumbers(
			rangeID
		)
		if (assignedDDIList) {
			// Concatenate string of assigned numbers
			concatenatedAssignedNumbers = assignedDDIList
				.map((item) => item.assignedNumber) // Extract assignedNumber
				.filter((num): num is string => num !== undefined) // Filter out undefined
				.join(', ') // Concatenate with commas
		}

		return concatenatedAssignedNumbers
	}

	// Gets the latest 10 DDIs based on click or search parameter
	const buildDDIAssignmentList = async (
		rangeID: number,
		concatenatedAssignedNumbers: string,
		ddiSearch?: string
	) => {
		// Get latest 10 DDIs
		var ddiAssignmentList: TUMDDIsForAssignment[] = await getDDIsForAssignment({
			DDIRangeID: rangeID,
			AssignedNumbersFilter: concatenatedAssignedNumbers,
			DDISearchValue: ddiSearch,
		})
		if (ddiAssignmentList) {
			setTUMAssignmentList(ddiAssignmentList)
		}

		// Set call done
		setPhoneCallDone(true)
	}

	// #endregion

	// #region - Handle Functions

	// Handle button form click logic
	const handleButtonClick = async () => {
		// Start loading
		setBtnLoading(true)

		// Check what has been changed and build configuration list
		var configurationList = [] as TeamsUserManagementConfigurationRequest[]

		// 1 - Check if something is to be removed
		if (removeBaseLicense || removeAddonLicense || removePhoneNumber) {
			// Hierachy on actions - Phone Number > Add-on License > Base License
			// Phone Number - Add to list
			if (removePhoneNumber) {
				// Get the number type
				var serviceTypeID = await getDDIServiceTypeID(user.phoneNumber)

				configurationList.push({
					JobID: uuidv4(),
					ActionTypeID: TUMConfigurationTypes['Remove TN'],
					UPN: user.upn,
					LineURI: user.phoneNumber,
					VoiceRoutingPolicy: user.voiceRoutingPolicy,
					ServiceTypeID: serviceTypeID,
				})
			}

			// Add-on License - Add to list
			if (removeAddonLicense) {
				configurationList.push({
					JobID: uuidv4(),
					ActionTypeID: TUMConfigurationTypes['Remove License'],
					UPN: user.upn,
					ServiceTypeID: TUMPhoneNumberTypes.TDR,
					SKUs: [
						{
							SKUID: user.addonLicenseSKUID,
						},
					],
				})
			}

			// Base License - Add to list
			if (removeBaseLicense) {
				configurationList.push({
					JobID: uuidv4(),
					ActionTypeID: TUMConfigurationTypes['Remove License'],
					UPN: user.upn,
					ServiceTypeID: TUMPhoneNumberTypes.TDR,
					SKUs: [
						{
							SKUID: user.baseLicenseSKUID,
						},
					],
				})
			}
		}

		// 2 - Check if something needs to be assigned
		if (
			// Base License
			(baseLicense.length > 0 && user.baseLicenseName + '' !== baseLicense) ||
			// Phone Number
			(phoneNumber.length > 0 && user.phoneNumber !== phoneNumber) ||
			// International calling (VRP)
			internationCalling !== user.internationalCalling
		) {
		}
		// Hierachy on actions - Base License > Add-on License > Phone Number
		// Base License
		if (baseLicense.length > 0 && user.baseLicenseName + '' !== baseLicense) {
			// Get license
			var base = baseLicenses.find(
				(base) => base.baseLicenseName === baseLicense
			)

			if (base) {
				configurationList.push({
					JobID: uuidv4(),
					ActionTypeID: TUMConfigurationTypes['Add License'],
					UPN: user.upn,
					ServiceTypeID: TUMPhoneNumberTypes.TDR,
					SKUs: [
						{
							SKUID: base.baseLicenseSKUID,
						},
					],
				})
			}
		}

		// Add-on License
		if (
			addonLicense.length > 0 &&
			user.addonLicenseName + '' !== addonLicense
		) {
			// Get license
			var addon = addonLicenses.find(
				(base) => base.addonLicenseName === addonLicense
			)

			if (addon) {
				configurationList.push({
					JobID: uuidv4(),
					ActionTypeID: TUMConfigurationTypes['Add License'],
					UPN: user.upn,
					ServiceTypeID: TUMPhoneNumberTypes.TDR,
					SKUs: [
						{
							SKUID: addon.addonLicenseSKUID,
						},
					],
				})
			}
		}

		// Phone Number OR International Calling change
		if (
			// Phone Number
			(phoneNumber.length > 0 && user.phoneNumber !== phoneNumber) ||
			// International calling (VRP)
			internationCalling !== user.internationalCalling
		) {
			// Get phone
			var filter = user.phoneNumber + ''
			if (phoneNumber.length > 0) {
				filter = phoneNumber
			}
			var phone = await validateDDI(filter)

			if (phone) {
				// Get VRP
				var vrp = tumVRPList.find(
					(vrp) => vrp.CountryISO2Code + '' === phone?.countryISO2Code + ''
				)

				// Check whether national or international calling
				var vrpToUse = ''
				if (vrp) {
					if (internationCalling) {
						vrpToUse = vrp.IntVoiceRoutingPolicy + ''
					} else {
						vrpToUse = vrp.DomVoiceRoutingPolicy + ''
					}
				}

				configurationList.push({
					JobID: uuidv4(),
					ActionTypeID: TUMConfigurationTypes['Add TN'],
					UPN: user.upn,
					LineURI: phone.ddi,
					ISO: phone.countryISO2Code,
					VoiceRoutingPolicy: vrpToUse,
					ServiceTypeID: phone.serviceTypeID,
				})
			}
		}

		// Send configuration list to the runbook
		await configureTeamsUser(configurationList)

		// End loading
		setBtnLoading(false)
	}

	// Handle close form
	const handleCloseForm = () => {
		// Reset Flags and then close form
		closeForm()
	}

	// Handle the saving of the values
	const handleValueChange = (type: string, value: any) => {
		// Check what has changed
		switch (type) {
			// Base License
			case 'base':
				setBaseLicense(value)

				// Validate license
				validateLicenseForPhone(
					'baseLicenseName',
					value,
					'addonLicenseName',
					addonLicense
				)
				break
			// Add-on License
			case 'add-on':
				setAddonLicense(value)

				// Validate license
				validateLicenseForPhone(
					'baseLicenseName',
					baseLicense,
					'addonLicenseName',
					value
				)
				break
			// Phone Number
			case 'phone':
				setPhoneNumber(value)
				break
		}
	}

	// Handle disable button
	const handleDisableButton = (): boolean => {
		// Return variable
		var disableButton = true

		// Check if certain fields were filled in
		if (
			// Base License
			(baseLicense.length > 0 && user.baseLicenseName + '' !== baseLicense) ||
			// Add-on License
			(isAddonRequired &&
				addonLicense.length > 0 &&
				user.addonLicenseName + '' !== addonLicense) ||
			// Phone Number
			(showPhoneNumber &&
				phoneNumber.length > 0 &&
				user.phoneNumber !== phoneNumber) ||
			// International calling (VRP)
			(showPhoneNumber && internationCalling !== user.internationalCalling) ||
			// Removal flags
			removeBaseLicense ||
			removeAddonLicense ||
			removePhoneNumber
		) {
			disableButton = false
		}

		return disableButton
	}

	// Handle remove base license
	const handleRemoveBaseLicenseChange = (e: ChangeEvent<HTMLInputElement>) => {
		var checked = e.target.checked
		setRemoveBaseLicense(checked)

		// If set to true, check if there is an add-on and/or phone already assigned to also be removed
		if (checked && checked === true) {
			// Add-on license remove
			if (user.addonLicenseSKUID && user.addonLicenseSKUID.length > 0) {
				setRemoveAddonLicense(true)
			}

			// Phone number remove
			if (user.phoneNumber) {
				setRemovePhoneNumber(true)
			}
		} else {
			// Reset flags
			setRemoveAddonLicense(false)
			setRemovePhoneNumber(false)
		}
	}

	// Handle remove add-on license
	const handleRemoveAddonLicenseChange = (e: ChangeEvent<HTMLInputElement>) => {
		var checked = e.target.checked
		setRemoveAddonLicense(checked)

		// If set to true, check if there is an add-on and/or phone already assigned to also be removed
		if (checked && checked === true) {
			// Phone number remove
			if (user.phoneNumber) {
				setRemovePhoneNumber(true)
			}
		} else {
			// Reset flags
			setRemovePhoneNumber(false)
		}
	}

	// Handle popover click
	const handlePopoverOpen = (e: any, type: string) => {
		// Check what is clicked
		switch (type) {
			// Base License
			case 'base':
				setBaseAnchorEl(e.currentTarget)
				break
			// Phone Number
			case 'phone':
				setPhoneAnchorEl(e.currentTarget)
				break
		}
	}

	const handlePopoverClose = () => {
		setBaseAnchorEl(null)
		setPhoneAnchorEl(null)
	}

	// Handle change of DDI range
	const handleRangeChange = async (e: { target: any }) => {
		// Range ID
		var rangeID = Number(e.target.value)
		setDDIRangeID(rangeID)

		// Make call to retrieve latest 10 numbers
		await buildDDIAssignment(rangeID)
	}

	// Check if assigned number exists in range list
	const assignedNumberInRange = (
		currentNumber: string,
		tumRangeList: TUMUserDDIRangeList[]
	) => {
		// Convert current number to int
		var valueToCheck = parseInt(currentNumber, 10)

		// Loop through list
		var rangeToUse = tumRangeList.find((range) => {
			// Convert start and end
			var startRange = parseInt(range.DDIRangeStart + '', 10)
			var endRange = parseInt(range.DDIRangeEnd + '', 10)
			return valueToCheck >= startRange && valueToCheck <= endRange
		})

		// Check if found then assign ddi range ID
		if (rangeToUse && rangeToUse.DDIRangeID) {
			setDDIRangeID(rangeToUse.DDIRangeID)
		}
	}

	// Handle filtering of DDIs based on search
	const handleFilteringOfDDIs = async (searchFilter: string) => {
		// Start loading
		setBuildDDILoading(true)

		// Make call
		await buildDDIAssignmentList(
			ddiRangeID,
			assignedNumbersString,
			searchFilter
		)

		// End loading
		setBuildDDILoading(false)
	}

	// Handle error handling for phone number
	const handlePhoneErrorHandling = (error: string) => {
		setPhoneError(error)
	}

	// #endregion

	return (
		<>
			{/* Custom Modal Form - Configure teams user */}
			<ModalForm
				icon={<ContactPhoneOutlinedIcon />}
				title='Configure User'
				description='Choose to assign/unassign license(s) and/or a phone number to this user'
				buttonText='Configure User'
				buttonAction={handleButtonClick}
				buttonLoading={btnLoading}
				closeModal={handleCloseForm}
				buttonDisabled={handleDisableButton()}>
				{/* Form */}
				<Box className='tum-edit-form-container'>
					{/* User Avatar, Name and Email */}
					<Box className='form-user-info'>
						{/* Avatar */}
						<Avatar className='avatar' {...stringAvatar(user.name + '')} />
						{/* User Details */}
						<Box className='user-info'>
							{/* Name */}
							<Typography component='h5'>{user.name}</Typography>
							{/* Email */}
							<Typography component='p'>{user.upn}</Typography>
						</Box>
					</Box>
					{/* Divider */}
					<Divider />
					{/* Form */}
					<Box className='tum-form-content'>
						{/* Base License Configuration > Autocomplete - (Base License), Checkbox - (Remove licenses) */}
						<Box className='license'>
							{/* Configuration */}
							<Box className='form-config'>
								{/* Label */}
								<Box className='form-label-container'>
									{/* Label */}
									<Typography component='p' className='form-label'>
										Base License:
									</Typography>
									{/* Info */}
									<Tooltip title='Click for information'>
										<IconButton
											onClick={(e) => handlePopoverOpen(e, 'base')}
											className='icon-button'>
											<InfoOutlinedIcon
												sx={{ color: theme.palette.primary.main }}
											/>
										</IconButton>
									</Tooltip>
									{/* Base License Popover */}
									<Popover
										id={baseInfoID}
										open={openBaseInfo}
										anchorEl={baseAnchorEl}
										onClose={handlePopoverClose}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'center',
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'center',
										}}>
										<BaseLicenseTip />
									</Popover>
								</Box>
								{/* Autocomplete */}
								<BaseLicenseAutocomplete
									disabled={removeBaseLicense}
									assignedLicense={
										user.baseLicenseName ? user.baseLicenseName : ''
									}
									baseLicenses={baseLicenses}
									handleValueChange={handleValueChange}
								/>
							</Box>
							{/* Checkbox */}
							<Box className='license-checkbox'>
								{/* Remove base license > Checkbox */}
								{user.baseLicenseSKUID && user.baseLicenseSKUID.length > 0 && (
									<FormControlLabel
										control={
											<Checkbox
												checked={removeBaseLicense}
												onChange={handleRemoveBaseLicenseChange}
											/>
										}
										label='Remove base license'
									/>
								)}
							</Box>
						</Box>
						{/* Add-on License Configuration > Autocomplete - (Add-on License), Checkbox - (Remove licenses) */}
						{isAddonRequired && (
							// License
							<Box className='license'>
								{/* Configuration */}
								<Box className='form-config'>
									{/* Label */}
									<Typography component='p' className='form-label'>
										Add-on License:
									</Typography>
									{/* Autocomplete */}
									<AddonLicenseAutocomplete
										disabled={removeAddonLicense}
										assignedLicense={
											user.addonLicenseName &&
											!user.addonLicenseName.includes('Incompatible')
												? user.addonLicenseName
												: ''
										}
										addonLicenses={addonLicenses}
										handleValueChange={handleValueChange}
									/>
								</Box>
								{/* Checkbox */}
								<Box className='license-checkbox'>
									{/* Remove base license > Checkbox */}
									{user.addonLicenseSKUID &&
										user.addonLicenseSKUID.length > 0 && (
											<FormControlLabel
												control={
													<Checkbox
														checked={removeAddonLicense}
														onChange={handleRemoveAddonLicenseChange}
													/>
												}
												label='Remove add-on license'
											/>
										)}
								</Box>
							</Box>
						)}
						{/* Phone Configuration > Select - (Number Type (TDR/OC)), Autocomplete - (Phone Number), Checkbox - (Remove Phone) */}
						{showPhoneNumber && !phoneLoading ? (
							<Box className='phone'>
								{/* Configuration */}
								<Box className='form-config'>
									{/* Label Container - Label and Error */}
									<Box className='form-label-error'>
										{/* Label and Icon */}
										<Box className='form-label-container'>
											{/* Label */}
											<Typography component='p' className='form-label'>
												Phone Number Assignment:
											</Typography>
											{/* Info */}
											<Tooltip title='Click for information'>
												<IconButton
													onClick={(e) => handlePopoverOpen(e, 'phone')}
													className='icon-button'>
													<InfoOutlinedIcon
														sx={{ color: theme.palette.primary.main }}
													/>
												</IconButton>
											</Tooltip>
											{/* Phone Number Popover */}
											<Popover
												id={phoneInfoID}
												open={openPhoneInfo}
												anchorEl={phoneAnchorEl}
												onClose={handlePopoverClose}
												anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'center',
												}}
												transformOrigin={{
													vertical: 'top',
													horizontal: 'center',
												}}>
												<PhoneNumberTip />
											</Popover>
										</Box>
										{/* Error */}
										{phoneError.length > 0 && (
											<Alert icon={<ErrorOutlineIcon />} severity='error'>
												{phoneError}
											</Alert>
										)}
									</Box>
									{/* Divider */}
									<Divider />
									{/* Range Select */}
									<Box className='form-input-wlbl'>
										{/* Label */}
										<Box className='input-label'>
											<Typography component='p'>Range Selection:</Typography>
										</Box>
										{/* Select */}
										<StyledSelect
											disabled={
												removeBaseLicense ||
												removeAddonLicense ||
												removePhoneNumber
											}
											fullWidth
											value={ddiRangeID}
											onChange={handleRangeChange}>
											{/* Map the list */}
											{tumUserDDIRangeList.map((item, index) => (
												<MenuItem key={index} value={item.DDIRangeID}>
													{`${item.DDIRangeStart} - ${item.DDIRangeEnd}`}
												</MenuItem>
											))}
										</StyledSelect>
									</Box>
									{/* Autocomplete Phone */}
									<Box className='form-input-wlbl'>
										{/* Label */}
										<Box className='input-label'>
											<Typography component='p'>Phone Number:</Typography>
										</Box>
										{/* Autocomplete */}
										<PhoneAutocomplete
											disabled={
												removeBaseLicense ||
												removeAddonLicense ||
												removePhoneNumber ||
												ddiRangeID === 0 ||
												initialDDIBuildLoading
											}
											currentValue={user.phoneNumber ? user.phoneNumber : ''}
											handleValueChange={handleValueChange}
											handleFilteringOfDDIs={handleFilteringOfDDIs}
											handlePhoneErrorHandling={handlePhoneErrorHandling}
											tumDDIAssignmentList={tumDDIAssignmentList}
											isLoading={initialDDIBuildLoading || buildDDILoading}
											callDone={phoneCallDone}
										/>
									</Box>
								</Box>
								{/* Checkboxes */}
								<Box className='phone-checkboxes'>
									{/* International calling > Checkbox */}
									<FormControlLabel
										control={
											<Checkbox
												disabled={
													removeBaseLicense ||
													removeAddonLicense ||
													removePhoneNumber ||
													ddiRangeID === 0 ||
													initialDDIBuildLoading ||
													phoneNumber.length === 0
												}
												checked={internationCalling}
												onChange={(e) =>
													setInternationalCalling(e.target.checked)
												}
											/>
										}
										label='Enable International Calling'
									/>
									{/* Remove phone number > Checkbox */}
									{user.phoneNumber && user.phoneNumber.length > 0 && (
										<FormControlLabel
											control={
												<Checkbox
													disabled={removeBaseLicense || removeAddonLicense}
													checked={removePhoneNumber}
													onChange={(e) =>
														setRemovePhoneNumber(e.target.checked)
													}
												/>
											}
											label='Remove phone number'
										/>
									)}
								</Box>
							</Box>
						) : (
							phoneLoading && <Skeleton animation='wave' />
						)}
					</Box>
					{/* NOTE - For the configuring logic, need to show a chip display showing pending, failed or completed */}
				</Box>
			</ModalForm>
		</>
	)
}

export default TUMEditForm
