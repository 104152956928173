import {
	AlertColor,
	Box,
	Button,
	LinearProgress,
	Typography,
	useTheme,
} from '@mui/material'
import './SDACard.scss'
import { StyledAlert } from '../../../../../../../styles/styledComponents/inputs/StyledAlert'

const CustomCard = ({
	isActive,
	cardTitle,
	cardDescription,
	cardBodyIconSrc,
	showButton,
	cardButtonTitle,
	cardButtonAction,
	cardButtonExternalLink,
	showLoading,
	cardMessage,
	cardMessageType,
	progress,
}: {
	isActive: boolean
	cardTitle: string
	cardDescription?: string
	cardBodyIconSrc?: string
	showButton: boolean
	cardButtonTitle?: string
	cardButtonAction?: any
	cardButtonExternalLink?: string
	showLoading?: boolean
	cardMessage?: string
	cardMessageType?: AlertColor
	progress?: number
}) => {
	const theme = useTheme()

	return (
		<>
			<Box className='sda-card-container'>
				<Box className='sda-card-content' bgcolor='card.default'>
					{cardBodyIconSrc ? (
						<img
							className='sda-card-body-img'
							src={require(`../../../../../../../assets/images/distinct/msTeams/${cardBodyIconSrc}`)}
							alt={cardTitle}
						/>
					) : null}

					{cardDescription && cardDescription.length > 0 ? (
						<Typography
							className={showButton ? 'card-text m' : 'card-text'}
							component='p'
							color='text.primary'>
							{cardDescription}
						</Typography>
					) : null}

					{showLoading && (
						<Box
							sx={{
								marginTop: '15px',
								padding: '10px',
								width: '500px',
							}}>
							<LinearProgress style={{ color: theme.palette.primary.main }} />
						</Box>
					)}

					{progress && progress > 0 ? (
						<Box
							sx={{
								marginTop: '55px',
								marginBottom: '10px',
								padding: '10px',
								width: '500px',
							}}>
							<LinearProgress
								variant='determinate'
								value={progress}
								style={{ color: theme.palette.primary.main }}
							/>
						</Box>
					) : null}

					{cardMessage && cardMessageType && cardMessage.length > 0 ? (
						<StyledAlert
							className='alert'
							severity={cardMessageType}
							sx={
								showButton
									? {
											marginBottom: '15px!important',
											lineHeight: 2,
											padding: '2px',
									  }
									: { lineHeight: 2, padding: '2px' }
							}>
							{cardMessage}
						</StyledAlert>
					) : null}

					{showButton && cardButtonTitle ? (
						<Button
							sx={
								cardDescription && cardDescription.length > 0
									? { marginTop: '40px' }
									: {}
							}
							variant='contained'
							disabled={!isActive}
							onClick={cardButtonAction}
							href={cardButtonExternalLink}>
							{cardButtonTitle}
						</Button>
					) : null}
				</Box>
			</Box>
		</>
	)
}

export default CustomCard
