import { Box, Divider, MenuItem, Typography } from '@mui/material'
import { RootState } from '../../../../store/store'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { validateField } from '../../../../utils/helperFunctions/helperFunctions'
import { AddWarmLeadRequest } from '../../../../utils/interfaces/APIModels'
import UseCustomBackendCall from '../../../../utils/customHooks/APICalls/UseCustomBackendCall'
import { StyledSelect } from '../../../../styles/styledComponents/inputs/StyledSelect'
import {
	LeadSize,
	LeadType,
	LeadWeight,
} from '../../../../utils/interfaces/DBModels'
import { LeadTypes } from '../../../../utils/enums/enums'
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined'
import { ModalForm } from '../../../shared/inputs/ModalForm/ModalForm'
import { LeadListDisplay } from '../../../../utils/interfaces/ComponentModels'
import { StyledTextField } from '../../../../styles/styledComponents/inputs/StyledTextField'

function WarmLead({
	leads,
	leadTypes,
	leadSizes,
	leadWeights,
	onAdd,
	handleCloseUpdateLeadModal,
}: {
	leads: LeadListDisplay[]
	leadTypes: LeadType[]
	leadSizes: LeadSize[]
	leadWeights: LeadWeight[]
	onAdd: () => void
	handleCloseUpdateLeadModal: any
}) {
	const [companyName, setCompanyName] = useState('')
	const [tenantID, setTenantID] = useState('')
	const [externalSalesAgent, setExternalSalesAgent] = useState('')
	const [addingLeads, setAddingLeads] = useState(false)

	const [leadSizeID, setLeadSizeID] = useState(1)
	const [leadTypeID, setLeadTypeID] = useState(1)
	const [leadWeightID, setLeadWeightID] = useState(1)
	const [locations, setLocations] = useState('')
	const [customerName, setCustomerName] = useState('')
	const [customerPhoneNumber, setCustomerPhoneNumber] = useState('')
	const [customerEmail, setCustomerEmail] = useState('')

	// error variables
	const [companyNameError, setCompanyNameError] = useState('')
	const [externalSalesAgentError, setExternalSalesAgentError] = useState('')
	const [tenantIDError, setTenantIDError] = useState('')
	const [locationsError, setLocationsError] = useState('')
	const [customerNameError, setCustomerNameError] = useState('')
	const [customerPhoneNumberError, setCustomerPhoneNumberError] = useState('')
	const [customerEmailError, setCustomerEmailError] = useState('')

	const { fetchCustomData } = UseCustomBackendCall()
	var partnerName = useSelector(
		(state: RootState) => state.RootReducer.partnerNameReducer.value
	)

	const handleAddWarmLead = async () => {
		var addWarmLeadRequest: AddWarmLeadRequest = {
			CompanyName: companyName,
			ExternalSalesAgent: externalSalesAgent,
			PartnerName: partnerName,
			TenantID: tenantID,
			LeadSizeID: leadSizeID,
			LeadTypeID: leadTypeID,
			Locations: locations,
			ContactInfo: {
				Name: customerName,
				PhoneNumber: customerPhoneNumber,
				Email: customerEmail,
			},
			LeadWeightID: leadWeightID,
		}
		setAddingLeads(true)
		await fetchCustomData<string[]>({
			QueryURL: `AddUnconsentedLead`,
			QueryObj: addWarmLeadRequest,
			ErrorMessage: 'Could not add lead',
			ShowErrorToast: true,
			ShowSuccessToast: true,
			SuccessMessage: 'Leads Successfully added',
			LogErrorToDB: true,
			FileAndFunctionName: 'WarmLeads.tsx handleAddWarmLead',
		})
		setAddingLeads(false)
		onAdd()
	}

	const handleFieldChange = (field: string, value: string | number | null) => {
		switch (field) {
			case 'companyName':
				setCompanyName(value as string)
				setCompanyNameError(validateField(field, value as string))
				break
			case 'externalSalesAgent':
				setExternalSalesAgent(value as string)
				setExternalSalesAgentError(validateField(field, value as string))
				break
			case 'tenantID':
				setTenantID(value as string)
				setTenantIDError(validateField(field, value))
				if ((value as string).length > 0 && leadTypeID === LeadTypes.OC) {
					var existingLead = leads.find((lead) => lead.TenantID === value)

					if (existingLead) {
						setTenantIDError(`Lead with Tenant ID: ${value} already exists`)
					}
				}
				break
			case 'leadTypeID':
				if (value === LeadTypes.DirectRouting) {
					setTenantID('')
					setTenantIDError('')
				}
				setLeadTypeID(Number(value))
				break
			case 'leadSize':
				setLeadSizeID(Number(value))
				break
			case 'leadWeight':
				setLeadWeightID(Number(value))
				break
			case 'locations':
				setLocations(value as string)
				setLocationsError(validateField(field, value))
				break
			case 'customerName':
				setCustomerName(value as string)
				setCustomerNameError(validateField(field, value))
				break
			case 'customerPhoneNumber':
				setCustomerPhoneNumber(value as string)
				setCustomerPhoneNumberError(validateField(field, value))
				break
			case 'customerEmail':
				setCustomerEmail(value as string)
				setCustomerEmailError(validateField(field, value))
				break
			default:
				break
		}
	}

	const isButtonDisabled = () => {
		return (
			companyName.length === 0 ||
			externalSalesAgent.length === 0 ||
			(leadTypeID === LeadTypes.OC && tenantID.length === 0) ||
			locations.length === 0 ||
			customerName.length === 0 ||
			customerPhoneNumber.length === 0 ||
			customerEmail.length === 0 ||
			companyNameError.length > 0 ||
			externalSalesAgentError.length > 0 ||
			tenantIDError.length > 0 ||
			locationsError.length > 0 ||
			customerNameError.length > 0 ||
			customerPhoneNumberError.length > 0 ||
			customerEmailError.length > 0
		)
	}

	return (
		<ModalForm
			icon={<SettingsApplicationsOutlinedIcon />}
			title='Add Lead'
			description='Add new lead to the system'
			buttonText='Add Lead'
			buttonAction={handleAddWarmLead}
			buttonLoading={addingLeads}
			buttonDisabled={isButtonDisabled()}
			closeModal={handleCloseUpdateLeadModal}>
			{/* Form Group - Company Name */}
			<Box className='form-group'>
				<Typography component='p' className='form-label'>
					Company Name
					<Typography component='span' className='required'>
						*
					</Typography>
				</Typography>
				<StyledTextField
					fullWidth
					type='text'
					onChange={(e) => handleFieldChange('companyName', e.target.value)}
					placeholder='Company Name'
				/>
				{companyNameError && (
					<Typography variant='body2' color='error'>
						{companyNameError}
					</Typography>
				)}
			</Box>
			{/* Form Group - Sales Agent Email */}
			<Box className='form-group'>
				<Typography component='p' className='form-label'>
					Sales Agent Email
					<Typography component='span' className='required'>
						*
					</Typography>
				</Typography>
				<StyledTextField
					fullWidth
					value={externalSalesAgent}
					onChange={(e) =>
						handleFieldChange('externalSalesAgent', e.target.value)
					}
					type='email'
					placeholder='Sales Agent Email'
				/>
				{externalSalesAgentError && (
					<Typography variant='body2' color='error'>
						{externalSalesAgentError}
					</Typography>
				)}
			</Box>
			{/* Form Group - Lead Size */}
			<Box className='form-group'>
				<Typography component='p' className='form-label'>
					Lead Size
					<Typography component='span' className='required'>
						*
					</Typography>
				</Typography>
				<StyledSelect
					fullWidth
					type='text'
					value={leadSizeID}
					onChange={(e) =>
						handleFieldChange('leadSize', Number(e.target.value))
					}>
					{leadSizes.map((leadSize, index) => (
						<MenuItem key={index} value={leadSize.LeadSizeID}>
							{leadSize.Minimum} - {leadSize.Maximum}
						</MenuItem>
					))}
				</StyledSelect>
			</Box>
			{/* Form Group - Lead Weight */}
			<Box className='form-group'>
				<Typography component='p' className='form-label'>
					Lead Stage
					<Typography component='span' className='required'>
						*
					</Typography>
				</Typography>
				<StyledSelect
					fullWidth
					type='text'
					value={leadWeightID}
					onChange={(e) =>
						handleFieldChange('leadWeight', Number(e.target.value))
					}>
					{leadWeights.map((leadWeight, index) => (
						<MenuItem key={index} value={leadWeight.LeadWeightID}>
							{leadWeight.SalesStage} - {leadWeight.Weight}%
						</MenuItem>
					))}
				</StyledSelect>
			</Box>
			{/* Form Group - Lead Type */}
			<Box className='form-group'>
				<Typography component='p' className='form-label'>
					Lead Type
					<Typography component='span' className='required'>
						*
					</Typography>
				</Typography>
				<StyledSelect
					fullWidth
					type='text'
					value={leadTypeID}
					placeholder='Lead Type'
					onChange={(e) =>
						handleFieldChange('leadTypeID', Number(e.target.value))
					}>
					{leadTypes.map((leadType, index) => (
						<MenuItem key={index} value={leadType.LeadTypeID}>
							{leadType.LeadTypeName}
						</MenuItem>
					))}
				</StyledSelect>
			</Box>
			{/* Form Group - Tenant ID */}
			{leadTypeID === LeadTypes.OC && (
				<Box className='form-group'>
					<Typography component='p' className='form-label'>
						Tenant ID
						<Typography component='span' className='required'>
							*
						</Typography>
					</Typography>
					<StyledTextField
						fullWidth
						type='text'
						onChange={(e) => handleFieldChange('tenantID', e.target.value)}
						placeholder='Tenant ID'
					/>
					{tenantIDError && (
						<Typography variant='body2' color='error'>
							{tenantIDError}
						</Typography>
					)}
				</Box>
			)}
			{/* Form Group - Locations */}
			<Box className='form-group'>
				<Typography component='p' className='form-label'>
					Locations
					<Typography component='span' className='required'>
						*
					</Typography>
				</Typography>
				<StyledTextField
					fullWidth
					value={locations}
					onChange={(e) => handleFieldChange('locations', e.target.value)}
					type='text'
					placeholder='UK'
				/>
				{locationsError && (
					<Typography variant='body2' color='error'>
						{locationsError}
					</Typography>
				)}
			</Box>
			{/* Divider and New Sections */}
			<Divider light />
			<Typography variant='h6' style={{ fontWeight: 'bold' }}>
				Customer's Contact Details
			</Typography>
			{/* Form Group - Name */}
			<Box className='form-group'>
				<Typography component='p' className='form-label'>
					Name
					<Typography component='span' className='required'>
						*
					</Typography>
				</Typography>
				<StyledTextField
					fullWidth
					type='text'
					onChange={(e) => handleFieldChange('customerName', e.target.value)}
					placeholder='Name'
				/>
				{customerNameError && (
					<Typography variant='body2' color='error'>
						{customerNameError}
					</Typography>
				)}
			</Box>
			{/* Form Group - Phone Number */}
			<Box className='form-group'>
				<Typography component='p' className='form-label'>
					Phone Number
					<Typography component='span' className='required'>
						*
					</Typography>
				</Typography>
				<StyledTextField
					fullWidth
					type='text'
					onChange={(e) =>
						handleFieldChange('customerPhoneNumber', e.target.value)
					}
					placeholder='+123456789'
				/>
				{customerPhoneNumberError && (
					<Typography variant='body2' color='error'>
						{customerPhoneNumberError}
					</Typography>
				)}
			</Box>
			{/* Form Group */}
			<Box className='form-group'>
				<Typography component='p' className='form-label'>
					Email
					<Typography component='span' className='required'>
						*
					</Typography>
				</Typography>
				<StyledTextField
					fullWidth
					value={customerEmail}
					onChange={(e) => handleFieldChange('customerEmail', e.target.value)}
					type='email'
					placeholder='Email'
				/>
				{customerEmailError && (
					<Typography variant='body2' color='error'>
						{customerEmailError}
					</Typography>
				)}
			</Box>
		</ModalForm>
	)
}

export default WarmLead
