import React from 'react'
import { StyledTextField } from '../../../../../styles/styledComponents/inputs/StyledTextField'

interface EditableTableCellProps {
	isInEditMode: boolean
	isColumnEditable?: boolean
	value: any
	onChange: (newValue: any) => void
}

// Editable table cell to allow cell to be changed to a text field on edit mode
export function EditableTableCell({
	isInEditMode,
	isColumnEditable,
	value,
	onChange,
}: EditableTableCellProps) {
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.value)
	}

	return isInEditMode && isColumnEditable ? (
		<StyledTextField value={value} onChange={handleInputChange} />
	) : (
		value
	)
}
