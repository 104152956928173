import { Box, Typography, styled } from '@mui/material'
import './LoadingBox.scss'
import Hourglass from '../Preloader/Hourglass/Hourglass'

const LoadingBox = ({
	title,
	description,
}: {
	title: string
	description?: string
}) => {
	// Style the box
	const StyledLoadingBox = styled(Box)(({ theme }) => ({
		' --border-size': '3px',
		'--border-angle': '0turn',
		minWidth: '200px',
		minHeight: '200px',
		padding: '30px',
		// Loading animation
		backgroundImage: `conic-gradient(from var(--border-angle), #fff, #fff 50%, #fff),conic-gradient(from var(--border-angle), transparent 20%, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
		backgroundSize:
			'calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)), cover',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		animation: 'bg-spin 3s linear infinite',
		'@keyframes bg-spin': {
			to: {
				'--border-angle': '1turn',
			},
		},
		// Box Content - Icon and Text
		'& .box-content': {
			display: 'flex',
			flexDirection: 'column',
			rowGap: '20px',
			'& h4': {
				fontSize: '18px',
				color: theme.palette.secondary.contrastText,
			},
			'& .box-icon': {
				width: '50px',
				height: '50px',
				borderRadius: '50%',
				background: theme.palette.background.default,
				padding: '15px',
				// Icon
				'& svg': {
					fontSize: '18px',
					color: theme.palette.primary.main,
				},
			},
			'& .box-text': {
				display: 'flex',
				flexDirection: 'column',
				rowGap: '5px',
			},
		},
	}))

	return (
		<StyledLoadingBox id='loading-box'>
			<Box className='box-content'>
				<Box className='box-icon'>
					<Hourglass />
				</Box>
				<Box className='box-text'>
					<Typography component='h4'>{title}</Typography>
					<Typography component='p'>{description}</Typography>
				</Box>
			</Box>
		</StyledLoadingBox>
	)
}

export default LoadingBox
