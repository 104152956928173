import { Box, useTheme, Typography } from '@mui/material'

const EmptyDisplay = ({
	title,
	description,
}: {
	title: string
	description: string
}) => {
	// Theme
	const theme = useTheme()

	return (
		<>
			<Box display='grid' gridTemplateColumns='1fr 2fr' columnGap='30px'>
				{/* SVG */}
				<svg
					version='1.1'
					xmlns='http://www.w3.org/2000/svg'
					xmlnsXlink='http://www.w3.org/1999/xlink'
					x='0px'
					y='0px'
					viewBox='0 0 500 500'
					enableBackground='new 0 0 500 500'
					xmlSpace='preserve'>
					<g id='Background_Complete'>
						<g>
							<g>
								<g>
									<g>
										<g>
											<rect
												x='43.823'
												y='49.178'
												fill='#EBEBEB'
												width='125.236'
												height='161.751'
											/>
										</g>
									</g>
								</g>
								<g>
									<g>
										<g>
											<g>
												<path
													fill='#E0E0E0'
													d='M169.059,49.178c0.012,0.011-0.104,0.157-0.341,0.433c-0.275,0.311-0.629,0.71-1.075,1.213
								c-1.004,1.101-2.418,2.65-4.23,4.635l-0.05,0.055l-0.079,0.001c-10.888,0.111-32.282,0.187-56.84,0.187
								c-24.566,0-45.959-0.075-56.846-0.187l-0.079-0.001l-0.05-0.055c-1.812-1.986-3.226-3.535-4.23-4.635
								c-0.446-0.503-0.799-0.902-1.075-1.213c-0.238-0.276-0.353-0.422-0.342-0.433c0.012-0.011,0.15,0.114,0.41,0.369
								c0.292,0.295,0.668,0.674,1.141,1.151c1.033,1.073,2.488,2.584,4.352,4.521l-0.128-0.056
								c10.887-0.111,32.28-0.187,56.846-0.187c24.558,0,45.952,0.075,56.84,0.187l-0.128,0.056
								c1.864-1.937,3.319-3.448,4.352-4.521c0.473-0.477,0.849-0.856,1.141-1.151C168.909,49.292,169.047,49.167,169.059,49.178z'
												/>
											</g>
										</g>
									</g>
								</g>
								<g>
									<g>
										<g>
											<g>
												<path
													fill='#E0E0E0'
													d='M43.823,210.928c-0.012-0.011,0.104-0.157,0.342-0.433c0.275-0.311,0.629-0.71,1.075-1.213
								c1.004-1.101,2.418-2.65,4.23-4.635l0.05-0.055l0.079-0.001c10.888-0.111,32.281-0.187,56.84-0.187
								c24.566,0,45.959,0.076,56.846,0.187l0.079,0.001l0.05,0.055c1.812,1.986,3.226,3.535,4.23,4.635
								c0.445,0.503,0.799,0.902,1.075,1.213c0.238,0.276,0.353,0.423,0.341,0.433c-0.012,0.011-0.15-0.114-0.41-0.369
								c-0.292-0.295-0.668-0.674-1.141-1.151c-1.033-1.074-2.488-2.584-4.352-4.521l0.128,0.056
								c-10.887,0.111-32.28,0.187-56.846,0.187c-24.558,0-45.952-0.076-56.84-0.187l0.128-0.055
								c-1.864,1.937-3.319,3.448-4.352,4.521c-0.473,0.477-0.849,0.856-1.141,1.151C43.973,210.815,43.834,210.939,43.823,210.928z
								'
												/>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<rect
											x='49.598'
											y='55.339'
											fill='#FFFFFF'
											width='113.686'
											height='149.428'
										/>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<path
											fill='#EBEBEB'
											d='M67.387,161.068c3.382-3.221,8.374-4.133,13.043-4.017c4.669,0.116,9.294,1.104,13.964,1.046
						c7.469-0.093,14.909-2.857,22.269-1.581c2.352,0.408,4.736,1.225,7.071,0.727c2.044-0.436,3.761-1.831,5.764-2.428
						c5.185-1.545,11.018,2.51,15.998,0.394v33.354H67.387V161.068z'
										/>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M68.96,162.409c-0.201,0-0.363-19.758-0.363-44.126c0-24.376,0.162-44.131,0.363-44.131
							c0.2,0,0.363,19.754,0.363,44.131C69.323,142.651,69.16,162.409,68.96,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M72.516,162.409c-0.2,0-0.363-19.758-0.363-44.126c0-24.376,0.162-44.131,0.363-44.131
							c0.2,0,0.363,19.754,0.363,44.131C72.879,142.651,72.716,162.409,72.516,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M76.072,162.409c-0.201,0-0.363-19.758-0.363-44.126c0-24.376,0.162-44.131,0.363-44.131
							c0.2,0,0.363,19.754,0.363,44.131C76.435,142.651,76.272,162.409,76.072,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M79.628,162.409c-0.2,0-0.363-19.758-0.363-44.126c0-24.376,0.163-44.131,0.363-44.131
							c0.2,0,0.363,19.754,0.363,44.131C79.991,142.651,79.829,162.409,79.628,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M83.185,162.409c-0.201,0-0.363-19.758-0.363-44.126c0-24.376,0.163-44.131,0.363-44.131
							c0.2,0,0.362,19.754,0.362,44.131C83.547,142.651,83.385,162.409,83.185,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M86.741,162.409c-0.2,0-0.363-19.758-0.363-44.126c0-24.376,0.162-44.131,0.363-44.131
							c0.2,0,0.363,19.754,0.363,44.131C87.104,142.651,86.941,162.409,86.741,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M90.297,162.409c-0.2,0-0.363-19.758-0.363-44.126c0-24.376,0.162-44.131,0.363-44.131
							c0.2,0,0.363,19.754,0.363,44.131C90.66,142.651,90.497,162.409,90.297,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M93.853,162.409c-0.2,0-0.363-19.758-0.363-44.126c0-24.376,0.163-44.131,0.363-44.131
							c0.2,0,0.363,19.754,0.363,44.131C94.216,142.651,94.053,162.409,93.853,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M97.409,162.409c-0.201,0-0.363-19.758-0.363-44.126c0-24.376,0.162-44.131,0.363-44.131
							c0.2,0,0.363,19.754,0.363,44.131C97.772,142.651,97.609,162.409,97.409,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M100.965,162.409c-0.2,0-0.363-19.758-0.363-44.126c0-24.376,0.163-44.131,0.363-44.131
							c0.2,0,0.363,19.754,0.363,44.131C101.328,142.651,101.166,162.409,100.965,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M104.521,162.409c-0.2,0-0.363-19.758-0.363-44.126c0-24.376,0.162-44.131,0.363-44.131
							c0.201,0,0.363,19.754,0.363,44.131C104.884,142.651,104.722,162.409,104.521,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M108.077,162.409c-0.2,0-0.363-19.758-0.363-44.126c0-24.376,0.162-44.131,0.363-44.131
							c0.2,0,0.363,19.754,0.363,44.131C108.44,142.651,108.278,162.409,108.077,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M111.634,162.409c-0.2,0-0.363-19.758-0.363-44.126c0-24.376,0.162-44.131,0.363-44.131
							c0.2,0,0.363,19.754,0.363,44.131C111.997,142.651,111.834,162.409,111.634,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M115.19,162.409c-0.2,0-0.363-19.758-0.363-44.126c0-24.376,0.162-44.131,0.363-44.131
							c0.2,0,0.363,19.754,0.363,44.131C115.553,142.651,115.39,162.409,115.19,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M118.746,162.409c-0.201,0-0.363-19.758-0.363-44.126c0-24.376,0.162-44.131,0.363-44.131
							c0.2,0,0.363,19.754,0.363,44.131C119.109,142.651,118.946,162.409,118.746,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M122.302,162.409c-0.201,0-0.363-19.758-0.363-44.126c0-24.376,0.162-44.131,0.363-44.131
							c0.2,0,0.363,19.754,0.363,44.131C122.665,142.651,122.502,162.409,122.302,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M125.858,162.409c-0.201,0-0.363-19.758-0.363-44.126c0-24.376,0.162-44.131,0.363-44.131
							c0.2,0,0.363,19.754,0.363,44.131C126.221,142.651,126.059,162.409,125.858,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M129.414,162.409c-0.2,0-0.363-19.758-0.363-44.126c0-24.376,0.162-44.131,0.363-44.131
							c0.2,0,0.363,19.754,0.363,44.131C129.777,142.651,129.615,162.409,129.414,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M132.971,162.409c-0.2,0-0.363-19.758-0.363-44.126c0-24.376,0.162-44.131,0.363-44.131
							c0.2,0,0.363,19.754,0.363,44.131C133.333,142.651,133.171,162.409,132.971,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M136.527,162.409c-0.201,0-0.363-19.758-0.363-44.126c0-24.376,0.162-44.131,0.363-44.131
							c0.2,0,0.363,19.754,0.363,44.131C136.889,142.651,136.727,162.409,136.527,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M140.083,162.409c-0.2,0-0.363-19.758-0.363-44.126c0-24.376,0.162-44.131,0.363-44.131
							c0.2,0,0.363,19.754,0.363,44.131C140.445,142.651,140.283,162.409,140.083,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<path
												fill='#E0E0E0'
												d='M143.639,162.409c-0.201,0-0.363-19.758-0.363-44.126c0-24.376,0.163-44.131,0.363-44.131
							c0.2,0,0.363,19.754,0.363,44.131C144.002,142.651,143.839,162.409,143.639,162.409z'
											/>
										</g>
									</g>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<rect
										x='343.963'
										y='123.118'
										fill='#EBEBEB'
										width='115.399'
										height='11.111'
									/>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<path
															fill='#E0E0E0'
															d='M394.886,59.978c-0.006-0.003,0.017-0.054,0.067-0.151c0.063-0.116,0.138-0.256,0.23-0.426
										c0.21-0.37,0.537-0.906,1.004-1.567c0.941-1.298,2.458-3.169,4.958-4.686c1.246-0.742,2.742-1.384,4.449-1.634
										c1.698-0.242,3.587-0.116,5.405,0.567c1.807,0.664,3.567,1.929,4.624,3.787c1.063,1.854,1.405,4.033,2.027,6.114
										c0.316,1.042,0.714,2.044,1.291,2.916c0.579,0.871,1.368,1.557,2.196,2.164c0.827,0.61,1.692,1.159,2.476,1.8
										c0.784,0.642,1.462,1.384,2.011,2.179c1.105,1.595,1.728,3.361,2.061,5.034c0.338,1.68,0.372,3.286,0.276,4.73
										c-0.204,2.896-0.814,5.169-1.21,6.728c-0.205,0.78-0.378,1.382-0.497,1.789c-0.12,0.407-0.19,0.619-0.202,0.616
										c-0.012-0.003,0.032-0.222,0.129-0.635c0.105-0.444,0.248-1.046,0.428-1.805c0.352-1.567,0.919-3.843,1.087-6.708
										c0.078-1.43,0.029-3.008-0.316-4.652c-0.34-1.638-0.961-3.357-2.04-4.898c-0.537-0.769-1.192-1.479-1.954-2.099
										c-0.764-0.62-1.624-1.164-2.467-1.782c-0.841-0.614-1.674-1.332-2.288-2.252c-0.609-0.917-1.02-1.957-1.341-3.016
										c-0.628-2.118-0.97-4.265-1.986-6.04c-1.003-1.773-2.68-2.994-4.426-3.646c-1.754-0.669-3.59-0.805-5.24-0.583
										c-1.656,0.228-3.127,0.842-4.358,1.554c-2.471,1.459-4.006,3.282-4.976,4.544c-0.481,0.643-0.827,1.164-1.057,1.519
										C395.021,59.799,394.897,59.984,394.886,59.978z'
														/>
													</g>
												</g>
											</g>
											<g>
												<g>
													<path
														fill='#E0E0E0'
														d='M422.109,67.612c-0.574-0.533-0.853-0.162-1.623-0.022c-0.771,0.14-1.468,0.55-2.081,1.038
									c-2.666,2.125-3.844,5.941-2.841,9.199c2.451,0.134,4.929-1.168,6.209-3.262c1.28-2.095,1.309-4.893,0.073-7.014'
													/>
												</g>
											</g>
											<g>
												<g>
													<path
														fill='#E0E0E0'
														d='M398.561,56.893c-0.288-0.728-0.7-0.513-1.456-0.718c-0.756-0.205-1.562-0.135-2.325,0.042
									c-3.32,0.771-6.026,3.709-6.522,7.082c2.155,1.175,4.952,1.066,7.009-0.274c2.057-1.34,3.287-3.854,3.083-6.3'
													/>
												</g>
											</g>
											<g>
												<g>
													<path
														fill='#E0E0E0'
														d='M427.295,82.118c-1.56-1.803-3.972-2.836-6.354-2.72c-2.382,0.116-4.682,1.378-6.059,3.324
									c1.739,1.594,4.079,2.516,6.437,2.539c2.359,0.022,4.715-1.063,6.485-2.623'
													/>
												</g>
											</g>
											<g>
												<g>
													<path
														fill='#E0E0E0'
														d='M425.361,71.285c2.392,0.069,4.793-1.002,6.339-2.829c1.546-1.827,2.206-4.371,1.742-6.719
									c-2.505-0.574-5.291,0.324-6.991,2.251c-1.699,1.928-2.24,4.805-1.356,7.218'
													/>
												</g>
											</g>
											<g>
												<g>
													<path
														fill='#E0E0E0'
														d='M417.073,58.549c2.233,0.86,4.853,0.648,6.919-0.561c2.066-1.209,3.533-3.39,3.876-5.758
									c-2.172-1.374-5.098-1.453-7.342-0.2c-2.243,1.253-3.709,3.788-3.678,6.357'
													/>
												</g>
											</g>
											<g>
												<g>
													<path
														fill='#E0E0E0'
														d='M414.295,54.391c-2.233-0.861-4.853-0.648-6.919,0.561c-2.065,1.209-3.533,3.39-3.876,5.758
									c2.172,1.374,5.098,1.453,7.341,0.2c2.243-1.253,3.709-3.788,3.678-6.357'
													/>
												</g>
											</g>
											<g>
												<g>
													<path
														fill='#E0E0E0'
														d='M410.983,52.152c2.267-0.767,4.146-2.605,4.96-4.856c0.815-2.25,0.549-4.866-0.702-6.906
									c-2.548,0.333-4.849,2.143-5.772,4.541c-0.923,2.398-0.43,5.284,1.238,7.239'
													/>
												</g>
											</g>
											<g>
												<g>
													<path
														fill='#E0E0E0'
														d='M402.496,52.557c0.816-2.25,0.551-4.865-0.698-6.906c-1.25-2.041-3.459-3.465-5.834-3.761
									c-1.33,2.199-1.352,5.126-0.054,7.344c1.298,2.218,3.861,3.633,6.429,3.551'
													/>
												</g>
											</g>
										</g>
										<g>
											<g>
												<polyline
													fill='#EBEBEB'
													points='436.711,111.173 441.233,81.548 406.578,81.987 409.997,110.785 							'
												/>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<path
																fill='#F5F5F5'
																d='M421.758,76.363c-1.861,1.163-4.044,1.347-5.751,0.487
											c-1.707-0.861-2.902-2.748-3.147-4.972c1.91-0.913,3.996-1.02,5.741-0.294c1.745,0.726,3.133,2.278,3.819,4.272'
															/>
														</g>
													</g>
													<g>
														<g>
															<path
																fill='#F5F5F5'
																d='M405.916,82.475c-1.861,1.163-4.044,1.348-5.751,0.487
											c-1.707-0.86-2.901-2.748-3.146-4.972c1.91-0.913,3.996-1.02,5.741-0.294c1.745,0.726,3.133,2.279,3.818,4.272'
															/>
														</g>
													</g>
													<g>
														<g>
															<path
																fill='#F5F5F5'
																d='M414.808,68.826c-0.915,1.994-2.637,3.349-4.536,3.569c-1.899,0.22-3.935-0.7-5.362-2.423
											c1.093-1.813,2.777-3.049,4.633-3.402c1.857-0.353,3.87,0.18,5.539,1.468'
															/>
														</g>
													</g>
													<g>
														<g>
															<path
																fill='#F5F5F5'
																d='M409.026,75.898c2.194-0.032,4.157,0.94,5.173,2.56c1.015,1.62,1.061,3.853,0.119,5.882
											c-2.107-0.207-3.947-1.195-5.065-2.72c-1.117-1.524-1.501-3.571-1.056-5.632'
															/>
														</g>
													</g>
													<g>
														<g>
															<path
																fill='#F5F5F5'
																d='M403.444,87.07c0.955,1.975,0.903,4.166-0.137,5.77c-1.04,1.604-3.045,2.588-5.282,2.591
											c-0.702-1.997-0.583-4.083,0.328-5.739c0.91-1.657,2.552-2.475,4.608-2.942'
															/>
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<path
																fill='#F5F5F5'
																d='M394.235,88.383c0.033,0.043,0.376-0.5,1.37-0.873c0.49-0.188,1.131-0.304,1.879-0.329
											c0.75-0.032,1.601,0.072,2.558,0.15c0.948,0.065,2.043,0.13,3.148-0.27c1.116-0.386,2.036-1.359,2.599-2.512
											c0.592-1.154,0.861-2.492,1.12-3.833c0.269-1.336,0.558-2.741,1.335-3.934c0.767-1.201,1.885-2.096,2.93-2.978
											c1.052-0.878,2.082-1.766,2.805-2.846c1.437-2.17,1.682-4.63,1.395-6.522c-0.288-1.92-1.046-3.346-1.701-4.193
											c-0.339-0.42-0.613-0.733-0.832-0.915c-0.207-0.193-0.319-0.288-0.328-0.279c-0.009,0.01,0.086,0.124,0.274,0.332
											c0.199,0.197,0.452,0.523,0.767,0.948c0.607,0.858,1.309,2.27,1.556,4.143c0.247,1.847-0.026,4.225-1.418,6.292
											c-0.695,1.024-1.699,1.885-2.754,2.758c-1.045,0.879-2.197,1.788-3.01,3.058c-0.825,1.27-1.114,2.727-1.38,4.065
											c-0.256,1.349-0.511,2.642-1.069,3.75c-0.533,1.107-1.374,2.006-2.399,2.375c-1.019,0.385-2.08,0.346-3.021,0.296
											c-0.946-0.059-1.817-0.147-2.585-0.095c-0.765,0.046-1.424,0.187-1.923,0.403c-0.503,0.209-0.843,0.473-1.043,0.675
											c-0.106,0.095-0.168,0.185-0.214,0.242C394.25,88.348,394.23,88.379,394.235,88.383z'
															/>
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<path
																fill='#F5F5F5'
																d='M394.235,88.383c0.033,0.043,0.376-0.5,1.37-0.873c0.49-0.188,1.131-0.304,1.879-0.329
											c0.75-0.032,1.601,0.072,2.558,0.15c0.948,0.065,2.043,0.13,3.148-0.27c1.116-0.386,2.036-1.359,2.599-2.512
											c0.592-1.154,0.861-2.492,1.12-3.833c0.269-1.336,0.558-2.741,1.335-3.934c0.767-1.201,1.885-2.096,2.93-2.978
											c1.052-0.878,2.082-1.766,2.805-2.846c1.437-2.17,1.682-4.63,1.395-6.522c-0.288-1.92-1.046-3.346-1.701-4.193
											c-0.339-0.42-0.613-0.733-0.832-0.915c-0.207-0.193-0.319-0.288-0.328-0.279c-0.009,0.01,0.086,0.124,0.274,0.332
											c0.199,0.197,0.452,0.523,0.767,0.948c0.607,0.858,1.309,2.27,1.556,4.143c0.247,1.847-0.026,4.225-1.418,6.292
											c-0.695,1.024-1.699,1.885-2.754,2.758c-1.045,0.879-2.197,1.788-3.01,3.058c-0.825,1.27-1.114,2.727-1.38,4.065
											c-0.256,1.349-0.511,2.642-1.069,3.75c-0.533,1.107-1.374,2.006-2.399,2.375c-1.019,0.385-2.08,0.346-3.021,0.296
											c-0.946-0.059-1.817-0.147-2.585-0.095c-0.765,0.046-1.424,0.187-1.923,0.403c-0.503,0.209-0.843,0.473-1.043,0.675
											c-0.106,0.095-0.168,0.185-0.214,0.242C394.25,88.348,394.23,88.379,394.235,88.383z'
															/>
														</g>
													</g>
												</g>
											</g>
											<g>
												<g>
													<g>
														<g>
															<path
																fill='#F5F5F5'
																d='M460.385,91.291c-0.144-2.894,1.255-5.821,3.598-7.526c2.343-1.705,5.558-2.137,8.268-1.11
											c-1.999,4.598-6.571,7.973-11.554,8.528'
															/>
														</g>
													</g>
													<g>
														<g>
															<path
																fill='#F5F5F5'
																d='M462.674,92.499c1.694,1.86,1.585,4.854,0.373,7.059c-1.212,2.204-3.299,3.776-5.389,5.176
											c-0.704-2.371-0.608-4.972,0.27-7.284c0.878-2.312,2.532-4.322,4.632-5.628'
															/>
														</g>
													</g>
													<g>
														<g>
															<path
																fill='#F5F5F5'
																d='M454.464,83.773c-0.269,2.707-1.684,5.281-3.825,6.959
											c-2.141,1.678-4.979,2.437-7.671,2.052c0.657-2.723,1.75-5.467,3.854-7.316c2.104-1.849,5.422-2.536,7.751-0.98'
															/>
														</g>
													</g>
													<g>
														<g>
															<path
																fill='#F5F5F5'
																d='M454.519,80.994c0.425-2.479,2.871-4.278,5.376-4.511c2.504-0.234,4.981,0.836,6.989,2.351
											c-1.503,1.722-3.677,2.842-5.952,3.066c-2.274,0.225-4.626-0.449-6.437-1.843'
															/>
														</g>
													</g>
													<g>
														<g>
															<path
																fill='#F5F5F5'
																d='M467.429,102.084c2.38,2.032,2.975,5.65,1.987,8.619c-0.989,2.969-3.305,5.335-5.908,7.072
											c-1-2.943-1.626-6.098-1.099-9.161c0.527-3.063,2.372-6.021,5.219-7.269'
															/>
														</g>
													</g>
													<g>
														<g>
															<path
																fill='#F5F5F5'
																d='M454.905,74.037c-1.254-1.848-3.395-3.063-5.624-3.194
											c-2.229-0.13-4.497,0.827-5.958,2.516c0.965,2.166,3.238,3.683,5.609,3.744c2.371,0.061,4.719-1.337,5.794-3.451'
															/>
														</g>
													</g>
													<g>
														<g>
															<path
																fill='#F5F5F5'
																d='M451.988,66.888c2.562,0.779,5.45,0.409,7.732-0.991c2.283-1.4,3.922-3.806,4.39-6.442
											c-1.907-0.169-3.841-0.254-5.726,0.083c-1.885,0.337-3.733,1.126-5.07,2.497
											C451.978,63.407,451.599,65.013,451.988,66.888'
															/>
														</g>
													</g>
													<g>
														<g>
															<path
																fill='#F5F5F5'
																d='M446.491,65.038c1.767-1.445,2.843-3.701,2.852-5.984c0.009-2.283-1.047-4.548-2.803-6.007
											c-1.713,2.082-2.893,4.598-3.398,7.245c-0.189,0.992-0.282,2.036,0.026,2.998c0.309,0.962,1.098,1.824,2.098,1.963'
															/>
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#F5F5F5'
																		d='M436.986,82.461c0.007-0.001,0.007-0.067,0-0.196c-0.009-0.15-0.021-0.338-0.035-0.571
													c-0.032-0.501-0.079-1.24-0.096-2.192c-0.005-1.882-0.002-4.725,1.288-7.874c0.66-1.548,1.66-3.165,3.166-4.455
													c1.477-1.307,3.54-2.178,5.763-2.12c1.101,0.021,2.236,0.272,3.251,0.814c1.026,0.522,1.884,1.381,2.512,2.398
													c1.256,2.072,1.567,4.674,1.458,7.239c-0.091,2.585-0.46,5.136-0.173,7.619c0.146,1.234,0.442,2.437,0.949,3.532
													c0.497,1.094,1.215,2.079,2.132,2.794c0.912,0.729,1.974,1.138,2.968,1.519c1.003,0.375,1.964,0.741,2.799,1.269
													c1.686,1.041,2.912,2.508,3.675,4.004c0.772,1.503,1.056,3.043,1.061,4.381c0.009,1.344-0.252,2.491-0.555,3.386
													c-0.628,1.798-1.365,2.606-1.311,2.635c0.005,0.004,0.048-0.046,0.127-0.148c0.077-0.104,0.201-0.252,0.334-0.466
													c0.279-0.419,0.658-1.067,0.988-1.971c0.329-0.9,0.617-2.061,0.627-3.434c0.015-1.365-0.257-2.95-1.034-4.501
													c-0.768-1.543-2.015-3.063-3.747-4.149c-1.714-1.141-3.976-1.424-5.718-2.795c-1.749-1.374-2.662-3.712-2.93-6.097
													c-0.276-2.418,0.089-4.951,0.183-7.563c0.109-2.597-0.201-5.278-1.52-7.445c-0.659-1.065-1.572-1.975-2.658-2.523
													c-1.073-0.569-2.263-0.825-3.411-0.843c-2.314-0.052-4.462,0.873-5.973,2.231c-1.544,1.342-2.55,3.006-3.208,4.589
													c-0.653,1.598-0.944,3.142-1.091,4.494c-0.139,1.356-0.133,2.531-0.098,3.486c0.04,0.956,0.11,1.695,0.167,2.195
													c0.029,0.232,0.053,0.418,0.072,0.568C436.967,82.396,436.979,82.461,436.986,82.461z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
											<g>
												<g>
													<g>
														<path
															fill='#F5F5F5'
															d='M386.529,76.267c0.063-0.013-0.48-0.853-0.518-2.54c-0.007-0.831,0.139-1.874,0.675-2.931
										c0.525-1.051,1.485-2.089,2.826-2.73c0.666-0.321,1.423-0.543,2.226-0.601c0.803-0.062,1.647,0.056,2.491,0.292
										c1.698,0.446,3.378,1.411,5.386,1.81c0.992,0.193,2.077,0.252,3.123-0.077c1.052-0.313,1.952-1.09,2.544-2.032
										c1.21-1.877,1.551-4.232,3.013-5.963c0.717-0.859,1.7-1.449,2.737-1.789c1.038-0.352,2.143-0.426,3.198-0.324
										c2.143,0.177,4.008,1.316,5.32,2.767c1.322,1.462,2.145,3.203,2.64,4.884c0.5,1.691,0.658,3.339,0.59,4.84
										c-0.125,3.007-0.628,5.415-0.899,7.079c-0.14,0.809-0.25,1.449-0.331,1.918c-0.03,0.202-0.055,0.366-0.075,0.501
										c-0.015,0.113-0.019,0.172-0.013,0.173c0.006,0.001,0.023-0.055,0.05-0.166c0.03-0.133,0.067-0.295,0.111-0.494
										c0.098-0.466,0.232-1.102,0.4-1.905c0.315-1.659,0.859-4.058,1.021-7.097c0.086-1.517-0.059-3.21-0.556-4.936
										c-0.492-1.72-1.323-3.511-2.683-5.03c-0.683-0.752-1.493-1.428-2.431-1.946c-0.937-0.519-2.002-0.846-3.108-0.955
										c-1.103-0.109-2.258-0.034-3.352,0.335c-1.092,0.356-2.139,0.982-2.906,1.9c-1.547,1.854-1.878,4.226-3.036,6.007
										c-0.562,0.893-1.378,1.596-2.343,1.89c-0.961,0.307-1.991,0.261-2.949,0.079c-1.934-0.37-3.623-1.322-5.37-1.771
										c-0.868-0.236-1.75-0.354-2.595-0.281c-0.843,0.069-1.631,0.309-2.32,0.651c-1.389,0.683-2.372,1.777-2.898,2.875
										c-0.536,1.103-0.662,2.178-0.633,3.027c0.028,0.857,0.221,1.502,0.374,1.92C386.395,76.071,386.529,76.267,386.529,76.267z
										'
														/>
													</g>
												</g>
											</g>
											<g>
												<g>
													<path
														fill='#F5F5F5'
														d='M406.795,63.692c-1.861,1.162-4.472,1.267-6.18,0.407c-1.707-0.86-2.901-2.748-3.146-4.972
									c1.91-0.913,3.996-1.02,5.741-0.294c1.745,0.726,3.133,2.278,3.819,4.272'
													/>
												</g>
											</g>
											<g>
												<g>
													<path
														fill='#F5F5F5'
														d='M400.227,69.652c0.024,2.194-1.246,4.479-2.869,5.488c-1.623,1.01-3.856,1.048-5.883,0.099
									c0.214-2.106,1.208-3.943,2.737-5.056c1.528-1.112,3.576-1.489,5.635-1.037'
													/>
												</g>
											</g>
											<g>
												<g>
													<path
														fill='#F5F5F5'
														d='M388.829,68.33c-1.974,0.958-4.582,0.783-6.188-0.255c-1.606-1.037-2.592-3.041-2.598-5.279
									c1.996-0.705,4.082-0.588,5.74,0.32c1.658,0.908,2.872,2.6,3.341,4.655'
													/>
												</g>
											</g>
											<g>
												<g>
													<path
														fill='#F5F5F5'
														d='M416.367,59.746c1.661-1.434,2.773-3.948,2.456-5.833c-0.317-1.885-1.767-3.584-3.819-4.475
									c-1.437,1.554-2.156,3.516-1.978,5.398c0.178,1.882,1.251,3.666,2.952,4.91'
													/>
												</g>
											</g>
											<g>
												<g>
													<g>
														<g>
															<path
																fill='#F5F5F5'
																d='M460.866,51.586c0.006-0.003-0.017-0.054-0.067-0.151c-0.063-0.116-0.138-0.256-0.23-0.426
											c-0.21-0.37-0.537-0.906-1.004-1.567c-0.941-1.298-2.458-3.169-4.958-4.686c-1.246-0.741-2.742-1.384-4.449-1.634
											c-1.698-0.242-3.587-0.116-5.405,0.567c-1.807,0.664-3.567,1.929-4.624,3.787c-1.063,1.854-1.405,4.033-2.027,6.114
											c-0.316,1.042-0.714,2.044-1.291,2.916c-0.579,0.871-1.369,1.557-2.196,2.164c-0.827,0.61-1.692,1.16-2.476,1.8
											c-0.784,0.642-1.462,1.384-2.011,2.179c-1.105,1.595-1.728,3.361-2.061,5.034c-0.338,1.679-0.372,3.286-0.276,4.73
											c0.204,2.896,0.814,5.169,1.21,6.728c0.205,0.78,0.378,1.382,0.497,1.789c0.12,0.407,0.19,0.619,0.202,0.616
											c0.013-0.003-0.032-0.222-0.129-0.635c-0.105-0.444-0.248-1.046-0.428-1.805c-0.352-1.567-0.919-3.843-1.087-6.709
											c-0.078-1.429-0.029-3.007,0.316-4.652c0.34-1.638,0.961-3.357,2.04-4.898c0.537-0.769,1.192-1.479,1.954-2.099
											c0.764-0.62,1.624-1.164,2.467-1.782c0.841-0.614,1.674-1.332,2.288-2.252c0.609-0.917,1.02-1.957,1.341-3.017
											c0.628-2.117,0.97-4.265,1.986-6.04c1.003-1.773,2.681-2.994,4.426-3.646c1.754-0.669,3.59-0.805,5.24-0.583
											c1.656,0.228,3.127,0.842,4.357,1.555c2.471,1.459,4.006,3.281,4.976,4.544c0.482,0.643,0.827,1.164,1.057,1.519
											C460.73,51.407,460.855,51.593,460.866,51.586z'
															/>
														</g>
													</g>
												</g>
												<g>
													<g>
														<path
															fill='#F5F5F5'
															d='M433.643,59.22c0.574-0.533,0.853-0.162,1.623-0.022c0.77,0.14,1.468,0.55,2.081,1.039
										c2.665,2.125,3.844,5.941,2.841,9.199c-2.451,0.134-4.929-1.168-6.209-3.262c-1.28-2.094-1.309-4.893-0.073-7.014'
														/>
													</g>
												</g>
												<g>
													<g>
														<path
															fill='#F5F5F5'
															d='M457.191,48.501c0.288-0.728,0.7-0.513,1.456-0.718c0.756-0.205,1.562-0.135,2.325,0.042
										c3.32,0.771,6.026,3.709,6.522,7.082c-2.155,1.175-4.952,1.066-7.009-0.274c-2.057-1.34-3.287-3.854-3.083-6.3'
														/>
													</g>
												</g>
												<g>
													<g>
														<path
															fill='#F5F5F5'
															d='M428.457,73.727c1.56-1.803,3.972-2.836,6.353-2.72c2.382,0.116,4.682,1.378,6.059,3.324
										c-1.739,1.594-4.078,2.516-6.437,2.538c-2.359,0.022-4.715-1.063-6.485-2.623'
														/>
													</g>
												</g>
												<g>
													<g>
														<path
															fill='#F5F5F5'
															d='M430.391,62.893c-2.392,0.069-4.793-1.002-6.339-2.829
										c-1.546-1.827-2.206-4.371-1.742-6.719c2.505-0.574,5.291,0.324,6.991,2.251c1.699,1.928,2.24,4.805,1.356,7.218'
														/>
													</g>
												</g>
												<g>
													<g>
														<path
															fill='#F5F5F5'
															d='M438.678,50.158c-2.233,0.86-4.853,0.648-6.919-0.561c-2.066-1.209-3.533-3.39-3.876-5.758
										c2.172-1.374,5.098-1.453,7.342-0.2c2.243,1.253,3.709,3.788,3.677,6.357'
														/>
													</g>
												</g>
												<g>
													<g>
														<path
															fill='#F5F5F5'
															d='M441.457,46c2.233-0.861,4.853-0.648,6.919,0.561c2.065,1.209,3.533,3.39,3.876,5.759
										c-2.172,1.374-5.098,1.453-7.341,0.2c-2.243-1.254-3.709-3.788-3.678-6.357'
														/>
													</g>
												</g>
												<g>
													<g>
														<path
															fill='#F5F5F5'
															d='M444.769,43.761c-2.267-0.767-4.146-2.606-4.96-4.856c-0.815-2.25-0.549-4.866,0.702-6.906
										c2.548,0.333,4.849,2.143,5.772,4.541c0.923,2.398,0.43,5.284-1.237,7.239'
														/>
													</g>
												</g>
												<g>
													<g>
														<path
															fill='#F5F5F5'
															d='M453.256,44.166c-0.816-2.25-0.552-4.865,0.698-6.906c1.25-2.041,3.459-3.465,5.834-3.761
										c1.33,2.199,1.352,5.126,0.054,7.344c-1.298,2.218-3.861,3.633-6.429,3.551'
														/>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<polygon
												fill='#EBEBEB'
												points='441.674,107.74 439.022,111.173 406.41,111.173 404.188,107.74 						'
											/>
										</g>
									</g>
									<g>
										<g>
											<g>
												<path
													fill='#FAFAFA'
													d='M408.571,94.229c-0.01,0.009,0.06,0.1,0.203,0.268c0.141,0.17,0.356,0.414,0.664,0.698
								c0.605,0.576,1.565,1.343,2.912,2.021c1.347,0.659,3.09,1.227,5.091,1.307c0.996,0.046,2.051-0.034,3.115-0.261
								c1.062-0.232,2.123-0.609,3.175-1.052c2.102-0.895,4.013-1.915,5.875-2.507c1.849-0.613,3.635-0.785,5.074-0.49
								c1.447,0.269,2.497,0.908,3.112,1.444c0.619,0.544,0.855,0.95,0.888,0.926c0.011-0.007-0.045-0.108-0.164-0.295
								c-0.122-0.184-0.324-0.443-0.625-0.739c-0.6-0.585-1.668-1.284-3.158-1.596c-1.483-0.341-3.341-0.188-5.232,0.42
								c-1.906,0.587-3.838,1.607-5.915,2.491c-1.04,0.438-2.078,0.808-3.109,1.039c-1.031,0.225-2.051,0.31-3.021,0.275
								c-1.946-0.059-3.655-0.586-4.99-1.201C409.787,95.689,408.633,94.146,408.571,94.229z'
												/>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<path
													fill='#FAFAFA'
													d='M408.246,91.683c-0.009,0.009,0.068,0.096,0.224,0.253c0.154,0.159,0.387,0.386,0.714,0.65
								c0.644,0.535,1.645,1.248,3.018,1.873c1.374,0.609,3.124,1.139,5.126,1.224c0.998,0.049,2.052-0.019,3.123-0.21
								c1.069-0.199,2.147-0.525,3.224-0.912c2.152-0.782,4.128-1.679,6.016-2.207c1.88-0.542,3.661-0.705,5.116-0.464
								c1.462,0.215,2.554,0.768,3.229,1.234c0.676,0.476,0.974,0.837,1.001,0.811c0.01-0.009-0.06-0.101-0.204-0.27
								c-0.146-0.166-0.379-0.397-0.711-0.659c-0.663-0.514-1.773-1.123-3.272-1.378c-1.493-0.283-3.336-0.141-5.252,0.392
								c-1.926,0.518-3.92,1.413-6.05,2.187c-1.066,0.383-2.123,0.705-3.165,0.904c-1.043,0.19-2.067,0.264-3.04,0.226
								c-1.953-0.064-3.673-0.555-5.035-1.122C409.578,93.029,408.302,91.601,408.246,91.683z'
												/>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<rect
																x='390.403'
																y='78.409'
																fill='#EBEBEB'
																width='6.534'
																height='44.709'
															/>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path
																	fill='#FAFAFA'
																	d='M396.129,84.5c0,0.155-1.253,0.281-2.798,0.281c-1.546,0-2.799-0.126-2.799-0.281
												c0-0.155,1.253-0.281,2.799-0.281C394.876,84.219,396.129,84.345,396.129,84.5z'
																/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path
																	fill='#FAFAFA'
																	d='M396.25,83.527c0,0.155-1.253,0.281-2.798,0.281c-1.546,0-2.798-0.126-2.798-0.281
												c0-0.155,1.253-0.281,2.798-0.281C394.997,83.246,396.25,83.372,396.25,83.527z'
																/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path
																	fill='#FAFAFA'
																	d='M396.129,119.661c0,0.155-1.253,0.281-2.798,0.281c-1.546,0-2.799-0.126-2.799-0.281
												c0-0.155,1.253-0.281,2.799-0.281C394.876,119.38,396.129,119.506,396.129,119.661z'
																/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path
																	fill='#FAFAFA'
																	d='M396.25,118.688c0,0.155-1.253,0.281-2.798,0.281c-1.546,0-2.798-0.126-2.798-0.281
												c0-0.155,1.253-0.281,2.798-0.281C394.997,118.406,396.25,118.532,396.25,118.688z'
																/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path
																	fill='#FAFAFA'
																	d='M393.209,112.848c-0.155,0-0.281-5.121-0.281-11.436c0-6.318,0.126-11.437,0.281-11.437
												c0.155,0,0.281,5.119,0.281,11.437C393.49,107.727,393.364,112.848,393.209,112.848z'
																/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<rect
																x='358.1'
																y='78.272'
																fill='#EBEBEB'
																width='11.946'
																height='44.708'
															/>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path
																	fill='#FAFAFA'
																	d='M360.899,123.554c-0.009,0.004-0.056-0.078-0.139-0.243
												c-0.085-0.164-0.2-0.413-0.34-0.745c-0.272-0.665-0.656-1.668-0.867-3.031c-0.191-1.34-0.245-3.139,0.698-4.919
												c0.467-0.873,1.176-1.705,2.117-2.336c0.939-0.618,2.165-1.015,3.441-0.813c0.634,0.107,1.261,0.372,1.779,0.822
												c0.519,0.437,0.901,1.138,0.856,1.886c-0.033,0.749-0.463,1.425-1.022,1.895c-0.56,0.473-1.278,0.798-2.037,0.865
												c-1.515,0.177-3.085-0.461-4.233-1.542c-1.19-1.063-1.915-2.653-2.1-4.297c-0.196-1.654,0.066-3.365,0.619-4.988
												c0.56-1.622,1.47-3.226,2.928-4.357c0.725-0.555,1.625-0.956,2.579-1.016c0.951-0.083,1.974,0.221,2.684,0.952
												c0.354,0.364,0.591,0.872,0.613,1.399c0.026,0.527-0.146,1.037-0.411,1.462c-0.535,0.862-1.451,1.414-2.41,1.645
												c-1.953,0.42-3.988-0.353-5.262-1.763c-1.286-1.414-1.857-3.266-2.049-5.033c-0.178-1.779-0.046-3.569,0.514-5.218
												c0.546-1.642,1.627-3.12,3.086-3.97c1.426-0.865,3.23-1.148,4.746-0.497c0.75,0.306,1.416,0.997,1.543,1.824
												c0.15,0.806-0.072,1.63-0.544,2.252c-1.001,1.262-2.718,1.472-4.025,1.065c-1.344-0.414-2.373-1.318-3.174-2.254
												c-1.614-1.911-2.312-4.174-2.382-6.131c-0.07-1.978,0.475-3.641,1.103-4.858c0.637-1.226,1.362-2.032,1.878-2.533
												c0.264-0.247,0.472-0.427,0.621-0.535c0.147-0.112,0.225-0.165,0.231-0.158c0.078,0.057-1.304,0.895-2.534,3.324
												c-0.585,1.206-1.083,2.834-0.985,4.745c0.096,1.893,0.802,4.07,2.37,5.886c0.778,0.893,1.77,1.734,2.995,2.097
												c1.184,0.365,2.716,0.128,3.529-0.93c0.391-0.517,0.573-1.228,0.444-1.879c-0.111-0.663-0.615-1.189-1.269-1.462
												c-1.327-0.564-2.971-0.317-4.281,0.488c-1.34,0.79-2.329,2.145-2.844,3.7c-0.523,1.553-0.65,3.281-0.474,4.985
												c0.186,1.692,0.737,3.429,1.915,4.716c1.14,1.271,3.012,1.965,4.722,1.591c0.836-0.203,1.62-0.682,2.06-1.394
												c0.463-0.698,0.448-1.61-0.132-2.18c-0.557-0.578-1.423-0.857-2.238-0.781c-0.83,0.05-1.623,0.396-2.285,0.901
												c-1.333,1.025-2.218,2.547-2.752,4.091c-0.538,1.562-0.791,3.198-0.613,4.753c0.173,1.552,0.828,3.005,1.923,3.996
												c1.058,1.008,2.493,1.59,3.846,1.44c1.314-0.109,2.595-1.111,2.676-2.319c0.04-0.596-0.253-1.166-0.699-1.552
												c-0.443-0.393-1.005-0.641-1.569-0.741c-1.153-0.195-2.289,0.151-3.18,0.721c-0.892,0.582-1.58,1.363-2.037,2.188
												c-0.921,1.668-0.914,3.421-0.757,4.737c0.173,1.341,0.52,2.347,0.756,3.02
												C360.77,123.198,360.92,123.544,360.899,123.554z'
																/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<rect
																x='413.319'
																y='94.791'
																transform='matrix(6.123234e-17 -1 1 6.123234e-17 302.1462 536.4368)'
																fill='#E0E0E0'
																width='11.946'
																height='44.709'
															/>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path
																	fill='#FAFAFA'
																	d='M441.149,120.319c0.004,0.009-0.076,0.056-0.237,0.139c-0.16,0.085-0.403,0.2-0.727,0.34
												c-0.649,0.272-1.629,0.656-2.959,0.868c-1.308,0.191-3.065,0.245-4.802-0.698c-0.853-0.467-1.664-1.176-2.281-2.117
												c-0.603-0.938-0.991-2.165-0.794-3.441c0.104-0.634,0.364-1.261,0.802-1.779c0.427-0.519,1.111-0.9,1.841-0.856
												c0.731,0.033,1.391,0.463,1.85,1.022c0.462,0.56,0.779,1.278,0.844,2.037c0.173,1.515-0.45,3.085-1.505,4.233
												c-1.038,1.19-2.59,1.915-4.195,2.1c-1.615,0.196-3.285-0.066-4.87-0.619c-1.584-0.56-3.149-1.47-4.254-2.928
												c-0.542-0.724-0.934-1.625-0.992-2.579c-0.081-0.951,0.216-1.974,0.929-2.684c0.355-0.354,0.851-0.591,1.366-0.613
												c0.515-0.026,1.012,0.146,1.427,0.411c0.842,0.536,1.38,1.451,1.606,2.41c0.41,1.953-0.345,3.988-1.721,5.262
												c-1.38,1.286-3.189,1.857-4.914,2.049c-1.737,0.178-3.485,0.046-5.095-0.514c-1.603-0.546-3.046-1.627-3.876-3.086
												c-0.844-1.426-1.121-3.231-0.486-4.746c0.299-0.75,0.973-1.416,1.781-1.543c0.787-0.15,1.592,0.072,2.199,0.543
												c1.232,1.001,1.437,2.718,1.04,4.025c-0.404,1.344-1.286,2.373-2.2,3.174c-1.866,1.614-4.075,2.312-5.986,2.382
												c-1.931,0.07-3.555-0.476-4.743-1.104c-1.197-0.637-1.984-1.361-2.473-1.878c-0.241-0.264-0.417-0.472-0.523-0.621
												c-0.109-0.147-0.161-0.225-0.154-0.231c0.056-0.078,0.874,1.304,3.245,2.534c1.177,0.586,2.767,1.083,4.633,0.985
												c1.848-0.096,3.973-0.802,5.747-2.37c0.872-0.778,1.693-1.771,2.047-2.995c0.357-1.184,0.125-2.717-0.908-3.529
												c-0.505-0.39-1.199-0.573-1.835-0.443c-0.648,0.111-1.16,0.615-1.427,1.269c-0.551,1.327-0.31,2.971,0.477,4.281
												c0.772,1.34,2.094,2.329,3.612,2.844c1.517,0.523,3.203,0.65,4.867,0.474c1.652-0.186,3.347-0.737,4.605-1.915
												c1.241-1.14,1.919-3.012,1.554-4.722c-0.198-0.837-0.666-1.62-1.361-2.06c-0.682-0.463-1.572-0.448-2.128,0.132
												c-0.564,0.557-0.837,1.423-0.762,2.238c0.049,0.83,0.387,1.623,0.879,2.285c1.001,1.333,2.486,2.218,3.994,2.752
												c1.525,0.538,3.122,0.791,4.641,0.613c1.515-0.172,2.934-0.828,3.901-1.923c0.984-1.058,1.552-2.493,1.406-3.846
												c-0.106-1.314-1.084-2.595-2.264-2.676c-0.582-0.04-1.138,0.253-1.515,0.699c-0.384,0.443-0.626,1.005-0.724,1.569
												c-0.19,1.153,0.147,2.289,0.704,3.18c0.569,0.892,1.331,1.58,2.136,2.037c1.629,0.921,3.34,0.914,4.625,0.757
												c1.309-0.173,2.291-0.52,2.949-0.756C440.801,120.448,441.139,120.298,441.149,120.319z'
																/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<rect
																x='369.505'
																y='82.208'
																fill='#F5F5F5'
																width='20.726'
																height='40.716'
															/>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path
																	fill='#E0E0E0'
																	d='M389.519,88.974c0,0.155-4.491,0.281-10.03,0.281c-5.541,0-10.032-0.126-10.032-0.281
												c0-0.155,4.491-0.281,10.032-0.281C385.028,88.693,389.519,88.818,389.519,88.974z'
																/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path
																	fill='#E0E0E0'
																	d='M389.777,92.06c0,0.155-4.434,0.281-9.902,0.281c-5.47,0-9.903-0.126-9.903-0.281
												c0-0.155,4.433-0.281,9.903-0.281C385.343,91.779,389.777,91.905,389.777,92.06z'
																/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path
																	fill='#E0E0E0'
																	d='M390.442,118.322c0,0.155-4.669,0.281-10.428,0.281c-5.76,0-10.428-0.126-10.428-0.281
												c0-0.155,4.668-0.281,10.428-0.281C385.773,118.041,390.442,118.167,390.442,118.322z'
																/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path
																	fill='#E0E0E0'
																	d='M390.162,114.694c0,0.155-4.549,0.281-10.159,0.281c-5.612,0-10.16-0.126-10.16-0.281
												c0-0.155,4.548-0.281,10.16-0.281C385.614,114.413,390.162,114.538,390.162,114.694z'
																/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
						<g>
							<g>
								<rect
									x='165.662'
									y='382.574'
									fill='#FAFAFA'
									width='49.493'
									height='13.093'
								/>
							</g>
							<g>
								<g>
									<path
										fill='#E0E0E0'
										d='M194.227,409.459c-0.005-0.005-0.01-0.085-0.015-0.238c-0.004-0.175-0.01-0.396-0.016-0.672
					c-0.009-0.616-0.021-1.475-0.037-2.577c-0.02-2.26-0.049-5.51-0.085-9.607l0.153,0.153c-11.301,0.023-29.273,0.059-49.49,0.099
					c0.111-0.112-0.254,0.251,0.249-0.252v0.003v0.007v0.013v0.026v0.053v0.105v0.211l0,0.421v0.84c0,0.559,0,1.115,0,1.67
					c0,1.11-0.001,2.212-0.001,3.304c-0.001,2.185-0.003,4.335-0.004,6.44l-0.247-0.247c14.093,0.045,26.425,0.084,35.27,0.112
					c4.406,0.03,7.941,0.054,10.41,0.07c1.201,0.014,2.142,0.025,2.815,0.032c0.303,0.007,0.545,0.012,0.736,0.016
					C194.134,409.448,194.222,409.453,194.227,409.459c0.005,0.005-0.072,0.011-0.229,0.016c-0.183,0.004-0.415,0.009-0.706,0.016
					c-0.66,0.008-1.583,0.019-2.762,0.033c-2.456,0.017-5.972,0.041-10.354,0.07c-8.888,0.028-21.28,0.067-35.442,0.112
					l-0.246,0.001l0-0.247c-0.001-2.105-0.003-4.255-0.004-6.44c0-1.093-0.001-2.194-0.001-3.304c0-0.555,0-1.112,0-1.67l0-0.84
					v-0.421v-0.211v-0.105v-0.053v-0.026v-0.013v-0.007v-0.003c0.505-0.505,0.142-0.14,0.255-0.253
					c20.217,0.04,38.189,0.076,49.49,0.099l0.155,0l-0.001,0.153c-0.036,4.136-0.065,7.416-0.085,9.698
					c-0.016,1.087-0.028,1.934-0.037,2.542c-0.007,0.264-0.012,0.477-0.016,0.645C194.238,409.394,194.233,409.464,194.227,409.459z
					'
									/>
								</g>
							</g>
						</g>
						<g>
							<g>
								<rect
									x='89.684'
									y='231.852'
									fill='#FAFAFA'
									width='49.493'
									height='13.093'
								/>
							</g>
							<g>
								<g>
									<path
										fill='#E0E0E0'
										d='M118.249,258.737c-0.005-0.005-0.01-0.085-0.015-0.238c-0.004-0.175-0.009-0.396-0.016-0.672
					c-0.009-0.616-0.021-1.475-0.037-2.577c-0.02-2.26-0.049-5.51-0.085-9.607l0.153,0.153c-11.301,0.023-29.273,0.059-49.49,0.099
					c0.112-0.112-0.254,0.251,0.249-0.252v0.003v0.007v0.013v0.026v0.053v0.105v0.211l0,0.421v0.84c0,0.559,0,1.115,0,1.67
					c0,1.11-0.001,2.212-0.001,3.304c-0.001,2.185-0.003,4.335-0.004,6.44l-0.247-0.247c14.093,0.045,26.425,0.084,35.27,0.112
					c4.406,0.03,7.941,0.054,10.41,0.07c1.201,0.014,2.142,0.025,2.815,0.032c0.303,0.007,0.545,0.012,0.736,0.016
					C118.156,258.726,118.244,258.731,118.249,258.737c0.005,0.005-0.072,0.011-0.229,0.016c-0.183,0.004-0.416,0.009-0.706,0.016
					c-0.66,0.008-1.583,0.019-2.762,0.033c-2.456,0.017-5.972,0.041-10.354,0.07c-8.888,0.028-21.28,0.067-35.442,0.112
					l-0.246,0.001l0-0.247c-0.001-2.105-0.003-4.255-0.004-6.44c0-1.093-0.001-2.194-0.001-3.304c0-0.555,0-1.112,0-1.67l0-0.84
					v-0.421v-0.211v-0.105v-0.053v-0.026v-0.013v-0.007v-0.003c0.505-0.505,0.142-0.14,0.255-0.253
					c20.217,0.04,38.189,0.076,49.49,0.099l0.155,0l-0.001,0.153c-0.036,4.136-0.065,7.416-0.085,9.698
					c-0.016,1.087-0.028,1.935-0.037,2.542c-0.006,0.264-0.012,0.477-0.016,0.645C118.259,258.672,118.254,258.742,118.249,258.737z
					'
									/>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<path
										fill='#E0E0E0'
										d='M402.585,205.867c-0.005-0.005-0.01-0.085-0.015-0.238c-0.004-0.175-0.009-0.396-0.016-0.672
					c-0.009-0.616-0.021-1.475-0.037-2.577c-0.02-2.26-0.049-5.51-0.085-9.607l0.153,0.153c-11.301,0.023-29.273,0.058-49.49,0.099
					c0.111-0.112-0.254,0.251,0.249-0.252v0.003v0.007v0.013v0.026v0.053v0.105l0,0.211v0.421v0.84c0,0.559,0,1.115,0,1.67
					c0,1.11-0.001,2.211-0.001,3.304c-0.001,2.185-0.003,4.335-0.004,6.44l-0.247-0.247c14.094,0.045,26.425,0.084,35.27,0.112
					c4.406,0.03,7.941,0.054,10.411,0.07c1.201,0.014,2.142,0.025,2.815,0.033c0.303,0.006,0.545,0.012,0.736,0.016
					C402.491,205.857,402.579,205.862,402.585,205.867c0.005,0.005-0.072,0.011-0.229,0.016c-0.183,0.004-0.415,0.009-0.706,0.016
					c-0.66,0.008-1.583,0.019-2.762,0.032c-2.456,0.017-5.972,0.041-10.354,0.07c-8.888,0.028-21.28,0.067-35.442,0.112
					l-0.247,0.001l0-0.248c-0.001-2.105-0.003-4.255-0.004-6.44c0-1.093-0.001-2.194-0.001-3.304c0-0.555,0-1.112,0-1.67l0-0.84
					v-0.421v-0.211v-0.105v-0.053v-0.026v-0.013v-0.007v-0.003c0.505-0.505,0.142-0.14,0.255-0.253
					c20.217,0.04,38.189,0.076,49.49,0.099l0.155,0l-0.001,0.153c-0.036,4.136-0.065,7.416-0.085,9.698
					c-0.016,1.087-0.028,1.934-0.037,2.542c-0.007,0.265-0.012,0.477-0.016,0.645C402.595,205.802,402.59,205.872,402.585,205.867z'
									/>
								</g>
							</g>
							<g>
								<g>
									<path
										fill='#E0E0E0'
										d='M409.295,192.774c-0.006-0.006-0.013-0.091-0.019-0.249c-0.005-0.188-0.012-0.416-0.02-0.694
					c-0.011-0.644-0.026-1.52-0.045-2.625c-0.023-2.296-0.057-5.538-0.098-9.525l0.181,0.181
					c-7.704,0.018-18.184,0.043-29.735,0.071c-0.227,0.225,0.305-0.307,0.25-0.251v0.003v0.007v0.013v0.027v0.054v0.108l0,0.215
					v0.43l0,0.856c0,0.569,0,1.135-0.001,1.698c-0.001,1.125-0.002,2.237-0.002,3.333c-0.003,2.19-0.006,4.314-0.009,6.348
					l-0.24-0.24c8.585,0.046,15.975,0.086,21.272,0.114c2.612,0.028,4.706,0.05,6.197,0.066c0.696,0.012,1.25,0.022,1.671,0.03
					C409.081,192.754,409.285,192.764,409.295,192.774c0.01,0.01-0.175,0.02-0.54,0.03c-0.408,0.007-0.946,0.018-1.621,0.03
					c-1.477,0.016-3.552,0.038-6.142,0.066c-5.337,0.028-12.784,0.068-21.434,0.114l-0.24,0.001l0-0.241
					c-0.003-2.035-0.006-4.158-0.009-6.348c-0.001-1.095-0.002-2.207-0.002-3.333c0-0.563,0-1.129-0.001-1.698l0-0.856v-0.43v-0.215
					v-0.108v-0.054v-0.027v-0.013v-0.007v-0.003c-0.055,0.053,0.478-0.479,0.254-0.254c11.551,0.028,22.031,0.053,29.735,0.071
					l0.183,0l-0.002,0.181c-0.041,4.033-0.074,7.313-0.098,9.635c-0.019,1.088-0.034,1.95-0.045,2.585
					C409.32,192.486,409.308,192.787,409.295,192.774z'
									/>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<path
										fill='#E0E0E0'
										d='M232.551,47.483c-0.005-0.005-0.01-0.085-0.015-0.238c-0.004-0.175-0.01-0.396-0.016-0.672
					c-0.009-0.616-0.021-1.475-0.037-2.577c-0.02-2.26-0.049-5.51-0.085-9.607l0.153,0.153c-11.301,0.023-29.273,0.058-49.49,0.099
					c0.111-0.112-0.254,0.252,0.249-0.252v0.003V34.4v0.013v0.026v0.053v0.105l0,0.211v0.421v0.839c0,0.559,0,1.116,0,1.67
					c0,1.11-0.001,2.211-0.001,3.304c-0.001,2.185-0.003,4.335-0.004,6.44l-0.247-0.247c14.094,0.045,26.425,0.084,35.27,0.112
					c4.406,0.03,7.941,0.054,10.41,0.07c1.201,0.014,2.142,0.025,2.815,0.032c0.303,0.006,0.546,0.012,0.736,0.016
					C232.458,47.473,232.546,47.478,232.551,47.483c0.005,0.005-0.072,0.011-0.229,0.016c-0.183,0.004-0.416,0.009-0.706,0.016
					c-0.66,0.008-1.583,0.019-2.762,0.032c-2.456,0.017-5.972,0.041-10.354,0.07c-8.888,0.028-21.28,0.067-35.442,0.112
					l-0.247,0.001l0-0.248c-0.001-2.105-0.003-4.255-0.004-6.44c0-1.093-0.001-2.194-0.001-3.304c0-0.555,0-1.112,0-1.67l0-0.839
					v-0.421v-0.211v-0.105V34.44v-0.026V34.4v-0.007V34.39c0.505-0.505,0.142-0.14,0.255-0.252
					c20.217,0.04,38.189,0.076,49.49,0.099l0.155,0l-0.001,0.153c-0.036,4.136-0.065,7.416-0.085,9.698
					c-0.016,1.087-0.028,1.934-0.037,2.542c-0.007,0.265-0.012,0.477-0.016,0.645C232.561,47.418,232.556,47.488,232.551,47.483z'
									/>
								</g>
							</g>
							<g>
								<g>
									<path
										fill='#E0E0E0'
										d='M239.262,34.39c-0.006-0.006-0.013-0.091-0.019-0.249c-0.005-0.188-0.012-0.416-0.02-0.694
					c-0.011-0.644-0.026-1.52-0.045-2.625c-0.023-2.296-0.057-5.538-0.098-9.525l0.181,0.181
					c-7.704,0.018-18.184,0.043-29.735,0.071c-0.226,0.225,0.305-0.307,0.25-0.251v0.003v0.007v0.013v0.027v0.054v0.108l0,0.215
					v0.429l0,0.856c0,0.569,0,1.135-0.001,1.698c-0.001,1.125-0.002,2.237-0.002,3.333c-0.003,2.19-0.006,4.314-0.009,6.348
					l-0.24-0.24c8.585,0.046,15.975,0.086,21.272,0.114c2.612,0.028,4.706,0.05,6.197,0.066c0.696,0.012,1.25,0.022,1.671,0.03
					C239.048,34.37,239.252,34.38,239.262,34.39c0.01,0.01-0.175,0.02-0.54,0.03c-0.408,0.007-0.946,0.018-1.621,0.03
					c-1.477,0.016-3.552,0.038-6.142,0.066c-5.337,0.028-12.784,0.068-21.434,0.114l-0.24,0.001l0-0.241
					c-0.003-2.035-0.006-4.158-0.009-6.348c-0.001-1.095-0.002-2.207-0.002-3.333c0-0.563,0-1.129-0.001-1.698l0-0.856v-0.429V21.51
					v-0.108v-0.054v-0.027v-0.013v-0.007v-0.003c-0.055,0.053,0.479-0.479,0.254-0.253c11.551,0.028,22.031,0.053,29.735,0.071
					l0.183,0l-0.002,0.181c-0.041,4.033-0.074,7.313-0.098,9.635c-0.019,1.088-0.034,1.95-0.045,2.585
					C239.286,34.102,239.274,34.403,239.262,34.39z'
									/>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<path
										fill='#E0E0E0'
										d='M429.758,425.858c-0.005-0.005-0.01-0.085-0.015-0.238c-0.004-0.175-0.009-0.396-0.016-0.672
					c-0.009-0.616-0.021-1.475-0.037-2.577c-0.02-2.26-0.049-5.51-0.085-9.607l0.153,0.153c-11.301,0.022-29.273,0.058-49.49,0.099
					c0.111-0.112-0.254,0.252,0.249-0.252v0.003v0.007v0.013v0.026v0.053v0.105l0,0.211v0.421v0.839c0,0.559,0,1.116,0,1.67
					c-0.001,1.11-0.001,2.211-0.001,3.304c-0.001,2.185-0.003,4.335-0.004,6.44l-0.246-0.246c14.094,0.045,26.425,0.084,35.27,0.112
					c4.406,0.03,7.941,0.054,10.411,0.07c1.201,0.014,2.142,0.025,2.815,0.032c0.303,0.006,0.545,0.012,0.736,0.016
					C429.665,425.848,429.753,425.853,429.758,425.858c0.005,0.005-0.072,0.011-0.229,0.016c-0.183,0.004-0.415,0.009-0.706,0.016
					c-0.66,0.008-1.583,0.019-2.762,0.032c-2.456,0.017-5.972,0.041-10.354,0.07c-8.888,0.028-21.28,0.067-35.442,0.112
					l-0.247,0.001l0-0.248c-0.001-2.105-0.003-4.255-0.004-6.44c0-1.093-0.001-2.194-0.001-3.304c0-0.555,0-1.112,0-1.67l0-0.839
					v-0.421v-0.211v-0.105v-0.053v-0.026v-0.013v-0.007v-0.003c0.505-0.505,0.142-0.14,0.255-0.252
					c20.217,0.04,38.189,0.076,49.49,0.099l0.155,0l-0.001,0.153c-0.036,4.136-0.065,7.417-0.085,9.698
					c-0.016,1.087-0.028,1.934-0.037,2.542c-0.007,0.265-0.012,0.477-0.016,0.645C429.768,425.793,429.763,425.863,429.758,425.858z
					'
									/>
								</g>
							</g>
							<g>
								<g>
									<path
										fill='#E0E0E0'
										d='M436.469,412.765c-0.006-0.006-0.013-0.091-0.019-0.249c-0.005-0.188-0.012-0.416-0.02-0.694
					c-0.011-0.644-0.026-1.52-0.045-2.625c-0.024-2.296-0.057-5.538-0.098-9.525l0.181,0.181
					c-7.704,0.018-18.184,0.043-29.735,0.071c-0.227,0.225,0.304-0.307,0.25-0.251v0.003v0.007v0.013v0.027v0.054v0.108l0,0.215
					v0.429l0,0.856c0,0.569,0,1.135-0.001,1.698c-0.001,1.125-0.001,2.237-0.002,3.333c-0.003,2.19-0.006,4.314-0.009,6.348
					l-0.24-0.24c8.585,0.046,15.975,0.086,21.272,0.114c2.613,0.028,4.706,0.05,6.197,0.066c0.696,0.012,1.25,0.022,1.671,0.03
					C436.255,412.745,436.459,412.755,436.469,412.765c0.01,0.01-0.175,0.02-0.54,0.03c-0.408,0.008-0.946,0.018-1.621,0.03
					c-1.477,0.016-3.552,0.038-6.142,0.066c-5.337,0.028-12.784,0.068-21.434,0.114l-0.24,0.001l0-0.241
					c-0.003-2.034-0.006-4.158-0.009-6.348c-0.001-1.095-0.001-2.207-0.002-3.333c0-0.563,0-1.129-0.001-1.698l0-0.856V400.1v-0.215
					v-0.108v-0.054v-0.027v-0.013v-0.007v-0.003c-0.055,0.053,0.479-0.479,0.254-0.254c11.551,0.028,22.031,0.053,29.735,0.071
					l0.183,0l-0.002,0.181c-0.041,4.033-0.074,7.313-0.098,9.635c-0.019,1.088-0.034,1.95-0.045,2.585
					C436.494,412.477,436.481,412.778,436.469,412.765z'
									/>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<path
										fill='#E0E0E0'
										d='M326.438,298.692c-0.005-0.005-0.01-0.085-0.015-0.238c-0.004-0.175-0.009-0.396-0.016-0.672
					c-0.009-0.616-0.021-1.475-0.037-2.577c-0.02-2.26-0.049-5.51-0.085-9.607l0.153,0.153c-11.301,0.023-29.273,0.058-49.49,0.099
					c0.111-0.112-0.254,0.252,0.249-0.252v0.003v0.007v0.013v0.026v0.053v0.105l0,0.211v0.421v0.84c0,0.559,0,1.116,0,1.67
					c0,1.11-0.001,2.211-0.001,3.304c-0.001,2.185-0.003,4.335-0.004,6.44l-0.246-0.247c14.093,0.045,26.425,0.084,35.27,0.112
					c4.406,0.03,7.941,0.054,10.411,0.07c1.201,0.014,2.142,0.025,2.815,0.033c0.303,0.006,0.545,0.012,0.736,0.016
					C326.344,298.681,326.433,298.687,326.438,298.692c0.005,0.005-0.072,0.011-0.229,0.016c-0.183,0.004-0.415,0.009-0.706,0.016
					c-0.66,0.008-1.583,0.019-2.762,0.032c-2.456,0.017-5.972,0.041-10.354,0.07c-8.888,0.028-21.28,0.067-35.442,0.112
					l-0.247,0.001l0-0.248c-0.001-2.105-0.003-4.255-0.004-6.44c0-1.093-0.001-2.195-0.001-3.304c0-0.555,0-1.112,0-1.67l0-0.84
					v-0.421v-0.211v-0.105v-0.053v-0.026v-0.013v-0.007v-0.003c0.505-0.505,0.142-0.14,0.255-0.253
					c20.217,0.04,38.189,0.076,49.49,0.099l0.155,0l-0.001,0.153c-0.036,4.136-0.065,7.416-0.085,9.698
					c-0.016,1.087-0.028,1.934-0.037,2.542c-0.007,0.265-0.012,0.477-0.016,0.645C326.448,298.627,326.443,298.697,326.438,298.692z
					'
									/>
								</g>
							</g>
							<g>
								<g>
									<path
										fill='#E0E0E0'
										d='M333.148,285.599c-0.006-0.006-0.013-0.091-0.019-0.249c-0.005-0.188-0.012-0.416-0.02-0.694
					c-0.011-0.644-0.026-1.52-0.045-2.625c-0.023-2.296-0.057-5.538-0.098-9.525l0.181,0.181
					c-7.704,0.018-18.184,0.043-29.735,0.071c-0.227,0.225,0.304-0.307,0.25-0.251v0.003v0.007v0.013v0.027v0.054v0.108l0,0.215
					v0.43l0,0.856c0,0.569,0,1.135-0.001,1.698c-0.001,1.125-0.001,2.237-0.002,3.333c-0.003,2.19-0.006,4.314-0.009,6.348
					l-0.24-0.24c8.585,0.046,15.975,0.086,21.272,0.114c2.613,0.028,4.706,0.05,6.197,0.066c0.696,0.012,1.25,0.022,1.671,0.03
					C332.934,285.579,333.139,285.589,333.148,285.599c0.01,0.01-0.175,0.02-0.54,0.03c-0.408,0.007-0.946,0.018-1.621,0.03
					c-1.477,0.016-3.552,0.038-6.142,0.066c-5.337,0.028-12.784,0.068-21.434,0.114l-0.24,0.001l-0.001-0.241
					c-0.003-2.034-0.006-4.158-0.009-6.348c-0.001-1.095-0.001-2.207-0.002-3.333c0-0.563,0-1.129-0.001-1.698l0-0.856v-0.43v-0.215
					v-0.108v-0.054v-0.027v-0.013v-0.007v-0.003c-0.055,0.053,0.479-0.479,0.254-0.254c11.551,0.028,22.031,0.053,29.735,0.071
					l0.183,0l-0.002,0.181c-0.041,4.033-0.074,7.313-0.098,9.635c-0.019,1.088-0.034,1.95-0.045,2.585
					C333.173,285.311,333.161,285.612,333.148,285.599z'
									/>
								</g>
							</g>
						</g>
					</g>
					<g id='Background_Simple' display='none'>
						<path
							display='inline'
							fill='#F5F5F5'
							d='M429.9,35.133l-1.618-0.436c-13.641-3.679-28.255,0.201-37.753,10.486
		c-1.221,1.322-2.347,2.732-3.363,4.224c-7.314,10.737-8.679,25.317-3.501,37.381c3.045,7.095,8.252,13.642,8.517,21.327
		c0.345,10.002-7.667,17.971-15.067,24.604c-7.4,6.633-15.397,14.629-15.009,24.629c0.244,6.263,3.904,12.008,8.49,16.331
		c12.143,11.444,30.829,14.208,46.625,9.373c15.796-4.836,28.832-16.357,38.275-29.871c13.229-18.93,20.154-42.86,16.154-65.799
		C467.65,64.442,451.814,43.101,429.9,35.133z'
						/>
						<path
							display='inline'
							fill='#F5F5F5'
							d='M67.129,48.001l1.618-0.436c13.641-3.679,28.255,0.201,37.753,10.486
		c1.221,1.322,2.347,2.732,3.363,4.224c7.314,10.737,8.679,25.317,3.501,37.381c-3.045,7.095-8.252,13.642-8.517,21.327
		c-0.345,10.002,7.667,17.971,15.067,24.604c7.4,6.633,15.397,14.629,15.009,24.629c-0.244,6.263-3.904,12.008-8.49,16.331
		c-12.143,11.444-30.829,14.208-46.625,9.373c-15.796-4.836-28.832-16.357-38.275-29.871c-13.229-18.93-20.154-42.86-16.154-65.799
		C29.379,77.311,45.215,55.969,67.129,48.001z'
						/>
						<circle
							display='inline'
							fill='#F5F5F5'
							cx='159.227'
							cy='225.679'
							r='17.568'
						/>
					</g>
					<g id='Floor'>
						<g>
							<path
								fill='#263238'
								d='M470.976,463.707c0,0.144-98.786,0.26-220.617,0.26c-121.873,0-220.638-0.117-220.638-0.26
			s98.765-0.26,220.638-0.26C372.189,463.447,470.976,463.564,470.976,463.707z'
							/>
						</g>
					</g>
					<g id='Plant'>
						<g>
							<path
								fill='#455A64'
								d='M146.331,257.795c-10.729,5.558-18.957,15.127-24.873,25.663s-9.729,22.097-13.505,33.575
			l-3.305,59.196c12.484-21.239,22.505-46.206,25.212-70.693c-2.769-0.247-5.539-0.493-8.308-0.74
			c5.57-0.85,10.246-4.886,13.221-9.672c2.975-4.786,4.506-10.304,6.001-15.737c-3.379,0.502-6.757,1.005-10.136,1.507
			C138.576,275.596,144.33,267.126,146.331,257.795z'
							/>
							<g opacity='0.3'>
								<path
									d='M146.331,257.795c-10.729,5.558-18.957,15.127-24.873,25.663s-9.729,22.097-13.505,33.575l-3.305,59.196
				c12.484-21.239,22.505-46.206,25.212-70.693c-2.769-0.247-5.539-0.493-8.308-0.74c5.57-0.85,10.246-4.886,13.221-9.672
				c2.975-4.786,4.506-10.304,6.001-15.737c-3.379,0.502-6.757,1.005-10.136,1.507C138.576,275.596,144.33,267.126,146.331,257.795z
				'
								/>
							</g>
							<path
								fill='#455A64'
								d='M102.8,390.099c0,0-27.097-32.434-26.785-63.396h10.946c0,0-11.89-7.819-10.949-12.197
			c0.941-4.378,1.254-20.954,1.254-20.954l10.008,2.502c0,0-7.506-3.753-8.444-9.695c-0.938-5.942,2.189-33.464,2.189-33.464
			s12.197,12.823,15.95,25.958c3.753,13.135,13.604,28.46,10.711,68.492C104.788,387.376,102.8,390.099,102.8,390.099z'
							/>
							<path
								fill='#455A64'
								d='M116.538,393.16c0,0,29.358-19.557,35.88-44.518l-8.801-2.395c0,0,11.27-3.685,11.471-7.411
			c0.202-3.726,3.577-17.121,3.577-17.121l-8.593-0.178c0,0,6.856-1.375,8.91-5.947c2.054-4.572,5.561-27.383,5.561-27.383
			s-12.612,7.641-18.503,17.38c-5.891,9.739-17.164,19.905-23.597,52.722C116.011,391.126,116.538,393.16,116.538,393.16z'
							/>
							<g>
								<path
									fill='#263238'
									d='M88.422,281.173c0.009-0.002,0.042,0.112,0.098,0.336c0.06,0.251,0.138,0.581,0.237,0.998
				c0.204,0.911,0.493,2.202,0.861,3.849c0.376,1.671,0.813,3.72,1.287,6.106c0.482,2.384,1.045,5.097,1.595,8.109
				c1.161,6.013,2.386,13.203,3.647,21.202c1.222,8.005,2.442,16.824,3.527,26.106c1.062,9.287,1.887,18.155,2.523,26.229
				c0.597,8.077,1.045,15.357,1.287,21.476c0.152,3.058,0.223,5.828,0.297,8.259c0.083,2.431,0.124,4.526,0.14,6.238
				c0.017,1.687,0.03,3.009,0.04,3.943c-0.001,0.429-0.002,0.768-0.003,1.026c-0.004,0.231-0.01,0.349-0.019,0.35
				c-0.009,0-0.021-0.118-0.035-0.349c-0.012-0.258-0.028-0.596-0.049-1.025c-0.033-0.933-0.079-2.254-0.137-3.94
				c-0.046-1.711-0.118-3.804-0.228-6.233c-0.102-2.429-0.199-5.196-0.374-8.252c-0.288-6.114-0.774-13.387-1.397-21.456
				c-0.662-8.066-1.501-16.926-2.562-26.206c-1.084-9.275-2.289-18.09-3.484-26.093c-1.234-7.997-2.421-15.189-3.535-21.207
				c-0.526-3.015-1.063-5.731-1.518-8.12c-0.447-2.39-0.855-4.444-1.2-6.121c-0.327-1.655-0.584-2.952-0.765-3.868
				c-0.078-0.422-0.139-0.755-0.186-1.009C88.429,281.293,88.413,281.175,88.422,281.173z'
								/>
							</g>
							<path
								fill='#455A64'
								d='M99.042,409.96c0,0-24.798-28.136-32.497-45.145l5.813-2.483c-4.213,0.695-8.53-1.116-11.797-3.865
			c-3.267-2.75-5.666-6.367-8.018-9.931l7.657-1.294c-6.963-1.954-13.098-6.706-16.73-12.96c9.036,1.486,17.21,6.449,23.947,12.652
			c5.455,5.024,10.075,10.855,14.511,16.814c2.164,2.906,4.058,6.003,5.635,9.265C91.728,381.628,99.056,399.627,99.042,409.96z'
							/>
							<g>
								<path
									fill='#263238'
									d='M152.808,304.584c0.008,0.004-0.031,0.101-0.114,0.287c-0.098,0.21-0.223,0.48-0.381,0.818
				c-0.36,0.746-0.866,1.792-1.508,3.122c-1.329,2.702-3.211,6.631-5.49,11.507c-2.286,4.873-4.916,10.718-7.742,17.249
				c-2.802,6.541-5.788,13.772-8.758,21.428c-2.953,7.665-5.61,15.026-7.937,21.751c-2.302,6.734-4.289,12.828-5.876,17.972
				c-1.595,5.141-2.845,9.314-3.679,12.206c-0.42,1.417-0.749,2.53-0.984,3.324c-0.111,0.356-0.2,0.64-0.269,0.861
				c-0.063,0.193-0.1,0.291-0.109,0.289c-0.009-0.002,0.011-0.105,0.057-0.303c0.056-0.225,0.128-0.513,0.219-0.875
				c0.213-0.801,0.511-1.923,0.89-3.352c0.774-2.911,1.972-7.102,3.522-12.259c1.543-5.16,3.494-11.271,5.774-18.019
				c2.304-6.74,4.95-14.112,7.906-21.784c2.973-7.662,5.975-14.895,8.804-21.431c2.853-6.525,5.519-12.359,7.849-17.215
				c2.322-4.859,4.256-8.766,5.641-11.441c0.68-1.312,1.215-2.343,1.596-3.079c0.176-0.328,0.317-0.59,0.427-0.795
				C152.745,304.669,152.8,304.58,152.808,304.584z'
								/>
							</g>
							<g>
								<path
									fill='#263238'
									d='M101.145,422.017c-0.018,0.002-0.059-0.176-0.12-0.517c-0.065-0.4-0.146-0.893-0.244-1.493
				c-0.189-1.299-0.513-3.172-0.968-5.472c-0.446-2.303-1.084-5.022-1.896-8.013c-0.839-2.983-1.867-6.234-3.121-9.583
				c-1.273-3.344-2.65-6.463-3.992-9.257c-1.367-2.782-2.686-5.245-3.873-7.267c-1.179-2.027-2.174-3.646-2.89-4.746
				c-0.323-0.515-0.589-0.938-0.804-1.281c-0.179-0.296-0.266-0.457-0.251-0.467c0.015-0.01,0.131,0.13,0.338,0.408
				c0.237,0.329,0.53,0.735,0.886,1.228c0.77,1.067,1.814,2.661,3.038,4.671c1.232,2.006,2.59,4.461,3.987,7.244
				c1.372,2.795,2.769,5.924,4.049,9.285c1.26,3.366,2.28,6.637,3.097,9.641c0.79,3.011,1.394,5.751,1.795,8.071
				c0.41,2.317,0.679,4.204,0.807,5.513c0.059,0.606,0.108,1.104,0.147,1.508C101.157,421.832,101.163,422.015,101.145,422.017z'
								/>
							</g>
							<rect
								x='79.593'
								y='415.398'
								fill={theme.palette.primary.main}
								width='54.273'
								height='11.071'
							/>
							<polygon
								fill={theme.palette.primary.main}
								points='84.765,426.469 90.635,463.707 123.43,463.707 128.902,426.469 		'
							/>
							<g>
								<path
									fill='#263238'
									d='M130.537,426.469c0,0.144-10.267,0.26-22.929,0.26c-12.666,0-22.931-0.117-22.931-0.26
				s10.265-0.26,22.931-0.26C120.27,426.209,130.537,426.326,130.537,426.469z'
								/>
							</g>
						</g>
					</g>
					<g id='Table'>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<path
																fill={theme.palette.primary.main}
																d='M383.273,248.775c0.302,0.751,0.433,1.571,0.381,2.379c0.19-0.955,0.559-1.873,1.08-2.695
											c0.195-0.307,0.504-0.637,0.857-0.548c0.354,0.09,0.466,0.534,0.479,0.898c0.035,1.001-0.194,2.01-0.658,2.897
											c0.647-0.819,1.496-1.477,2.451-1.899c0.17-0.075,0.374-0.14,0.531-0.042c0.184,0.115,0.189,0.377,0.158,0.592
											c-0.241,1.684-1.432,3.198-3.012,3.829l-0.518,0.075c-1.104-0.304-2.084-1.035-2.691-2.007
											c-0.607-0.971-0.833-2.173-0.622-3.299c0.081-0.429,0.324-0.928,0.761-0.936
											C382.862,248.014,383.127,248.412,383.273,248.775z'
															/>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#455A64'
																		d='M394.909,272.015c-1.204-0.87-3.47-1.7-4.932-1.988c1.034-3.532,1.095-7.346,0.139-10.9
													c-0.378-1.406-0.923-2.794-1.841-3.923c-0.918-1.129-2.252-1.978-3.704-2.068c-1.567-0.097-3.068,0.716-4.115,1.906
													l-1.82,4.21c-0.105,0.613-0.184,1.232-0.219,1.85c-0.225,4.07,0.71,8.195,2.658,11.775
													c-1.897,2.092-3.951,5.697-3.332,9.876h21.615C399.735,278.745,398.209,274.397,394.909,272.015z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path
																			fill='#263238'
																			d='M380.111,274.221c-0.009-0.007,0.047-0.087,0.163-0.235
														c0.122-0.141,0.277-0.375,0.535-0.622c0.484-0.523,1.281-1.203,2.397-1.832c1.112-0.632,2.573-1.167,4.263-1.361
														c1.682-0.199,3.602-0.009,5.42,0.757c1.806,0.796,3.261,2.062,4.273,3.421c1.019,1.362,1.633,2.791,1.937,4.034
														c0.308,1.244,0.361,2.289,0.313,3c-0.002,0.357-0.066,0.63-0.084,0.816c-0.028,0.186-0.047,0.281-0.059,0.28
														c-0.096,0.006,0.273-1.596-0.395-4.036c-0.326-1.21-0.949-2.595-1.954-3.911c-0.998-1.315-2.417-2.533-4.161-3.303
														c-1.757-0.74-3.616-0.934-5.257-0.757c-1.647,0.173-3.082,0.671-4.185,1.265
														C381.084,272.927,380.173,274.295,380.111,274.221z'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path
																			fill='#263238'
																			d='M378.305,262.651c-0.043-0.002-0.074-0.548-0.039-1.432
														c0.04-0.881,0.147-2.119,0.583-3.419c0.436-1.302,1.177-2.347,1.85-2.943c0.671-0.606,1.207-0.8,1.218-0.769
														c0.029,0.049-0.455,0.312-1.057,0.935c-0.605,0.614-1.28,1.628-1.701,2.881c-0.421,1.254-0.564,2.462-0.662,3.332
														C378.404,262.109,378.353,262.653,378.305,262.651z'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path
																			fill='#263238'
																			d='M390.803,280.003c-0.06-0.072,0.891-0.946,2.018-2.064
														c1.131-1.115,2.016-2.055,2.087-1.995c0.068,0.056-0.713,1.101-1.856,2.228
														C391.912,279.302,390.858,280.071,390.803,280.003z'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path
																			fill='#263238'
																			d='M388.394,278.745c-0.088-0.021,0.069-0.982,0.351-2.144
														c0.282-1.163,0.582-2.088,0.67-2.067c0.088,0.021-0.069,0.981-0.351,2.144
														C388.782,277.841,388.482,278.766,388.394,278.745z'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path
																			fill='#263238'
																			d='M381.976,276.345c0.072-0.048,0.702,0.785,1.23,1.954
														c0.532,1.167,0.745,2.19,0.661,2.213c-0.091,0.026-0.439-0.935-0.96-2.077
														C382.391,277.291,381.896,276.395,381.976,276.345z'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path
																			fill='#263238'
																			d='M388.917,266.25c-0.072-0.055,0.582-1.042,1.462-2.206
														c0.88-1.164,1.652-2.063,1.724-2.008c0.072,0.055-0.582,1.042-1.462,2.206
														C389.761,265.406,388.989,266.305,388.917,266.25z'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path
																			fill='#263238'
																			d='M387.39,259.49c-0.075-0.051,0.666-1.263,1.655-2.706
														c0.989-1.443,1.851-2.572,1.926-2.52c0.075,0.051-0.666,1.263-1.655,2.706
														C388.327,258.413,387.465,259.541,387.39,259.49z'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path
																			fill='#263238'
																			d='M384.955,261.264c0.091-0.006,0.228,1.02,0.308,2.289c0.079,1.27,0.07,2.305-0.02,2.31
														c-0.09,0.006-0.228-1.019-0.308-2.29C384.856,262.304,384.865,261.27,384.955,261.264z'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path
																			fill='#263238'
																			d='M375.245,264.339c0.041-0.078,1.442,0.574,3.067,1.567
														c1.627,0.99,2.849,1.938,2.798,2.01c-0.054,0.076-1.352-0.745-2.969-1.729
														C376.526,265.2,375.201,264.421,375.245,264.339z'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g opacity='0.3'>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path
																			d='M383.026,270.158c1.048-0.615,2.291-0.805,3.504-0.865c1.259-0.063,2.668,0.065,3.521,0.994v0.045
														c-3.025-0.215-6.554,0.82-8.877,2.77C381.156,271.887,381.978,270.773,383.026,270.158z'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path
																	fill={theme.palette.primary.main}
																	d='M393.3,227.706c0.616-0.165,1.269-0.191,1.896-0.073
												c-0.729-0.239-1.412-0.613-2.005-1.098c-0.222-0.181-0.451-0.454-0.347-0.721c0.104-0.268,0.461-0.314,0.747-0.29
												c0.786,0.067,1.553,0.341,2.203,0.787c-0.58-0.583-1.014-1.309-1.254-2.095c-0.043-0.14-0.074-0.306,0.017-0.419
												c0.107-0.133,0.313-0.112,0.477-0.068c1.294,0.347,2.366,1.421,2.71,2.715l0.01,0.412
												c-0.342,0.834-1.006,1.532-1.822,1.915s-1.777,0.447-2.637,0.176c-0.328-0.103-0.695-0.341-0.66-0.683
												C392.666,227.956,393.003,227.786,393.3,227.706z'
																/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path
																	fill={theme.palette.primary.main}
																	d='M436.896,200.078c0.113,0.628,0.084,1.28-0.085,1.895
												c0.299-0.706,0.729-1.356,1.262-1.906c0.199-0.205,0.491-0.411,0.748-0.286c0.258,0.126,0.275,0.486,0.226,0.769
												c-0.132,0.777-0.47,1.519-0.969,2.129c0.629-0.529,1.389-0.901,2.193-1.074c0.143-0.031,0.311-0.049,0.416,0.052
												c0.123,0.118,0.086,0.321,0.028,0.481c-0.454,1.26-1.614,2.238-2.933,2.473l-0.411-0.024
												c-0.803-0.411-1.442-1.131-1.755-1.976c-0.313-0.846-0.296-1.809,0.046-2.643c0.13-0.318,0.398-0.664,0.736-0.601
												C436.701,199.425,436.842,199.775,436.896,200.078z'
																/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path
																	fill='#455A64'
																	d='M406.545,256.601c0.32,0.012,0.62,0.049,0.926,0.078
												c0.561-6.175-1.311-12.444,0.575-18.347l-0.25,0.322c-2.272,0.298-4.636,0.208-6.777-0.608
												c-2.141-0.816-4.041-2.418-4.901-4.542c-0.861-2.123-0.54-4.762,1.044-6.417c2.138-2.233,5.902-2.116,8.584-0.577
												c2.587,1.484,4.4,3.977,6.106,6.432c0.898-0.705,1.886-1.293,2.952-1.697c-2.98-4.102-5.861-8.41-6.801-13.364
												c-1.028-5.421,0.997-11.844,6.029-14.108c4.286-1.928,9.659-0.186,12.68,3.414c0.236,0.282,0.436,0.586,0.646,0.885
												l-0.101-0.292c0.747-1.882,2.215-3.492,4.075-4.291c1.86-0.799,4.098-0.735,5.85,0.281
												c1.751,1.016,2.934,2.997,2.874,5.021c-0.068,2.281-1.671,4.345-3.723,5.344c-1.966,0.958-4.267,1.034-6.414,0.593
												c0.275,1.905,0.263,3.864-0.032,5.759c-0.672,4.313-2.595,8.324-4.879,12.055c0.63,0.427,1.23,0.9,1.757,1.452
												c3.883,4.066,4.133,10.594,2.098,15.835c-2.035,5.241-7.547,9.107-11.37,13.229l-0.004-0.003
												c1.645,2.782,2.509,6.103,2.937,9.221c1.33-2.781,4.298-4.773,7.401-4.75c1.669,0.012,3.538,0.755,4.054,2.341
												c0.455,1.398-0.311,2.913-1.305,3.996c-2.315,2.523-6.46,3.676-9.787,2.866l-0.042-1.021
												c0.21,4.006-0.169,7.046-0.169,7.046h-24.664C395.914,282.753,389.323,255.963,406.545,256.601z'
																/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<rect
																			x='367.641'
																			y='282.753'
																			transform='matrix(-1 -1.224647e-16 1.224647e-16 -1 793.6422 575.8506)'
																			fill={theme.palette.primary.main}
																			width='58.36'
																			height='10.344'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<polygon
																			fill={theme.palette.primary.main}
																			points='421.643,291.045 414.546,321.955 379.763,321.955 371.999,291.045 
																											'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<path
																				fill='#263238'
																				d='M367.673,293.11c0,0.113,13.164,0.205,29.399,0.205
															c16.241,0,29.402-0.092,29.402-0.205c0-0.113-13.161-0.205-29.402-0.205
															C380.837,292.905,367.673,292.997,367.673,293.11z'
																			/>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M401.969,257.62c-0.005-0.01,0.111-0.076,0.337-0.193
													c0.222-0.127,0.569-0.271,1.025-0.439c0.911-0.323,2.322-0.605,4.061-0.468c1.73,0.139,3.783,0.734,5.715,2.001
													c1.933,1.265,3.651,3.223,4.909,5.554c1.26,2.338,1.98,4.79,2.35,7.049c0.361,2.265,0.395,4.331,0.396,6.054
													c-0.01,1.725-0.065,3.118-0.099,4.081c-0.019,0.453-0.035,0.82-0.048,1.108c-0.014,0.253-0.026,0.386-0.038,0.386
													c-0.011,0-0.021-0.133-0.028-0.387c-0.004-0.289-0.008-0.657-0.014-1.11c-0.001-1.006-0.003-2.388-0.005-4.078
													c-0.031-1.718-0.089-3.771-0.463-6.006c-0.381-2.227-1.102-4.645-2.34-6.942c-1.236-2.29-2.913-4.211-4.788-5.458
													c-1.876-1.251-3.877-1.857-5.569-2.022c-1.701-0.163-3.09,0.077-4.001,0.359
													C402.454,257.391,401.986,257.658,401.969,257.62z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M407.184,244.525c-0.011,0.001-0.025-0.1-0.04-0.292
													c-0.008-0.193-0.047-0.477-0.034-0.845c-0.007-0.734,0.076-1.803,0.365-3.088c0.294-1.281,0.805-2.789,1.714-4.285
													c0.442-0.753,0.994-1.496,1.654-2.184c0.654-0.693,1.422-1.335,2.309-1.831c0.882-0.504,1.82-0.853,2.754-1.059
													c0.939-0.19,1.864-0.239,2.738-0.203c1.752,0.1,3.283,0.549,4.492,1.066c1.212,0.516,2.13,1.067,2.73,1.488
													c0.308,0.199,0.524,0.388,0.678,0.503c0.151,0.12,0.227,0.188,0.22,0.197c-0.017,0.023-0.349-0.209-0.968-0.593
													c-0.617-0.386-1.542-0.899-2.748-1.381c-1.202-0.482-2.711-0.897-4.418-0.979c-0.852-0.027-1.75,0.028-2.657,0.215
													c-0.903,0.204-1.807,0.543-2.658,1.029c-0.856,0.479-1.602,1.098-2.24,1.769c-0.645,0.666-1.188,1.386-1.628,2.119
													c-0.904,1.455-1.428,2.928-1.747,4.182c-0.313,1.259-0.431,2.311-0.463,3.038
													C407.204,244.118,407.213,244.524,407.184,244.525z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M430.112,218.427c-0.047,0.003-0.123-0.757-0.209-1.981
													c-0.048-0.611-0.11-1.336-0.244-2.13c-0.128-0.8-0.331-1.648-0.699-2.503c-0.712-1.717-1.583-3.177-2.254-4.195
													c-0.674-1.02-1.185-1.581-1.148-1.616c0.016-0.015,0.162,0.108,0.405,0.355c0.237,0.252,0.569,0.629,0.933,1.128
													c0.728,0.998,1.641,2.453,2.367,4.204c0.376,0.87,0.583,1.774,0.694,2.58c0.12,0.812,0.159,1.549,0.177,2.165
													C430.162,217.666,430.155,218.424,430.112,218.427z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M426.928,273.888c0.06-0.069,0.812,0.488,1.681,1.243
													c0.869,0.755,1.525,1.423,1.466,1.491c-0.059,0.068-0.812-0.488-1.681-1.243
													C427.525,274.624,426.869,273.956,426.928,273.888z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M428.999,271.827c-0.009-0.09,0.927-0.255,2.091-0.369
													c1.164-0.114,2.115-0.133,2.123-0.042c0.009,0.09-0.927,0.255-2.091,0.369
													C429.959,271.898,429.008,271.918,428.999,271.827z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M427.429,268.188c-0.066-0.068,0.531-0.734,1.172-1.621
													c0.646-0.884,1.096-1.656,1.181-1.615c0.079,0.036-0.25,0.896-0.916,1.808
													C428.205,267.674,427.488,268.253,427.429,268.188z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M424.667,266.518c0.088,0.02-0.082,1.09-0.382,2.389c-0.299,1.3-0.613,2.336-0.701,2.316
													c-0.088-0.02,0.082-1.09,0.382-2.39C424.265,267.534,424.579,266.497,424.667,266.518z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M422.821,274.856c-0.061-0.067,0.329-0.517,0.871-1.004
													c0.542-0.488,1.03-0.828,1.09-0.76c0.061,0.067-0.329,0.517-0.871,1.004
													C423.37,274.583,422.882,274.923,422.821,274.856z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M414.244,273.241c-0.065-0.063,0.408-0.663,1.057-1.34
													c0.649-0.677,1.229-1.176,1.294-1.113c0.065,0.063-0.408,0.663-1.058,1.34
													C414.889,272.806,414.31,273.304,414.244,273.241z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M412.383,265.321c-0.077-0.047,0.277-0.766,0.791-1.606
													c0.514-0.84,0.993-1.482,1.071-1.435c0.077,0.047-0.277,0.766-0.791,1.606
													C412.939,264.726,412.46,265.369,412.383,265.321z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M406.922,258.874c0.091-0.001,0.176,0.92,0.191,2.056
													c0.015,1.137-0.046,2.06-0.137,2.061c-0.091,0.001-0.176-0.919-0.191-2.057
													C406.77,259.798,406.832,258.875,406.922,258.874z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M395.463,261.059c0.078-0.052,0.752,0.843,1.629,1.903
													c0.875,1.063,1.625,1.894,1.559,1.961c-0.06,0.063-0.925-0.674-1.812-1.752
													C395.949,262.095,395.389,261.106,395.463,261.059z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M393.993,272.315c0.017-0.089,0.931,0.013,2.042,0.227c1.111,0.215,1.997,0.46,1.98,0.55
													c-0.017,0.089-0.931-0.013-2.042-0.227C394.862,272.65,393.976,272.404,393.993,272.315z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M405.879,276.236c-0.083-0.026,0.188-1.072,0.789-2.253
													c0.598-1.183,1.281-2.02,1.351-1.968c0.078,0.054-0.473,0.957-1.058,2.117
													C406.372,275.288,405.969,276.267,405.879,276.236z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M401.918,270.836c-0.091,0.002-0.181-0.743-0.201-1.663
													c-0.021-0.921,0.036-1.669,0.127-1.671c0.091-0.002,0.181,0.743,0.201,1.664
													C402.065,270.086,402.008,270.834,401.918,270.836z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M414.707,280.356c-0.082-0.029,0.148-0.851,0.697-1.74
													c0.545-0.892,1.175-1.469,1.237-1.409c0.07,0.063-0.431,0.719-0.957,1.581
													C415.154,279.647,414.796,280.391,414.707,280.356z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M401.138,281.137c-0.083,0.037-0.479-0.68-0.885-1.6
													c-0.406-0.921-0.668-1.697-0.585-1.733c0.083-0.037,0.479,0.68,0.886,1.601
													C400.959,280.324,401.221,281.1,401.138,281.137z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M422.232,256.043c0.003-0.091,1.015-0.131,2.261-0.09
													c1.246,0.041,2.254,0.148,2.25,0.239c-0.003,0.09-1.015,0.131-2.261,0.09
													C423.237,256.24,422.229,256.134,422.232,256.043z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M427.152,247.622c-0.052-0.074,0.919-0.852,2.169-1.739
													c1.251-0.886,2.307-1.545,2.359-1.471c0.052,0.074-0.919,0.852-2.17,1.739
													C428.261,247.037,427.204,247.696,427.152,247.622z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M426.642,236.806c-0.038-0.087,0.803-0.526,1.769-1.17
													c0.97-0.639,1.701-1.243,1.767-1.174c0.061,0.061-0.587,0.789-1.585,1.447
													C427.598,236.572,426.674,236.886,426.642,236.806z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M421.129,232.983c-0.088-0.022,0.126-1.202,0.478-2.637
													c0.352-1.435,0.709-2.581,0.797-2.559c0.088,0.021-0.126,1.202-0.478,2.637
													C421.574,231.858,421.217,233.004,421.129,232.983z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M411.916,230.256c0.079-0.044,0.559,0.667,1.072,1.588
													c0.513,0.921,0.865,1.704,0.786,1.748c-0.079,0.044-0.559-0.667-1.073-1.588
													C412.188,231.082,411.837,230.3,411.916,230.256z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M403.975,248.506c0.061-0.074,1.112,0.731,2.495,1.581
													c1.38,0.856,2.569,1.435,2.531,1.523c-0.031,0.079-1.299-0.373-2.703-1.244
													C404.89,249.5,403.918,248.57,403.975,248.506z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M416.847,252.61c-0.048-0.077,0.661-0.6,1.581-1.168
													c0.921-0.568,1.706-0.966,1.754-0.888c0.048,0.077-0.66,0.6-1.582,1.168C417.68,252.289,416.895,252.687,416.847,252.61
													z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M419.005,243.88c-0.067-0.072,0.765-0.889,1.539-2.081
													c0.783-1.187,1.198-2.276,1.291-2.244c0.081,0.02-0.206,1.193-1.016,2.423
													C420.016,243.214,419.055,243.947,419.005,243.88z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M414.777,241.911c-0.091-0.004-0.12-0.952-0.066-2.116
													c0.054-1.165,0.171-2.105,0.262-2.1c0.091,0.004,0.12,0.951,0.066,2.116
													C414.985,240.975,414.868,241.915,414.777,241.911z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M412.443,250.538c-0.09,0.01-0.275-0.947-0.412-2.138
													c-0.138-1.191-0.176-2.165-0.086-2.176c0.09-0.01,0.275,0.947,0.412,2.138
													C412.494,249.553,412.533,250.527,412.443,250.538z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M405.916,224.548c0.094-0.013,0.308,1.09,0.688,2.414
													c0.375,1.326,0.776,2.376,0.689,2.414c-0.078,0.037-0.621-0.971-1.004-2.324
													C405.901,225.701,405.831,224.557,405.916,224.548z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M401.319,239.701c-0.044-0.084,0.849-0.63,1.886-1.389c1.04-0.754,1.834-1.437,1.9-1.369
													c0.062,0.061-0.645,0.864-1.707,1.635C402.339,239.352,401.358,239.778,401.319,239.701z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M395.073,235.048c-0.006-0.094,1.035-0.223,2.299-0.467
													c1.265-0.24,2.28-0.505,2.309-0.415c0.029,0.082-0.958,0.493-2.248,0.738
													C396.145,235.152,395.077,235.135,395.073,235.048z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M401.152,229.851c0.049-0.076,0.637,0.213,1.314,0.646
													c0.677,0.433,1.186,0.846,1.137,0.923c-0.049,0.076-0.637-0.213-1.314-0.646
													C401.612,230.341,401.103,229.928,401.152,229.851z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M398.117,224.238c0.053-0.069,0.787,0.42,1.495,1.236c0.712,0.813,1.095,1.608,1.02,1.65
													c-0.081,0.049-0.578-0.647-1.267-1.435C398.679,224.899,398.058,224.311,398.117,224.238z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M393.983,229.586c0.035-0.087,0.965,0.219,2.125,0.529
													c1.159,0.314,2.117,0.515,2.104,0.608c-0.01,0.087-1.007,0.029-2.189-0.291
													C394.84,230.116,393.949,229.667,393.983,229.586z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M422.628,222.389c-0.074-0.059,0.832-1.282,1.864-2.842
													c1.037-1.558,1.813-2.867,1.896-2.822c0.077,0.04-0.575,1.43-1.622,3.003
													C423.723,221.304,422.694,222.445,422.628,222.389z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M420.788,215.84c-0.09-0.012-0.02-1.107,0.156-2.446
													c0.176-1.339,0.392-2.415,0.482-2.403c0.09,0.012,0.02,1.107-0.156,2.446
													C421.093,214.776,420.877,215.852,420.788,215.84z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M414.422,216.859c0.096-0.018,0.364,1.242,0.993,2.684
													c0.619,1.447,1.354,2.504,1.275,2.562c-0.063,0.055-0.937-0.943-1.576-2.432
													C414.467,218.188,414.34,216.867,414.422,216.859z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M406.946,218.443c0.076-0.06,1.006,1.036,2.293,2.242
													c1.283,1.212,2.433,2.073,2.378,2.152c-0.046,0.072-1.298-0.68-2.603-1.913
													C407.705,219.698,406.877,218.493,406.946,218.443z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M409.839,204.863c0.094-0.016,0.383,1.364,0.897,3.018
													c0.508,1.656,1.046,2.959,0.96,2.999c-0.076,0.038-0.758-1.22-1.273-2.902
													C409.901,206.299,409.754,204.875,409.839,204.863z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M418.453,200.396c0.09-0.005,0.23,1.077,0.312,2.417
													c0.082,1.341,0.074,2.432-0.016,2.437c-0.09,0.005-0.23-1.077-0.312-2.417
													C418.355,201.493,418.362,200.402,418.453,200.396z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M436.23,211.879c0.024-0.091,0.841,0.054,1.852,0.134
													c1.012,0.085,1.841,0.074,1.85,0.168c0.01,0.086-0.831,0.246-1.877,0.159
													C437.01,212.257,436.206,211.963,436.23,211.879z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M435.705,207.395c-0.042-0.08,1.097-0.768,2.545-1.536
													c1.448-0.769,2.657-1.327,2.699-1.246c0.042,0.08-1.097,0.768-2.545,1.536
													C436.957,206.917,435.748,207.475,435.705,207.395z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M430.898,207.556c-0.087-0.027,0.157-1.049,0.544-2.282
													c0.387-1.233,0.771-2.211,0.858-2.184c0.086,0.027-0.157,1.049-0.544,2.283
													C431.368,206.605,430.984,207.583,430.898,207.556z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g opacity='0.3'>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		d='M417.436,263.078c-0.047-2.34-1.343-4.733-3.286-6.037c-1.943-1.304-5.442-1.433-6.817-0.455
													c2.036-0.022,4.343,0.852,5.98,2.063C414.951,259.859,416.333,261.366,417.436,263.078'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g opacity='0.3'>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		d='M425.045,232.983c-0.846-1.842-2.702-3.294-4.696-3.659c-1.994-0.365-4.817,0.743-5.571,1.995
													c1.61-0.721,3.743-0.825,5.462-0.429C421.959,231.285,423.577,232.004,425.045,232.983'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g opacity='0.3'>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		d='M430.076,215.085c0.789-1.362,0.912-3.09,0.322-4.549c-0.59-1.459-1.733-2.178-3.246-2.609
													c1.548,2.243,2.765,4.521,2.883,7.244'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M398.942,259.035c0.018,0.024-0.258,0.238-0.665,0.717
													c-0.405,0.477-0.956,1.207-1.492,2.189c-0.989,1.993-1.885,5.028-2.141,8.444c-0.265,3.424,0.096,6.539,0.434,8.763
													c0.174,1.114,0.341,2.012,0.457,2.633c0.119,0.62,0.173,0.967,0.149,0.972c-0.024,0.006-0.125-0.33-0.286-0.942
													c-0.158-0.613-0.364-1.506-0.571-2.621c-0.404-2.224-0.811-5.363-0.542-8.833c0.262-3.468,1.203-6.522,2.278-8.541
													c0.576-0.987,1.166-1.703,1.611-2.16C398.62,259.198,398.931,259.018,398.942,259.035z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path
																	fill='#263238'
																	d='M426.001,282.753c0,0.006-0.06,0.011-0.177,0.017c-0.136,0.004-0.307,0.01-0.521,0.016
												c-0.482,0.007-1.152,0.018-2.002,0.031c-1.764,0.012-4.273,0.03-7.363,0.052c-6.239,0.012-14.818,0.029-24.293,0.048
												c-9.342-0.018-17.811-0.035-24.003-0.047l0.117-0.118c-0.012,3.182-0.022,5.764-0.029,7.578
												c-0.01,0.876-0.019,1.565-0.025,2.06c-0.006,0.22-0.011,0.396-0.015,0.536c-0.005,0.121-0.011,0.182-0.016,0.182
												s-0.012-0.062-0.018-0.182c-0.005-0.14-0.011-0.316-0.018-0.536c-0.009-0.495-0.022-1.185-0.038-2.06
												c-0.018-1.814-0.044-4.397-0.076-7.578l-0.001-0.118l0.118,0c6.193-0.012,14.661-0.028,24.003-0.047
												c9.476,0.019,18.054,0.036,24.293,0.048c3.09,0.022,5.6,0.04,7.363,0.052c0.85,0.013,1.52,0.023,2.002,0.031
												c0.213,0.007,0.385,0.012,0.521,0.016C425.941,282.742,426.001,282.748,426.001,282.753z'
																/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#E0E0E0'
																		d='M406.293,309.674v12.814h-55.1c-3.538,0-6.407-2.868-6.407-6.407v0
													c0-3.538,2.869-6.407,6.407-6.407'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#FAFAFA'
																		d='M406.293,319.802h-18.59c-1.809,0-3.275-1.466-3.275-3.275v-0.892
													c0-1.809,1.466-3.275,3.275-3.275h18.59V319.802z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<polygon
															fill='#455A64'
															points='430.141,301.077 421.39,321.872 427.022,321.872 430.91,310.652 									'
														/>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<polygon
																fill='#455A64'
																points='457.012,282.338 460.441,322.055 431.828,321.955 428.649,282.338 										'
															/>
														</g>
													</g>
												</g>
											</g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<polygon
																	fill='#FAFAFA'
																	points='454.218,285.444 457.179,318.24 435.71,318.522 432.809,285.254 											'
																/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<polygon
																fill='#263238'
																points='449.662,289.984 451.347,312.551 439.895,312.551 437.874,289.984 										'
															/>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<rect
															x='356.173'
															y='372.546'
															fill='#455A64'
															width='12.38'
															height='91.074'
														/>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<rect
															x='452.073'
															y='371.8'
															fill='#455A64'
															width='12.38'
															height='91.908'
														/>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<polygon
																		fill='#455A64'
																		points='470.264,373.64 351.547,373.863 351.547,329.906 470.264,329.906 												
													'
																	/>
																</g>
															</g>
														</g>
													</g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path
																			fill='#263238'
																			d='M470.264,373.64c0,0-0.01-0.267-0.015-0.776c-0.004-0.517-0.009-1.268-0.016-2.251
														c-0.008-1.984-0.021-4.896-0.037-8.659c-0.019-7.524-0.048-18.439-0.082-32.049l0.151,0.151
														c-29.326,0.018-71.687,0.043-118.717,0.071l0.222-0.222c0,0.033,0,0.068,0,0.104
														c-0.003,15.302-0.006,30.11-0.008,43.853l-0.214-0.214c34.283-0.024,63.932-0.045,85.024-0.06
														c10.554,0.006,18.964,0.01,24.747,0.014c2.886,0.006,5.115,0.011,6.634,0.015c0.75,0.004,1.322,0.008,1.72,0.01
														C470.06,373.632,470.264,373.64,470.264,373.64s-0.187,0.009-0.566,0.014c-0.391,0.004-0.955,0.009-1.694,0.017
														c-1.509,0.009-3.723,0.022-6.589,0.04c-5.773,0.025-14.166,0.061-24.699,0.106c-21.128,0.064-50.828,0.155-85.169,0.26
														l-0.214,0.001l0-0.214c-0.003-13.744-0.005-28.551-0.008-43.853c0-0.035,0-0.071,0-0.104v-0.222l0.222,0
														c47.03,0.028,89.391,0.053,118.717,0.071l0.152,0l-0.001,0.151c-0.035,13.648-0.063,24.594-0.082,32.139
														c-0.016,3.748-0.029,6.648-0.037,8.625c-0.007,0.971-0.012,1.713-0.016,2.224
														C470.275,373.393,470.264,373.64,470.264,373.64z'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M460.506,364.956c0,0-0.011-0.175-0.016-0.505c-0.004-0.341-0.009-0.826-0.017-1.46
													c-0.009-1.292-0.022-3.181-0.038-5.625c-0.02-4.897-0.049-12.01-0.086-20.935l0.157,0.135
													c-24.01,0.017-59.929,0.042-99.975,0.07h-0.006l0.24-0.206c-0.003,9.909-0.005,19.524-0.008,28.525l-0.232-0.199
													c28.757,0.037,53.72,0.069,71.509,0.092c8.9,0.024,16.001,0.043,20.897,0.056c2.437,0.011,4.321,0.02,5.614,0.026
													c0.631,0.005,1.115,0.01,1.458,0.013C460.33,364.948,460.506,364.956,460.506,364.956s-0.156,0.008-0.476,0.013
													c-0.336,0.003-0.81,0.007-1.43,0.013c-1.281,0.006-3.149,0.014-5.565,0.026c-4.882,0.013-11.966,0.032-20.843,0.056
													c-17.829,0.023-42.847,0.055-71.668,0.092l-0.232,0l0-0.199c-0.002-9.001-0.005-18.615-0.007-28.525l0-0.206h0.239
													h0.006c40.046,0.028,75.965,0.054,99.975,0.071l0.158,0l-0.001,0.135c-0.037,8.96-0.066,16.1-0.086,21.016
													c-0.017,2.431-0.03,4.309-0.039,5.595c-0.007,0.624-0.013,1.101-0.017,1.436
													C460.516,364.799,460.506,364.956,460.506,364.956z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M364.097,361.214c0.088,0.077-0.617,0.929-1.575,1.901
													c-0.958,0.973-1.805,1.699-1.893,1.621c-0.088-0.077,0.617-0.929,1.575-1.902
													C363.161,361.862,364.009,361.137,364.097,361.214z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M364.097,340.173c-0.089,0.077-0.965-0.698-1.957-1.729
													c-0.992-1.032-1.725-1.93-1.636-2.006c0.089-0.076,0.965,0.698,1.957,1.729
													C363.453,339.199,364.185,340.097,364.097,340.173z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M460.684,336.521c0.103,0.084-0.854,1.004-2.177,1.98
													c-1.323,0.977-2.403,1.743-2.493,1.671c-0.103-0.084,0.854-1.003,2.177-1.98
													C459.514,337.215,460.594,336.449,460.684,336.521z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M460.508,364.958c-0.092,0.082-1.172-0.69-2.412-1.723
													c-1.241-1.033-2.171-1.936-2.079-2.018s1.172,0.69,2.413,1.723C459.669,363.973,460.6,364.877,460.508,364.958z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M456.014,361.215c0,0-0.01-0.129-0.015-0.37c-0.004-0.254-0.009-0.608-0.015-1.069
													c-0.008-0.951-0.02-2.334-0.035-4.128c-0.019-3.603-0.046-8.847-0.081-15.475l0.146,0.126
													c-20.882,0.018-54.138,0.047-91.523,0.08h-0.006l0.24-0.206c-0.002,7.233-0.004,14.285-0.005,21.043l-0.234-0.201
													c26.13,0.036,48.978,0.068,65.311,0.091c8.169,0.024,14.708,0.044,19.222,0.057c2.245,0.011,3.983,0.02,5.18,0.027
													c0.581,0.005,1.028,0.01,1.348,0.013C455.851,361.207,456.014,361.215,456.014,361.215s-0.143,0.009-0.438,0.013
													c-0.313,0.003-0.75,0.007-1.319,0.013c-1.185,0.006-2.906,0.015-5.129,0.026c-4.501,0.013-11.022,0.033-19.168,0.057
													c-16.374,0.023-39.279,0.055-65.475,0.091l-0.234,0v-0.202c-0.002-6.758-0.003-13.81-0.005-21.043v-0.206h0.239h0.006
													c37.385,0.033,70.641,0.062,91.523,0.08l0.147,0l-0.001,0.125c-0.035,6.66-0.062,11.93-0.081,15.55
													c-0.015,1.782-0.027,3.155-0.035,4.1c-0.007,0.452-0.012,0.798-0.015,1.047
													C456.024,361.103,456.014,361.215,456.014,361.215z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#E0E0E0'
																		d='M418.683,348.752c-0.682-0.432-1.445-0.721-2.229-0.96
													c-0.712-0.217-1.456-0.324-2.204-0.324h-6.111c-1.801,0-3.565,0.606-4.868,1.782c-0.323,0.292-0.579,0.58-0.729,0.834
													c-0.524,0.891-0.547,2.132,0.238,2.829c0.785,0.697,2.39,0.357,2.523-0.654c-0.038,1.337,1.405,2.456,2.814,2.366
													c1.409-0.09,2.608-1.186,3.006-2.469c-0.015,1.135,1.009,2.195,2.204,2.281c1.195,0.086,2.377-0.815,2.543-1.939
													c0.754,0.93,1.87,1.799,3.158,1.404c0.735-0.226,1.277-0.837,1.463-1.546
													C420.873,350.888,419.921,349.536,418.683,348.752z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path
																			fill='#263238'
																			d='M409.227,347.441c0.063,0.111-1.409,0.715-2.51,2.176
														c-1.13,1.442-1.283,2.953-1.414,2.931c-0.052-0.003-0.09-0.385,0.034-0.989c0.121-0.602,0.443-1.418,1.029-2.184
														c0.589-0.764,1.31-1.305,1.878-1.599C408.813,347.478,409.208,347.395,409.227,347.441z'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path
																			fill='#263238'
																			d='M411.569,347.682c0.118,0.018,0.102,1.048-0.036,2.3
														c-0.139,1.253-0.349,2.263-0.469,2.257c-0.12-0.006-0.103-1.036,0.037-2.3
														C411.24,348.674,411.45,347.664,411.569,347.682z'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path
																			fill='#263238'
																			d='M414.516,347.615c0.028-0.042,0.346,0.079,0.709,0.459
														c0.364,0.374,0.721,1.019,0.859,1.781c0.137,0.76,0.078,1.448,0.012,1.931c-0.071,0.485-0.155,0.777-0.214,0.773
														c-0.061-0.004-0.085-0.308-0.093-0.79c-0.011-0.481-0.005-1.142-0.133-1.842c-0.128-0.699-0.403-1.29-0.674-1.674
														C414.714,347.865,414.476,347.666,414.516,347.615z'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<rect
																	x='343.029'
																	y='321.955'
																	fill='#455A64'
																	width='134.35'
																	height='10.577'
																/>
															</g>
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M477.378,332.533c0,0-0.01-0.24-0.016-0.697c-0.004-0.466-0.011-1.141-0.019-2.027
													c-0.011-1.788-0.027-4.421-0.047-7.853l0.082,0.082c-21.22,0.022-73.381,0.076-134.343,0.139h-0.007l0.222-0.222
													c0,3.623,0,7.215-0.001,10.577l-0.221-0.221c37.571,0.037,71.125,0.07,95.3,0.093
													c12.096,0.028,21.843,0.051,28.58,0.066c3.362,0.014,5.971,0.024,7.752,0.031c0.88,0.007,1.553,0.012,2.02,0.015
													C477.137,332.522,477.378,332.533,477.378,332.533s-0.221,0.01-0.667,0.016c-0.46,0.003-1.124,0.009-1.991,0.015
													c-1.77,0.007-4.361,0.018-7.702,0.031c-6.725,0.015-16.454,0.038-28.528,0.066c-24.215,0.024-57.827,0.057-95.462,0.094
													h-0.221v-0.221c0-3.362-0.001-6.954-0.001-10.577l0-0.222h0.222h0.007c60.962,0.063,113.122,0.117,134.343,0.139
													l0.083,0l-0.001,0.083c-0.021,3.453-0.036,6.102-0.047,7.901c-0.008,0.878-0.015,1.547-0.019,2.009
													C477.388,332.313,477.378,332.533,477.378,332.533z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g id='Speech_Bubbles'>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<ellipse
														transform='matrix(0.9653 -0.261 0.261 0.9653 -17.2693 46.7403)'
														fill='#FFFFFF'
														cx='167.333'
														cy='88.386'
														rx='27.222'
														ry='27.222'
													/>
													<g>
														<path
															fill='#263238'
															d='M187.932,70.589c-0.007,0.006-0.117-0.096-0.325-0.301
										c-0.222-0.223-0.525-0.528-0.913-0.918c-0.201-0.204-0.423-0.431-0.667-0.68c-0.256-0.239-0.56-0.474-0.876-0.744
										c-0.636-0.534-1.352-1.172-2.267-1.747c-1.738-1.272-4.105-2.503-6.998-3.476c-2.895-0.956-6.387-1.548-10.225-1.295
										c-3.816,0.263-8.013,1.309-11.899,3.625c-3.845,2.318-7.534,5.687-9.951,10.164c-0.662,1.085-1.132,2.282-1.65,3.471
										c-0.229,0.606-0.407,1.236-0.614,1.858c-0.225,0.618-0.362,1.264-0.498,1.913c-0.338,1.286-0.447,2.626-0.614,3.968
										c-0.03,1.353-0.109,2.72,0.045,4.093c0.243,2.736,0.801,5.526,1.94,8.141c1.104,2.623,2.626,5.107,4.508,7.347
										c1.942,2.189,4.18,4.057,6.615,5.531c2.422,1.508,5.103,2.466,7.775,3.104c1.337,0.351,2.701,0.472,4.044,0.639
										c1.353,0.03,2.694,0.116,4.016-0.031c0.662-0.04,1.321-0.081,1.965-0.215c0.646-0.115,1.294-0.2,1.928-0.338
										c1.252-0.34,2.505-0.631,3.674-1.129c4.781-1.742,8.651-4.903,11.503-8.371c2.856-3.509,4.5-7.51,5.314-11.247
										c0.807-3.76,0.728-7.302,0.202-10.304c-0.543-3.003-1.416-5.524-2.423-7.427c-0.437-0.988-0.964-1.79-1.4-2.496
										c-0.221-0.351-0.41-0.686-0.609-0.974c-0.21-0.278-0.402-0.53-0.575-0.759c-0.329-0.44-0.586-0.784-0.775-1.036
										C188.011,70.719,187.926,70.595,187.932,70.589c0.007-0.006,0.105,0.106,0.292,0.331c0.199,0.245,0.47,0.579,0.818,1.007
										c0.18,0.225,0.379,0.474,0.598,0.747c0.207,0.285,0.404,0.617,0.633,0.965c0.452,0.702,0.996,1.5,1.451,2.489
										c1.041,1.904,1.953,4.438,2.528,7.468c0.559,3.03,0.666,6.614-0.129,10.431c-0.801,3.793-2.451,7.863-5.337,11.441
										c-2.882,3.536-6.806,6.763-11.669,8.55c-1.189,0.509-2.463,0.81-3.738,1.158c-0.644,0.142-1.305,0.23-1.962,0.349
										c-0.656,0.136-1.326,0.181-2.001,0.223c-1.346,0.151-2.712,0.068-4.09,0.038c-1.368-0.167-2.758-0.289-4.121-0.645
										c-2.724-0.646-5.456-1.622-7.926-3.156c-2.483-1.501-4.765-3.405-6.744-5.636c-1.919-2.281-3.469-4.815-4.593-7.489
										c-1.158-2.667-1.727-5.511-1.97-8.299c-0.154-1.4-0.073-2.792-0.039-4.17c0.171-1.367,0.286-2.731,0.632-4.041
										c0.139-0.661,0.281-1.318,0.511-1.947c0.213-0.633,0.395-1.273,0.63-1.89c0.529-1.211,1.012-2.427,1.688-3.53
										c2.474-4.551,6.236-7.965,10.152-10.303c3.958-2.337,8.225-3.377,12.094-3.62c3.891-0.233,7.422,0.394,10.339,1.387
										c2.915,1.009,5.289,2.279,7.022,3.586c0.912,0.593,1.624,1.247,2.252,1.797c0.312,0.277,0.612,0.521,0.864,0.766
										c0.239,0.256,0.456,0.49,0.652,0.701c0.373,0.406,0.664,0.723,0.878,0.955C187.842,70.47,187.939,70.584,187.932,70.589z'
														/>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
								<g>
									<g>
										<path
											fill={theme.palette.primary.main}
											d='M159.939,80.783h4.312c0,0,0-3.62,3.762-3.685c1.954-0.034,2.967,1.246,3.379,2.227
						c0.478,1.139,0.333,2.436-0.307,3.492c-0.731,1.206-2.334,2.302-5.24,1.79v9.722h4.264v-5.695c0,0,5.98-1.546,5.98-8.136
						c0-6.305-5.654-7.77-8.075-7.77C166.163,72.728,159.858,73.176,159.939,80.783z'
										/>
									</g>
									<g>
										<rect
											x='165.833'
											y='96.331'
											fill={theme.palette.primary.main}
											width='4.327'
											height='4.041'
										/>
									</g>
								</g>
							</g>
							<g>
								<path
									fill='#FFFFFF'
									d='M204.56,101.136c0,1.826-1.48,3.306-3.306,3.306c-1.826,0-3.306-1.48-3.306-3.306
				c0-1.826,1.48-3.306,3.306-3.306C203.08,97.83,204.56,99.31,204.56,101.136z'
								/>
								<g>
									<path
										fill='#263238'
										d='M204.56,101.136c-0.046,0.002-0.053-0.294-0.202-0.782c-0.147-0.48-0.49-1.173-1.216-1.699
					c-0.702-0.528-1.84-0.813-2.933-0.398c-1.083,0.382-2.013,1.535-2.001,2.879c-0.012,1.344,0.918,2.498,2.001,2.88
					c1.093,0.415,2.231,0.129,2.933-0.398c0.726-0.526,1.068-1.219,1.215-1.699C204.507,101.43,204.514,101.135,204.56,101.136
					c0.025-0.002,0.09,0.296-0.005,0.832c-0.095,0.524-0.408,1.314-1.189,1.942c-0.753,0.629-2.044,1.01-3.32,0.556
					c-1.264-0.412-2.374-1.755-2.358-3.331c-0.015-1.576,1.094-2.918,2.358-3.33c1.275-0.453,2.566-0.073,3.32,0.556
					c0.781,0.628,1.094,1.418,1.189,1.942C204.65,100.84,204.585,101.138,204.56,101.136z'
									/>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<path
																							fill='#FFFFFF'
																							d='M303.618,97.88l9.094-0.533c3.833,3.568,8.668,6.155,14.18,7.258
																		c15.85,3.172,31.271-7.106,34.443-22.956c3.172-15.85-7.106-31.271-22.956-34.443
																		c-15.85-3.172-31.271,7.105-34.443,22.956c-1.324,6.618-0.297,13.158,2.495,18.766l-0.011-0.012L303.618,97.88z'
																						/>
																					</g>
																				</g>
																			</g>
																		</g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<path
																								fill='#263238'
																								d='M303.618,97.88c0,0,0.05-0.187,0.165-0.568c0.119-0.394,0.291-0.962,0.516-1.708
																			c0.467-1.531,1.155-3.781,2.049-6.709l0.039-0.126l0.09,0.098l0.011,0.012l-0.124,0.084
																			c-1.682-3.361-3.284-8.184-3.096-14.058c0.043-1.461,0.207-2.977,0.502-4.524c0.37-1.528,0.696-3.133,1.332-4.695
																			c1.158-3.152,2.94-6.314,5.416-9.141c2.467-2.826,5.622-5.311,9.309-7.098c3.68-1.79,7.893-2.872,12.283-2.963
																			c4.389-0.119,8.927,0.867,13.11,2.836c4.177,1.974,8.024,4.974,10.887,8.859c0.357,0.49,0.725,0.971,1.071,1.464
																			c0.31,0.517,0.62,1.034,0.929,1.549l0.463,0.772l0.39,0.809c0.257,0.54,0.513,1.078,0.769,1.615
																			c0.236,0.545,0.415,1.113,0.624,1.666c0.19,0.56,0.435,1.101,0.556,1.679c0.281,1.143,0.631,2.264,0.76,3.425
																			c0.75,4.596,0.379,9.242-0.91,13.46c-1.321,4.214-3.553,7.976-6.322,11.015c-2.768,3.048-6.058,5.378-9.478,6.953
																			c-3.423,1.582-6.97,2.406-10.331,2.629c-1.684,0.153-3.318,0.033-4.892-0.062c-1.559-0.243-3.076-0.41-4.487-0.81
																			c-5.69-1.488-9.863-4.381-12.585-6.94l0.055,0.02c-2.963,0.154-5.243,0.272-6.794,0.352
																			c-0.755,0.037-1.331,0.065-1.73,0.085C303.806,97.876,303.618,97.88,303.618,97.88s0.209-0.02,0.603-0.047
																			c0.404-0.028,0.985-0.067,1.749-0.119c1.539-0.101,3.799-0.248,6.738-0.44l0.031-0.002l0.024,0.022
																			c2.729,2.525,6.891,5.373,12.542,6.825c1.402,0.391,2.908,0.55,4.454,0.786c1.561,0.087,3.181,0.201,4.849,0.044
																			c3.329-0.231,6.839-1.058,10.224-2.632c3.382-1.567,6.633-3.88,9.366-6.899c2.733-3.011,4.934-6.733,6.235-10.9
																			c1.27-4.172,1.631-8.764,0.889-13.304c-0.126-1.147-0.474-2.255-0.752-3.384c-0.119-0.571-0.361-1.106-0.55-1.659
																			c-0.207-0.547-0.383-1.107-0.617-1.646c-0.253-0.531-0.506-1.062-0.76-1.596l-0.385-0.799l-0.458-0.762
																			c-0.305-0.509-0.611-1.019-0.918-1.53c-0.342-0.488-0.706-0.963-1.058-1.446
																			c-2.829-3.839-6.629-6.803-10.755-8.756c-4.131-1.947-8.618-2.927-12.955-2.813
																			c-4.339,0.085-8.506,1.149-12.148,2.914c-3.65,1.761-6.776,4.213-9.225,7.005
																			c-2.457,2.792-4.231,5.919-5.388,9.039c-0.635,1.545-0.963,3.135-1.336,4.649
																			c-0.298,1.533-0.467,3.035-0.516,4.485c-0.211,5.826,1.352,10.626,2.998,13.983l0.241,0.493l-0.365-0.408
																			l-0.011-0.012l0.128-0.028c-0.926,2.894-1.637,5.118-2.121,6.632c-0.242,0.75-0.427,1.322-0.555,1.718
																			C303.69,97.676,303.618,97.88,303.618,97.88z'
																							/>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<path
														fill={theme.palette.primary.main}
														d='M323.677,70.33h4.168c0,0,0-3.499,3.637-3.562c1.889-0.033,2.868,1.205,3.266,2.152
									c0.462,1.101,0.322,2.355-0.297,3.376c-0.707,1.166-2.256,2.225-5.065,1.73v9.397h4.121v-5.505c0,0,5.78-1.494,5.78-7.864
									c0-6.095-5.465-7.51-7.805-7.51C329.693,62.545,323.598,62.977,323.677,70.33z'
													/>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<rect
														x='329.374'
														y='85.36'
														fill={theme.palette.primary.main}
														width='4.182'
														height='3.906'
													/>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g id='Character'>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<path
																							fill='#263238'
																							d='M240.727,428.207l0.7,22.783c0,0-22.922,8.363-23.203,12.586l44.223,0.229
																		l0.366-35.543L240.727,428.207z'
																						/>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<path
																								fill='#E0E0E0'
																								d='M255.112,449.145c0.855,0.238,1.441,1.204,1.238,2.067
																			c-0.203,0.864-1.18,1.469-2.038,1.245c-0.858-0.223-1.598-1.36-1.299-2.196c0.298-0.835,1.484-1.483,2.264-1.06'
																							/>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<path
																							fill='#455A64'
																							d='M262.448,463.805l-0.104-3.584l-42.361,1.121c0,0-1.973,0.846-1.758,2.234
																		L262.448,463.805z'
																						/>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<path
																								fill='#FFFFFF'
																								d='M240.712,450.761c0.006,0.216,1.085,0.32,2.144,1.02
																			c1.077,0.672,1.62,1.611,1.819,1.526c0.199-0.041-0.116-1.333-1.413-2.158
																			C241.973,450.312,240.666,450.563,240.712,450.761z'
																							/>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<path
																								fill='#FFFFFF'
																								d='M236.064,452.656c-0.05,0.209,0.883,0.581,1.612,1.498
																			c0.755,0.896,0.935,1.884,1.151,1.876c0.203,0.035,0.359-1.228-0.569-2.352
																			C237.339,452.547,236.069,452.45,236.064,452.656z'
																							/>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<path
																								fill='#FFFFFF'
																								d='M233.4,458.194c0.196,0.047,0.502-1.031-0.046-2.216
																			c-0.54-1.19-1.556-1.661-1.648-1.482c-0.123,0.177,0.529,0.815,0.964,1.795
																			C233.129,457.26,233.186,458.171,233.4,458.194z'
																							/>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<path
																								fill='#FFFFFF'
																								d='M241.128,445.551c0.09,0.197,1.082-0.095,2.302-0.006
																			c1.222,0.064,2.17,0.479,2.283,0.294c0.126-0.156-0.797-0.96-2.236-1.045
																			C242.04,444.699,241.023,445.381,241.128,445.551z'
																							/>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M292.677,433.112l2.395,27.473l-4.371,22.275c-0.315,1.605,0.795,3.142,2.418,3.347l0,0
													c0.909,0.115,1.81-0.211,2.43-0.887c3.941-4.297,18.361-20.177,18.073-22.177c-0.333-2.317-0.602-29.146-0.602-29.146
													L292.677,433.112z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#455A64'
																		d='M293.119,486.207l20.384-24.921l0.075,1.166c0.064,1.008-0.226,2.007-0.83,2.816
													c-1.616,2.166-6.208,8.048-17.116,20.048C294.997,486.014,294.051,486.35,293.119,486.207L293.119,486.207z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g opacity='0.6'>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path
																			fill='#FFFFFF'
																			d='M307.286,452.77c-0.659,0.521-0.817,1.578-0.324,2.258
														c0.494,0.68,1.568,0.85,2.236,0.34c0.668-0.509,0.897-1.773,0.331-2.393c-0.566-0.62-1.838-0.751-2.365-0.097'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path
																			fill='#FFFFFF'
																			d='M298.637,473.779c-0.151,0.086-0.864-1.315-2.551-1.986
														c-1.666-0.721-3.161-0.235-3.207-0.403c-0.028-0.066,0.306-0.302,0.944-0.434c0.632-0.135,1.569-0.105,2.495,0.278
														c0.924,0.385,1.608,1.027,1.959,1.57C298.635,473.349,298.704,473.752,298.637,473.779z'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path
																			fill='#FFFFFF'
																			d='M296.913,477.59c-0.123,0.128-1.064-0.711-2.513-1.027
														c-1.443-0.349-2.654-0.005-2.71-0.174c-0.088-0.135,1.199-0.804,2.846-0.416
														C296.186,476.343,297.052,477.506,296.913,477.59z'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path
																			fill='#FFFFFF'
																			d='M301.983,468.822c-0.121,0.139-1.561-1.158-3.772-1.847
														c-2.201-0.724-4.126-0.507-4.143-0.69c-0.054-0.144,1.966-0.657,4.328,0.113
														C300.764,467.144,302.111,468.736,301.983,468.822z'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path
																			fill='#FFFFFF'
																			d='M301.953,460.41c-0.063,0.169-1.624-0.368-3.607-0.313
														c-1.984,0.028-3.52,0.632-3.59,0.465c-0.085-0.13,1.446-1.035,3.578-1.07
														C300.464,459.437,302.032,460.276,301.953,460.41z'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path
																			fill='#FFFFFF'
																			d='M301.454,452.327c-0.075,0.164-1.663-0.655-3.746-0.425
														c-2.089,0.184-3.484,1.298-3.59,1.151c-0.049-0.054,0.229-0.419,0.84-0.827c0.606-0.407,1.567-0.813,2.69-0.926
														c1.123-0.109,2.146,0.101,2.819,0.382C301.146,451.961,301.491,452.264,301.454,452.327z'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path
																			fill='#FFFFFF'
																			d='M297.658,450.362c-0.035,0.003-0.121-0.363-0.104-1.039
														c0.018-0.671,0.149-1.664,0.652-2.779c0.265-0.53,0.532-1.207,1.277-1.619c0.37-0.207,0.944-0.173,1.294,0.17
														c0.346,0.334,0.477,0.779,0.515,1.215c0.083,1.748-1.308,3.534-3.256,3.911c-1.959,0.343-3.818-0.668-4.696-2.127
														c-0.196-0.38-0.345-0.851-0.161-1.329c0.18-0.482,0.727-0.705,1.126-0.663c0.826,0.07,1.371,0.507,1.874,0.826
														c0.997,0.702,1.636,1.466,2.015,2.018c0.382,0.556,0.515,0.907,0.484,0.924c-0.092,0.078-0.782-1.307-2.737-2.585
														c-0.491-0.291-1.059-0.663-1.651-0.68c-0.288-0.01-0.528,0.12-0.615,0.358c-0.091,0.229-0.011,0.57,0.157,0.868
														c0.74,1.192,2.441,2.113,4.092,1.797c1.645-0.301,2.866-1.873,2.812-3.281c-0.018-0.339-0.13-0.674-0.323-0.863
														c-0.191-0.186-0.465-0.223-0.712-0.104c-0.522,0.25-0.844,0.851-1.107,1.351
														C297.562,448.824,297.782,450.381,297.658,450.362z'
																		/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
								<g>
									<g>
										<g>
											<g>
												<path
													fill='#263238'
													d='M218.081,239.309c0,0-3.353,12.982-2.137,28.798c0.471,6.12,21.123,175.429,21.123,175.429
								h27.884l-7.838-159.127l2.283,0.495c0,0,15.221,67.867,16.463,78.856c1.116,9.876,14.786,87.509,14.786,87.509l26.307-0.292
								l-9.787-100.204l-9.476-91.068l-3.565-26.509L218.081,239.309z'
												/>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<path
									fill='#E0E0E0'
									d='M221.312,267.47c25.701,8.863,59.469,13.306,76.795-3.761l-5.614-129.378h-65.45L221.312,267.47z'
								/>
								<g>
									<path
										fill='#263238'
										d='M270.759,201.65c0.096,0.107-4.428,4.348-10.104,9.472c-5.678,5.126-10.358,9.193-10.454,9.086
					c-0.096-0.107,4.427-4.347,10.105-9.473C265.982,205.611,270.663,201.543,270.759,201.65z'
									/>
								</g>
								<path
									opacity='0.3'
									d='M246.414,273.866c0,0,1.653-108.65-0.531-113.946c-2.185-5.296-9.969,112.37-9.29,112.37
				L246.414,273.866z'
								/>
								<path
									fill={theme.palette.primary.main}
									d='M219.278,161.77l-3.316,54.79l-2.402,55.037c7.112,5.432,16.786,8.26,28.337,9.179l4.517-111.201
				v-42.656l-0.804-0.544l-5.52,4.77l-1.264,0.135c-0.885-1.816-2.275-1.144-2.275-1.144c-0.442-0.74-1.074-0.874-1.074-0.874
				c-1.327-0.202-2.149,0.874-2.149,0.874l-1.011-1.076c-1.643-0.471-2.579,1.558-2.579,1.558
				c-14.43,1.6-16.385,10.079-18.639,15.464L219.278,161.77z'
								/>
								<path
									fill={theme.palette.primary.main}
									d='M223.506,162.844c-0.153,0.46-1.381,31.309-1.381,31.309s3.376,3.53,2.149,8.134
				c-1.228,4.604-3.471,15.225-9.563,15.688c-8.544,0.65-16.528-4.331-16.374-19.678c0.154-15.347,12.626-52.215,12.626-52.215
				l14.692,10.417L223.506,162.844z'
								/>
								<path
									fill={theme.palette.primary.main}
									d='M272.862,124.874c0,0,5.095,3.309,6.581,5.723l3.689-0.032c0.649-0.066,1.335-0.808,2.17-0.78
				c0.494,0.016,1.086,0.773,1.627,0.819c0.462,0.04,0.836-0.778,1.323-0.718c1.028,0.127,1.793,0.816,2.882,1.085
				c4.129,1.018,8.715,2.416,10.88,3.966c5.594,4.008,7.472,11.12,7.472,11.12s-15.104,16.292-14.052,17.062
				c1.052,0.77,0.479,41.597,0.479,41.597s5.587,62.238,4.101,64.28c-1.486,2.043-16.487,11.135-30.601,10.336
				c0,0-0.456-7.898,0.716-26.477c1.762-27.933,1.565-37.976,2.059-48.034c0.797-16.218-0.138-46.821,0.014-51.899
				C272.314,149.253,272.513,124.958,272.862,124.874z'
								/>
								<g opacity='0.3'>
									<path
										d='M293.728,194.41c-2.468,4.115-3.498,9.074-2.873,13.832c0.625,4.758,2.901,9.282,6.348,12.621v-6.342l-2.969-18.647
					L293.728,194.41z'
									/>
								</g>
								<path
									fill={theme.palette.primary.main}
									d='M309.486,146.059c0,0,5.28,39.827,8.034,43.503c2.754,3.676,1.843,8.865,1.131,13.916
				c-0.779,5.527-3.545,18.659-14.047,17.756c-5.511-0.474-8.784-4.462-10.665-10.41c-3.263-10.322,0.966-22.506,0.965-25.308
				s-3.324-23.695-2.935-24.79C292.359,159.631,309.486,146.059,309.486,146.059z'
								/>
								<g>
									<path
										fill='#263238'
										d='M246.236,153.051c-0.03,0.017-0.226-0.292-0.588-0.849c-0.368-0.549-0.887-1.371-1.712-2.222
					c-0.412-0.423-0.914-0.841-1.523-1.158c-0.598-0.327-1.34-0.474-2.125-0.561c-1.565-0.154-3.374,0.156-4.971,1.105l-0.578,0.344
					l0.202-0.644c0.248-0.789,0.497-1.62,0.742-2.471c1.175-4.22,2.238-8.035,3.047-10.941c0.377-1.319,0.694-2.428,0.947-3.312
					c0.233-0.779,0.378-1.206,0.413-1.197c0.035,0.009-0.044,0.453-0.217,1.248c-0.173,0.795-0.441,1.941-0.787,3.354
					c-0.696,2.825-1.681,6.72-2.903,10.993c-0.245,0.854-0.498,1.688-0.753,2.485l-0.376-0.3c3.433-2.003,7.614-1.239,9.127,0.822
					c0.824,0.918,1.301,1.778,1.624,2.361C246.121,152.699,246.262,153.036,246.236,153.051z'
									/>
								</g>
								<g opacity='0.3'>
									<path
										d='M275.761,149.131c1.61-0.733,3.508-0.731,5.206-0.236l0.021,0.083c0.478,1.316,0.813,2.684,0.997,4.072
					c-2.88-1.689-6.546-2.393-9.501-0.837C273.461,150.8,274.151,149.864,275.761,149.131z'
									/>
								</g>
								<g>
									<path
										fill='#263238'
										d='M272.19,152.413c-0.022-0.015,0.143-0.319,0.525-0.825c0.385-0.501,0.985-1.214,1.904-1.932
					c0.46-0.356,1.002-0.712,1.648-0.99c0.648-0.278,1.418-0.423,2.211-0.415c1.593-0.01,3.391,0.579,4.818,1.851l-0.422,0.223
					c-0.161-1.133-0.37-2.325-0.581-3.546c-0.806-4.409-1.535-8.396-2.089-11.426c-0.242-1.385-0.444-2.547-0.605-3.469
					c-0.133-0.821-0.19-1.279-0.155-1.286c0.035-0.007,0.16,0.437,0.354,1.246c0.194,0.809,0.457,1.984,0.768,3.438
					c0.619,2.91,1.444,6.935,2.24,11.407c0.212,1.226,0.418,2.419,0.572,3.569l0.093,0.688l-0.514-0.465
					c-1.319-1.193-2.99-1.767-4.483-1.787c-1.521-0.079-2.751,0.57-3.659,1.226C272.999,151.268,272.264,152.48,272.19,152.413z'
									/>
								</g>
								<g opacity='0.3'>
									<path
										d='M226.876,203.021c-3.347-0.337-6.82,0.966-9.12,3.42c-2.3,2.454-3.374,6.005-2.821,9.323
					c0.167-0.094,1.127-0.468,1.127-0.468l-0.29,4.899c3.791-0.523,7.468-2.939,9.539-6.156
					C227.382,210.822,227.969,206.689,226.876,203.021z'
									/>
								</g>
								<g>
									<path
										fill={theme.palette.primary.main}
										d='M214.71,217.975c3.093-0.272,5.995-2.005,8.069-4.471c2.075-2.466,3.357-5.621,3.943-8.878
					c0.658-3.659,0.391-7.71-1.673-10.713c-2.101-3.058-5.127-4.322-8.492-5.531'
									/>
									<g>
										<path
											fill='#263238'
											d='M216.558,188.382c0.011-0.031,0.561,0.121,1.536,0.464c0.966,0.355,2.389,0.87,3.963,1.883
						c1.562,1.003,3.241,2.634,4.217,4.947c0.977,2.3,1.226,5.1,0.867,7.917c-0.367,2.822-1.238,5.439-2.467,7.583
						c-1.216,2.155-2.814,3.789-4.353,4.842c-1.539,1.069-3,1.566-4.012,1.785c-0.508,0.111-0.91,0.158-1.182,0.175
						c-0.273,0.015-0.416,0.014-0.418-0.004c-0.005-0.047,0.566-0.097,1.551-0.368c0.978-0.275,2.378-0.812,3.848-1.889
						c1.47-1.063,2.988-2.668,4.148-4.777c1.172-2.097,2.01-4.658,2.368-7.413c0.35-2.747,0.118-5.457-0.794-7.667
						c-0.907-2.216-2.487-3.812-3.979-4.826c-1.505-1.025-2.893-1.59-3.832-2C217.073,188.635,216.545,188.418,216.558,188.382z'
										/>
									</g>
								</g>
								<g>
									<path
										fill='#263238'
										d='M218.89,154.797c0.041-0.012,0.279,0.662,0.666,1.869c0.405,1.312,0.934,3.026,1.563,5.067
					l0.008,0.026l0,0.033c0.028,2.951,0.061,6.55,0.098,10.446c0.044,4.845,0.083,9.227,0.113,12.536
					c0.01,1.527,0.019,2.802,0.025,3.799c0,0.897-0.017,1.395-0.052,1.396c-0.036,0.001-0.088-0.493-0.15-1.389
					c-0.063-0.896-0.129-2.193-0.191-3.797c-0.125-3.208-0.225-7.642-0.266-12.54c-0.033-3.922-0.028-7.547,0.003-10.451
					l0.008,0.058c-0.56-2.061-1.03-3.793-1.39-5.117C219.008,155.506,218.849,154.809,218.89,154.797z'
									/>
								</g>
								<g>
									<path
										fill='#263238'
										d='M299.626,155.996c0.032,0.023-0.289,0.535-0.895,1.437c-0.674,0.979-1.551,2.252-2.604,3.782
					l0.037-0.113c0.015,3.148-0.108,7.329-0.336,11.896c-0.258,4.962-0.491,9.45-0.667,12.832c-0.074,1.566-0.135,2.872-0.183,3.889
					c-0.044,0.919-0.085,1.427-0.121,1.426c-0.036,0-0.063-0.509-0.082-1.43c-0.016-0.92-0.013-2.253,0.017-3.898
					c0.059-3.291,0.266-7.833,0.515-12.845c0.228-4.565,0.398-8.735,0.463-11.874l0.001-0.065l0.035-0.048
					c1.11-1.489,2.035-2.728,2.745-3.681C299.212,156.442,299.594,155.974,299.626,155.996z'
									/>
								</g>
								<g>
									<path
										fill='#263238'
										d='M306.759,221.216c0,0.036-0.597,0.043-1.688,0.023c-1.07-0.135-2.681-0.306-4.448-1.203
					c-1.757-0.877-3.586-2.485-4.932-4.707c-1.357-2.217-2.292-4.94-2.886-7.845c-0.645-2.91-0.68-5.796-0.363-8.351
					c0.316-2.563,1.004-4.804,1.754-6.599c0.767-1.792,1.6-3.142,2.247-4.015c0.304-0.45,0.593-0.761,0.773-0.982
					c0.186-0.217,0.29-0.325,0.304-0.313c0.11,0.07-1.551,1.871-2.983,5.45c-0.695,1.785-1.334,3.997-1.618,6.516
					c-0.285,2.514-0.237,5.329,0.396,8.188c0.587,2.867,1.492,5.537,2.787,7.702c1.286,2.17,3.014,3.738,4.691,4.629
					c1.686,0.909,3.25,1.133,4.299,1.328C306.162,221.118,306.759,221.18,306.759,221.216z'
									/>
								</g>
								<g opacity='0.3'>
									<path
										d='M242.244,148.567c-1.61-0.733-3.508-0.731-5.206-0.236l-0.02,0.083c-0.478,1.316-0.813,2.684-0.997,4.072
					c2.88-1.689,6.635-1.775,9.59-0.219C244.868,150.662,243.855,149.3,242.244,148.567z'
									/>
								</g>
							</g>
							<g>
								<g>
									<g>
										<path
											fill='#FFBF9D'
											d='M256.059,67.181l-1.122,0.046c-11.295,0.465-20.337,9.526-20.779,20.822
						c-0.412,10.506-0.732,20.762-0.43,26.376c0.624,11.624,12.562,12.768,12.562,12.768c0.026,0-0.019,4.04-0.025,7.456
						c5.411,9.152,18.378,10.085,24.786,1.602l1.434-1.799l1.764-44.528C274.789,77.805,268.189,67.388,256.059,67.181z'
										/>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<path
																						fill={theme.palette.primary.main}
																						d='M246.289,127.301c0,0,7.349,0.127,14.959-5.103c0,0-3.282,8.894-14.931,8.327
																	L246.289,127.301z'
																					/>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<g>
																									<g>
																										<g>
																											<path
																												fill='#263238'
																												d='M239.511,96.958c0.005,0.878,0.753,1.598,1.671,1.61
																							c0.916,0.011,1.655-0.69,1.65-1.568c-0.005-0.878-0.753-1.599-1.669-1.611
																							C240.245,95.377,239.506,96.08,239.511,96.958z'
																											/>
																										</g>
																									</g>
																								</g>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<g>
																									<g>
																										<g>
																											<g>
																												<path
																													fill='#263238'
																													d='M236.843,93.077c0.215,0.208,1.434-0.775,3.222-0.844
																								c1.784-0.095,3.134,0.771,3.319,0.544c0.091-0.101-0.134-0.483-0.723-0.876
																								c-0.579-0.39-1.555-0.754-2.668-0.703c-1.113,0.053-2.033,0.501-2.554,0.943
																								C236.907,92.585,236.737,92.984,236.843,93.077z'
																												/>
																											</g>
																										</g>
																									</g>
																								</g>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<g>
																									<g>
																										<g>
																											<path
																												fill='#263238'
																												d='M255.985,96.761c0.005,0.878,0.753,1.598,1.671,1.609
																							c0.916,0.011,1.656-0.689,1.65-1.568c-0.005-0.878-0.753-1.599-1.669-1.611
																							C256.72,95.18,255.98,95.882,255.985,96.761z'
																											/>
																										</g>
																									</g>
																								</g>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<g>
																									<g>
																										<g>
																											<g>
																												<path
																													fill='#263238'
																													d='M254.38,92.768c0.215,0.208,1.437-0.775,3.223-0.844
																								c1.784-0.094,3.134,0.771,3.319,0.544c0.091-0.101-0.133-0.483-0.723-0.875
																								c-0.579-0.391-1.555-0.754-2.668-0.704c-1.113,0.052-2.033,0.501-2.554,0.942
																								C254.446,92.276,254.275,92.675,254.38,92.768z'
																												/>
																											</g>
																										</g>
																									</g>
																								</g>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<g>
																									<g>
																										<g>
																											<path
																												fill='#263238'
																												d='M248.536,106.766c0.004-0.102-1.115-0.248-2.925-0.404
																							c-0.459-0.028-0.894-0.101-0.984-0.409c-0.121-0.325,0.049-0.825,0.249-1.365
																							c0.383-1.122,0.785-2.299,1.206-3.531c1.67-5.026,2.859-9.15,2.656-9.216
																							c-0.203-0.065-1.721,3.955-3.391,8.98c-0.401,1.239-0.784,2.42-1.149,3.55
																							c-0.151,0.526-0.424,1.127-0.163,1.801c0.137,0.336,0.482,0.57,0.778,0.636
																							c0.296,0.077,0.554,0.072,0.78,0.076C247.409,106.907,248.533,106.868,248.536,106.766z'
																											/>
																										</g>
																									</g>
																								</g>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<path
																								fill='#263238'
																								d='M254.837,83.883c0.196,0.479,1.971,0.177,4.078,0.346
																			c2.111,0.127,3.827,0.673,4.087,0.226c0.116-0.214-0.197-0.659-0.9-1.102c-0.696-0.443-1.796-0.846-3.06-0.936
																			c-1.264-0.087-2.409,0.159-3.161,0.5C255.124,83.257,254.752,83.655,254.837,83.883z'
																							/>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<path
																								fill='#263238'
																								d='M237.473,84.827c0.388,0.391,1.826-0.088,3.576-0.178
																			c1.746-0.141,3.242,0.144,3.567-0.294c0.141-0.212-0.108-0.609-0.771-0.968c-0.653-0.358-1.733-0.628-2.934-0.549
																			c-1.201,0.08-2.228,0.489-2.821,0.929C237.488,84.209,237.301,84.635,237.473,84.827z'
																							/>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<path
																				fill='#FFBF9D'
																				d='M273.653,98.576c0.195-0.094,7.894-2.694,7.968,5.268s-8.573,6.669-8.589,6.441
															C273.016,110.057,273.653,98.576,273.653,98.576z'
																			/>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<path
																					fill={theme.palette.primary.main}
																					d='M276.075,106.971c0.034-0.026,0.14,0.096,0.375,0.201
																c0.229,0.102,0.623,0.171,1.037,0.011c0.838-0.316,1.534-1.658,1.559-3.094c0.013-0.725-0.145-1.413-0.407-1.982
																c-0.249-0.58-0.629-0.984-1.053-1.055c-0.422-0.093-0.72,0.163-0.815,0.388c-0.104,0.224-0.052,0.382-0.095,0.396
																c-0.022,0.02-0.172-0.132-0.111-0.461c0.031-0.159,0.123-0.347,0.309-0.502c0.189-0.157,0.47-0.239,0.765-0.21
																c0.614,0.037,1.173,0.597,1.461,1.221c0.322,0.627,0.517,1.401,0.501,2.215c-0.036,1.605-0.843,3.114-1.991,3.457
																c-0.559,0.151-1.027-0.013-1.271-0.199C276.091,107.163,276.047,106.986,276.075,106.971z'
																				/>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#263238'
																		d='M218.087,77.568c0.121-0.47,0.458-0.86,0.874-1.112c0.906-0.549,1.957-0.489,2.973-0.517
													c2.526-0.069,5.025-0.744,7.264-1.913c-0.79-0.389-1.529-0.864-2.159-1.479c-1.302-1.269-2.064-3.227-1.494-4.953
													c0.107-0.324,0.291-0.664,0.616-0.769c0.515-0.165,0.974,0.338,1.339,0.737c1.201,1.311,2.303,1.868,4.069,1.669
													c1.097-0.124,2.159-0.382,3.017-0.93c0.034-0.036,0.064-0.076,0.099-0.112c0.409-0.432,0.809-0.845,1.202-1.244
													c0.001-0.001,0.002-0.002,0.003-0.004l-0.001,0.003c2.962-3.005,5.514-5.093,8.154-6.19
													c3.869-1.609,10.363-2.317,14.428-1.3c4.065,1.018,7.742,4.264,8.353,8.41c2.251-1.947,5.664-1.97,8.445-0.889
													l0.069-0.031c3.095,1.064,4.884,4.301,5.83,7.435c0.051,0.171,0.097,0.345,0.146,0.518
													c1.061,1.641,2.494,3.04,4.195,4.005c0.3,0.17,0.644,0.379,0.684,0.721c0.04,0.339-0.244,0.625-0.52,0.827
													c-0.733,0.538-1.59,0.895-2.483,1.065c0.29,0.744,0.63,1.466,1.047,2.155c0.355,0.585,0.776,1.287,0.479,1.903
													c-0.23,0.477-0.83,0.679-1.355,0.608c-0.524-0.071-0.992-0.355-1.442-0.633c0.353,0.819,0.706,1.639,1.06,2.458
													c-1.113,0.207-2.269,0.131-3.356-0.186c-0.622,2.948-1.052,8.398-1.786,10.4c-2.113,0.061-2.629-0.163-3.691,0.203
													c-1.373,0.473-0.819,2.908-2.622,2.235c-0.917-0.343-2.359-3.789-2.675-4.715c-2.098-6.151-2.847-12.756-2.187-19.221
													c-1.293-1.347-2.846-2.442-4.55-3.205l0.14,0.123c-3.268-1.093-6.964-2.164-10.035-0.601
													c-2.382,1.213-3.762,3.715-5.531,5.719c-3.352,3.798-7.172,5.849-12.231,6.124c-0.416,3.52-0.486,9.27-0.621,12.552
													l-2.875-6.534l-0.002,0.027c-0.231-0.382-0.422-0.768-0.607-1.154l-0.064-0.117v-0.015
													c-0.294-0.621-0.574-1.243-0.944-1.855c-0.01,0.009-0.021,0.019-0.031,0.028c-1.759,1.588-4.405-0.779-3.025-2.706
													c0.274-0.382,0.602-0.724,0.979-1.007c-0.803,0.772-2.01,1.151-3.079,0.836c-1.069-0.315-1.907-1.383-1.828-2.495
													c0.055-0.758,0.589-1.442,1.27-1.75c-1.434,0.127-2.906-0.169-4.165-0.869
													C218.634,79.364,217.821,78.603,218.087,77.568z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#455A64'
																		d='M263.171,69.244c-0.023,0.03,0.491,0.303,1.181,1.056
													c0.683,0.75,1.454,2.059,1.832,3.849c0.194,0.889,0.32,1.885,0.332,2.958c0.017,1.073-0.056,2.223-0.125,3.435
													c-0.064,1.212-0.136,2.492-0.044,3.822c0.047,0.664,0.134,1.339,0.286,2.013c0.154,0.662,0.337,1.381,0.845,1.946
													c0.404,0.461,1.074,0.659,1.651,0.534c0.581-0.11,1.096-0.425,1.47-0.853c0.74-0.883,0.914-1.995,1.089-2.975
													l-0.244,0.032c0.41,0.875,0.83,1.738,1.445,2.425c0.606,0.691,1.439,1.143,2.281,1.209
													c0.419,0.033,0.841-0.039,1.199-0.224c0.365-0.183,0.628-0.49,0.807-0.813c0.171-0.329,0.258-0.689,0.236-1.035
													c-0.024-0.346-0.162-0.672-0.384-0.902l-0.123,0.112c0.91,1.013,1.865,1.552,2.525,1.85
													c0.333,0.149,0.599,0.237,0.779,0.293c0.181,0.051,0.276,0.073,0.279,0.066c0.002-0.008-0.089-0.044-0.264-0.109
													c-0.174-0.069-0.433-0.17-0.756-0.33c-0.64-0.318-1.565-0.872-2.438-1.878l-0.122,0.112
													c0.397,0.411,0.428,1.14,0.097,1.731c-0.167,0.291-0.408,0.565-0.73,0.72c-0.319,0.161-0.703,0.224-1.087,0.19
													c-0.774-0.068-1.55-0.492-2.117-1.149c-0.581-0.656-0.988-1.497-1.392-2.373l-0.172-0.371l-0.073,0.404
													c-0.175,0.979-0.362,2.047-1.037,2.843c-0.335,0.383-0.807,0.668-1.315,0.763c-0.51,0.105-1.05-0.058-1.403-0.452
													c-0.444-0.489-0.63-1.169-0.78-1.821c-0.149-0.658-0.236-1.318-0.284-1.971c-0.095-1.308-0.029-2.58,0.029-3.79
													c0.061-1.212,0.127-2.367,0.1-3.453c-0.022-1.086-0.161-2.095-0.37-2.996c-0.408-1.812-1.223-3.137-1.942-3.877
													C263.704,69.484,263.171,69.244,263.171,69.244z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#455A64'
																		d='M227.253,80.992c-0.003,0.003,0.029,0.03,0.094,0.078
													c0.079,0.056,0.176,0.125,0.293,0.209c0.128,0.094,0.291,0.203,0.497,0.311c0.203,0.113,0.433,0.251,0.713,0.365
													c1.088,0.502,2.829,0.993,4.998,0.84c2.148-0.145,4.719-0.936,7.003-2.677c2.309-1.7,4.324-4.19,5.962-7.033
													c0.832-1.42,1.506-2.875,2.252-4.219c0.741-1.345,1.563-2.59,2.564-3.577c0.989-0.992,2.142-1.73,3.334-2.076
													c0.594-0.184,1.195-0.231,1.768-0.224c0.575,0.024,1.121,0.134,1.625,0.293c1.008,0.326,1.825,0.871,2.446,1.435
													c0.624,0.569,1.044,1.173,1.335,1.691c0.542,1.065,0.579,1.752,0.621,1.74c0.005-0.001,0.001-0.042-0.011-0.122
													c-0.016-0.097-0.036-0.214-0.059-0.356c-0.061-0.311-0.2-0.762-0.457-1.311c-0.281-0.535-0.697-1.161-1.325-1.754
													c-0.626-0.587-1.454-1.159-2.489-1.506c-0.517-0.17-1.08-0.29-1.677-0.32c-0.594-0.014-1.224,0.032-1.84,0.217
													c-1.239,0.35-2.432,1.105-3.448,2.115c-1.03,1.006-1.87,2.272-2.62,3.626c-0.756,1.354-1.43,2.805-2.255,4.212
													c-1.626,2.822-3.612,5.283-5.876,6.967c-2.242,1.725-4.752,2.517-6.867,2.686c-2.132,0.174-3.852-0.279-4.941-0.746
													C227.797,81.384,227.274,80.958,227.253,80.992z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path
																		fill='#455A64'
																		d='M226.134,75.883c-0.009,0.016,0.141,0.117,0.421,0.286
													c0.278,0.174,0.703,0.388,1.245,0.614c0.54,0.231,1.209,0.445,1.967,0.621c0.76,0.163,1.611,0.281,2.509,0.304
													c0.899,0.013,1.754-0.069,2.52-0.2c0.764-0.145,1.441-0.331,1.991-0.539c0.55-0.204,0.984-0.4,1.268-0.563
													c0.288-0.157,0.442-0.252,0.433-0.268c-0.023-0.046-0.663,0.292-1.764,0.648c-0.549,0.183-1.219,0.346-1.97,0.474
													c-0.754,0.115-1.592,0.188-2.472,0.175c-0.88-0.023-1.714-0.131-2.462-0.277c-0.745-0.16-1.408-0.35-1.949-0.555
													C226.785,76.203,226.159,75.839,226.134,75.883z'
																	/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
								<g>
									<path
										fill='#263238'
										d='M255.055,110.989c-0.088,0.058-0.226-0.502-0.856-0.77c-0.317-0.127-0.725-0.106-1.231-0.011
					c-0.5,0.077-1.047,0.175-1.617,0.298c-2.285,0.483-4.051,1.222-4.114,1.082c-0.064-0.117,1.648-1.091,4.005-1.591
					c0.585-0.126,1.15-0.212,1.667-0.264c0.498-0.07,1.054-0.068,1.445,0.153c0.396,0.213,0.579,0.539,0.655,0.75
					C255.086,110.851,255.084,110.979,255.055,110.989z'
									/>
								</g>
							</g>
							<g>
								<path
									fill='#FFBF9D'
									d='M192.509,168.711c0.227,0.341,1.816,2.271,2.157,4.2c0.341,1.93,4.663,27.01,5.79,30.493
				c1.409,4.354,4.317,13.222,15.893,9.895c8.014-2.303,8.287-14.505,2.838-21.203c-5.449-6.698-14.531-25.883-15.212-28.381
				c-0.681-2.497-7.152-10.035-14.645-11.999c-7.492-1.964-11.239-2.042-13.963-5.635c-2.725-3.594-6.13-5.41-7.72-2.686
				c-1.589,2.725,7.72,10.898,7.72,10.898l13.055,12.488L192.509,168.711z'
								/>
								<path
									fill='#FFBF9D'
									d='M167.465,153.788c0,0-5.766-7.049-9.522-4.085c0,0,4.875,6.522,6.522,8.301
				c1.647,1.779,5.93,3.031,5.93,3.031s4.282,4.941,6.588,5.6c2.306,0.659,3.755,0,3.755,0s0.066,1.12,3.689,1.647
				s13.243-0.461,13.243-0.461l5.939-4.977c0,0-2.974-4.972-7.52-5.104c-4.546-0.132-7.906,3.36-7.906,3.36l-3.953-0.066
				l-2.438-3.162c0,0,0.465-8.307-4.48-7.379c0,0-0.177-2.661-2.285-2.925c-2.108-0.263-2.697,1.913-2.697,1.913
				s0.436-3.271-1.343-3.93c-1.779-0.659-2.635,0.132-3.162,1.054C167.299,147.529,167.465,153.788,167.465,153.788z'
								/>
								<g>
									<path
										fill='#FF9A6C'
										d='M181.2,166.634c-0.056,0.043-0.704-0.68-1.655-1.927c-0.472-0.626-1.026-1.378-1.587-2.258
					c-0.278-0.41-0.58-0.973-0.687-1.545c-0.132-0.568-0.204-1.155-0.259-1.756c-0.19-2.404-0.09-4.57-0.001-6.134
					c0.099-1.564,0.233-2.526,0.303-2.521c0.073,0.005,0.07,0.972,0.065,2.533c0.004,1.559-0.035,3.721,0.151,6.076
					c0.054,0.586,0.122,1.157,0.238,1.687c0.101,0.543,0.316,0.952,0.598,1.407c0.528,0.877,1.048,1.647,1.479,2.296
					C180.714,165.788,181.257,166.59,181.2,166.634z'
									/>
								</g>
								<g>
									<path
										fill='#FF9A6C'
										d='M172.335,163.105c-0.046,0.039-0.512-0.646-0.729-1.901c-0.239-1.244-0.314-2.987-0.225-4.893
					c0.191-3.817,0.809-6.853,0.95-6.829c0.143,0.021-0.241,3.076-0.43,6.852c-0.088,1.886-0.071,3.595,0.069,4.813
					C172.088,162.373,172.41,163.063,172.335,163.105z'
									/>
								</g>
								<g>
									<path
										fill='#FF9A6C'
										d='M170.705,161.384c-0.051,0.048-0.65-0.477-1.516-1.432c-0.215-0.24-0.446-0.507-0.685-0.805
					c-0.23-0.32-0.503-0.573-0.689-1.072c-0.255-0.866-0.331-1.795-0.409-2.777c-0.13-1.961-0.118-3.733-0.083-5.015
					c0.04-1.282,0.117-2.074,0.188-2.073c0.157,0,0.18,3.171,0.414,7.052c0.074,0.961,0.146,1.9,0.347,2.666
					c0.088,0.311,0.378,0.665,0.576,0.958c0.214,0.285,0.421,0.56,0.623,0.829C170.256,160.72,170.759,161.333,170.705,161.384z'
									/>
								</g>
								<g>
									<path
										fill='#FF9A6C'
										d='M188.184,161.101c-0.001,0.032-0.37,0.057-1.049,0.074c-0.779,0.008-1.747,0.017-2.907,0.029
					l-0.077,0.001l-0.054-0.066c-0.739-0.913-1.853-1.977-2.584-3.52l-0.023-0.048l0-0.054c0.003-1.183,0.034-2.481-0.322-3.758
					c-0.18-0.634-0.46-1.26-0.88-1.793c-0.415-0.549-0.973-0.937-1.659-1.017c-0.427-0.055-0.87-0.005-1.294,0.101l-0.304,0.076
					l-0.019-0.313c-0.04-0.64-0.173-1.265-0.489-1.764c-0.306-0.504-0.792-0.849-1.334-1.032c-0.501-0.197-1.047-0.045-1.497,0.239
					c-0.451,0.284-0.821,0.684-1.068,1.114l-0.374,0.651l-0.039-0.744c-0.06-1.14-0.134-2.239-0.723-2.888
					c-0.594-0.642-1.469-0.756-2.088-0.657c-0.643,0.098-1.085,0.399-1.337,0.636c-0.253,0.242-0.347,0.414-0.374,0.398
					c-0.014-0.005,0.039-0.202,0.277-0.491c0.236-0.282,0.691-0.648,1.388-0.795c0.67-0.143,1.653-0.08,2.397,0.663
					c0.735,0.781,0.811,1.97,0.901,3.106l-0.413-0.093c0.277-0.503,0.68-0.956,1.202-1.298c0.503-0.338,1.234-0.563,1.909-0.309
					c0.623,0.2,1.234,0.62,1.606,1.228c0.383,0.6,0.53,1.31,0.575,2.006l-0.323-0.237c0.469-0.118,0.975-0.181,1.489-0.114
					c0.821,0.082,1.559,0.611,1.998,1.214c0.471,0.603,0.77,1.293,0.958,1.977c0.371,1.383,0.312,2.717,0.289,3.9l-0.023-0.102
					c0.673,1.49,1.709,2.531,2.445,3.511l-0.13-0.065c1.159,0.05,2.127,0.092,2.904,0.126
					C187.816,161.032,188.184,161.069,188.184,161.101z'
									/>
								</g>
								<g>
									<path
										fill='#FF9A6C'
										d='M198.555,157.94c-0.031,0.161-2.261-0.561-4.959,0.08c-2.71,0.592-4.408,2.209-4.505,2.076
					c-0.043-0.042,0.313-0.507,1.064-1.073c0.746-0.566,1.918-1.188,3.325-1.512c1.408-0.318,2.734-0.265,3.651-0.078
					C198.054,157.618,198.576,157.883,198.555,157.94z'
									/>
								</g>
							</g>
							<g>
								<path
									fill='#FF9A6C'
									d='M339.205,150.869c-0.536-0.255-1.17-0.115-1.739,0.054c-2.628,0.781-5.075,2.1-7.309,3.688
				l1.931,6.653c0.749-1.443,4.24-3.326,5.465-4.396c1.224-1.07,2.407-2.396,2.544-4.017
				C340.162,152.082,339.902,151.2,339.205,150.869z'
								/>
								<path
									fill='#FFBF9D'
									d='M296.439,207.952c0,0-0.971-8.02-0.128-14.815c0.549-4.422,4.215-17.498,4.47-21.585
				c0.255-4.087,0.383-6.003,2.938-7.919c2.554-1.916,2.554-1.022,6.131-3.193c3.576-2.171,4.342-3.704,9.707-3.959
				c5.364-0.255,9.579,11.239,9.579,11.239s-0.255,2.427-1.277,3.065c-1.022,0.639-2.299,0.766-5.492,0.639
				s-6.386,0.639-6.642,2.554s0.128,8.94,1.405,13.666c1.277,4.726,0.255,10.218-0.639,14.816
				c-0.894,4.598-2.737,14.636-10.09,15.454C297.768,218.874,296.439,207.952,296.439,207.952z'
								/>
								<path
									fill='#FFBF9D'
									d='M309.513,165.581c0,0,3.967-0.402,4.642-1.179c0.675-0.777,2.872-8.432,4.124-10.179
				c1.252-1.747,2.096-2.096,3.452-1.811c0,0-0.181-3.428,2.915-3.924c0,0,1.935-0.298,2.242,4.347c0,0-0.332-4.422,2.954-5.932
				c0.804-0.369,1.771-0.089,2.163,0.704c0.083,0.169,0.15,0.356,0.189,0.562c0.198,1.064,0.462,5.977,0.462,5.977
				s0.36,6.7,0.456,6.409c0.096-0.291,4.127-9.308,4.705-10.181c0.511-0.772,0.945-1.62,2.848-1.478
				c0.625,0.047,1.161,0.462,1.35,1.059c0.061,0.194-0.107,1.167-0.172,1.432c-0.629,2.557-2.339,9.725-3.013,10.889
				c-0.673,1.164-6.844,7.665-9.36,7.875c-2.516,0.21-3.969,0.015-3.969,0.015L309.513,165.581z'
								/>
								<g>
									<path
										fill='#FF9A6C'
										d='M317.719,168.946c-0.058-0.051,0.583-0.793,1.41-2.122c0.403-0.666,0.868-1.479,1.196-2.44
					c0.174-0.48,0.28-0.992,0.338-1.538c0.043-0.57,0.086-1.154,0.131-1.753c0.187-2.402,0.385-4.574,0.555-6.145
					c0.169-1.571,0.31-2.54,0.382-2.535c0.072,0.005,0.061,0.984-0.015,2.564c-0.076,1.58-0.216,3.76-0.404,6.167
					c-0.048,0.6-0.096,1.185-0.142,1.755c-0.068,0.582-0.198,1.149-0.394,1.648c-0.374,1.01-0.888,1.825-1.34,2.482
					C318.511,168.333,317.766,168.992,317.719,168.946z'
									/>
								</g>
								<g>
									<path
										fill='#FF9A6C'
										d='M326.852,152.393c0.072-0.001,0.155,0.96,0.232,2.514c0.077,1.554,0.147,3.7,0.192,6.072
					c0.009,0.592,0.019,1.169,0.028,1.732c-0.008,0.547,0.06,1.106-0.07,1.667c-0.263,1.059-0.753,1.869-1.159,2.54
					c-0.839,1.329-1.5,2.043-1.552,2.003c-0.063-0.046,0.491-0.837,1.233-2.189c0.358-0.674,0.8-1.502,1.008-2.453
					c0.1-0.449,0.032-0.982,0.03-1.55c-0.012-0.563-0.025-1.141-0.038-1.733c-0.045-2.374-0.057-4.525-0.039-6.082
					C326.733,153.358,326.78,152.395,326.852,152.393z'
									/>
								</g>
								<g>
									<path
										fill='#FF9A6C'
										d='M333.004,159.92c0.063-0.032,0.33,0.436,0.351,1.267c0.005,0.416-0.033,0.899-0.224,1.439
					c-0.197,0.513-0.474,1.025-0.784,1.55c-1.256,2.085-2.519,3.591-2.629,3.512c-0.125-0.087,0.947-1.727,2.181-3.777
					c0.304-0.513,0.586-1.009,0.786-1.462c0.183-0.437,0.266-0.903,0.302-1.274C333.059,160.416,332.935,159.944,333.004,159.92z'
									/>
								</g>
								<g>
									<path
										fill='#FF9A6C'
										d='M305.666,165.537c0.003-0.224,3.833,0.439,8.196-1.311c0.071-0.024,0.141-0.048,0.207-0.07
					l-0.158,0.153c0.815-2.127,1.575-4.033,2.148-5.401c0.573-1.368,0.959-2.197,1.025-2.172c0.066,0.025-0.2,0.901-0.685,2.302
					s-1.19,3.329-2.001,5.457l-0.044,0.114l-0.114,0.039c-0.067,0.023-0.14,0.047-0.212,0.072c-1.112,0.368-2.183,0.677-3.185,0.885
					c-1.004,0.224-1.948,0.238-2.71,0.217C306.595,165.772,305.66,165.604,305.666,165.537z'
									/>
								</g>
							</g>
						</g>
					</g>
				</svg>
				{/* Text */}
				<Box
					display='flex'
					flexDirection='column'
					rowGap='15px'
					justifyContent='center'>
					<Typography
						component='h1'
						sx={{ fontSize: '36px!important', textTransform: 'uppercase' }}>
						{title}
					</Typography>
					<Typography component='p' sx={{ fontSize: '16px!important' }}>
						{description}
					</Typography>
				</Box>
			</Box>
		</>
	)
}

export default EmptyDisplay
